/* eslint-disable */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import ResetPassword from '../../component/ResetPassword';
import { getKey } from '../../util/storage';
import { Wrapper } from './style';

const ResetPasswordPage = (props) => {
    useEffect(() => {
        const session = getKey('session');
        if (session === '' || session === undefined || session === null) {
            props.history.push('/');
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Wrapper>
            <ResetPassword pageProps={props} />
        </Wrapper>
    );
};

export default withRouter(ResetPasswordPage);
