import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import { toggleTheme } from '../../../actions/theme';
import { Button, LightThemeIcon, DarkThemeIcon } from './styles';

const ThemeToggle = () => {
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.theme);

    useEffect(() => {
        if (!theme.currentMode) {
            dispatch(toggleTheme(window.localStorage.getItem('theme')));
        }
    }, [theme.currentMode, dispatch]);

    const themeChange = () => {
        const value = theme.currentMode === 'light' ? 'dark' : 'light';
        window.localStorage.setItem('theme', value);
        dispatch(toggleTheme(value));
    };

    return (
        <>
            <Tooltip placement="bottomRight" title="Toggle light/dark theme">
                <Button onClick={() => themeChange()}>
                    {theme.currentMode === 'dark' ? (
                        <LightThemeIcon />
                    ) : (
                        <DarkThemeIcon />
                    )}
                </Button>
            </Tooltip>
        </>
    );
};

export default ThemeToggle;
