import React, {useState} from 'react'
import { Title } from './style'
import { useRef } from 'react'

export default function Input(props) {
    const {title, filters, onChange, id, type = ''} = props
    const val = useRef(0);
    const handleChange = (value) => {
        val.current = value;
        const obj = {...filters}
        obj[id] = value;
        if(type == 'number' && (val.current.length > 9 || val.current.length == 0)){
            onChange(obj)
        }
    }
    return (
        <div>
            <Title>{title}</Title>
            <div style={{backgroundColor: 'white', width: '100%', borderRadius: 5, padding: '0.5rem'}}>
                <input
                    placeholder={title}
                    type={type}
                    onChange={(e)=>{handleChange(e.target.value)}}
                />
            </div>
        </div>
    )
}