
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Grid } from '@material-ui/core';
import { Select } from 'antd';
import CircularProgress from '@material-ui/core/CircularProgress';
import { size, trim, toString } from 'lodash';
import { CommentReplyWrapper, StyledButton } from './styles';
import { CreateCommentApi } from '../../../api/createComment';
import { buID } from '../../../constant';
import MentionInput from '../../MentionInput';
import { getTaggedUsers } from '../../../util/mention';

const { Option } = Select;

const CommentReplyBox = (props) => {
    const {
        authorList,
        postID,
        getComments,
        parentCommentID,
        getPostDetails,
        commentReplyInitialContent,
    } = props;

    const [loading, setLoading] = useState(false);
    const [commentReplyContent, setCommentReplyContent] = useState(commentReplyInitialContent);
    const [currentAuthorID, setCurrentAuthorID] = useState();

    const handleAuthorSelectChange = (value) => {
        setCurrentAuthorID(value);
    };

    const createAuthorListOptions = (authorArr) => {
        const optionArray = [];

        authorArr.map((author) => {
            optionArray.push(
                <Option key={author.id} value={author.id}>
                    {author.display_name}
                </Option>,
            );
            return 0;
        });

        return optionArray;
    };

    const CreateCommentReply = () => {
        const params = {
            post_id: postID,
            content: commentReplyContent,
            buid: toString(buID),
            user_id: currentAuthorID,
            parent_comment_id: parentCommentID,
            tagged_users: getTaggedUsers(commentReplyContent),
        };

        setLoading(true);
        CreateCommentApi(params)
            .then((res) => {
                if (getPostDetails) {
                    getPostDetails();
                }
                getComments();
                setCommentReplyContent('');
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        swal('success!', 'Comment Successfully', 'success');
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Comment Failed';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('OOPS!', errorMessage, 'error');
            });
    };

    const handleCreateCommentSubmit = (event) => {
        event.preventDefault();
        CreateCommentReply();
    };

    return (
        <CommentReplyWrapper>
            <form onSubmit={(e) => handleCreateCommentSubmit(e)}>
                <Grid xs={12} sm={12} lg={12} md={12}>
                    <MentionInput
                        value={commentReplyContent}
                        placeholder="Write your comment here"
                        onChange={(e) => setCommentReplyContent(e.target.value)}
                        style={{ color: 'black' }}
                    />
                </Grid>
                <Grid
                    container
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Grid xs={6} sm={6} lg={6} style={{ padding: '3px' }}>
                        <Grid container>
                            <Grid xs={4} sm={4} lg={4}>
                                <label>Comment as:</label>
                            </Grid>
                            <Grid xs={8} sm={8} lg={8}>
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={handleAuthorSelectChange}
                                >
                                    {authorList
                                        && createAuthorListOptions(authorList)}
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={6} style={{ padding: '3px' }}>
                        <StyledButton
                            disabled={
                                loading
                                || !size(currentAuthorID)
                                || !size(trim(commentReplyContent))
                            }
                            type="submit"
                            onClick={(e) => handleCreateCommentSubmit(e)}
                        >
                            {loading ? (
                                <CircularProgress color="white" size={20} />
                            ) : (
                                'Submit'
                            )}
                        </StyledButton>
                    </Grid>
                </Grid>
            </form>
        </CommentReplyWrapper>
    );
};

CommentReplyBox.propTypes = {
    parentCommentID: PropTypes.number,
    postID: PropTypes.string,
    getComments: PropTypes.func.isRequired,
    getPostDetails: PropTypes.func.isRequired,
    authorList: PropTypes.arrayOf(PropTypes.shape({})),
    commentReplyInitialContent: PropTypes.string,
};

CommentReplyBox.defaultProps = {
    postID: '',
    parentCommentID: '',
    authorList: [],
    commentReplyInitialContent: '',
};

export default CommentReplyBox;
