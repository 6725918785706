import React from "react";
import { withRouter } from "react-router-dom";
import { Wrapper } from "./styles";
import GeneralUpdate from "../../component/GeneralUpdate";

const GeneralUpdatePage = () => (
  <Wrapper>
    <GeneralUpdate />
  </Wrapper>
);

export default withRouter(GeneralUpdatePage);
