import { SWITCH_THEME } from '../actions/types';

// eslint-disable-next-line
export default function (state = {}, action) {
    switch (action.type) {
        case SWITCH_THEME:
            return { ...state, currentMode: action.payload };
        default:
            return state;
    }
}
