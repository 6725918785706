/* eslint-disable */
/* eslint-disable comma-dangle */
/* eslint-disable no-shadow */
/* eslint-disable operator-linebreak */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import { ReactComponent as Cartoon } from '../../assets/img/Cartoon2.svg';
import validateAuth from '../../util/validateAuth';
import { loginAuth } from '../../actions/auth';
// eslint-disable-next-line import/no-unresolved
import './style.css';

import { getKey } from '../../util/storage';

const initialState = {
    email: '',
    password: '',
};
const Login = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    const { isLoading, action = '' } = auth;
    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState({});

    const handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validateAuth(values);
        setErrors(validationErrors);
        if (isEmpty(validationErrors)) {
            dispatch(loginAuth(values.email, values.password));
        }
    };

    useEffect(() => {
        const session = getKey('session');
        if (action === 'reset-password' && !isEmpty(session)) {
            history.push('/reset-password');
        }
    }, [action]);
    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const TweenMax = window.TweenMax || {};
    const Expo = window.Expo || {};
    const Quad = window.Quad || {};

    const emailRef = useRef(null);
    const mySVGRef = useRef(null);
    const [isFocse, setIsFoces] = useState(false);
    const armL = document.querySelector('.armL');
    const armR = document.querySelector('.armR');
    // const armLL = document.querySelector('#armL');
    // let armRR = document.querySelector("#armR");
    const eyeL = document.querySelector('.eyeL');
    const eyeR = document.querySelector('.eyeR');
    const nose = document.querySelector('.nose');
    const mouth = document.querySelector('.mouth');
    const mouthBG = document.querySelector('.mouthBG');
    // let mouthSmallBG = document.querySelector(".mouthSmallBG");
    // let mouthMediumBG = document.querySelector(".mouthMediumBG");
    // let mouthLargeBG = document.querySelector(".mouthLargeBG");
    const mouthMaskPath = document.querySelector('#mouthMaskPath');
    const mouthOutline = document.querySelector('.mouthOutline');
    const tooth = document.querySelector('.tooth');
    const tongue = document.querySelector('.tongue');
    const chin = document.querySelector('.chin');
    const face = document.querySelector('.face');
    const eyebrow = document.querySelector('.eyebrow');
    const outerEarL = document.querySelector('.earL .outerEar');
    const outerEarR = document.querySelector('.earR .outerEar');
    const earHairL = document.querySelector('.earL .earHair');
    const earHairR = document.querySelector('.earR .earHair');
    const hair = document.querySelector('.hair');

    let caretPos;
    let curEmailIndex;
    let screenCenter;
    let svgCoords;
    const eyeMaxHorizD = 20;
    const eyeMaxVertD = 10;
    const noseMaxHorizD = 23;
    const noseMaxVertD = 10;
    let dFromC;
    let eyeDistH;
    // eyeLDistV,
    // eyeRDistV,
    // eyeDistR,
    let mouthStatus = 'small';

    // useEffect(() => {
    //     let email = document.querySelector("#login__email");
    //     let password = document.querySelector("#login__password");
    // }, [email]);

    function getPosition(el) {
        let xPos = 0;
        let yPos = 0;

        while (el) {
            if (el.tagName === 'BODY') {
                // deal with browser quirks with body/window/document and page scroll
                const xScroll =
                    el.scrollLeft || document.documentElement.scrollLeft;
                const yScroll =
                    el.scrollTop || document.documentElement.scrollTop;

                xPos += el.offsetLeft - xScroll + el.clientLeft;
                yPos += el.offsetTop - yScroll + el.clientTop;
            } else {
                // for all other non-BODY elements
                xPos += el.offsetLeft - el.scrollLeft + el.clientLeft;
                yPos += el.offsetTop - el.scrollTop + el.clientTop;
            }

            // eslint-disable-next-line no-param-reassign
            el = el.offsetParent;
        }
        return {
            x: xPos,
            y: yPos,
        };
    }

    function coverEyes() {
        TweenMax.to(armL, 0.45, {
            x: -93,
            y: 2,
            rotation: 0,
            ease: Quad.easeOut,
        });
        TweenMax.to(armR, 0.45, {
            x: -93,
            y: 2,
            rotation: 0,
            ease: Quad.easeOut,
            delay: 0.1,
        });
    }

    function uncoverEyes() {
        TweenMax.to(armL, 1.35, {
            y: 220,
            ease: Quad.easeOut,
        });
        TweenMax.to(armL, 1.35, {
            rotation: 105,
            ease: Quad.easeOut,
            delay: 0.1,
        });
        TweenMax.to(armR, 1.35, {
            y: 220,
            ease: Quad.easeOut,
        });
        TweenMax.to(armR, 1.35, {
            rotation: -105,
            ease: Quad.easeOut,
            delay: 0.1,
        });
    }

    function resetFace() {
        TweenMax.to([eyeL, eyeR], 1, {
            x: 0,
            y: 0,
            ease: Expo.easeOut,
        });
        TweenMax.to(nose, 1, {
            x: 0,
            y: 0,
            scaleX: 1,
            scaleY: 1,
            ease: Expo.easeOut,
        });
        TweenMax.to(mouth, 1, {
            x: 0,
            y: 0,
            rotation: 0,
            ease: Expo.easeOut,
        });
        TweenMax.to(chin, 1, {
            x: 0,
            y: 0,
            scaleY: 1,
            ease: Expo.easeOut,
        });
        TweenMax.to([face, eyebrow], 1, {
            x: 0,
            y: 0,
            skewX: 0,
            ease: Expo.easeOut,
        });
        TweenMax.to([outerEarL, outerEarR, earHairL, earHairR, hair], 1, {
            x: 0,
            y: 0,
            scaleY: 1,
            ease: Expo.easeOut,
        });
    }

    function getAngle(x1, y1, x2, y2) {
        const angle = Math.atan2(y1 - y2, x1 - x2);
        return angle;
    }

    function getCoord() {
        const email = emailRef.current;
        const mySVG = mySVGRef.current;
        const carPos = email.selectionEnd;
        const div = document.createElement('div');
        const span = document.createElement('span');
        const copyStyle = window.getComputedStyle(email);
        let emailCoords = {};
        let caretCoords = {};
        let centerCoords = {};
        [].forEach.call(copyStyle, (prop) => {
            div.style[prop] = copyStyle[prop];
        });
        div.style.position = 'absolute';
        document.body.appendChild(div);
        div.textContent = email.value.substr(0, carPos);
        span.textContent = email.value.substr(carPos) || '.';
        div.appendChild(span);

        emailCoords = getPosition(email);
        caretCoords = getPosition(span);
        centerCoords = getPosition(mySVG);
        svgCoords = getPosition(mySVG);
        screenCenter = centerCoords.x + mySVG.offsetWidth / 2;
        caretPos = caretCoords.x + emailCoords.x;

        dFromC = screenCenter - caretPos;
        let pFromC = Math.round((caretPos / screenCenter) * 100) / 100;
        // eslint-disable-next-line no-empty
        if (pFromC < 1) {
        } else if (pFromC > 1) {
            pFromC -= 2;
            pFromC = Math.abs(pFromC);
        }

        eyeDistH = -dFromC * 0.05;
        if (eyeDistH > eyeMaxHorizD) {
            eyeDistH = eyeMaxHorizD;
        } else if (eyeDistH < -eyeMaxHorizD) {
            eyeDistH = -eyeMaxHorizD;
        }

        const eyeLCoords = {
            x: svgCoords.x + 84,
            y: svgCoords.y + 76,
        };
        const eyeRCoords = {
            x: svgCoords.x + 113,
            y: svgCoords.y + 76,
        };
        const noseCoords = {
            x: svgCoords.x + 97,
            y: svgCoords.y + 81,
        };
        const mouthCoords = {
            x: svgCoords.x + 100,
            y: svgCoords.y + 100,
        };
        const eyeLAngle = getAngle(
            eyeLCoords.x,
            eyeLCoords.y,
            emailCoords.x + caretCoords.x,
            emailCoords.y + 25
        );
        const eyeLX = Math.cos(eyeLAngle) * eyeMaxHorizD;
        const eyeLY = Math.sin(eyeLAngle) * eyeMaxVertD;
        const eyeRAngle = getAngle(
            eyeRCoords.x,
            eyeRCoords.y,
            emailCoords.x + caretCoords.x,
            emailCoords.y + 25
        );
        const eyeRX = Math.cos(eyeRAngle) * eyeMaxHorizD;
        const eyeRY = Math.sin(eyeRAngle) * eyeMaxVertD;
        const noseAngle = getAngle(
            noseCoords.x,
            noseCoords.y,
            emailCoords.x + caretCoords.x,
            emailCoords.y + 25
        );
        const noseX = Math.cos(noseAngle) * noseMaxHorizD;
        const noseY = Math.sin(noseAngle) * noseMaxVertD;
        const mouthAngle = getAngle(
            mouthCoords.x,
            mouthCoords.y,
            emailCoords.x + caretCoords.x,
            emailCoords.y + 25
        );
        const mouthX = Math.cos(mouthAngle) * noseMaxHorizD;
        const mouthY = Math.sin(mouthAngle) * noseMaxVertD;
        const mouthR = Math.cos(mouthAngle) * 6;
        const chinX = mouthX * 0.8;
        const chinY = mouthY * 0.5;
        let chinS = 1 - (dFromC * 0.15) / 100;
        if (chinS > 1) {
            chinS = 1 - (chinS - 1);
        }
        const faceX = mouthX * 0.3;
        const faceY = mouthY * 0.4;
        const faceSkew = Math.cos(mouthAngle) * 5;
        const eyebrowSkew = Math.cos(mouthAngle) * 25;
        const outerEarX = Math.cos(mouthAngle) * 4;
        const outerEarY = Math.cos(mouthAngle) * 5;
        const hairX = Math.cos(mouthAngle) * 6;
        const hairS = 1.2;
        TweenMax.to(eyeL, 1, {
            x: -eyeLX,
            y: -eyeLY,
            ease: Expo.easeOut,
        });
        TweenMax.to(eyeR, 1, {
            x: -eyeRX,
            y: -eyeRY,
            ease: Expo.easeOut,
        });
        TweenMax.to(nose, 1, {
            x: -noseX,
            y: -noseY,
            rotation: mouthR,
            transformOrigin: 'center center',
            ease: Expo.easeOut,
        });
        TweenMax.to(mouth, 1, {
            x: -mouthX,
            y: -mouthY,
            rotation: mouthR,
            transformOrigin: 'center center',
            ease: Expo.easeOut,
        });
        TweenMax.to(chin, 1, {
            x: -chinX,
            y: -chinY,
            scaleY: chinS,
            ease: Expo.easeOut,
        });
        TweenMax.to(face, 1, {
            x: -faceX,
            y: -faceY,
            skewX: -faceSkew,
            transformOrigin: 'center top',
            ease: Expo.easeOut,
        });
        TweenMax.to(eyebrow, 1, {
            x: -faceX,
            y: -faceY,
            skewX: -eyebrowSkew,
            transformOrigin: 'center top',
            ease: Expo.easeOut,
        });
        TweenMax.to(outerEarL, 1, {
            x: outerEarX,
            y: -outerEarY,
            ease: Expo.easeOut,
        });
        TweenMax.to(outerEarR, 1, {
            x: outerEarX,
            y: outerEarY,
            ease: Expo.easeOut,
        });
        TweenMax.to(earHairL, 1, {
            x: -outerEarX,
            y: -outerEarY,
            ease: Expo.easeOut,
        });
        TweenMax.to(earHairR, 1, {
            x: -outerEarX,
            y: outerEarY,
            ease: Expo.easeOut,
        });
        TweenMax.to(hair, 1, {
            x: hairX,
            scaleY: hairS,
            transformOrigin: 'center bottom',
            ease: Expo.easeOut,
        });

        document.body.removeChild(div);
    }

    function onEmailInput(e) {
        getCoord(e);
        const { value } = e.target;
        // setEmail(value);
        setValues({
            ...values,
            [e.target.name]: e.target.value,
        });
        curEmailIndex = value.length;

        // very crude email validation for now to trigger effects
        if (curEmailIndex > 0) {
            if (mouthStatus === 'small') {
                mouthStatus = 'medium';
                TweenMax.to([mouthBG, mouthOutline, mouthMaskPath], 1, {
                    shapeIndex: 8,
                    ease: Expo.easeOut,
                });
                TweenMax.to(tooth, 1, {
                    x: 0,
                    y: 0,
                    ease: Expo.easeOut,
                });
                TweenMax.to(tongue, 1, {
                    x: 0,
                    y: 1,
                    ease: Expo.easeOut,
                });
                TweenMax.to([eyeL, eyeR], 1, {
                    scaleX: 0.85,
                    scaleY: 0.85,
                    ease: Expo.easeOut,
                });
            }
            if (value.includes('@')) {
                mouthStatus = 'large';
                TweenMax.to([mouthBG, mouthOutline, mouthMaskPath], 1, {
                    ease: Expo.easeOut,
                });
                TweenMax.to(tooth, 1, {
                    x: 3,
                    y: -2,
                    ease: Expo.easeOut,
                });
                TweenMax.to(tongue, 1, {
                    y: 2,
                    ease: Expo.easeOut,
                });
                TweenMax.to([eyeL, eyeR], 1, {
                    scaleX: 0.65,
                    scaleY: 0.65,
                    ease: Expo.easeOut,
                    transformOrigin: 'center center',
                });
            } else {
                mouthStatus = 'medium';
                TweenMax.to([mouthBG, mouthOutline, mouthMaskPath], 1, {
                    ease: Expo.easeOut,
                });
                TweenMax.to(tooth, 1, {
                    x: 0,
                    y: 0,
                    ease: Expo.easeOut,
                });
                TweenMax.to(tongue, 1, {
                    x: 0,
                    y: 1,
                    ease: Expo.easeOut,
                });
                TweenMax.to([eyeL, eyeR], 1, {
                    scaleX: 0.85,
                    scaleY: 0.85,
                    ease: Expo.easeOut,
                });
            }
        } else {
            mouthStatus = 'small';
            TweenMax.to([mouthBG, mouthOutline, mouthMaskPath], 1, {
                shapeIndex: 9,
                ease: Expo.easeOut,
            });
            TweenMax.to(tooth, 1, {
                x: 0,
                y: 0,
                ease: Expo.easeOut,
            });
            TweenMax.to(tongue, 1, {
                y: 0,
                ease: Expo.easeOut,
            });
            TweenMax.to([eyeL, eyeR], 1, {
                scaleX: 1,
                scaleY: 1,
                ease: Expo.easeOut,
            });
        }
    }

    function onEmailFocus(event) {
        handleChange(event);
        setIsFoces(true);
        // e.target.parentElement.classList.add("focusWithText");
        getCoord();
    }

    useEffect(() => {
        setIsFoces(true);
    },[]);

    function onEmailBlur(event) {
        handleChange(event);
        // const validationErrors = validateAuth(values);
        // setErrors(validationErrors);
        setIsFoces(false);
        // if (e.target.value == "") {
        //     e.target.parentElement.classList.remove("focusWithText");
        // }
        resetFace();
    }

    function onPasswordFocus() {
        coverEyes();
    }

    function onPasswordBlur() {
        // const validationErrors = validateAuth(values);
        // setErrors(validationErrors);
        uncoverEyes();
    }

    // useEffect(() => {
    //     TweenMax.set(armL, {
    //         x: -93,
    //         y: 220,
    //         rotation: 105,
    //         transformOrigin: "top left",
    //     });
    //     TweenMax.set(armR, {
    //         x: -93,
    //         y: 220,
    //         rotation: -105,
    //         transformOrigin: "top right",
    //     });
    // });

    function armsRemove() {
        const armL1 = document.querySelector('.armL');
        const armR1 = document.querySelector('.armR');
        const armLL = document.querySelector('#armL');
        if (armLL && armL1) {
            TweenMax.set(armL1, {
                x: -93,
                y: 220,
                rotation: 105,
                transformOrigin: 'top left',
            });
            TweenMax.set(armR1, {
                x: -93,
                y: 220,
                rotation: -105,
                transformOrigin: 'top right',
            });
        }
        return null;
    }

    useEffect(() => (mySVGRef.current ? armsRemove() : {}), [mySVGRef.current]);

    // useEffect(() => armsRemove(), []);
    // useEffect(armsRemove());
    // useEffect(async (e) => {
    //   //   debugger;
    //   if (armL && armR) {
    //     await armsRemove(e);

    //   }
    // });

    // TweenMax.set(armL, {
    //     x: -93,
    //     y: 220,
    //     rotation: 105,
    //     transformOrigin: "top left",
    // });
    // TweenMax.set(armR, {
    //     x: -93,
    //     y: 220,
    //     rotation: -105,
    //     transformOrigin: "top right",
    // });

    return (
        <>
            {/* <Overlay /> */}
            {/* <LoginContainer>const [email1, setEmail] = useState("hello");
                <CartoonContainer>
                    <img src={Cartoon} alt="sign in" />
                </CartoonContainer> */}
            <div className="overlay" />
            <div className="login-container">
                <div className="cartoon-container" ref={mySVGRef}>
                    {/* <button onClick={armsRemove}>remove</button> */}
                    {/* <img src={Cartoon} alt="Cartoon" /> */}
                    <Cartoon />
                </div>
                <form className="form login" autoComplete="off">
                    <div className="form__field form__field--email">
                        <label
                            className="label label--icon"
                            htmlFor="login__email"
                        >
                            <svg className="icon">
                                <use
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    xlinkHref="#user"
                                />
                            </svg>
                            {/* <Email /> */}
                            <span className="hidden">Email</span>
                        </label>
                        <div
                            className={`form__input-wrapper ${
                                isFocse ? 'focusWithText' : ''
                            }`}
                        >
                            <input
                                id="login__email"
                                type="text"
                                name="email"
                                ref={emailRef}
                                className="form__input"
                                placeholder="Employee ID"
                                onFocus={(e) => onEmailFocus(e)}
                                onBlur={(e) => onEmailBlur(e)}
                                onChange={(e) => onEmailInput(e)}
                                required
                                autoComplete="new-password"
                            />
                            <div className="underline-wrapper">
                                <span className="underline" />
                            </div>
                        </div>
                    </div>
                    {errors.email && (
                        <p className="error-text">{errors.email}</p>
                    )}

                    <div className="form__field form__field--password">
                        <label
                            className="label label--icon"
                            htmlFor="login__password"
                        >
                            <svg className="icon">
                                <use
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    xlinkHref="#lock"
                                />
                            </svg>
                            <span className="hidden">Password</span>
                        </label>
                        <div className="form__input-wrapper">
                            <input
                                id="login__password"
                                type="password"
                                autoComplete="new-password"
                                name="password"
                                className="form__input"
                                placeholder="Password"
                                value={values.password}
                                onFocus={(e) => onPasswordFocus(e)}
                                onBlur={(e) => onPasswordBlur(e)}
                                onChange={handleChange}
                                required
                            />
                            <div className="underline-wrapper">
                                <span className="underline" />
                            </div>
                        </div>
                    </div>
                    {errors.password && (
                        <p className="error-text">{errors.password}</p>
                    )}
                    <div className="login-actions-container">
                        {/* <div className="form__field form__field--remember-me">
              <label
                className="label label--remember-me"
                htmlFor="login__remember-me"
              >
                <input id="login__remember-me" type="checkbox" />
                <span className="checkmark"></span>
                <span className="remember-me-text">Remember me</span>
              </label>
            </div> */}
                        <div className="form__field form__field--remember-me">
                            <NavLink to="/forgot-password">
                                <div className="forgot-password">
                                    Forgot Password?
                                </div>
                            </NavLink>
                            {/* <a className="forgot-password" href="/#">
                                Forgot Password?
                            </a> */}
                        </div>

                        <div className="form__field form__field--submit">
                            <input
                                type="submit"
                                value="log in"
                                onClick={handleSubmit}
                                disabled={isLoading}
                            />
                        </div>
                    </div>
                </form>

                <svg
                    width="0"
                    height="0"
                    xmlns="http://www.w3.org/2000/svg"
                    className="icons"
                >
                    <symbol id="lock" viewBox="0 0 1792 1792">
                        <path d="M640 768h512V576q0-106-75-181t-181-75-181 75-75 181v192zm832 96v576q0 40-28 68t-68 28H416q-40 0-68-28t-28-68V864q0-40 28-68t68-28h32V576q0-184 132-316t316-132 316 132 132 316v192h32q40 0 68 28t28 68z" />
                    </symbol>
                    <symbol id="user" viewBox="0 0 1792 1792">
                        <path d="M1600 1405q0 120-73 189.5t-194 69.5H459q-121 0-194-69.5T192 1405q0-53 3.5-103.5t14-109T236 1084t43-97.5 62-81 85.5-53.5T538 832q9 0 42 21.5t74.5 48 108 48T896 971t133.5-21.5 108-48 74.5-48 42-21.5q61 0 111.5 20t85.5 53.5 62 81 43 97.5 26.5 108.5 14 109 3.5 103.5zm-320-893q0 159-112.5 271.5T896 896 624.5 783.5 512 512t112.5-271.5T896 128t271.5 112.5T1280 512z" />
                    </symbol>
                </svg>
            </div>
            {/* </LoginContainer> */}
        </>
    );
};
export default Login;
