import styled from 'styled-components';


export const Wrapper = styled.div`
    padding-right: 0.1rem;
    padding-left: 0.1rem;
    min-height: 100vh;
    background-color: ${(props) => props.theme.background};
`;

export const Wrapper1 = styled.div`
    padding-right: 0.1rem;
    padding-left: 0.1rem;
    min-height: 100vh;
    background-color: ${(props) => props.theme.background};
`;
