import styled from 'styled-components';

export const Img = styled.img`
    display: block;
    margin: auto;
    height: 23rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
        height: 16rem;
    }

    @media (max-width: 425px) {
        height: 10rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
`;

export const ErrorTitleText = styled.div`
    display: block;
    margin: auto;
    font-size: 3rem;
    font-weight: 800;
    font-family: 'Titillium Web';
    text-align: center;
    margin-top: 0.5rem;

    @media (max-width: 768px) {
        font-size: 1.5rem;
    }
`;

export const ErrorDescriptionText = styled.div`
    display: block;
    margin: auto;
    font-size: 2rem;
    font-weight: 550;
    font-family: 'Titillium Web';
    text-align: center;
    margin-top: 0.5rem;

    @media (max-width: 768px) {
        font-size: 1rem;
    }
`;

export const ErrorFooterText = styled.div`
    display: block;
    margin: auto;
    font-size: 1.1rem;
    font-weight: 550;
    font-family: 'Titillium Web';
    text-align: center;
    margin-top: 0.5rem;

    @media (max-width: 768px) {
        font-size: 0.5rem;
    }
`;
