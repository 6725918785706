/* eslint-disable */
import styled from 'styled-components';
import { Skeleton } from 'antd';

export const Wrapper = styled.div`
    margin: 1rem auto;
`;

export const ImageWrapper = styled.img`
    display: block;
    max-width: 100%;
    cursor: pointer;
    object-fit: fill;
    object-position: center;
    padding: 0 1.5rem;
    max-height: 20rem;
    margin: auto
`;

export const StlyedSkeleton = styled(Skeleton)`
    padding: 0.5rem 2rem;
`;

export const Title = styled.div`
    word-break: break-word;
    white-space: pre-line;
    color: ${(p) => p.theme.text};
    margin: 1rem 0;
`;

export const ContentWrapper = styled.div`
    color: ${(p) => p.theme.text};
    padding: 0.5rem 1.5rem;
`;