import React from 'react';
import { withRouter } from 'react-router-dom';
import Reported from '../../component/Reported';
import { Wrapper } from './styles';

const ReportedPage = () => (
    <Wrapper>
        <Reported />
    </Wrapper>
);

export default withRouter(ReportedPage);
