import React from "react";
import { withRouter } from "react-router-dom";
import { Wrapper } from "./styles";
import SipUpdate from "../../component/SipUpdate";

const SipUpdatePage = () => (
  <Wrapper>
    <SipUpdate />
  </Wrapper>
);

export default withRouter(SipUpdatePage);
