import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import DeleteIcon from '@material-ui/icons/Delete';

export const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            marginLeft: 0,
        },
    },
    input: {
        display: 'none',
    },
}));

export const PreviewImage = styled.img`
    max-width: 100%;
    display: block;
    max-height: 20rem;
    border-radius: 5px;
`;

export const ImageContainer = styled.div`
    display: block;
    margin-top: 3rem;
    position: relative;
`;

export const DeleteMedia = styled(DeleteIcon)`
    position: absolute;
    top: 0;
    right: 0px;    
    cursor: pointer;
`;
