/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Button as But } from "@material-ui/core";
import swal from "sweetalert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Modal, Button, Select, Input } from "antd";
import { isEmpty, truncate, size } from "lodash";
import { RNS3 } from "react-native-aws3";
import styled from "styled-components";
import CommonTable from "../Common/Table";
import { Button as ButtonUpload } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  FilterGrid,
  FilterTitleWrapper,
  StyledButton,
  StyledInput,
  LabelInput,
  ErrorText,
  IconContainer,
  StyledDiv,
  RightGridItem,
  LeftGridItem,
  StyledDateTimePicker,
  useStyles,
  ImageContainer,
  DeleteMedia,
} from "./styles";
import CommonLoader from "../Common/Loader";
import CommonDropdown from "../Common/Dropdown";
import CommonDatePicker from "../Common/DatePicker";
import Paginator from "../Common/Paginator";
import {
  formattedValue,
  snakeToUpperCase,
  isValidImageUploadObject,
  dateFormating,
} from "../../util/helper";
import {
  GetBannerListApi,
  CreateBannerApi,
  UpdateBannerApi,
  DeleteBannerApi,
} from "../../api/banner";
import {
  getBannerImageUrl,
  getImageWithBannerCreds,
  s3Config,
} from "../../util/custom";
import Image from "../Image";
import { PreviewImage } from "../Image/styles";
import {
  BulkCreateSIPUpdate,
  CreateSIPUpdate,
  DeleteSipUpdate,
  GetSipClientCode,
  GetSipUpdate,
  UpdateSipUpdate,
} from "../../api/getUpdateList";
import {
  FetchReferralMobileNumberList,
  FetchReferralReferreeId,
  FetchReferralRewrdNames,
  UpdateReferral,
  fetchReferralList,
} from "../../api/referral";

const StyleModal = styled(Modal)`
  .ant-modal-header {
    background: ${(props) => props.theme.dark};
    color: ${(props) => props.theme.dark};
  }

  .ant-modal-body {
    background: ${(props) => props.theme.dark};
  }

  .ant-modal-title {
    color: ${(props) => props.theme.text};
  }
  .ant-modal-footer {
    background: ${(props) => props.theme.dark};
  }
  .ant-modal-close-icon {
    color: ${(props) => props.theme.text};
  }

  .ant-modal .sc-AzgDb .jcGGxa {
    padding: 0px;
  }
`;

const Referral = () => {
  const auth = useSelector((state) => state.auth);
  const maxTitleCharacters = 25;
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [data, setData] = useState([]);
  // const [authorList, setAuthorList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(1);
  const { Option } = Select;
  const [redirectionParams, setRedirectionParams] = useState([
    { key: "", value: "" },
  ]);
  const [redirectionParams2, setRedirectionParams2] = useState([
    { key: "", value: "" },
  ]);
  const [filters, setFilters] = useState({
    page: currentPage,
    limit: pageSize,
    action: "",
    status: "",
    step: "",
    mobile_number: "",
    referree_id: "",
  });

  const defaultBanner = {
    client_code: "",
    client_name: "",
    sip_regn_nos: "",
    sub_broker_code: "",
    portfolio_name: "",
    portfolio_id: "",
    reg_dates: "",
    sip_amount: "",
    sip_due_date: "",
  };

  const defaultIsUpdatedObject = {
    startTime: false,
    endTime: false,
    banner_image: false,
  };

  const [currentBanner, setCurrentBanner] = useState(defaultBanner);
  const [errors, setErrors] = useState({});

  const [updateBanner, setUpdateBanner] = useState({});
  const [updateErrors, setUpdateErrors] = useState({});
  const [isUpdated, setIsUpdated] = useState(defaultIsUpdatedObject);
  const [client_code, setClientCodes] = useState([]);
  const [mobileNumberList, setMobileNumberList] = useState([]);
  const [rewardNames, setRewardNames] = useState([]);
  const [refereeIdList, setRefereeIdList] = useState([]);
  const getMobileNumber = () => {
    setLoading(true);
    FetchReferralMobileNumberList()
      .then((res) => {
        setLoading(false);
        const apiData = (res.data || {}).data || {};
        setMobileNumberList(apiData);
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to fetch data";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };
  const getRewardNames = () => {
    setLoading(true);
    FetchReferralRewrdNames()
      .then((res) => {
        setLoading(false);
        const apiData = (res.data || {}).data || {};
        setRewardNames(apiData);
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to fetch data";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  const getReferreeIdList = () => {
    setLoading(true);
    FetchReferralReferreeId()
      .then((res) => {
        setLoading(false);
        const apiData = (res.data || {}).data || {};
        setRefereeIdList(apiData);
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to fetch data";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  useEffect(() => {
    getMobileNumber();
    getRewardNames();
    getReferreeIdList();
  }, []);

  useEffect(() => {
    console.log(
      "🚀 ~ file: index.jsx:183 ~ SipUpdate ~ client_code:",
      client_code
    );
  }, [client_code]);
  const getReferralList = (params) => {
    setLoading(true);
    fetchReferralList(params)
      .then((res) => {
        console.log("🚀 ~ file: index.jsx:156 ~ .then ~ res:", res);
        let success = false;
        if (res.status === 200) {
          success = true;
          const apiData = res.data;
          console.log("🚀 ~ file: index.jsx:154 ~ .then ~ apiData:", apiData);
          setData(apiData.results);
          setCurrentPage(apiData.page);
          setPageSize(apiData.page_limit);
          setTotal(apiData.total_count);
          setFilters(params);
          setLoading(false);
        }
        if (!success) {
          setLoading(false);
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to fetch data";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  const updateReferralApi = (isActiveParams = null) => {
    const updateParams = isActiveParams;
    setLoading(true);
    UpdateReferral(updateParams)
      .then((res) => {
        setLoading(false);
        getReferralList(filters);
        swal("Yayy!", "Successfully Updated Referral", "success");
      })
      .catch((error) => {
        console.log(
          "🚀 ~ file: index.jsx:251 ~ updateReferralApi ~ error:",
          error
        );
        setLoading(false);
        let errorMessage = "Failed to Update Referral";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = error.response.data.error;
            swal("Oops!", errorMessage, "error");
          }
        }
      });
  };

  const tableColumns = [
    {
      title: "Mobile Number",
      dataIndex: "user_mobile_number",
      key: "user_mobile_number",
      width: 50,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Referred By",
      dataIndex: "user_name",
      key: "user_name",
      width: 70,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Reward Value",
      dataIndex: "reward_value",
      key: "reward_value",
      width: 50,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Step Reward Name",
      dataIndex: "reward_name",
      key: "reward_name",
      width: 60,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 20,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Referred To",
      dataIndex: "referree_id",
      key: "referree_id",
      render: (value) => <p>{formattedValue(value)}</p>,
      width: 200,
      align: "center",
    },
    {
      title: "Referred Name",
      dataIndex: "referree_name",
      key: "referree_name",
      render: (value) => <p>{formattedValue(value)}</p>,
      width: 70,
      align: "center",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      align: "center",
      width: 200,
      render: (val) => <p>{dateFormating(val)}</p>,
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      align: "center",
      width: 200,
      render: (val) => <p>{dateFormating(val)}</p>,
    },
    {
      title: "Is Completed",
      dataIndex: "status",
      key: "status",
      width: 50,
      align: "center",
      render: (value, item) =>
        value == "completed" ? (
          // eslint-disable-next-line
          <div
            onClick={(e) => {
              updateReferralApi({ id: item.id, params: { status: "pending" } });
            }}
          >
            <CheckCircleIcon />
          </div>
        ) : (
          // eslint-disable-next-line
          <div
            onClick={(e) => {
              updateReferralApi({
                id: item.id,
                params: { status: "completed" },
              });
            }}
          >
            <CheckBoxOutlineBlankIcon />
          </div>
        ),
    },
  ];

  useEffect(() => {
    filters.page = currentPage;
    filters.page_limit = pageSize;
    getReferralList(filters);
  }, [currentPage, pageSize]);

  const handleFilterChange = (filters) => {
    getReferralList(filters);
  };

  const IsDeleted = {
    key: "status",
    data: [
      {
        id: "pending",
        title: "Pending",
        selected: false,
      },
      {
        id: "completed",
        title: "Completed",
        selected: false,
      },
    ],
  };

  const SubPlacement = {
    key: "sub_placement",
    data: [
      {
        id: "topScroll",
        title: "Top Scroll",
        selected: true,
      },
      {
        id: "playToWin",
        title: "Play To Win",
        selected: false,
      },
      {
        id: "openYourAccount",
        title: "Open Your Account",
        selected: false,
      },
      {
        id: "getLoans",
        title: "Get Loans",
        selected: false,
      },
      {
        id: "community",
        title: "Community",
        selected: false,
      },
      {
        id: "offersForYou",
        title: "Offers For You",
        selected: false,
      },
    ],
  };

  const Placement = {
    key: "placement",
    data: [
      {
        id: "Home",
        title: "Home",
        selected: true,
      },
      {
        id: "Community",
        title: "Community",
        selected: false,
      },
    ],
  };

  const TargetUsers = {
    key: "target_users",
    data: [
      {
        id: "all",
        title: "All",
        selected: true,
      },
      {
        id: "invested",
        title: "Invested",
        selected: false,
      },
      {
        id: "non_invested",
        title: "Non Invested",
        selected: false,
      },
      {
        id: "signed_up",
        title: "Signed Up",
        selected: false,
      },
    ],
  };

  return (
    <Grid container spacing={2}>
      <CommonLoader
        isLoading={loading}
        size="large"
        sentences={["please wait ..."]}
        type="pacman"
      />
      <Grid
        container
        spacing={2}
        style={{
          paddingRight: "1rem",
          paddingLeft: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
          <FilterTitleWrapper>Filters</FilterTitleWrapper>
          {mobileNumberList.length > 0 && (
            <CommonDropdown
              list={{
                key: "mobile_number",
                data: mobileNumberList,
              }}
              title="Mobile Number"
              searchable
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
          )}
          {rewardNames.length > 0 && (
            <CommonDropdown
              list={{
                key: "step",
                data: rewardNames,
              }}
              title="Step Reward Name"
              searchable
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
          )}
          <CommonDropdown
            list={IsDeleted}
            title="Status"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          {refereeIdList.length > 0 && (
            <CommonDropdown
              list={{
                key: "referree_id",
                data: refereeIdList,
              }}
              title="Referred To"
              searchable
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
          )}
        </FilterGrid>
        <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
          <CommonTable
            data={data}
            columns={tableColumns}
            pagination={false}
            scroll={{ x: "overflow" }}
          />
          <Paginator
            totalRecords={total}
            pageLimit={pageSize}
            pageNeighbours={2}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Referral;
