/* eslint-disable implicit-arrow-linebreak */
import {
    DeleteSchemePortfolioMappingApiURL,
    CreateSchemePortfolioMappingApiURL,
    UpdateSchemePortfolioMappingApiURL,
} from './apiConfig';
import axios from './instance';

export const DeleteSchemePortfolioMappingApi = (params) =>
    axios.delete(DeleteSchemePortfolioMappingApiURL, { data: { ...params } });

export const CreateSchemePortfolioMappingApi = (params) =>
    axios.post(CreateSchemePortfolioMappingApiURL, params);

export const UpdateSchemePortfolioMappingApi = (params) =>
    axios.put(UpdateSchemePortfolioMappingApiURL, params);
