import styled from 'styled-components';

export default {
    control: {
        fontFamily: 'monospace',
        backgroundColor: '#fff',
        fontSize: 13,
    },

    '&multiLine': {
        control: {
            fontFamily: 'monospace',
            minHeight: 100,
            maxHeight: 100,
        },
        highlighter: {
            padding: 9,
            border: '1px solid transparent',
            maxHeight: 100,
        },
        input: {
            padding: 9,
            border: '1px solid silver',
        },
    },

    '&singleLine': {
        display: 'inline-block',
        width: 180,

        highlighter: {
            padding: 1,
            border: '2px inset transparent',
        },
        input: {
            padding: 1,
            border: '2px inset',
        },
    },

    suggestions: {
        list: {
            backgroundColor: 'white',
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 12,
        },
        item: {
            padding: '5px 11px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',
            '&focused': {
                backgroundColor: '#d4ecff',
            },
        },
    },
};

export const Img = styled.img`
    height: 30px;
    width: 30px;
    border-radius: 15px;
    object-fit: cover;
    background-image: url('https://d35u5jwboagvu7.cloudfront.net/default-profile-image.svg');
    '&focused': {
        background-color: '#d4ecff',
    },
`;

export const Suggestion = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const Text = styled.label`
    font-weight: 600;
`;

export const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    padding: 5px 11px;
`;
