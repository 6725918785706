import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import swal from 'sweetalert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Modal, Button, Select } from 'antd';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { size, trim } from 'lodash';
import CommonTable from '../Common/Table';

import {
    FilterGrid,
    FilterTitleWrapper,
    StyledButton,
    StyledInput,
    LabelInput,
    ErrorText,
    IconContainer,
    YesIcon,
    NoIcon,
    StyledDatePicker,
} from './styles';
import {
    GetApplicationVersionHistoryListApi,
    CreateApplicationVersionHistoryApi,
    DeleteApplicationVersionHistoryApi,
    UpdateApplicationVersionHistoryApi,
} from '../../api/applicationVersionHistory';
import CommonLoader from '../Common/Loader';
import CommonDropdown from '../Common/Dropdown';
import Paginator from '../Common/Paginator';

import {
    formattedValue,
    snakeToUpperCase,
    dateFormating,
    dateFormattingServer,
} from '../../util/helper';

import { buID, subBuID } from '../../constant';

const StyleModal = styled(Modal)`
    .ant-modal-header {
        background: ${(props) => props.theme.dark};
        color: ${(props) => props.theme.dark};
    }

    .ant-modal-body {
        background: ${(props) => props.theme.dark};
    }

    .ant-modal-title {
        color: ${(props) => props.theme.text};
    }
    .ant-modal-footer {
        background: ${(props) => props.theme.dark};
    }
    .ant-modal-close-icon {
        color: ${(props) => props.theme.text};
    }

    .ant-modal .sc-AzgDb .jcGGxa {
        padding: 0px;
    }
`;

const ApplicationVersionHistory = () => {
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [data, setData] = useState([]);
    // const [authorList, setAuthorList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(1);
    const { Option } = Select;

    const [filters, setFilters] = useState({
        page: currentPage,
        page_limit: pageSize,
        sort_by: 'updated_at',
        sort_type: 'desc',
        is_deleted: 'false',
        buid: buID,
    });

    const [errors, setErrors] = useState({});

    const [currentOS, setCurrentOS] = useState('android');
    const [currentApplication, setCurrentApplication] = useState({});
    const [currentAccessURL, setCurrentAccessURL] = useState('');
    const [currentVersionNumber, setCurrentVersionNumber] = useState('');
    const [currentReleaseNotes, setCurrentReleaseNotes] = useState('');
    const [currentIsForceUpdate, setCurrentIsForceUpdate] = useState(false);
    const [currentReleaseDate, setCurrentReleaseDate] = useState(new Date());

    const sortTypeList = {
        key: 'sort_type',
        data: [
            {
                id: 'asc',
                title: 'Asc',
                selected: false,
            },
            {
                id: 'desc',
                title: 'Desc',
                selected: true,
            },
        ],
    };

    const sortByList = {
        key: 'sort_by',
        data: [
            {
                id: 'id',
                title: 'id',
                selected: false,
            },
            {
                id: 'created_at',
                title: 'created_at',
                selected: false,
            },
            {
                id: 'updated_at',
                title: 'updated_at',
                selected: true,
            },
            {
                id: 'version_number',
                title: 'version_number',
                selected: false,
            },
            {
                id: 'release_date',
                title: 'release_date',
                selected: false,
            },
        ],
    };

    const OSList = {
        key: 'os',
        data: [
            {
                id: 'android',
                title: 'Android',
                selected: true,
            },
            {
                id: 'ios',
                title: 'IOS',
                selected: false,
            },
        ],
    };

    const IsForceUpdateList = {
        key: 'is_force_update',
        data: [
            {
                id: true,
                title: 'True',
                selected: false,
            },
            {
                id: false,
                title: 'False',
                selected: false,
            },
            {
                id: undefined,
                title: 'None',
                selected: true,
            },
        ],
    };

    const getApplicationVersionHistoryList = (params) => {
        setLoading(true);
        GetApplicationVersionHistoryListApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setData(apiData.results);
                        setCurrentPage(apiData.page);
                        setPageSize(apiData.page_limit);
                        setTotal(apiData.total_count);
                        setFilters(params);
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error',
                    );
                }
            })
            .catch((error) => {
                setLoading(false)
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const deleteVersion = (id) => {
        swal({
            title: 'Are you sure you want to delete this version ?',
            text: '',
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true,
        }).then((success) => {
            if (success) {
                setLoading(true);
                const params = {
                    id,
                };
                DeleteApplicationVersionHistoryApi(params)
                    .then((res) => {
                        setLoading(false);
                        if (res.status === 200) {
                            if ((res.data || {}).success) {
                                getApplicationVersionHistoryList(filters);
                                swal(
                                    'success!',
                                    'Successfully Deleted Version',
                                    'success',
                                );
                            }
                        } else {
                            swal(
                                'Oops!',
                                ((res.data || {}).data || {}).error_desc,
                                'error',
                            );
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        let errorMessage = 'Failed to delete version';
                        if (error.response) {
                            if (error.response.status === 400) {
                                errorMessage = (error.response.data || {})
                                    .error_desc;
                            }
                        }
                        swal('Oops!', errorMessage, 'error');
                    });
            }
        });
    };

    const clearApplicationState = () => {
        setCurrentOS('');
        setCurrentAccessURL('');
        setCurrentVersionNumber('');
        setCurrentReleaseNotes('');
        setCurrentIsForceUpdate('');
        setCurrentReleaseDate(new Date());
        setShowUpdateModal(false);
        setShowCreateModal(false);
        setErrors({});
        setCurrentApplication({});
    };

    const handleUpdateModalCancel = () => {
        clearApplicationState();
    };

    const onRowEdit = (item) => {
        setCurrentOS(item.os);
        setCurrentApplication({ ...item });
        setCurrentAccessURL(item.access_url);
        setCurrentVersionNumber(item.version_number);
        setCurrentReleaseNotes(item.release_notes);
        setCurrentIsForceUpdate(item.is_force_update);
        setCurrentReleaseDate(item.release_date);
        setShowUpdateModal(true);
    };

    const renderEdit = (item) => (
        <IconContainer>
            <EditIcon size="small" onClick={() => onRowEdit(item)} />
        </IconContainer>
    );

    const renderDelete = (item) => (
        <div>
            {item.is_deleted === false ? (
                <IconContainer>
                    <DeleteIcon
                        size="small"
                        onClick={() => deleteVersion(item.id)}
                    />
                </IconContainer>
            ) : null}
        </div>
    );

    const tableColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            textWrap: 'name',
            width: 20,
            render: (name) => (
                <div>{snakeToUpperCase(formattedValue(name))}</div>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type) => <div>{formattedValue(type)}</div>,
            width: 20,
        },
        {
            title: 'OS',
            dataIndex: 'os',
            key: 'os',
            render: (status) => <div>{snakeToUpperCase(status)}</div>,
            align: 'center',
            width: 40,
        },
        {
            title: 'Access URL',
            dataIndex: 'access_url',
            key: 'access_url',
            render: (accessURL) => <div>{formattedValue(accessURL)}</div>,
            width: 20,
        },
        {
            title: 'Version',
            dataIndex: 'version_number',
            key: 'version_number',
            render: (versionNumber) => (
                <div>{formattedValue(versionNumber)}</div>
            ),
            width: 20,
        },
        {
            title: 'Release Date',
            dataIndex: 'release_date',
            key: 'release_date',
            render: (releaseDate) => <div>{dateFormating(releaseDate)}</div>,
            width: 20,
        },
        {
            title: 'Force Update',
            dataIndex: 'is_force_update',
            key: 'is_force_update',
            render: (isForceUpdate) => (
                <IconContainer>
                    {isForceUpdate === true ? <YesIcon /> : <NoIcon />}
                </IconContainer>
            ),
            width: 20,
        },
        {
            title: 'Release Notes',
            dataIndex: 'release_notes',
            key: 'release_notes',
            render: (releaseNotes) => <div>{formattedValue(releaseNotes)}</div>,
        },
        {
            title: 'Updated At',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (value) => <p>{dateFormating(value)}</p>,
            width: 110,
            align: 'center',
        },
    ];

    if (!JSON.parse(filters.is_deleted || false)) {
        tableColumns.push({
            title: 'Edit',
            dataIndex: 'id',
            key: 'id',
            render: (text, item) => renderEdit(item),
            align: 'center',
            width: 10,
        });
        tableColumns.push({
            title: 'Delete',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 10,
            render: (text, item) => renderDelete(item),
        });
    }

    useEffect(() => {
        filters.page = currentPage;
        filters.page_limit = pageSize;
        getApplicationVersionHistoryList(filters);
    }, [currentPage, pageSize]);

    const showCreateVersionModal = () => {
        setShowCreateModal(true);
    };

    const handleReleaseDateChange = (date) => {
        if (date === null || date === undefined) {
            setCurrentReleaseDate(new Date());
        } else if (date.toString() === 'Invalid Date') {
            // set error for invalid date
        } else {
            setCurrentReleaseDate(date);
        }
    };

    const handleCreateVersionModalCancel = () => {
        clearApplicationState();
    };

    const submitApplicationRequest = (api, params) => {
        setLoading(true);
        api(params)
            .then((res) => {
                let success = false;
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        clearApplicationState();
                        getApplicationVersionHistoryList(filters);
                        swal(
                            'success!',
                            'Successfully Created Version',
                            'success',
                        );
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error',
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Version Creation Failed';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('OOPS!', errorMessage, 'error');
            });
    };

    const createVersion = () => {
        const params = {
            name: 'lxme_client_app',
            type: 'mobile',
            buid: buID,
            sub_buid: subBuID,
            os: currentOS,
            access_url: currentAccessURL,
            version_number: currentVersionNumber,
            release_notes: currentReleaseNotes,
            release_date: dateFormattingServer(currentReleaseDate),
            is_force_update: currentIsForceUpdate.toString(),
        };
        submitApplicationRequest(CreateApplicationVersionHistoryApi, params);
    };

    const updateVersion = () => {
        const params = {
            id: currentApplication.id,
            access_url: currentAccessURL,
            sub_buid: subBuID,
            version_number: currentVersionNumber,
            release_notes: currentReleaseNotes,
            is_force_update: currentIsForceUpdate.toString(),
            release_date: dateFormattingServer(currentReleaseDate),
        };
        submitApplicationRequest(UpdateApplicationVersionHistoryApi, params);
    };

    const validateFields = (action) => {
        const validationErrors = {};
        // Release Notes
        if (size(trim(currentReleaseNotes)) < 1) {
            validationErrors.releaseNotes = 'Required Release Notes';
        }

        // Access URL
        if (size(trim(currentAccessURL)) < 1) {
            validationErrors.accessURL = 'Required Access URL';
        }

        // Force Update
        if (
            currentIsForceUpdate === null
            || currentIsForceUpdate === ''
            || currentIsForceUpdate === undefined
        ) {
            validationErrors.isForceUpdate = 'Invalid Value for Force Update';
        }

        if (action !== 'update') {
            // Version Number
            if (size(currentVersionNumber.split('.')) !== 3) {
                validationErrors.versionNumber = 'Invalid Version Number';
            }

            // OS
            if (trim(currentOS) !== 'android' && trim(currentOS) !== 'ios') {
                validationErrors.os = 'Invalid OS';
            }
        }

        return validationErrors;
    };

    const handleCreateVersionSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validateFields('create');
        setErrors(validationErrors);
        if (isEmpty(validationErrors)) {
            createVersion();
        }
    };

    const handleUpdateVersionSubmit = () => {
        const validationErrors = validateFields('update');
        setErrors(validationErrors);
        if (isEmpty(validationErrors)) {
            updateVersion();
        }
    };

    const handleFilterChange = (params) => {
        getApplicationVersionHistoryList(params);
    };

    const createListOptions = (list) => {
        const optionsArray = [];

        list.map((item) => {
            optionsArray.push(
                <Option key={item.id} value={item.id}>
                    {item.title}
                </Option>,
            );
            return 0;
        });

        return optionsArray;
    };

    const IsDeleted = {
        key: 'is_deleted',
        data: [
            {
                id: 'true',
                title: 'True',
                selected: false,
            },
            {
                id: 'false',
                title: 'False',
                selected: true,
            },
        ],
    };

    return (
        <Grid container spacing={2}>
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <StyledButton primary onClick={() => showCreateVersionModal()}>
                    Add Version
                </StyledButton>
            </Grid>
            <Grid
                container
                spacing={2}
                style={{
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                    paddingBottom: '1rem',
                }}
            >
                <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
                    <div>
                        <FilterTitleWrapper>Filters</FilterTitleWrapper>
                        <CommonDropdown
                            list={OSList}
                            title="OS"
                            onChange={(val) => handleFilterChange(val)}
                            filters={filters}
                        />
                        <CommonDropdown
                            list={IsForceUpdateList}
                            title="Force Update"
                            onChange={(val) => handleFilterChange(val)}
                            filters={filters}
                        />
                        <CommonDropdown
                            list={IsDeleted}
                            title="Is Deleted"
                            onChange={(val) => handleFilterChange(val)}
                            filters={filters}
                        />
                        <CommonDropdown
                            list={sortByList}
                            title="Sort By"
                            searchable={false}
                            onChange={(val) => handleFilterChange(val)}
                            filters={filters}
                        />
                        <CommonDropdown
                            list={sortTypeList}
                            title="Sort Type"
                            searchable={false}
                            onChange={(val) => handleFilterChange(val)}
                            filters={filters}
                        />
                    </div>
                    {/* )} */}
                </FilterGrid>
                <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                    <CommonTable
                        rowKey="id"
                        data={data}
                        columns={tableColumns}
                        pagination={false}
                        scroll={{ x: 'overflow' }}
                    />
                    <Paginator
                        totalRecords={total}
                        pageLimit={pageSize}
                        pageNeighbours={2}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </Grid>
            </Grid>
            <Grid>
                {showCreateModal && (
                    <StyleModal
                        title="Add Version"
                        visible={showCreateModal}
                        onOk={(e) => handleCreateVersionSubmit(e)}
                        onCancel={() => handleCreateVersionModalCancel()}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                onClick={(e) => handleCreateVersionSubmit(e)}
                                loading={loading}
                            >
                                Submit
                            </Button>,
                        ]}
                    >
                        <LabelInput>OS</LabelInput>
                        <Select
                            style={{ width: '100%' }}
                            value={currentOS}
                            onChange={(e) => setCurrentOS(e)}
                        >
                            {OSList && createListOptions(OSList.data)}
                        </Select>
                        {errors.os && <ErrorText>{errors.os}</ErrorText>}
                        <br />
                        <LabelInput>Version Number</LabelInput>
                        <StyledInput
                            id="version_number"
                            placeholder="Version Number"
                            name="version_number"
                            value={currentVersionNumber}
                            onChange={(e) => {
                                setCurrentVersionNumber(e.target.value);
                            }}
                        />
                        {errors.versionNumber && (
                            <ErrorText>{errors.versionNumber}</ErrorText>
                        )}
                        <br />
                        <LabelInput>Access URL</LabelInput>
                        <StyledInput
                            id="access_url"
                            placeholder="Access URL"
                            name="access_url"
                            value={currentAccessURL}
                            onChange={(e) => {
                                setCurrentAccessURL(e.target.value);
                            }}
                        />
                        {errors.accessURL && (
                            <ErrorText>{errors.accessURL}</ErrorText>
                        )}
                        <br />
                        <LabelInput>Force Update</LabelInput>
                        <Select
                            style={{ width: '100%' }}
                            value={currentIsForceUpdate}
                            onChange={(e) => setCurrentIsForceUpdate(e)}
                        >
                            {IsForceUpdateList
                                && createListOptions(IsForceUpdateList.data)}
                        </Select>
                        {errors.isForceUpdate && (
                            <ErrorText>{errors.isForceUpdate}</ErrorText>
                        )}
                        <br />
                        <LabelInput>Release Date</LabelInput>
                        <StyledDatePicker
                            clearable="true"
                            // format="dd/MM/yyyy"
                            margin="normal"
                            variant="inline"
                            showTodayButton
                            disablePast
                            id="release-date-picker"
                            value={currentReleaseDate}
                            onChange={handleReleaseDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'select release date',
                            }}
                        />
                        {errors.releaseDate && (
                            <ErrorText>{errors.releaseDate}</ErrorText>
                        )}
                        <br />
                        <LabelInput>Release Notes</LabelInput>
                        <StyledInput
                            id="release_notes"
                            placeholder="Release Notes"
                            name="release_notes"
                            value={currentReleaseNotes}
                            rows={4}
                            onChange={(e) => setCurrentReleaseNotes(e.target.value)}
                        />
                        {errors.releaseNotes && (
                            <ErrorText>{errors.releaseNotes}</ErrorText>
                        )}
                        <br />
                    </StyleModal>
                )}
            </Grid>
            {/* end */}
            <Grid>
                {showUpdateModal && (
                    <StyleModal
                        title="Update Version"
                        visible={showUpdateModal}
                        onOk={handleUpdateVersionSubmit}
                        onCancel={handleUpdateModalCancel}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                onClick={handleUpdateVersionSubmit}
                                loading={loading}
                            >
                                Update
                            </Button>,
                        ]}
                    >
                        <LabelInput>Access URL</LabelInput>
                        <StyledInput
                            id="access_url"
                            placeholder="Access URL"
                            name="access_url"
                            value={currentAccessURL}
                            onChange={(e) => setCurrentAccessURL(e.target.value)}
                        />
                        {errors.accessURL && (
                            <ErrorText>{errors.accessURL}</ErrorText>
                        )}
                        <br />
                        <LabelInput>Force Update</LabelInput>
                        <Select
                            style={{ width: '100%' }}
                            value={currentIsForceUpdate}
                            onChange={(e) => setCurrentIsForceUpdate(e)}
                        >
                            {IsForceUpdateList
                                && createListOptions(IsForceUpdateList.data)}
                        </Select>
                        {errors.isForceUpdate && (
                            <ErrorText>{errors.isForceUpdate}</ErrorText>
                        )}
                        <br />
                        <LabelInput>Release Date</LabelInput>
                        <StyledDatePicker
                            clearable="true"
                            margin="normal"
                            variant="inline"
                            showTodayButton
                            id="update-release-date-picker"
                            value={currentReleaseDate}
                            onChange={handleReleaseDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'select release date',
                            }}
                        />
                        {errors.releaseDate && (
                            <ErrorText>{errors.releaseDate}</ErrorText>
                        )}
                        <br />
                        <LabelInput>Release Notes</LabelInput>
                        <StyledInput
                            id="release_notes"
                            placeholder="Release Notes"
                            name="release_notes"
                            value={currentReleaseNotes}
                            rows={4}
                            onChange={(e) => {
                                setCurrentReleaseNotes(e.target.value);
                            }}
                        />
                        {errors.releaseNotes && (
                            <ErrorText>{errors.releaseNotes}</ErrorText>
                        )}
                        <br />
                    </StyleModal>
                )}
            </Grid>
        </Grid>
    );
};

export default ApplicationVersionHistory;
