import axios from './instance';
import {
    ForgotPasswordApiURL,
    ConfirmForgotPasswordApiURL,
    ResetPasswordApiURL,
} from './apiConfig';

export const ForgotPasswordApi = (params) => axios.post(ForgotPasswordApiURL, params);

export const ConfirmForgotPasswordApi = (params) => axios.post(ConfirmForgotPasswordApiURL, params);

export const ResetPasswordApi = (params) => axios.post(ResetPasswordApiURL, params);
