/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import Grid from '@material-ui/core/Grid';
import CommonLoader from '../Common/Loader';
import { GetEventRegistrationListApi } from '../../api/event';
import CommonTable from '../Common/Table';
import { FilterGrid, FilterTitleWrapper } from './styles';
import CommonDropdown from '../Common/Dropdown';
import Paginator from '../Common/Paginator';
import CommonDatePicker from '../Common/DatePicker';
import {
    formattedValue,
    snakeToUpperCase,
    dateFormating,
} from '../../util/helper';

const EventDetail = (props) => {
    const { pageProps } = props;

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(1);

    const [filters, setFilters] = useState({
        page: currentPage,
        page_limit: pageSize,
        sort_by: 'updated_at',
        sort_type: 'desc',
        is_deleted: 'false',
    });

    const getEventRegistrationList = (params) => {
        if ('event_id' in params) {
            setLoading(true);
            GetEventRegistrationListApi(params)
                .then((res) => {
                    let success = false;
                    if (res.status === 200) {
                        if ((res.data || {}).success) {
                            success = true;
                            const apiData = (res.data || {}).data || {};
                            setData(apiData.results);
                            setCurrentPage(apiData.page);
                            setPageSize(apiData.page_limit);
                            setTotal(apiData.total_count);
                            setFilters(params);
                            setLoading(false);
                        }
                    }
                    if (!success) {
                        setLoading(false);
                        swal(
                            'Oops!',
                            ((res.data || {}).data || {}).error_desc,
                            'error',
                        );
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    let errorMessage = 'Failed to fetch data';
                    if (error.response) {
                        if (error.response.status === 400) {
                            errorMessage = (error.response.data || {})
                                .error_desc;
                        }
                    }
                    swal('Oops!', errorMessage, 'error');
                });
        }
    };

    useEffect(() => {
        const { id } = ((pageProps || {}).match || {}).params || {};
        const newFilters = { ...filters, event_id: id };
        setFilters(newFilters);
        getEventRegistrationList(newFilters);
    }, [pageProps]);

    useEffect(() => {
        filters.page = currentPage;
        filters.page_limit = pageSize;
        getEventRegistrationList(filters);
    }, [currentPage, pageSize]);

    const handleFilterChange = (filters) => {
        getEventRegistrationList(filters);
    };

    const tableColumns = [
        {
            title: 'Registration ID',
            dataIndex: 'id',
            key: 'id',
            width: 10,
            render: (id) => <>{id}</>,
        },
        {
            title: 'User ID',
            dataIndex: 'user_id',
            key: 'user_id',
            width: 10,
            render: (value) => <>{value}</>,
        },
        {
            title: 'User Name',
            dataIndex: 'user_details',
            key: 'user_details',
            width: 30,
            render: (text, item) => (
                <div>
                    {formattedValue((item.user_details || {}).display_name)}
                </div>
            ),
        },
        {
            title: 'User Role',
            dataIndex: 'user_details',
            key: 'user_details',
            width: 70,
            render: (text, item) => (
                <div>
                    {snakeToUpperCase(
                        formattedValue((item.user_details || {}).role),
                    )}
                </div>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'user_details',
            key: 'user_details',
            width: 30,
            render: (text, item) => (
                <div>{formattedValue((item.user_details || {}).email)}</div>
            ),
        },
        {
            title: 'Mobile Number',
            dataIndex: 'user_details',
            key: 'user_details',
            width: 30,
            render: (text, item) => (
                <div>
                    {formattedValue((item.user_details || {}).mobile_number)}
                </div>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 70,
            align: 'center',
            render: (status) => (
                <div>{snakeToUpperCase(formattedValue(status))}</div>
            ),
        },
        {
            title: 'Registration time',
            dataIndex: 'created_at',
            key: 'created_at',
            textWrap: 'word-break',
            width: 110,
            align: 'center',
            render: (value) => <p>{dateFormating(value)}</p>,
        },
    ];

    const sortTypeList = {
        key: 'sort_type',
        data: [
            {
                id: 'asc',
                title: 'Asc',
                selected: false,
            },
            {
                id: 'desc',
                title: 'Desc',
                selected: true,
            },
        ],
    };

    const sortByList = {
        key: 'sort_by',
        data: [
            {
                id: 'id',
                title: 'id',
                selected: false,
            },
            {
                id: 'created_at',
                title: 'created_at',
                selected: false,
            },
            {
                id: 'updated_at',
                title: 'updated_at',
                selected: true,
            },
            {
                id: 'status',
                title: 'status',
                selected: false,
            },
        ],
    };

    const IsDeleted = {
        key: 'is_deleted',
        data: [
            {
                id: 'true',
                title: 'True',
                selected: false,
            },
            {
                id: 'false',
                title: 'False',
                selected: true,
            },
        ],
    };

    const StatusList = {
        key: 'status',
        data: [
            {
                id: 'registered',
                title: 'Registered',
                selected: false,
            },
            {
                id: 'joined',
                title: 'Joined',
                selected: false,
            },
        ],
    };

    return (
        <Grid container spacing={2}>
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />
            <Grid
                container
                spacing={2}
                style={{
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                    paddingBottom: '1rem',
                    paddingTop: '2rem',
                }}
            >
                <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
                    <FilterTitleWrapper>Filters</FilterTitleWrapper>
                    <CommonDropdown
                        list={StatusList}
                        title="Status"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={IsDeleted}
                        title="Is Deleted"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={sortByList}
                        title="Sort By"
                        searchable={false}
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={sortTypeList}
                        title="Sort Type"
                        searchable={false}
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Created At LTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="created_at_lte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Created At GTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="created_at_gte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Updated At LTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="updated_at_lte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Updated At GTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="updated_at_gte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                </FilterGrid>
                <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                    <CommonTable
                        data={data}
                        columns={tableColumns}
                        pagination={false}
                        scroll={{ x: 'overflow' }}
                    />
                    <Paginator
                        totalRecords={total}
                        pageLimit={pageSize}
                        pageNeighbours={2}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

EventDetail.propTypes = {
    pageProps: PropTypes.shape({}).isRequired,
};

export default EventDetail;
