/* eslint-disable max-len */

import axios from './instance';
import {
    GetApplicationVersionHistoryListApiURL,
    CreateApplicationVersionHistoryApiURL,
    UpdateApplicationVersionHistoryApiURL,
    DeleteApplicationVersionHistoryApiURL,
} from './apiConfig';

export const GetApplicationVersionHistoryListApi = (queryParams) => axios.get(GetApplicationVersionHistoryListApiURL, { params: queryParams });

export const CreateApplicationVersionHistoryApi = (params) => axios.post(CreateApplicationVersionHistoryApiURL, params);

export const DeleteApplicationVersionHistoryApi = (params) => axios.delete(DeleteApplicationVersionHistoryApiURL, {
        data: { ...params },
    });

export const UpdateApplicationVersionHistoryApi = (params) => axios.put(UpdateApplicationVersionHistoryApiURL, params);
