import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { size } from "lodash";
import { getImageUrl } from "../../util/custom";

import { useStyles, DeleteMedia, ImageContainer, PreviewImage } from "./styles";

const ImagePreview = (props) => {
  const { value, onChange, langKey, update = false } = props;
  const classes = useStyles();

  const getMediaUrl = () => {
    if (!value) {
      return "";
    }

    if (value.bucket) {
      return getImageUrl(value);
    }
    if (value.size) {
      return URL.createObjectURL(value);
    }
    if (typeof value === "string") {
      return value;
    }
    return "";
  };
  const uniqueId = "contained-button-file" + langKey + update;
  const isImagePresent = !!size(getMediaUrl());

  return (
    <div className={classes.root}>
      <input
        accept="image/*"
        className={classes.input}
        id={uniqueId}
        onChange={onChange}
        type="file"
      />
      <label htmlFor={uniqueId}>
        <Button
          variant="contained"
          startIcon={<CloudUploadIcon />}
          color="primary"
          component="span"
        >
          {isImagePresent ? "Replace" : "Upload"}
        </Button>
      </label>
      {!!size(getMediaUrl()) && (
        <ImageContainer>
          <DeleteMedia onClick={() => onChange()} size="small" color="error" />
          <PreviewImage src={getMediaUrl()} />
        </ImageContainer>
      )}
    </div>
  );
};

ImagePreview.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
};

ImagePreview.defaultProps = {
  value: null,
};

export default ImagePreview;
