/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CommonTable from '../Common/Table';
import CommonLoader from '../Common/Loader';
import { GetModelPortfolioMasterListApi } from '../../api/modelPortfolio';
import {
    DeleteSchemePortfolioMappingApi,
    CreateSchemePortfolioMappingApi,
    UpdateSchemePortfolioMappingApi,
} from '../../api/schemePortfolioMapping';
import { getBannerImage } from '../../util/custom';
import {
    FilterGrid,
    FilterTitleWrapper,
    StyledButton,
    PortfolioName,
    PortfolioDescription,
    PortfolioLabel,
    PortfolioText,
    IconContainer,
    StyleModal,
    LabelInput,
    ErrorText,
    StyledTag,
    PortfolioTextLabel,
    ImageWrapper,
    PitchingText,
    PitchingDescription
} from './styles';
import { toSnakeAndLowerCase, snakeToUpperCase } from '../../util/helper';
import { size, trim } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { Button, Select, Input } from 'antd';
import parseInt from 'lodash/parseInt';


const ModelPortfoliosDetail = (props) => {
    const { pageProps } = props;
    const [portfolio, setPortfolio] = useState({});
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [errors, setErrors] = useState({});
    const [updateList, setUpdateList] = useState(false);
    const [schemeID, setSchemeID] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [schemeCode, setSchemeCode] = useState('');
    const [assetClassID, setAssetClassID] = useState('');
    const [systemCategoryID, setSystemCategoryID] = useState('');
    const [systemPortfolioId, setSystemPortfolioId] = useState('');
    const [systemSchemeId, setSystemSchemeId] = useState('');
    const [bseCode, setBseCode] = useState('');
    const [isinCode, setIsinCode] = useState('');
    const [allocationPercentage, setAllocationPercentage] = useState('');
    const [threeYearReturn, setThreeYearReturn] = useState('');
    const [fiveYearReturn, setFiveYearReturn] = useState('');
    const [inceptionReturn, setInceptionReturn] = useState('');
    const [expenseratio, setExpenseratio] = useState('');
    const [exitload, setExitload] = useState('');
    const [riskCategory, setRiskCategory] = useState('');
    const [isActive, setIsActive] = useState('true');

    useEffect(() => {
        const { id, systemId } = ((pageProps || {}).match || {}).params || {};
        if (id !== 'null') {
            getModelPortfolioMasterList(id);
        }
    }, [pageProps, updateList]);

    const tableColumns = [
        {
            title: 'Scheme Name',
            dataIndex: 'name',
            key: '1',
            align: 'center',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: '2',
            align: 'center',
        },
        {
            title: 'Last 3Y Return',
            dataIndex: 'three_year_return',
            key: '3',
            align: 'center',
            render: (item) => <p>{item}</p>,
        },
        {
            title: 'Last 5Y Return',
            dataIndex: 'five_year_return',
            key: '4',
            align: 'center',
            render: (item) => <p>{item}</p>,
        },
        {
            title: 'Since Inception Retrun',
            dataIndex: 'inception_return',
            key: '5',
            align: 'center',
            render: (item) => <p>{item}</p>,
        },
        {
            title: 'Allocation Percentage',
            dataIndex: 'allocation_percentage',
            key: '6',
            align: 'center',
            render: (item) => <p>{item}</p>,
        },
        {
            title: 'Scheme Type',
            dataIndex: 'asset_class',
            key: '7',
            align: 'center',
            render: (item) => <p>{item}</p>,
        },
        {
            title: 'BSE Code',
            dataIndex: 'bse_code',
            key: '8',
            align: 'center',
            render: (item) => <p>{item}</p>,
        },
        {
            title: 'System Scheme ID',
            dataIndex: 'system_scheme_id',
            key: '9',
            align: 'center',
            render: (item) => <p>{item}</p>,
        },
        {
            title: 'Scheme Code',
            dataIndex: 'scheme_code',
            key: '10',
            align: 'center',
            render: (item) => <p>{item}</p>,
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: '11',
            align: 'center',
            render: (item) => <p>{snakeToUpperCase(item)}</p>,
        },
        {
            title: 'Purchase Multiplier',
            dataIndex: 'purchase_multiplier',
            key: '12',
            align: 'center',
            render: (item) => <p>{item}</p>,
        },
        {
            title: 'ISIN Code',
            dataIndex: 'isin_code',
            key: '13',
            align: 'center',
            render: (item) => <p>{item}</p>,
        },
        {
            title: 'Exit Load Text',
            dataIndex: 'exit_load_text',
            key: '14',
            align: 'center',
            render: (item) => <p>{item}</p>,
        },
        {
            title: 'Expense Ratio',
            dataIndex: 'expense_ratio',
            key: '15',
            align: 'center',
            render: (item) => <p>{item}</p>,
        },
        
        {
            title: 'Risk Category',
            dataIndex: 'risk_category',
            key: '16',
            align: 'center',
            render: (item) => <p>{snakeToUpperCase(item)}</p>,
        },

        {
            title: 'Edit',
            dataIndex: 'id',
            key: 'id',
            width: 10,
            render: (text, item) => (
                <IconContainer>
                    <EditIcon onClick={() => showupdateSchemesModal(item)} />
                </IconContainer>
            ),
            align: 'center',
        },
        {
            title: 'Delete',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 10,
            render: (text, item) =>
                item.is_deleted === false ? (
                    <IconContainer>
                        <DeleteIcon
                            onClick={() => deleteSchemePortfolioMapping(text)}
                        />
                    </IconContainer>
                ) : null,
        }
    ];

    const StatusList = {
        key: 'status',
        data: [
            {
                id: 'true',
                title: 'true',
                selected: true,
            },
            {
                id: 'false',
                title: 'false',
                selected: false,
            },
        ],
    };

    const AssetClassList = {
        key: 'asset_class',
        data: [
            {
                id: '46001',
                title: 'Equity',
            },
            {
                id: '46002',
                title: 'Debt',
            },
            {
                id: '46003',
                title: 'Gold',
            },
            {
                id: '46004',
                title: 'Hybrid',
            },
        ],
    };

    const CategoryList = {
        key: 'category',
        data: [
            {
                id: '48001',
                title: 'LARGE CAP',
            },
            {
                id: '48002',
                title: 'MID CAP',
            },
            {
                id: '48004',
                title: 'MULTI CAP',
            },
            {
                id: '48018',
                title: 'SHORT TERM DEBT',
            },
            {
                id: '48020',
                title: 'LIQUID PLUS',
            },
            {
                id: '48023',
                title: 'GOLD ETF',
            },
            {
                id: '48067',
                title: 'FLEXI CAP',
            },
            {
                id: '48068',
                title: 'LARGE AND MID CAP',
            },
            {
                id: '48069',
                title: 'EQUITY SAVINGS',
            },
        ],
    };

    const RiskCategoryList = {
        key : "risk_category",
        data : [
            {
                id: "low",
                title: 'LOW',
            }, 
            {
                id: "low_to_moderate",
                title: 'LOW TO MODERATE',
            }, 
            {
                id: "moderate",
                title: 'MODERATE',
            }, 
            {
                id: "moderately_high",
                title: 'MODERATELY HIGH',
            }, 
            {
                id: "high",
                title: 'HIGH',
            },
            {
                id: "very_high",
                title: 'VERY HIGH',
            }, 
        ]
    }

    const createOptionList = (list) => {
        const optionArray = [];
        // eslint-disable-next-line array-callback-return
        list.map((item) => {
            optionArray.push(
                <Option key={item.id} value={item.id}>
                    {item.title}
                </Option>,
            );
        });
        return optionArray;
    };

    const getAssetClassTitle = (assetClass) => {
        var filtered = AssetClassList.data.filter(function (element) {
            return element.id == assetClass
        });
        return (filtered[0] || {}).title;
    }

    const getSystemCategoryTitle = (category) => {
        var filtered = CategoryList.data.filter(function (element) {
            return element.id == category
        });
        return (filtered[0] || {}).title;
    }

    const handleCreateSchemeSubmit = (event) => {
        event.preventDefault();
        const values = {
            name,
            description,
            schemeCode,
            assetClass: assetClassID,
            category: systemCategoryID,
            systemPortfolioId,
            systemSchemeId,
            bseCode,
            isinCode,
            allocationPercentage,
            threeYearReturn,
            fiveYearReturn,
            inceptionReturn,
            exitload,
            expenseratio,
            riskCategory,
            isActive
        };
        setErrors({});
        let validationErrors = {};
        validationErrors = validateFields(values);
        setErrors(validationErrors);
        if (isEmpty(validationErrors)) {
            createSchemes();
        }
    };

    const handleCreateSchemeCancel = () => {
        setSchemeID('')
        setName('');
        setDescription('');
        setSchemeCode('');
        setAssetClassID('');
        setSystemCategoryID('');
        setSystemPortfolioId('');
        setSystemSchemeId('');
        setBseCode('');
        setIsinCode('');
        setAllocationPercentage('');
        setThreeYearReturn('');
        setFiveYearReturn('');
        setErrors({});
        setInceptionReturn('');
        setExpenseratio('');
        setExitload('');
        setRiskCategory('');
        setIsActive('true');
        setShowCreateModal(false);
    };

    const handleUpdateSchemeSubmit = (event) => {
        event.preventDefault();
        const values = {
            name,
            description,
            schemeCode,
            assetClass: assetClassID,
            category: systemCategoryID,
            systemPortfolioId,
            systemSchemeId,
            bseCode,
            isinCode,
            allocationPercentage,
            threeYearReturn,
            fiveYearReturn,
            inceptionReturn,
            exitload,
            expenseratio,
            riskCategory,
            isActive
        };
        setErrors({});
        let validationErrors = {};
        validationErrors = validateFields(values);
        setErrors(validationErrors);
        if (isEmpty(validationErrors)) {
            updateScheme();
        }
    };

    const handleUpdateSchemeCancel = () => {
        setSchemeID('')
        setName('');
        setDescription('');
        setSchemeCode('');
        setAssetClassID('');
        setSystemCategoryID('');
        setSystemPortfolioId('');
        setSystemSchemeId('');
        setBseCode('');
        setIsinCode('');
        setAllocationPercentage('');
        setThreeYearReturn('');
        setFiveYearReturn('');
        setErrors({});
        setInceptionReturn('');
        setIsActive('true');
        setShowUpdateModal(false);
    };

    const validateFields = (values) => {
        const validationErrors = {};
        
        // Scheme Name
        if (size(trim(values.name)) === 0) {
            validationErrors.name = 'Required Scheme Name';
        }
        if (isEmpty(trim(values.description))) {
            validationErrors.description = 'Required Description';
        }
        if (isEmpty(trim(values.schemeCode))) {
            validationErrors.schemeCode = 'Required Scheme Code';
        }
        if (isEmpty(trim(values.assetClass)) || parseInt(values.assetClass) < 1) {
            validationErrors.assetClass = 'Required Asset Class';
        }
        if (isEmpty(trim(values.category)) || parseInt(values.category) < 1) {
            validationErrors.category = 'Required Category';
        }
        if (isEmpty(trim(values.systemSchemeId)) || parseInt(values.systemSchemeId) < 1) {
            validationErrors.systemSchemeId = 'Invalid System Scheme ID';
        }
        if (isEmpty(trim(values.bseCode))) {
            validationErrors.bseCode = 'Required BSE Code';
        }

        if (isEmpty(trim(values.isinCode))) {
            validationErrors.isinCode = 'Required ISIN Code';
        }

        if (isEmpty(trim(values.allocationPercentage)) || parseInt(values.allocationPercentage) < 1) {
            validationErrors.allocationPercentage = 'Invalid Allocation Percentage';
        }

        if (isEmpty(trim(values.threeYearReturn)) || parseInt(values.threeYearReturn) < 1) {
            validationErrors.threeYearReturn = 'Invalid Three Year Return';
        }

        if (isEmpty(trim(values.fiveYearReturn)) || parseInt(values.fiveYearReturn) < 1) {
            validationErrors.fiveYearReturn = 'Invalid Five Year Return';
        }

        if (isEmpty(trim(values.inceptionReturn)) || parseInt(values.inceptionReturn) < 1) {
            validationErrors.inceptionReturn = 'Invalid Inception Return';
        }

        if (isEmpty(trim(values.exitload))) {
            validationErrors.exitload = 'Required Exit Load Text';
        }

        if (isEmpty(trim(values.expenseratio))) {
            validationErrors.expenseratio = 'Required Expense Ratio';
        }

        if (isEmpty(trim(values.riskCategory)) || parseInt(values.riskCategory) < 1) {
            validationErrors.riskCategory = 'Required Risk Category';
        }

        return validationErrors;
    };

    const createSchemes = () => {
        const params = {
            name: trim(name),
            description: trim(description),
            scheme_code: trim(schemeCode),
            asset_class: getAssetClassTitle(assetClassID),
            asset_class_id: assetClassID,
            system_portfolio_id: portfolio.system_portfolio_id.toString(),
            system_scheme_id: systemSchemeId,
            bse_code: trim(bseCode),
            isin_code: trim(isinCode),
            allocation_percentage: trim(allocationPercentage),
            system_category_id: systemCategoryID,
            category: toSnakeAndLowerCase(getSystemCategoryTitle(systemCategoryID)),
            is_active: isActive,
            three_year_return: threeYearReturn,
            five_year_return: fiveYearReturn,
            inception_return: inceptionReturn,
            risk_category: riskCategory,
            exit_load_text: exitload,
            expense_ratio: expenseratio.toString(),
        };

        setLoading(true);
        CreateSchemePortfolioMappingApi(params)
            .then((res) => {
                let success = false;
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        handleCreateSchemeCancel();
                        setUpdateList(!updateList);
                        swal(
                            'success!',
                            'Successfully Create Scheme',
                            'success'
                        );
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Scheme Creation Failed';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('OOPS!', errorMessage, 'error');
            });
    };

    const updateScheme = () => {
        const params = {
            id: schemeID,
            name: trim(name),
            description: trim(description),
            scheme_code: trim(schemeCode),
            asset_class: getAssetClassTitle(assetClassID),
            asset_class_id: trim(assetClassID),
            system_portfolio_id: portfolio.system_portfolio_id.toString(),
            system_scheme_id: trim(systemSchemeId),
            bse_code: trim(bseCode),
            isin_code: trim(isinCode),
            allocation_percentage: trim(allocationPercentage),
            category: toSnakeAndLowerCase(getSystemCategoryTitle(systemCategoryID)),
            system_category_id: systemCategoryID,
            is_active: isActive,
            three_year_return: threeYearReturn,
            five_year_return: fiveYearReturn,
            inception_return: inceptionReturn,
            exit_load_text: exitload,
            expense_ratio: expenseratio.toString(),
            risk_category: riskCategory,
        };

        // update from
        setLoading(true);
        UpdateSchemePortfolioMappingApi(params).then((res) => {
                setLoading(false);
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        handleUpdateSchemeCancel();
                        setUpdateList(!updateList);
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to Update Scheme';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const deleteSchemePortfolioMapping = (id) => {
        swal({
            title: 'Are you sure you want to delete this scheme ?',
            text: '',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((success) => {
            if (success) {
                setLoading(true);
                const params = {
                    id,
                };
                DeleteSchemePortfolioMappingApi(params)
                    .then((res) => {
                        let success = false;
                        setLoading(false);
                        if (res.status === 200) {
                            if ((res.data || {}).success) {
                                success = true;
                                setUpdateList(!updateList);
                                swal(
                                    'success!',
                                    'Successfully Deleted Scheme',
                                    'success'
                                );
                            }
                        }
                        if (!success) {
                            setLoading(false);
                            swal(
                                'Oops!',
                                ((res.data || {}).data || {}).error_desc,
                                'error'
                            );
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        let errorMessage = 'Failed to delete Scheme';
                        if (error.response) {
                            if (error.response.status === 400) {
                                errorMessage = (error.response.data || {})
                                    .error_desc;
                            }
                        }
                        swal('Oops!', errorMessage, 'error');
                    });
            }
        });
    };

    const showCreateSchemeModal = () => {
        setShowCreateModal(true);
    };

    const showupdateSchemesModal = (obj) => {
        setSchemeID(obj.id)
        setName(obj.name);
        setDescription(obj.description);
        setSchemeCode(obj.scheme_code);
        setAssetClassID(obj.asset_class_id);
        setSystemCategoryID(obj.system_category_id);
        setSystemSchemeId(obj.system_scheme_id);
        setBseCode(obj.bse_code);
        setIsinCode(obj.isin_code);
        setAllocationPercentage(obj.allocation_percentage);
        setThreeYearReturn(obj.three_year_return);
        setFiveYearReturn(obj.five_year_return);
        setErrors({});
        setInceptionReturn(obj.inception_return);
        setIsActive(obj.is_active.toString());
        setExitload(obj.exit_load_text);
        setExpenseratio(obj.expense_ratio);
        setRiskCategory(obj.risk_category);
        setShowUpdateModal(true);
    };

    const getModelPortfolioMasterList = (id) => {
        setLoading(true);
        const params = {
            id: id,
        };

        GetModelPortfolioMasterListApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setPortfolio(((apiData.results)[0]) || {});
                        setData((apiData.results)[0].scheme_details || []);
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const renderSIPDates = (dates = []) => {
        const tagArray = [];
        if (dates != null) {
            dates.map((date) => {
                tagArray.push(<StyledTag key={date}>{date}</StyledTag>);
                return 0;
            });
        }
        return tagArray;
    };

    const renderPitchingFacts = (facts = []) => {
        const factArray = [];
        if (facts != null) {
            facts.map((fact) => {
                factArray.push(
                    <PitchingDescription>
                        <li key={fact}>
                        </li>
                        <PitchingText>{fact}</PitchingText>
                    </PitchingDescription>
                );
                return 0;
            });
        }
        return factArray;
    };

    return (
        <Grid container spacing={2}>
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />
            <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
                <FilterTitleWrapper>PortFolio Details</FilterTitleWrapper>
                <ImageWrapper src={getBannerImage(
                    portfolio.banner_image
                )} />
                <PortfolioName>{(portfolio || {}).name}</PortfolioName>
                <PortfolioDescription>
                    <PortfolioLabel>Target Return</PortfolioLabel>
                    <PortfolioText>{portfolio.target_return_percentage} %</PortfolioText>
                </PortfolioDescription>
                <PortfolioTextLabel>Recommended For</PortfolioTextLabel>
                <PortfolioDescription>
                    <PortfolioLabel>Recommended For</PortfolioLabel>
                    <PortfolioText>{portfolio.recommended_period}</PortfolioText>
                </PortfolioDescription>
                <PortfolioTextLabel>Lumpsum</PortfolioTextLabel>
                <PortfolioDescription>
                    <PortfolioLabel>Max lumpsum </PortfolioLabel>
                    <PortfolioText>₹ {portfolio.max_lumpsum_amount}</PortfolioText>
                </PortfolioDescription>
                <PortfolioDescription>
                    <PortfolioLabel>Min lumpsum</PortfolioLabel>
                    <PortfolioText>₹ {portfolio.min_lumpsum_amount}</PortfolioText>
                </PortfolioDescription>
                <PortfolioTextLabel>SIP</PortfolioTextLabel>
                <PortfolioDescription>
                    <PortfolioLabel>Max SIP </PortfolioLabel>
                    <PortfolioText>₹ {portfolio.max_sip_amount}</PortfolioText>
                </PortfolioDescription>
                <PortfolioDescription>
                    <PortfolioLabel>Min SIP</PortfolioLabel>
                    <PortfolioText>₹ {portfolio.min_sip_amount}</PortfolioText>
                </PortfolioDescription>
                <PortfolioDescription>
                    <PortfolioLabel>SIP Dates</PortfolioLabel>
                    <PortfolioText>{renderSIPDates(portfolio.sip_dates)}</PortfolioText>
                </PortfolioDescription>

                <PortfolioTextLabel>Redemption</PortfolioTextLabel>

                <PortfolioDescription>
                    <PortfolioLabel>Min Amount</PortfolioLabel>
                    <PortfolioText>₹{portfolio.min_redemption_amount}</PortfolioText>
                </PortfolioDescription>

                <PortfolioTextLabel>Product Comparisions</PortfolioTextLabel>
                <PortfolioDescription>
                    <PortfolioLabel>FD</PortfolioLabel>
                    <PortfolioText>{((((portfolio.product_comparisions || {}).products || [])[0]) || {}).return_percentage} %</PortfolioText>
                </PortfolioDescription>
                <PortfolioDescription>
                    <PortfolioLabel>Lxme</PortfolioLabel>
                    <PortfolioText>{((((portfolio.product_comparisions || {}).products || [])[1]) || {}).return_percentage} %</PortfolioText>
                </PortfolioDescription>
                <PortfolioDescription>
                    <PortfolioLabel>Saving A/C</PortfolioLabel>
                    <PortfolioText>{((((portfolio.product_comparisions || {}).products || [])[2]) || {}).return_percentage} %</PortfolioText>
                </PortfolioDescription>
                <PortfolioTextLabel>Pitching Facts</PortfolioTextLabel>
                {renderPitchingFacts(portfolio.pitching_facts)}
            </FilterGrid>
            <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    style={{ marginBottom: '1rem' }}
                >
                    <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledButton
                            onClick={() =>
                                showCreateSchemeModal()
                            }
                        >
                            Add Scheme
                        </StyledButton>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <CommonTable
                        rowKey={'id'}
                        data={data}
                        columns={tableColumns}
                        pagination={false}
                        scroll={{ x: 'overflow' }}
                    />
                </Grid>
            </Grid>
            <Grid>
                {showCreateModal && (
                    <StyleModal
                        title="Create Scheme"
                        visible={showCreateModal}
                        onOk={(e) => handleCreateSchemeSubmit(e)}
                        onCancel={() => handleCreateSchemeCancel()}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                onClick={(e) =>
                                    handleCreateSchemeSubmit(e)
                                }
                            >
                                Add Scheme
                            </Button>,
                        ]}
                    >
                        <LabelInput>Scheme Name</LabelInput>
                        <Input
                            id="name"
                            placeholder="Scheme Name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        {errors.name && <ErrorText>{errors.name}</ErrorText>}
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Description</LabelInput>
                                <Input
                                    id="description"
                                    placeholder="Description"
                                    name="description"
                                    value={description}
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                />
                                {errors.description && (
                                    <ErrorText>{errors.description}</ErrorText>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Scheme Code</LabelInput>
                                <Input
                                    id="schemeCode"
                                    placeholder="Scheme Code"
                                    name="schemeCode"
                                    value={schemeCode}
                                    onChange={(e) =>
                                        setSchemeCode(e.target.value)
                                    }
                                />
                                {errors.schemeCode && (
                                    <ErrorText>{errors.schemeCode}</ErrorText>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Asset Class</LabelInput>
                                <Select
                                    placeholder="Asset Class"
                                    style={{ width: '100%' }}
                                    value={getAssetClassTitle(assetClassID)}
                                    onChange={(e) => (setAssetClassID(e))}
                                >
                                    {AssetClassList
                                        && createOptionList(AssetClassList.data)}
                                </Select>
                                {errors.assetClass && (
                                    <ErrorText>{errors.assetClass}</ErrorText>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Category</LabelInput>
                                <Select
                                    placeholder="Category"
                                    style={{ width: '100%' }}
                                    value={getSystemCategoryTitle(systemCategoryID)}
                                    onChange={(e) => setSystemCategoryID(e)}
                                >
                                    {CategoryList
                                        && createOptionList(CategoryList.data)}
                                </Select>
                                {errors.category && (
                                    <ErrorText>{errors.category}</ErrorText>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <LabelInput>System Scheme ID</LabelInput>
                                <Input
                                    type="number"
                                    id="systemSchemeId"
                                    placeholder="System Scheme ID"
                                    name="systemSchemeId"
                                    value={systemSchemeId}
                                    onChange={(e) => setSystemSchemeId(e.target.value)}
                                />
                                {errors.systemSchemeId && (
                                    <ErrorText>{errors.systemSchemeId}</ErrorText>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <LabelInput>ISIN Code</LabelInput>
                                <Input
                                    id="isinCode"
                                    placeholder="ISIN CODE"
                                    name="isinCode"
                                    value={isinCode}
                                    onChange={(e) => setIsinCode(e.target.value)}
                                />
                                {errors.isinCode && (
                                    <ErrorText>{errors.isinCode}</ErrorText>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LabelInput>BSE Code</LabelInput>
                                <Input
                                    id="bseCode"
                                    placeholder="BSE Code"
                                    name="bseCode"
                                    value={bseCode}
                                    onChange={(e) => setBseCode(e.target.value)}
                                />
                                {errors.bseCode && (
                                    <ErrorText>{errors.bseCode}</ErrorText>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Allocation Percentage</LabelInput>
                                <Input
                                    type="number"
                                    id="allocationPercentage"
                                    placeholder="Allocation Percentage"
                                    name="allocationPercentage"
                                    value={allocationPercentage}
                                    maxLength={15}
                                    min={1}
                                    max={100}
                                    onChange={(e) => setAllocationPercentage(e.target.value)}
                                />
                                {errors.allocationPercentage && (
                                    <ErrorText>{errors.allocationPercentage}</ErrorText>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Three Year Return</LabelInput>
                                <Input
                                    type="number"
                                    id="threeYearReturn"
                                    placeholder="Three Year Return"
                                    name="threeYearReturn"
                                    value={threeYearReturn}
                                    maxLength={15}
                                    min={1}
                                    max={100}
                                    onChange={(e) => setThreeYearReturn(e.target.value)}

                                />
                                {errors.threeYearReturn && (
                                    <ErrorText>{errors.threeYearReturn}</ErrorText>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Five Year Return</LabelInput>
                                <Input
                                    type="number"
                                    id="fiveYearReturn"
                                    placeholder="Five Year Return"
                                    name="fiveYearReturn"
                                    value={fiveYearReturn}
                                    maxLength={15}
                                    min={1}
                                    max={100}
                                    onChange={(e) => setFiveYearReturn(e.target.value)}
                                />
                                {errors.fiveYearReturn && (
                                    <ErrorText>{errors.fiveYearReturn}</ErrorText>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Return Since Inception</LabelInput>
                                <Input
                                    type="number"
                                    id="inceptionReturn"
                                    placeholder="Since Inception Return"
                                    name="inceptionReturn"
                                    value={inceptionReturn}
                                    maxLength={15}
                                    min={1}
                                    max={100}
                                    onChange={(e) => setInceptionReturn(e.target.value)}
                                />
                                {errors.inceptionReturn && (
                                    <ErrorText>{errors.inceptionReturn}</ErrorText>
                                )}
                            </Grid>
                        </Grid>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Exit Load Text</LabelInput>
                                <Input
                                    id="exitload"
                                    placeholder="Exit Load Text"
                                    name="exitload"
                                    value={exitload}
                                    onChange={(e) =>
                                        setExitload(e.target.value)
                                    }
                                />
                                {errors.exitload && (
                                    <ErrorText>{errors.exitload}</ErrorText>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Expense Ratio</LabelInput>
                                <Input
                                    id="expenseratio"
                                    type="number"
                                    placeholder="Expense Ratio"
                                    name="expenseratio"
                                    value={expenseratio}
                                    maxLength={15}
                                    min={0}
                                    max={100}
                                    precision={2}
                                    onChange={(e) =>
                                        setExpenseratio(e.target.value)
                                    }
                                />
                                {errors.expenseratio && (
                                    <ErrorText>{errors.expenseratio}</ErrorText>
                                )}
                            </Grid>
                        </Grid>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Risk Category</LabelInput>
                                <Select
                                    placeholder="Risk Category"
                                    style={{ width: '100%' }}
                                    value={riskCategory}
                                    onChange={(e) => setRiskCategory(e)}
                                >
                                    {RiskCategoryList
                                        && createOptionList(RiskCategoryList.data)}
                                </Select>
                                {errors.riskCategory && (
                                    <ErrorText>{errors.riskCategory}</ErrorText>
                                )}
                            </Grid>

                            <Grid item xs={12} md={6}>
                                
                                <LabelInput>Is Active</LabelInput>
                                <Select
                                    style={{ width: '100%' }}
                                    value={isActive}
                                    onChange={(e) => setIsActive(e)}
                                >
                                    {StatusList
                                        && createOptionList(StatusList.data)}
                                </Select>
                                {errors.isActive && (
                                    <ErrorText>{errors.isActive}</ErrorText>
                                )}
                            </Grid>
                        </Grid>
                    </StyleModal>
                )}
            </Grid>

            <Grid>
                {showUpdateModal && (
                    <StyleModal
                        title="Update Scheme"
                        visible={showUpdateModal}
                        onOk={(e) => handleUpdateSchemeSubmit(e)}
                        onCancel={() => handleUpdateSchemeCancel()}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                onClick={(e) =>
                                    handleUpdateSchemeSubmit(e)
                                }
                            >
                                Edit Scheme
                            </Button>,
                        ]}
                    >
                        <LabelInput>Scheme Name</LabelInput>
                        <Input
                            id="name"
                            placeholder="Scheme Name"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        {errors.name && <ErrorText>{errors.name}</ErrorText>}
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Description</LabelInput>
                                <Input
                                    id="description"
                                    placeholder="Description"
                                    name="description"
                                    value={description}
                                    onChange={(e) =>
                                        setDescription(e.target.value)
                                    }
                                />
                                {errors.description && (
                                    <ErrorText>{errors.description}</ErrorText>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Scheme Code</LabelInput>
                                <Input
                                    id="schemeCode"
                                    placeholder="Scheme Code"
                                    name="schemeCode"
                                    value={schemeCode}
                                    onChange={(e) =>
                                        setSchemeCode(e.target.value)
                                    }
                                />
                                {errors.schemeCode && (
                                    <ErrorText>{errors.schemeCode}</ErrorText>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Asset Class</LabelInput>
                                <Select
                                    placeholder="Asset Class"
                                    style={{ width: '100%' }}
                                    value={getAssetClassTitle(assetClassID)}
                                    onChange={(e) => {
                                        setAssetClassID(e)
                                    }}
                                >
                                    {AssetClassList
                                        && createOptionList(AssetClassList.data)}
                                </Select>
                                {errors.assetClass && (
                                    <ErrorText>{errors.assetClass}</ErrorText>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Category</LabelInput>
                                <Select
                                    placeholder="Category"
                                    style={{ width: '100%' }}
                                    value={getSystemCategoryTitle(systemCategoryID)}
                                    onChange={(e) => setSystemCategoryID(e)}
                                >
                                    {CategoryList
                                        && createOptionList(CategoryList.data)}
                                </Select>
                                {errors.category && (
                                    <ErrorText>{errors.category}</ErrorText>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <LabelInput>System Scheme ID</LabelInput>
                                <Input
                                    type="number"
                                    id="systemSchemeId"
                                    placeholder="System Scheme ID"
                                    name="systemSchemeId"
                                    value={systemSchemeId}
                                    onChange={(e) => setSystemSchemeId(e.target.value)}
                                />
                                {errors.systemSchemeId && (
                                    <ErrorText>{errors.systemSchemeId}</ErrorText>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <LabelInput>ISIN Code</LabelInput>
                                <Input
                                    id="isinCode"
                                    placeholder="ISIN CODE"
                                    name="isinCode"
                                    value={isinCode}
                                    onChange={(e) => setIsinCode(e.target.value)}
                                />
                                {errors.isinCode && (
                                    <ErrorText>{errors.isinCode}</ErrorText>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LabelInput>BSE Code</LabelInput>
                                <Input
                                    id="bseCode"
                                    placeholder="BSE Code"
                                    name="bseCode"
                                    value={bseCode}
                                    onChange={(e) => setBseCode(e.target.value)}
                                />
                                {errors.bseCode && (
                                    <ErrorText>{errors.bseCode}</ErrorText>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Allocation Percentage</LabelInput>
                                <Input
                                    type="number"
                                    id="allocationPercentage"
                                    placeholder="Allocation Percentage"
                                    name="allocationPercentage"
                                    value={allocationPercentage}
                                    maxLength={15}
                                    min={1}
                                    max={100}
                                    onChange={(e) => setAllocationPercentage(e.target.value)}
                                />
                                {errors.allocationPercentage && (
                                    <ErrorText>{errors.allocationPercentage}</ErrorText>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Three Year Return</LabelInput>
                                <Input
                                    type="number"
                                    id="threeYearReturn"
                                    placeholder="Three Year Return"
                                    name="threeYearReturn"
                                    value={threeYearReturn}
                                    maxLength={15}
                                    min={1}
                                    max={100}
                                    onChange={(e) => setThreeYearReturn(e.target.value)}

                                />
                                {errors.threeYearReturn && (
                                    <ErrorText>{errors.threeYearReturn}</ErrorText>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Five Year Return</LabelInput>
                                <Input
                                    type="number"
                                    id="fiveYearReturn"
                                    placeholder="Five Year Return"
                                    name="fiveYearReturn"
                                    value={fiveYearReturn}
                                    maxLength={15}
                                    min={1}
                                    max={100}
                                    onChange={(e) => setFiveYearReturn(e.target.value)}
                                />
                                {errors.fiveYearReturn && (
                                    <ErrorText>{errors.fiveYearReturn}</ErrorText>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Return Since Inception</LabelInput>
                                <Input
                                    type="number"
                                    id="inceptionReturn"
                                    placeholder="Since Inception Return"
                                    name="inceptionReturn"
                                    value={inceptionReturn}
                                    maxLength={15}
                                    min={1}
                                    max={100}
                                    onChange={(e) => setInceptionReturn(e.target.value)}
                                />
                                {errors.inceptionReturn && (
                                    <ErrorText>{errors.inceptionReturn}</ErrorText>
                                )}
                            </Grid>
                        </Grid>

                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Exit Load Text</LabelInput>
                                <Input
                                    id="exitload"
                                    placeholder="Exit Load Text"
                                    name="exitload"
                                    value={exitload}
                                    onChange={(e) =>
                                        setExitload(e.target.value)
                                    }
                                />
                                {errors.exitload && (
                                    <ErrorText>{errors.exitload}</ErrorText>
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Expense Ratio</LabelInput>
                                <Input
                                    id="expenseratio"
                                    type="number"
                                    placeholder="Expense Ratio"
                                    name="expenseratio"
                                    value={expenseratio}
                                    maxLength={15}
                                    min={0}
                                    max={100}
                                    precision={2}
                                    onChange={(e) =>
                                        setExpenseratio(e.target.value)
                                    }
                                />
                                {errors.expenseratio && (
                                    <ErrorText>{errors.expenseratio}</ErrorText>
                                )}
                            </Grid>
                        </Grid>


                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                    <LabelInput>Risk Category</LabelInput>
                                    <Select
                                        placeholder="Risk Category"
                                        style={{ width: '100%' }}
                                        value={riskCategory}
                                        onChange={(e) => setRiskCategory(e)}
                                    >
                                        {RiskCategoryList
                                            && createOptionList(RiskCategoryList.data)}
                                    </Select>
                                    {errors.riskCategory && (
                                        <ErrorText>{errors.riskCategory}</ErrorText>
                                    )}
                            </Grid>
                            
                            <Grid item xs={12} md={6}>
                                <LabelInput>Is Active</LabelInput>
                                <Select
                                    style={{ width: '100%' }}
                                    value={isActive}
                                    onChange={(e) => setIsActive(e)}
                                >
                                    {StatusList
                                        && createOptionList(StatusList.data)}
                                </Select>
                                {errors.isActive && (
                                    <ErrorText>{errors.isActive}</ErrorText>
                                )}
                            </Grid>
                        </Grid>
                    </StyleModal>
                )}
            </Grid>
        </Grid>
    );
};
export default ModelPortfoliosDetail;