/* eslint-disable */
/* eslint-disable import/prefer-default-export */
import {
    GetPollListApiURL,
    GetPollFiltersApiURL,
    DeletePollApiURL,
    CreatePollApiURL,
    UpdatePollApiURL,
    GetPollDetialApiURL,
} from './apiConfig';
import axios from './instance';

export const GetPollListApi = (params) =>
    axios.get(GetPollListApiURL, { params });

export const GetPollFiltersApi = () => axios.get(GetPollFiltersApiURL);

export const DeletePollApi = (params) =>
    axios.delete(DeletePollApiURL, { data: { ...params } });

export const CreatePollApi = (params) => axios.post(CreatePollApiURL, params);

export const UpdatePollApi = (params) => axios.put(UpdatePollApiURL, params);

export const GetPollDetialApi = (params) =>
    axios.get(GetPollDetialApiURL, { params });
