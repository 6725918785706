import styled from 'styled-components';
import FlagIcon from '@material-ui/icons/Flag';

export const RedFlagIcon = styled(FlagIcon)`
    color: red;
`;

export const GreenFlagIcon = styled(FlagIcon)`
    color: green;
`;
