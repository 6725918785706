/* eslint-disable */
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Tag, Input, Upload } from 'antd';

const { TextArea } = Input;

export const CommentReplyWrapper = styled.div``;

export const StyledInput = styled(TextArea)`
    margin-bottom: 0.5rem;
`;

export const StyledButton = styled.button`
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    background: ${(props) => props.theme.pinklogo};
    color: ${(props) => props.theme.white};
    font-size: 1em;
    width: 100%;
    padding: 0.2em 1em;
    outline: none;
    border: 2px solid ${(props) => props.theme.pinklogo};
    border-radius: 3px;
    float: right;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;