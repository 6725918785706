/* eslint-disable */
/* eslint-disable comma-dangle */
/* eslint-disable no-shadow */

import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import swal from "sweetalert";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useSelector } from "react-redux";

import Avatar from "@material-ui/core/Avatar";
import { truncate, toLower, map } from "lodash";
import CommonTable from "../Common/Table";
import {
  FilterGrid,
  FilterTitleWrapper,
  StyleGrid,
  StyledSwitch,
  StyledButton,
} from "./styles";
import Paginator from "../Common/Paginator";
import CommonLoader from "../Common/Loader";
import {
  GetAppUsersListApi,
  ActiveUserApi,
  DeactiveUserApi,
  GetAppUserFiltersApi,
} from "../../api/appUserList";
import { GetRefreshUserHoldingsApi } from "../../api/getHoldingList";
import { getUserAvatar } from "../../util/custom";
import CommonDropdown from "../Common/Dropdown";
import { getKey } from "../../util/storage";

const AppUsers = () => {
  const auth = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(1);
  const [userRole, setUserRole] = useState(auth.role || "");
  const [filters, setFilters] = useState({
    page: currentPage,
    page_limit: pageSize,
    sort_by: "updated_at",
    sort_type: "desc",
    is_deleted: "false",
    is_active: "true",
  });
  const [userFilters, setUserFilters] = useState({
    display_name: {
      key: "display_name",
      data: [],
    },
    email: {
      key: "email",
      data: [],
    },
    id: {
      key: "id",
      data: [],
    },
    mobile_number: {
      key: "mobile_number",
      data: [],
    },
    party_id: {
      key: "party_id",
      data: [],
    },
  });

  const getAppUserList = (params) => {
    setLoading(true);
    GetAppUsersListApi(params)
      .then((res) => {
        let success = false;
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            const apiData = (res.data || {}).data || {};
            setData(apiData.results);
            setCurrentPage(apiData.page);
            setPageSize(apiData.page_limit);
            setTotal(apiData.total_count);
            setFilters(params);
            setLoading(false);
          }
        }
        if (!success) {
          setLoading(false);
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to fetch data";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  const getUserFilterList = () => {
    setLoading(true);
    GetAppUserFiltersApi()
      .then((res) => {
        let success = false;
        setLoading(false);
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            const apiData = (res.data || {}).data || {};
            console.log(
              "🚀 ~ file: index.jsx:125 ~ .then ~ apiData.display_name_list:",
              apiData.display_name_list
            );

            setUserFilters({
              display_name: {
                key: "display_name",
                data: apiData.display_name_list,
              },
              email: {
                key: "email",
                data: apiData.email_list,
              },
              id: {
                key: "id",
                data: apiData.id_list,
              },
              mobile_number: {
                key: "mobile_number",
                data: apiData.mobile_number_list,
              },
              party_id: {
                key: "party_id",
                data: apiData.party_id_list,
              },
            });
          }
        }
        if (!success) {
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to fetch data";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  useEffect(() => {
    filters.page = currentPage;
    filters.page_limit = pageSize;
    getAppUserList(filters);
  }, [currentPage, pageSize]);

  useEffect(() => {
    getUserFilterList();
  }, []);

  useEffect(() => {
    let role = "";
    role = auth.role;
    if (role === "" || role === null || role === undefined) {
      role = getKey("role");
    }
    setUserRole(role);
  }, [auth]);

  const modifyUsersStatus = (userInfo, isChecked) => {
    setData((users) => [
      ...map(users, (user) => {
        if (user.id === userInfo.id) {
          user.is_active = isChecked;
        }
        return user;
      }),
    ]);
  };

  const deactivateUser = (userInfo, isChecked) => {
    swal({
      title: "Are you sure to deactive this user ?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((success) => {
      const params = {
        user_id: userInfo.id,
      };
      if (success) {
        setLoading(true);
        DeactiveUserApi(params)
          .then((res) => {
            setLoading(false);
            let success = false;
            if (res.status === 200) {
              if ((res.data || {}).success) {
                success = true;
                getAppUserList(filters);
                swal("success!", "Successfully deactivate the user", "success");
              }
            }
            if (!success) {
              swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
            }
          })
          .catch((error) => {
            setLoading(false);
            modifyUsersStatus(userInfo, !isChecked);
            let errorMessage = "Failed to Deactive user";
            if (error.response) {
              if (error.response.status === 400) {
                errorMessage = (error.response.data || {}).error_desc;
              }
            }
            swal("Oops!", errorMessage, "error");
          });
      } else {
        modifyUsersStatus(userInfo, !isChecked);
      }
    });
  };

  const activateUser = (userInfo, isChecked) => {
    swal({
      title: "Are you sure to activate this user ?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((success) => {
      const params = {
        user_id: userInfo.id,
      };
      if (success) {
        setLoading(true);
        ActiveUserApi(params)
          .then((res) => {
            setLoading(false);
            let success = false;
            if (res.status === 200) {
              if ((res.data || {}).success) {
                success = true;
                getAppUserList(filters);
                swal("success!", "Successfully actived the user", "success");
              }
            }
            if (!success) {
              swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
            }
          })
          .catch((error) => {
            setLoading(false);
            modifyUsersStatus(userInfo, !isChecked);
            let errorMessage = "Failed to active user";
            if (error.response) {
              if (error.response.status === 400) {
                errorMessage = (error.response.data || {}).error_desc;
              }
            }
            swal("Oops!", errorMessage, "error");
          });
      } else {
        modifyUsersStatus(userInfo, !isChecked);
      }
    });
  };

  const handleChange = (userInfo, isChecked) => {
    modifyUsersStatus(userInfo, isChecked);
    if (isChecked) {
      activateUser(userInfo, isChecked);
    } else {
      deactivateUser(userInfo, isChecked);
    }
  };

  const renderActions = (userInfo) => {
    return (
      <FormControlLabel
        control={
          <StyledSwitch
            checked={!!userInfo.is_active}
            onChange={(checked) => handleChange(userInfo, checked)}
          />
        }
      />
    );
  };

  const refreshUserHoldings = (id) => {
    setLoading(true);
    const params = {
      user_id: id,
    };
    GetRefreshUserHoldingsApi(params)
      .then((res) => {
        let success = false;
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            const apiData = (res.data || {}).data || {};
            setLoading(false);
            swal(
              "Yayy!",
              "Successfully fetched holdings for the user",
              "success"
            );
          }
        }
        if (!success) {
          setLoading(false);
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to fetch holdings";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  const renderRefreshHoldings = (userInfo) => {
    return (
      userInfo.role === "user" && (
        <StyledButton onClick={() => refreshUserHoldings(userInfo.id)}>
          Refresh
        </StyledButton>
      )
    );
  };

  const tableColumns = [
    {
      title: "User",
      dataIndex: "profile_picture",
      key: "id",
      width: 10,
      align: "center",
      render: (profile) => (
        <Avatar alt="Remy Sharp" src={getUserAvatar(profile)} />
      ),
    },
    // {
    //     title: 'ID',
    //     dataIndex: 'id',
    //     key: 'id',
    //     width: 10,
    //     align: 'center',
    // },
    {
      title: "Name",
      dataIndex: "display_name",
      key: "display_name",
    },
    {
      title: "Mobile",
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email) => toLower(email),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Occupation",
      dataIndex: "occupation",
      width: 120,
      key: "occupation",
    },
    {
      title: "Bio",
      key: "biography",
      dataIndex: "biography",
      width: 180,
      render: (content) =>
        truncate(content, {
          length: 125,
          separator: "...",
        }),
    },
  ];

  if (userRole === "admin" || userRole === "super_admin") {
    tableColumns.push({
      title: "Actions",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (record, index) => renderActions(index),
    });
    tableColumns.push({
      title: "Holdings",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (record, index) => renderRefreshHoldings(index),
    });
  }

  const sortTypeList = {
    key: "sort_type",
    data: [
      {
        id: "asc",
        title: "Asc",
        selected: false,
      },
      {
        id: "desc",
        title: "Desc",
        selected: true,
      },
    ],
  };

  const sortByList = {
    key: "sort_by",
    data: [
      {
        id: "id",
        title: "id",
        selected: false,
      },
      {
        id: "created_at",
        title: "created_at",
        selected: false,
      },
      {
        id: "updated_at",
        title: "updated_at",
        selected: true,
      },
    ],
  };

  const IsActive = {
    key: "is_active",
    data: [
      {
        id: "true",
        title: "Active",
        selected: true,
      },
      {
        id: "false",
        title: "Inactive",
        selected: false,
      },
    ],
  };

  const roleList = {
    key: "role",
    data: [
      {
        id: "user",
        title: "User",
        selected: false,
      },
      {
        id: "lead",
        title: "Lead",
        selected: false,
      },
    ],
  };

  const handleFilterChange = (filters) => {
    getAppUserList(filters);
  };

  return (
    <StyleGrid
      container
      spacing={2}
      style={{
        paddingTop: "1rem",
        paddingRight: "1rem",
        paddingLeft: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <CommonLoader
        isLoading={loading}
        size="large"
        sentences={["please wait ..."]}
        type="pacman"
      />
      <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
        <FilterTitleWrapper>Filters</FilterTitleWrapper>
        <CommonDropdown
          list={sortByList}
          title="Sort By"
          searchable={false}
          onChange={(filters) => handleFilterChange(filters)}
          filters={filters}
        />
        <CommonDropdown
          list={sortTypeList}
          title="Sort Type"
          searchable={false}
          onChange={(filters) => handleFilterChange(filters)}
          filters={filters}
        />
        <CommonDropdown
          list={IsActive}
          title="Active"
          onChange={(filters) => handleFilterChange(filters)}
          filters={filters}
        />
        <CommonDropdown
          list={roleList}
          title="Role"
          onChange={(filters) => handleFilterChange(filters)}
          filters={filters}
        />
        {userFilters.id.data.length > 0 && (
          <CommonDropdown
            list={userFilters.id}
            title="ID"
            searchable
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
        )}
        {userFilters.display_name.data.length > 0 && (
          <CommonDropdown
            list={userFilters.display_name}
            title="Display Name"
            searchable
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
        )}
        {userFilters.email.data.length > 0 && (
          <CommonDropdown
            list={userFilters.email}
            title="Email"
            searchable
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
        )}
        {userFilters.mobile_number.data.length > 0 && (
          <CommonDropdown
            list={userFilters.mobile_number}
            title="Mobile Number"
            searchable
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
        )}
        {userFilters.party_id.data.length > 0 && (
          <CommonDropdown
            list={userFilters.party_id}
            title="Party ID"
            searchable
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
        )}
      </FilterGrid>
      <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
        <CommonTable
          rowKey="id"
          data={data}
          columns={tableColumns}
          pagination={false}
          scroll={{ x: "overflow" }}
        />
        <Paginator
          totalRecords={total}
          pageLimit={pageSize}
          pageNeighbours={2}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </Grid>
    </StyleGrid>
  );
};
export default AppUsers;
