import styled from 'styled-components';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

const LogoutButton = styled(PowerSettingsNewIcon)`
    background-color: Transparent;
    color: #fff;
    font-size: 1.2rem;
    margin: 0.5rem;
    cursor: pointer;
    &:hover {
        color: ${(props) => props.theme.pinklogo};
    }
`;
export default LogoutButton;
