/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import NavBar from '../component/NavBar';

const propTypes = {
    isAuthenticated: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
        .isRequired,
    redirect: PropTypes.string,
    restricted: PropTypes.bool,
};

const defaultProps = {
    restricted: false,
    redirect: '/',
    isAuthenticated: false,
};

export const PrivateRoute = ({
    isAuthenticated,
    component: Component,
    redirect: pathname,
    restricted,
    ...rest
}) => (
    <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        render={(props) => (isAuthenticated && !restricted ? (
                <>
                    <NavBar />
                    <Component {...props} />
                </>
            ) : (
                <Redirect to={{ pathname }} />
            ))}
    />
);

PrivateRoute.propTypes = propTypes;
PrivateRoute.defaultProps = defaultProps;

export default PrivateRoute;
