import React from 'react';
import { withRouter } from 'react-router-dom';
import EventDetail from '../../component/EventDetail';
import { Wrapper } from './styles';

const EventDetailPage = (props) => (
    <Wrapper>
        <EventDetail pageProps={props} />
    </Wrapper>
);
export default withRouter(EventDetailPage);
