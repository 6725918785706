import React from 'react';
import { withRouter } from 'react-router-dom';
import QuestionDetail from '../../component/QuestionDetail';
import { Wrapper } from './styles';

const QuestionDetailPage = (props) => (
    <Wrapper>
        <QuestionDetail pageProps={props} />
    </Wrapper>
);

export default withRouter(QuestionDetailPage);
