import styled from 'styled-components';
import { Table } from 'antd';

export const StyledTable = styled(Table)`
    .ant-table-thead tr th {
        color: ${(props) => (props.currentTheme === 'light' ? '#000' : '#fff')}!important;
        background: ${(props) => (props.currentTheme === 'light' ? '#fff' : '#141414')}!important;
        transition: none !important;
    }
    .ant-table-row {
        color: ${(props) => (props.currentTheme === 'light' ? '#000' : '#fff')}!important;
        background: ${(props) => (props.currentTheme === 'light' ? '#fff' : '#141414')}!important;
        transition: none !important;
    }
    .ant-table-row:hover {
        color: ${(props) => (props.currentTheme === 'light' ? '#000' : '#fff')}!important;
        background: ${(props) => (props.currentTheme === 'light' ? '#f8f8f8' : '#000')}!important;
        transition: none !important;
    }
    .ant-table-tbody tr:hover:not(.ant-table-expanded-row) td {
        background: unset;
    }
    .ant-table-expanded-row.ant-table-expanded-row-level-1 > td {
        color: ${(props) => (props.currentTheme === 'light' ? '#000' : '#fff')}!important;
        background: ${(props) => (props.currentTheme === 'light' ? '#f8f8f8' : '#000')}!important;
        transition: none !important;
    }
    .ant-table-expanded-row.ant-table-expanded-row-level-1 > td {
        margin: 0px;
        background: ${(props) => props.theme.nestedTableBackground}!important;
    }
`;

export const IconWrapper = styled.div`
    cursor: pointer
`;
