/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Avatar } from 'antd';
import styled from 'styled-components';
import {
    HeaderWrapper,
    AuhtorName,
    CreatedAt,
    StyledAvatar,
    Author,
    StlyedSkeleton,
} from './styles';

import { getUserProfilePicture } from '../../util/custom';

const PostDetailHeader = (props) => {
    const {
        authorImageURL,
        authorName,
        createdAt,
        postID,
        postType,
        loading,
    } = props;

    return loading ? (
        <StlyedSkeleton avatar paragraph={{ rows: 0 }} />
    ) : (
        <HeaderWrapper>
            <Author>
                <StyledAvatar src={getUserProfilePicture(authorImageURL)} />
                <span>
                    <AuhtorName>{authorName}</AuhtorName>
                    <CreatedAt>{createdAt}</CreatedAt>
                </span>
            </Author>
        </HeaderWrapper>
    );
};

PostDetailHeader.propTypes = {
    authorImageURL: PropTypes.string.isRequired,
    authorName: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    postID: PropTypes.string.isRequired,
    postType: PropTypes.string.isRequired,
    loading: PropTypes.bool,
};

PostDetailHeader.defaultProps = {
    loading: false,
};

export default PostDetailHeader;
