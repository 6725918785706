import { all } from 'redux-saga/effects';
import { watchUserLogin } from './authSaga';

// eslint-disable-next-line func-names
const rootSaga = function* () {
    yield all([
        /* add generator function here */
        // watchUserOtpVerification(),
        watchUserLogin(),
    ]);
};
export default rootSaga;
