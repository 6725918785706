import styled from 'styled-components';
import { Skeleton, Modal, Avatar } from 'antd';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';

export const FooterActionSection = styled.span`
    margin: 0 0.5rem;
`;

export const FooterActionCountSection = styled.span`
    position: absolute;
`;

export const FooterActionIconSection = styled.span`
    margin: 0 1.2rem;
    cursor: pointer;
`;

export const StlyedSkeleton = styled(Skeleton)`
    padding: 0.5rem 2rem;
`;

export const StyleModal = styled(Modal)`
    .ant-modal-header {
        background: ${(props) => props.theme.likedark};
        color: ${(props) => props.theme.dark};
    }

    .ant-modal-body {
        background: ${(props) => props.theme.likedark};
        height: 100%;
    }

    .ant-modal-title {
        color: ${(props) => props.theme.text};
    }
    .ant-modal-footer {
        background: ${(props) => props.theme.likedark};
    }
    .ant-modal-close-icon {
        color: ${(props) => props.theme.text};
    }
    .ant-modal .sc-AzgDb .jcGGxa {
        padding: 0px;
    }
`;
export const LabelInput = styled.div`
    font-size: 0.8rem;
    font-weight: 600;
    /* margin-bottom: 0.8rem; */
    color: ${(props) => props.theme.text};
`;

export const StyledAvatar = styled(Avatar)`
    margin-right: 1rem;
    width: ${(props) => (props.isChild ? 2 : 2.5)}rem;
    height: ${(props) => (props.isChild ? 2 : 2.5)}rem;


`;

export const Author = styled.span`
    display: flex;
    flex-direction: row;
    
    align-items: center;
`;

export const StyledScrollbar = styled.div`
    /* width */
    ::-webkit-scrollbar {
        width: 0.5rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 15px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #8d8b8b;
    }
`;

export const BorderTop = styled.div`
    ${'' /* border-top: 1px solid ${(props) => props.theme.text}; */}
    padding-top: 1rem;
`;

export const DeleteCommentIcon = styled(DeleteIcon)`
    float: right;
    color: #e9137c;
    cursor: pointer;
    font-size: 1rem;
    margin-left: 5px;
`;

export const FooterWrapper = styled.div`
    display: flex;
    padding: 0 1rem;
    flex-direction: row;
    align-items: center;
    padding-bottom: 1rem;
    font-size: 1rem;
    color: ${(props) => props.theme.text};
`;

export const AuthorName = styled.span`
    font-size: 0.8rem;
    font-weight: 600;
    color: ${(props) => props.theme.text};
`;

export const CreatedAt = styled.div`
    font-size: 0.7rem;
    color: ${(p) => p.theme.text};
    border-bottom: 1px solid rgba(0, 0, 0, 0.54);
    border: 0;
`;

export const LikeWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    color: ${(props) => props.theme.text};
    padding: 0.7rem 0rem;
    margin: 0rem 0 0.5rem 0;
    border-bottom: 1px solid ${(props) => props.theme.text};
`;

export const CommentReplySection = styled(Grid)`
    padding: 0.4rem;
    margin-top: ${(props) => (props.isFirstChild ? 1 : 0)}rem; 
    margin-left: auto;
`;

export const CommentWrapper = styled.div`
    justify-content: space-between;
    align-items: flex-start;
    color: ${(props) => props.theme.text};
    cursor: pointer;
    border-radius: 5px;
    padding: 0.4rem;
    display: flex;
    flex-wrap: wrap;
    margin-top: ${(props) => (props.isFirstChild ? 1 : 0)}rem;
`;

export const CommentText = styled.div`
    text-align: justify;
    flex: 1;
    justify-content: space-between;
    color: ${(p) => p.theme.text};
    border-radius: 2px 15px;
    background: ${(props) => props.theme.contentBackground};
    padding: 0.2rem 1rem 0.2rem 1rem;
    min-height:80px;
    @media (max-width: 320px) {
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        margin: 0rem 0rem 0rem 0rem;
        min-width: 15vw;
    }
    @media (max-width: 768px) {
        min-width: 53vw;
    }
`;

export const AuthorInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const CommentsWrapper = styled.div`
    align-items: center;
    padding-bottom: 1rem;
    font-size: 1rem;
    padding: 0 1rem;
    color: ${(props) => props.theme.text};
`;

export const SubCommentWrapper = styled.div`
    margin: 0rem 0rem 0rem 3rem;
    @media (max-width: 320px) {
        padding: 0rem 0rem 0rem 0rem;
        margin: 0rem 0rem 0rem 1rem;
    }
`;

export const MainGrid = styled(Grid)`
    margin: 0!important;
    padding-top: 10px;
    border-top: 1px solid ${(props) => props.theme.postHeader};
    max-width: 100%;
`;

export const CommentGrid = styled(Grid)`
    display: block;
    width: 100%;
`;

export const CommentContentWrapper = styled.div`
    ${'' /* background-color: blue; */}
    font-size: 14px;

`;

export const CommentArea = styled.div`
    background-color: ${(props) => props.theme.postHeader};
    min-height: 4rem;
    border-radius: 2px 15px;
    padding: 3px 10px;
    padding-bottom: 1rem;
`;

export const CommentFooterArea = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    /* '& > p' {
        margin-right: 1rem;
    } */
`;

export const CommentSection = styled.div`
    flex: 1;
    position: relative;
`;

export const DeleteLink = styled.div`
    font-size: 13px;
    margin-left: ${(props) => (props.addLeftMargin ? 10 : 0)}px;
`;

export const ReplyLink = styled.div`
    display: flex;
    margin-right: 1rem;
    font-size: 13px;
    cursor: pointer;
    align-items: center;
    cursor: pointer;
`;

export const ReplyText = styled.div``;

export const ReplyCount = styled.div`
    margin-left: 5px;
`;

export const LikeCount = styled.div`
    margin-left: 3px;
    color: #5a5151;
`;

export const LikeSection = styled.div`
    background: white;
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(-50%) translateX(-10%);
    border-radius: 50px;
    display: flex;
    align-items: center;
    box-shadow: 1px 10px 20px #6666 !important;
    padding: 0px 10px;
`;

export const ChildCommentWrapper = styled.div`
    width: 90%;
    margin-left: auto;
`;

export const IconTooltipWrapper = styled.span`
    margin: 0 1rem;
`;
