import { size, isEmpty } from 'lodash';
import {
    AUTHENTICATE,
    AUTHENTICATE_SUCCESS,
    AUTHENTICATE_FAILED,
    LOGOUT,
    LOGOUT_FAILED,
    LOGOUT_SUCCESS,
    SET_PROFILE,
    CLEAR_PROFILE,
} from '../actions/types';
import {
    setToken,
    clearAccessRefreshToken,
    setAdmin,
    clearAdmin,
    setData,
    getData,
    clearData,
} from '../util/storage';

let preloadedState = getData('adminUser');
if (preloadedState && size(preloadedState)) {
    preloadedState = JSON.parse(preloadedState);
}

const initialState = {
    isLoading: false,
    admin: {},
    error: {},
    ...(isEmpty(preloadedState) ? {} : preloadedState),
    isAuthenticated: false,
};

const handleUserAuth = (payload) => {
    if (payload.action === 'login-success') {
        setAdmin(payload.admin);
        setData('adminUser', payload);
        setToken({
            accessToken: payload.access_token,
            refreshToken: payload.refresh_token,
        });
        return {
            isAuthenticated: true,
            ...payload,
        };
    }
    setData('adminUser', payload);
    return {
        isAuthenticated: false,
        ...payload,
     };
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
    switch (action.type) {
        case AUTHENTICATE:
            return {
                ...state,
                isLoading: true,
            };
        case AUTHENTICATE_SUCCESS:
        case SET_PROFILE:

            return {
                ...state,
                isLoading: false,
                ...handleUserAuth(action.payload),
                // isAuthenticated: true,
                // ...action.payload,
            };
        case AUTHENTICATE_FAILED:
            return {
                ...state,
                error: action.error,
                isAuthenticated: false,
                isLoading: false,
            };
        case LOGOUT:
            return {
                ...initialState,
                isLoading: true,
            };

        case LOGOUT_SUCCESS:
        case CLEAR_PROFILE:
            clearAccessRefreshToken();
            clearAdmin();
            clearData('adminUser');
            return {
                ...initialState,
                ...action.payload,
                isLoading: false,
                isAuthenticated: false,
                status: 'profileCleared',
            };
        case LOGOUT_FAILED:
            return {
                ...initialState,
                isLoading: false,
                error: action.error,
            };

        default:
            return state;
    }
}
