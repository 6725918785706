import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Paginator from 'react-hooks-paginator';

const CommonPaginator = (props) => {
    const {
        totalRecords,
        pageLimit,
        pageNeighbours,
        currentPage,
        setCurrentPage,
    } = props;
    // eslint-disable-next-line
    const [offset, setOffset] = useState(0);

    return (
        <Paginator
            totalRecords={totalRecords}
            pageLimit={pageLimit}
            pageNeighbours={pageNeighbours}
            setOffset={setOffset}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
        />
    );
};

CommonPaginator.propTypes = {
    totalRecords: PropTypes.number,
    pageLimit: PropTypes.number,
    pageNeighbours: PropTypes.number,
    currentPage: PropTypes.number,
    setCurrentPage: PropTypes.func,
};

CommonPaginator.defaultProps = {
    totalRecords: 1,
    pageLimit: 10,
    pageNeighbours: 1,
    currentPage: 1,
    setCurrentPage: () => {},
};

export default CommonPaginator;
