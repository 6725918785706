/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Wrapper, ImageWrapper, StlyedSkeleton } from './styles';
import MentionText from '../MentionText';

const Title = styled.div`
    word-break: break-word;
    white-space: pre-line;
    color: ${(p) => p.theme.text};
    margin: 1rem 0;
`;

const ContentWrapper = styled.div`
    color: ${(p) => p.theme.text};
    padding: 0.5rem 1.5rem;
`;

const PostDetailBody = (props) => {
    const {
        content, contentImageURL, postID, postType, loading,
    } = props;

    return loading ? (
        <StlyedSkeleton paragraph={{ rows: 4 }} />
    ) : (
        <Wrapper>
            <Title>
                <ContentWrapper>
                    <MentionText text={content} />
                </ContentWrapper>
            </Title>
            {!isEmpty(contentImageURL) && (
                <ImageWrapper src={contentImageURL} />
            )}
        </Wrapper>
    );
};

PostDetailBody.propTypes = {
    contentImageURL: PropTypes.string,
    content: PropTypes.string,
    postID: PropTypes.string.isRequired,
    postType: PropTypes.string.isRequired,
    loading: PropTypes.bool,
};

PostDetailBody.defaultProps = {
    loading: false,
    contentImageURL: '',
    content: '',
};

export default PostDetailBody;
