/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Modal, Button, Select } from 'antd';
import swal from 'sweetalert';
import { size, trim, isEmpty } from 'lodash';
import {
    GetAvailablePinnedSlotApi,
    GetPinnedEntityDetailsApi,
    AddPinnedEntityApi,
    RemovePinnedEntityApi,
    UpdatePinnedEntityApi,
} from '../../api/pinnedEntity';
import {
    LabelInput,
    ErrorText,
    StyledDatePicker,
} from './styles';
import {
    dateFormating,
    dateFormattingServerUTC,
} from '../../util/helper';

const StyleModal = styled(Modal)`
    .ant-modal-header {
        background: ${(props) => props.theme.dark};
        color: ${(props) => props.theme.dark};
    }

    .ant-modal-body {
        background: ${(props) => props.theme.dark};
    }

    .ant-modal-title {
        color: ${(props) => props.theme.text};
    }
    .ant-modal-footer {
        background: ${(props) => props.theme.dark};
    }
    .ant-modal-close-icon {
        color: ${(props) => props.theme.text};
    }

    .ant-modal .sc-AzgDb .jcGGxa {
        padding: 0px;
    }
`;

export const PinEntityModal = (props) => {
    const {
 entityObj, entityType, onClose, filters, fetchData,
} = props;
    const { Option } = Select;

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [availableSlotList, setAvailableSlotList] = useState([]);
    const [currentSelectedCreateSlot, setCurrentSelectedCreateSlot] = useState(
        '',
    );
    const [currentCreateEndTime, setCurrentCreateEndTime] = useState(
        new Date(),
    );
    const [currentSelectedUpdateSlot, setCurrentSelectedUpdateSlot] = useState(
        '',
    );
    const [currentUpdateEndTime, setCurrentUpdateEndTime] = useState('');
    const [
        currentPinnedEntityDetails,
        setCurrentPinnedEntityDetails,
    ] = useState({});
    const [errors, setErrors] = useState({});
    const [action, setAction] = useState('');
    const [showTime, setShowTime] = useState(false);

    const GetAvailableSlots = () => {
        setLoading(true);
        const params = {
            entity_type: entityType,
        };
        GetAvailablePinnedSlotApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setAvailableSlotList(apiData.available_slots || []);
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error',
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch available slots';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const GetPinnedEntityDetails = () => {
        setLoading(true);
        const params = {
            entity_id: entityObj.id || '',
        };
        GetPinnedEntityDetailsApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setCurrentPinnedEntityDetails(apiData);
                        setCurrentUpdateEndTime(apiData.end_at);
                        setShowTime(true);
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error',
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch pinned entity details';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    useEffect(() => {
        GetAvailableSlots();
        if (entityObj.is_pinned === false) {
            setAction('create');
            setShowCreateModal(true);
        } else {
            GetPinnedEntityDetails();
            setAction('update');
            setShowUpdateModal(true);
        }
    }, [entityObj]);

    const renderOptions = (optionsArray) => {
        const optionsList = [];

        optionsArray.map((option) => {
            optionsList.push(
                <Option key={option.id} value={option.id}>
                    {option.title}
                </Option>,
            );
        });

        return optionsList;
    };

    const validateFields = () => {
        const validationErrors = {};

        if (action === 'create') {
            // currentSelectedCreateSlot
            if (size(trim(currentSelectedCreateSlot)) < 1) {
                validationErrors.createSlot = 'Please Select a postion to pin';
            }
        }

        return validationErrors;
    };

    const handleAvailableSlotSelectChange = (value) => {
        if (action === 'create') {
            setCurrentSelectedCreateSlot(value);
        } else if (action === 'update') {
            setCurrentSelectedUpdateSlot(value);
        }
    };

    const handleEndDateChange = (dateTime) => {
        if (dateTime === null || dateTime === undefined) {
            setCurrentCreateEndTime(new Date());
        } else if (dateTime.toString() === 'Invalid Date') {
            // set error for invalid date
        } else if (action === 'create') {
            setCurrentCreateEndTime(dateTime);
        } else if (action === 'update') {
            setCurrentUpdateEndTime(dateTime);
        }
    };

    const handleCreatePinModalCancel = () => {
        setCurrentSelectedCreateSlot('');
        setCurrentCreateEndTime('');
        setAction('');
        setShowCreateModal(false);
        onClose();
    };

    const handleCreatePinSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validateFields();
        setErrors(validationErrors);
        if (isEmpty(validationErrors)) {
            setLoading(true);
            const params = {
                entity_id: entityObj.id || '',
                entity_type: entityType,
                position: currentSelectedCreateSlot.toString(),
                end_at: dateFormattingServerUTC(currentCreateEndTime),
            };
            AddPinnedEntityApi(params)
                .then((res) => {
                    let success = false;
                    if (res.status === 200) {
                        if ((res.data || {}).success) {
                            success = true;
                            setLoading(false);
                            swal(
                                'Yayy!',
                                'Successfully Created Pinned Entity',
                                'success',
                            );
                            handleCreatePinModalCancel();
                            fetchData(filters);
                        }
                    }
                    if (!success) {
                        setLoading(false);
                        swal(
                            'Oops!',
                            ((res.data || {}).data || {}).error_desc,
                            'error',
                        );
                        GetAvailableSlots();
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    let errorMessage = 'Failed to Pin Entity';
                    if (error.response) {
                        if (error.response.status === 400) {
                            errorMessage = (error.response.data || {})
                                .error_desc;
                        }
                    }
                    swal('Oops!', errorMessage, 'error');
                    GetAvailableSlots();
                });
        }
    };

    const handleUpdatePinModalCancel = () => {
        setCurrentSelectedUpdateSlot('');
        setCurrentUpdateEndTime('');
        setAction('');
        setShowUpdateModal(false);
        onClose();
    };

    const handleUpdatePinSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validateFields();
        setErrors(validationErrors);
        if (isEmpty(validationErrors)) {
            setLoading(true);
            const params = {
                entity_id: currentPinnedEntityDetails.entity_id,
                end_at: dateFormattingServerUTC(currentUpdateEndTime),
            };
            if (size(trim(currentSelectedUpdateSlot)) > 0) {
                params.position = currentSelectedUpdateSlot.toString();
            }
            UpdatePinnedEntityApi(params)
                .then((res) => {
                    let success = false;
                    if (res.status === 200) {
                        if ((res.data || {}).success) {
                            success = true;
                            setLoading(false);
                            swal(
                                'Yayy!',
                                'Successfully Updated Pinned Entity',
                                'success',
                            );
                            handleUpdatePinModalCancel();
                            fetchData(filters);
                        }
                    }
                    if (!success) {
                        setLoading(false);
                        swal(
                            'Oops!',
                            ((res.data || {}).data || {}).error_desc,
                            'error',
                        );
                        GetAvailableSlots();
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    let errorMessage = 'Failed to Update Pin Entity';
                    if (error.response) {
                        if (error.response.status === 400) {
                            errorMessage = (error.response.data || {})
                                .error_desc;
                        }
                    }
                    swal('Oops!', errorMessage, 'error');
                    GetAvailableSlots();
                });
        }
    };

    const handleRemovePinSubmit = (event) => {
        event.preventDefault();
        swal({
            title: 'Are you sure you want to remove pin from this post ?',
            text: '',
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true,
        }).then((success) => {
            if (success) {
                setLoading(true);
                const params = {
                    entity_id: currentPinnedEntityDetails.entity_id,
                };
                RemovePinnedEntityApi(params)
                    .then((res) => {
                        let apiSuccess = false;
                        if (res.status === 200) {
                            if ((res.data || {}).success) {
                                apiSuccess = true;
                                setLoading(false);
                                swal(
                                    'Yayy!',
                                    'Successfully Removed Pinned Entity',
                                    'success',
                                );
                                handleUpdatePinModalCancel();
                                fetchData(filters);
                            }
                        }
                        if (!apiSuccess) {
                            setLoading(false);
                            swal(
                                'Oops!',
                                ((res.data || {}).data || {}).error_desc,
                                'error',
                            );
                            GetAvailableSlots();
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        let errorMessage = 'Failed to Remove Pin Entity';
                        if (error.response) {
                            if (error.response.status === 400) {
                                errorMessage = (error.response.data || {})
                                    .error_desc;
                            }
                        }
                        swal('Oops!', errorMessage, 'error');
                        GetAvailableSlots();
                    });
            }
        });
    };

    const renderCreateModal = () => (
        <StyleModal
            title="Add Pin"
            visible={showCreateModal}
            onOk={(e) => handleCreatePinSubmit(e)}
            onCancel={() => handleCreatePinModalCancel()}
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    onClick={(e) => handleCreatePinSubmit(e)}
                    loading={loading}
                >
                    Submit
                </Button>,
            ]}
        >
            <LabelInput>Available Positions </LabelInput>
            <Select
                style={{ width: '100%' }}
                value={currentSelectedCreateSlot}
                onChange={(e) => handleAvailableSlotSelectChange(e)}
            >
                {availableSlotList && renderOptions(availableSlotList)}
            </Select>
            {errors.createSlot && <ErrorText>{errors.createSlot}</ErrorText>}
            <br />
            <LabelInput>End DateTime</LabelInput>
            <StyledDatePicker
                clearable="true"
                // format="dd/MM/yyyy"
                margin="normal"
                variant="inline"
                showTodayButton
                disablePast
                id="create-end-date-picker"
                value={currentCreateEndTime}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'select end date',
                }}
            />
            {errors.createEndDate && (
                <ErrorText>{errors.createEndDate}</ErrorText>
            )}
        </StyleModal>
    );

    const renderUpdateModal = () => (
        <StyleModal
            title="Update Pin"
            visible={showUpdateModal}
            onCancel={() => handleUpdatePinModalCancel()}
            footer={[
                <Button
                    key="submit"
                    type="primary"
                    onClick={(e) => handleUpdatePinSubmit(e)}
                    loading={loading}
                >
                    Update
                </Button>,
                <Button
                    key="submit"
                    type="secondary"
                    onClick={(e) => handleRemovePinSubmit(e)}
                    loading={loading}
                >
                    Remove
                </Button>,
            ]}
        >
            <LabelInput>
                Current Position :
{' '}
<span>{entityObj.pinned_position}</span>
            </LabelInput>
            <br />
            <LabelInput>
                Current End Time :
{' '}
                {size(currentPinnedEntityDetails) > 0 && (
                    <span>
                        {dateFormating(currentPinnedEntityDetails.end_at)}
                    </span>
                )}
            </LabelInput>
            <br />
            <LabelInput>Available Positions</LabelInput>
            <Select
                style={{ width: '100%' }}
                value={currentSelectedUpdateSlot}
                onChange={(e) => handleAvailableSlotSelectChange(e)}
            >
                {availableSlotList && renderOptions(availableSlotList)}
            </Select>
            <br />
            {showTime && (
                <>
                    <LabelInput>End DateTime</LabelInput>
                    <StyledDatePicker
                        clearable="true"
                        // format="dd/MM/yyyy"
                        margin="normal"
                        variant="inline"
                        showTodayButton
                        disablePast
                        id="update-end-date-picker"
                        value={currentUpdateEndTime}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'select end date',
                        }}
                    />
                    {errors.createEndDate && (
                        <ErrorText>{errors.createEndDate}</ErrorText>
                    )}
                </>
            )}
        </StyleModal>
    );

    const renderController = () => {
        switch (action) {
            case 'create':
                return showCreateModal && renderCreateModal();
            case 'update':
                return showUpdateModal && renderUpdateModal();
            default:
                return null;
        }
    };

    return renderController();
};

export default PinEntityModal;
