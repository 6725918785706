/* eslint-disable implicit-arrow-linebreak */
import {
    GetModelPortfolioMasterListApiURL,
    DeleteModelPortfolioApiURL,
    CreateModelPortfolioApiURL,
    UpdateModelPortfolioApiURL,
} from './apiConfig';
import axios from './instance';

export const GetModelPortfolioMasterListApi = (params) =>
    axios.get(GetModelPortfolioMasterListApiURL, { params });

export const DeleteModelPortfolioApi = (params) =>
    axios.delete(DeleteModelPortfolioApiURL, { data: { ...params } });

export const CreateModelPortfolioApi = (params) =>
    axios.post(CreateModelPortfolioApiURL, params);

export const UpdateModelPortfolioApi = (params) =>
    axios.put(UpdateModelPortfolioApiURL, params);
