/* eslint-disable implicit-arrow-linebreak */
import axios from './instance';
import {
    GetBannerListApiURL,
    CreateBannerApiURL,
    DeleteBannerApiURL,
    UpdateBannerApiURL,
    GetBannerRegistrationListApiURL,
} from './apiConfig';

export const GetBannerListApi = (params) =>
    axios.get(GetBannerListApiURL, { params });

export const CreateBannerApi = (params) => axios.post(CreateBannerApiURL, params);

export const DeleteBannerApi = (params) =>
    axios.delete(DeleteBannerApiURL, { data: { ...params } });

export const UpdateBannerApi = (params) => axios.put(UpdateBannerApiURL, params);

export const GetBannerRegistrationListApi = (params) =>
    axios.get(GetBannerRegistrationListApiURL, { params });
