import React from 'react';
import { withRouter } from 'react-router-dom';
import Orders from '../../component/Orders';
import { Wrapper } from './styles';

const OrdersPage = () => (
    <Wrapper>
        <Orders />
    </Wrapper>
);

export default withRouter(OrdersPage);
