import React from 'react';
import { withRouter } from 'react-router-dom';
import { Wrapper } from './styles';
import AuditEvent from '../../component/AuditEvent';

const AuditEventPage = (props) => (
    <Wrapper>
        <AuditEvent pageProps={props} />
    </Wrapper>
);

export default withRouter(AuditEventPage);
