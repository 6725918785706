import React from 'react';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import { CopyIcon } from './styles';

const CopyToClipboard = (props) => {
    const { content } = props;
    const { addToast } = useToasts();
    const copyContent = async () => {
        try {
            await navigator.clipboard.writeText(content).then(() => {
                addToast(`Copied: ${content}`, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 1500,
                });
            });
        } catch (err) {
            addToast(`Failed To Copy: ${content}`, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 2500,
            });
        }
    };

    return <CopyIcon onClick={() => copyContent()} />;
};

CopyToClipboard.propTypes = {
    content: PropTypes.string.isRequired,
};

export default CopyToClipboard;
