import React from 'react';
import { withRouter } from 'react-router-dom';
import Event from '../../component/Event';
import { Wrapper } from './styles';

const EventPage = () => (
    <Wrapper>
        <Event />
    </Wrapper>
);

export default withRouter(EventPage);
