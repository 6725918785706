/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import swal from "sweetalert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import { Modal, Button, Select, Input } from "antd";
import { isEmpty, truncate, size } from "lodash";
import { RNS3 } from "react-native-aws3";
import styled from "styled-components";
import CommonTable from "../Common/Table";
import { Button as ButtonUpload } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  FilterGrid,
  FilterTitleWrapper,
  StyledButton,
  StyledInput,
  LabelInput,
  ErrorText,
  IconContainer,
  StyledDiv,
  RightGridItem,
  LeftGridItem,
  StyledDateTimePicker,
  useStyles,
  ImageContainer,
  DeleteMedia,
} from "./styles";
import CommonLoader from "../Common/Loader";
import CommonDropdown from "../Common/Dropdown";
import Paginator from "../Common/Paginator";
import {
  formattedValue,
  snakeToUpperCase,
  isValidImageUploadObject,
} from "../../util/helper";
import {
  GetBannerListApi,
  CreateBannerApi,
  UpdateBannerApi,
  DeleteBannerApi,
} from "../../api/banner";
import { GetUploadS3ImageApi } from "../../api/uploadImageS3";
import {
  getBannerImageUrl,
  getImageWithBannerCreds,
  s3Config,
} from "../../util/custom";
import Image from "../Image";
import { PreviewImage } from "../Image/styles";
import { clearAccessRefreshToken, clearAdmin } from "../../util/storage";

const StyleModal = styled(Modal)`
  .ant-modal-header {
    background: ${(props) => props.theme.dark};
    color: ${(props) => props.theme.dark};
  }

  .ant-modal-body {
    background: ${(props) => props.theme.dark};
  }

  .ant-modal-title {
    color: ${(props) => props.theme.text};
  }
  .ant-modal-footer {
    background: ${(props) => props.theme.dark};
  }
  .ant-modal-close-icon {
    color: ${(props) => props.theme.text};
  }

  .ant-modal .sc-AzgDb .jcGGxa {
    padding: 0px;
  }
`;

const Banner = () => {
  const auth = useSelector((state) => state.auth);
  const maxTitleCharacters = 25;
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [data, setData] = useState([]);
  // const [authorList, setAuthorList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(1);
  const { Option } = Select;
  const [redirectionParams, setRedirectionParams] = useState([
    { key: "", value: "" },
  ]);

  const [filters, setFilters] = useState({
    page: currentPage,
    page_limit: pageSize,
    sort_by: "position",
    sort_type: "desc",
    is_deleted: "false",
  });

  const defaultBanner = {
    title: "",
    name: "",
    description: "",
    position: "",
    // type: '',
    // cta_text: '',
    redirection_type: "",
    redirection_in_app: "",
    redirection_url: "",
    redirection_params: [{ key: "", value: "" }],
    placement: "",
    sub_placement: "",
    // meta: null,
    is_active: "",
    target_users: "",
    ui_version: "",
    banner_image: {
      en: "",
      hi: "",
      mr: "",
      guj: "",
      ben: "",
      tam: "",
      kan: "",
      tel: "",
    },
    cta_text: "",
  };

  const defaultIsUpdatedObject = {
    startTime: false,
    endTime: false,
    banner_image: false,
    // banner_image: true,
  };

  const [currentBanner, setCurrentBanner] = useState(defaultBanner);
  const [errors, setErrors] = useState({});

  const [updateBanner, setUpdateBanner] = useState({});
  const [updateErrors, setUpdateErrors] = useState({});
  const [isUpdated, setIsUpdated] = useState(defaultIsUpdatedObject);

  const getBannerList = (params) => {
    setLoading(true);
    GetBannerListApi(params)
      .then((res) => {
        // console.log(res, params);
        let success = false;
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            const apiData = (res.data || {}).data || {};
            console.log("🚀 ~ file: index.jsx:154 ~ .then ~ apiData:", apiData);
            setData(apiData.results);
            setCurrentPage(apiData.page);
            setPageSize(apiData.page_limit);
            setTotal(apiData.total_count);
            setFilters(params);
            setLoading(false);
          }
        }
        if (!success) {
          setLoading(false);
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to fetch data";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  const deleteBanner = (id) => {
    swal({
      title: "Are you sure you want to delete this Banner ?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((success) => {
      if (success) {
        setLoading(true);
        const deleteParams = {
          id,
        };
        DeleteBannerApi(deleteParams)
          .then((res) => {
            let apiSuccess = false;
            setLoading(false);
            if (res.status === 200) {
              if ((res.data || {}).success) {
                apiSuccess = true;
                getBannerList(filters);
                swal("success!", "Successfully Deleted Banner", "success");
              }
            }
            if (!apiSuccess) {
              setLoading(false);
              swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
            }
          })
          .catch((error) => {
            setLoading(false);
            let errorMessage = "Failed to delete Banner";
            if (error.response) {
              if (error.response.status === 400) {
                errorMessage = (error.response.data || {}).error_desc;
              }
            }
            swal("Oops!", errorMessage, "error");
          });
      }
    });
  };

  const showCreateBannerModal = () => {
    setRedirectionParams([{ key: "", value: "" }]);
    setShowCreateModal(true);
  };

  const handleCreateBannerModalCancel = () => {
    setShowCreateModal(false);
    setCurrentBanner(defaultBanner);
    setErrors({});
  };

  const showUpdateBannerModal = (bannerObj) => {
    const updateObject = Object.create(defaultBanner);
    const arr = [];
    if (
      bannerObj.redirection_params !== null &&
      bannerObj.redirection_params !== undefined
    ) {
      const newArr = Object.keys(bannerObj.redirection_params).map((item) => {
        const obj = { key: item, value: bannerObj.redirection_params[item] };
        return obj;
      });
      setRedirectionParams(newArr);
    } else {
      setRedirectionParams([{ key: "", value: "" }]);
    }
    updateObject.id = bannerObj.id;
    updateObject.title = bannerObj.title;
    updateObject.description = bannerObj.description;
    updateObject.name = bannerObj.name;
    // updateObject.type = bannerObj.type;
    updateObject.position = bannerObj.position.toString();
    updateObject.cta_text = bannerObj.cta_text;
    updateObject.redirection_type = bannerObj.redirection_type;
    updateObject.redirection_url = bannerObj.redirection_url;
    updateObject.redirection_in_app = bannerObj.redirection_url;
    updateObject.redirection_params = JSON.stringify(arr);
    updateObject.placement = bannerObj.placement;
    updateObject.sub_placement = bannerObj.sub_placement;
    // updateObject.meta = bannerObj.meta;
    updateObject.is_active = bannerObj.is_active ? "true" : "false";
    updateObject.target_users = bannerObj.target_users;
    updateObject.ui_version = bannerObj.ui_version;
    updateObject.banner_image = bannerObj.banner_image;
    setUpdateBanner(updateObject);
    setShowUpdateModal(true);
  };

  const handleUpdateModalCancel = () => {
    setShowUpdateModal(false);
    setUpdateBanner({});
    setUpdateErrors({});
    setIsUpdated(defaultIsUpdatedObject);
  };

  const renderEdit = (item) => (
    <IconContainer>
      <EditIcon onClick={() => showUpdateBannerModal(item)} />
    </IconContainer>
  );

  const generateBannerParams = (bannerObj, image, action) => {
    // eslint-disable-next-line prefer-const
    let obj = {};
    redirectionParams.forEach((item, index) => {
      if (item.key !== "") {
        obj[item.key] = item.value;
      }
    });
    if (JSON.stringify(obj) === "{}") {
      obj = {};
    }
    // console.log(redirectionParams, obj.toString(), JSON.stringify(obj));
    const paramsObj = {
      title: bannerObj.title,
      description: bannerObj.description,
      name: bannerObj.name,
      // type: bannerObj.type,
      position: bannerObj.position,
      cta_text: bannerObj.cta_text,
      redirection_type: bannerObj.redirection_type,
      redirection_url:
        bannerObj.redirection_type === "in_app"
          ? bannerObj.redirection_in_app
          : bannerObj.redirection_url,
      redirection_params: obj,
      placement: bannerObj.placement,
      sub_placement:
        bannerObj.ui_version === "v2" ? bannerObj.sub_placement : "",
      // meta: bannerObj.meta,
      is_active: bannerObj.is_active,
      target_users: bannerObj.target_users,
      ui_version: bannerObj.ui_version,
      banner_image: image,
    };
    if (action === "update") {
      paramsObj.id = bannerObj.id;
    }

    return paramsObj;
  };

  const createBanner = (image) => {
    console.log("🚀 ~ file: index.jsx:325 ~ createBanner ~ image:", image);
    const params = generateBannerParams(currentBanner, image, "create");
    console.log("🚀 ~ file: index.jsx:326 ~ createBanner ~ params:", params);
    setLoading(true);
    // console.log('Params ', params);
    CreateBannerApi(params)
      .then((res) => {
        let success = false;
        setLoading(false);
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            handleCreateBannerModalCancel();
            getBannerList(filters);
            swal("success!", "Successfully Created Banner", "success");
          }
        }
        if (!success) {
          let err = ((res.data || {}).data || {}).error_desc;
          if (JSON.stringify(err).includes("duplicate")) {
            err = "This position is already exist.";
          }
          swal("Oops!", err, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Banner Creation Failed";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
            if (
              JSON.stringify(error.response.data.error_desc).includes(
                "duplicate"
              )
            ) {
              errorMessage = "This position is already exist.";
            }
          }
        }
        swal("OOPS!", errorMessage, "error");
      });
  };

  const updateBannerApiCall = (image, isActiveParams = null) => {
    const updateParams =
      isActiveParams !== null
        ? isActiveParams
        : generateBannerParams(updateBanner, image, "update");
    setLoading(true);
    // console.log('Params ', updateParams);
    UpdateBannerApi(updateParams)
      .then((res) => {
        setLoading(false);
        let success = false;
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            handleUpdateModalCancel();
            getBannerList(filters);
            swal("Yayy!", "Successfully Updated Banner", "success");
          }
        }
        if (!success) {
          let err = ((res.data || {}).data || {}).error_desc;
          if (JSON.stringify(err).includes("duplicate")) {
            err = "This position is already exist.";
          }
          swal("Oops!", err, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to Update Banner";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
            if (
              JSON.stringify(error.response.data.error_desc).includes(
                "duplicate"
              )
            ) {
              errorMessage = "This position is already exist.";
            }
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 10,
      render: (id) => <>{id}</>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 70,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 70,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      textWrap: "word-break",
      width: 150,
      render: (description) => (
        <div>
          {truncate(description || "", {
            length: 125,
            separator: "...",
          })}
        </div>
      ),
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      width: 70,
      align: "center",
      render: (status) => <div>{snakeToUpperCase(formattedValue(status))}</div>,
    },
    {
      title: "Banner Image",
      dataIndex: "banner_image",
      key: "banner_image",
      textWrap: "word-break",
      width: 110,
      align: "center",
      render: (value) => {
        return (
          <div style={{ width: "300px" }}>
            <PreviewImage src={getBannerImageUrl(value)} width="300px" />
          </div>
        );
      },
    },
    // {
    //     title: 'CTA Text',
    //     dataIndex: 'cta_text',
    //     key: 'cta_text',
    //     textWrap: 'word-break',
    //     width: 110,
    //     align: 'center',
    //     render: (value) => <p>{formattedValue(value)}</p>,
    // },
    {
      title: "Redirection Type",
      dataIndex: "redirection_type",
      key: "redirection_type",
      width: 20,
      align: "center",
      render: (value) => <div>{formattedValue(value)}</div>,
    },
    {
      title: "Redirection In App",
      dataIndex: "redirection_url",
      key: "redirection_url",
      width: 20,
      align: "center",
      render: (value, item) => (
        <div>
          {formattedValue(item.redirection_type === "in_app" ? value : null)}
        </div>
      ),
    },
    {
      title: "Redirection Params",
      dataIndex: "redirection_params",
      key: "redirection_params",
      width: 20,
      align: "center",
      render: (value, item) => (
        <div>
          {formattedValue(
            item.redirection_type === "in_app" ? JSON.stringify(value) : null
          )}
        </div>
      ),
    },
    {
      title: "Redirection URL",
      dataIndex: "redirection_url",
      key: "redirection_url",
      width: 20,
      align: "center",
      render: (value, item) => (
        <div>
          {formattedValue(
            item.redirection_type === "external_link" ? value : null
          )}
        </div>
      ),
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      key: "is_active",
      width: 180,
      align: "center",
      render: (value, item) =>
        value ? (
          // eslint-disable-next-line
          <div
            onClick={(e) => {
              updateBannerApiCall(null, { id: item.id, is_active: false });
            }}
          >
            <CheckCircleIcon />
          </div>
        ) : (
          // eslint-disable-next-line
          <div
            onClick={(e) => {
              updateBannerApiCall(null, { id: item.id, is_active: true });
            }}
          >
            <CheckBoxOutlineBlankIcon />
          </div>
        ),
      // <input
      // type="checkbox"
      // checked={value}
      // onChange={() => {
      //     const obj = item;
      //     obj.is_active = !obj.is_active;
      //     console.log('Id - ', obj);
      //     updateBannerApiCall(obj);
      // }}
      // />
    },
    // {
    //     title: 'Meta',
    //     dataIndex: 'meta',
    //     key: 'meta',
    //     width: 180,
    //     align: 'center',
    //     render: (text, item) => <div>{renderMeetingDetails(item)}</div>,
    // },
    {
      title: "Target Users",
      dataIndex: "target_users",
      key: "target_users",
      width: 180,
      align: "center",
      render: (value) => <div>{formattedValue(value)}</div>,
    },
    {
      title: "UI Version",
      dataIndex: "ui_version",
      key: "ui_version",
      width: 180,
      align: "center",
      render: (value) => <div>{formattedValue(value)}</div>,
    },
    {
      title: "Placement",
      dataIndex: "placement",
      key: "placement",
      width: 180,
      align: "center",
      render: (value) => <div>{formattedValue(value)}</div>,
    },
    {
      title: "Sub Placement",
      dataIndex: "sub_placement",
      key: "sub_placement",
      width: 180,
      align: "center",
      render: (value) => <div>{formattedValue(value)}</div>,
    },
    // {
    //     title: 'View Registrations',
    //     dataIndex: 'id',
    //     key: 'id',
    //     align: 'center',
    //     render: (item) => (
    //         <IconContainer>
    //             <NavLink to={`/banner/${item}`}>
    //                 <VisibilityIcon />
    //             </NavLink>
    //         </IconContainer>
    //     ),
    // },
  ];

  if (!JSON.parse(filters.is_deleted || false)) {
    tableColumns.push({
      title: "Edit",
      dataIndex: "id",
      key: "id",
      render: (text, item) => renderEdit(item),
      align: "center",
    });
    tableColumns.push({
      title: "Delete",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, item) =>
        item.is_deleted === false ? (
          <IconContainer>
            <DeleteIcon onClick={() => deleteBanner(item.id)} />
          </IconContainer>
        ) : null,
    });
  }

  const createOptionList = (list) => {
    const optionArray = [];
    // eslint-disable-next-line array-callback-return
    list.map((item) => {
      optionArray.push(
        <Option key={item.id} value={item.id}>
          {item.title}
        </Option>
      );
    });

    return optionArray;
  };

  const RedirectionType = {
    key: "redirection_type",
    data: [
      {
        id: "in_app",
        title: "In App",
        selected: false,
      },
      {
        id: "external_link",
        title: "External Link",
        selected: false,
      },
    ],
  };

  const handleSelectChange = (value, key, action) => {
    if (action === "create") {
      if (key === "redirection_type") {
        setRedirectionParams([{ key: "", value: "" }]);
        setCurrentBanner({
          ...currentBanner,
          redirection_type: value,
          redirection_url: "",
          redirection_in_app: "",
        });
      } else if (key === "redirection_in_app") {
        setRedirectionParams([{ key: "", value: "" }]);
        setCurrentBanner({ ...currentBanner, redirection_in_app: value });
      } else if (key === "is_active") {
        setCurrentBanner({ ...currentBanner, is_active: value });
      } else if (key === "placement") {
        setCurrentBanner({ ...currentBanner, placement: value });
      } else if (key === "sub_placement") {
        setCurrentBanner({ ...currentBanner, sub_placement: value });
      } else if (key === "target_users") {
        setCurrentBanner({ ...currentBanner, target_users: value });
      } else if (key === "ui_version") {
        setCurrentBanner({ ...currentBanner, ui_version: value });
      }
    } else if (action === "update") {
      if (key === "redirection_type") {
        setRedirectionParams([{ key: "", value: "" }]);
        setUpdateBanner({
          ...updateBanner,
          redirection_type: value,
          redirection_url: "",
          redirection_in_app: "",
        });
      } else if (key === "redirection_in_app") {
        setRedirectionParams([{ key: "", value: "" }]);
        setUpdateBanner({ ...updateBanner, redirection_in_app: value });
      } else if (key === "is_active") {
        setUpdateBanner({ ...updateBanner, is_active: value });
      } else if (key === "placement") {
        setUpdateBanner({ ...updateBanner, placement: value });
      } else if (key === "sub_placement") {
        setUpdateBanner({ ...updateBanner, sub_placement: value });
      } else if (key === "target_users") {
        setUpdateBanner({ ...updateBanner, target_users: value });
      } else if (key === "ui_version") {
        setUpdateBanner({ ...updateBanner, ui_version: value });
      }
    }
  };

  const onFileChange = (banner, action, key) => {
    console.log("🚀 ~ file: index.jsx:730 ~ onFileChange ~ banner:", banner);
    if (action === "create") {
      console.log("🚀 ~ file: index.jsx:733 ~ onFileChange ~ banner:", banner);

      if (!banner) {
        setCurrentBanner({
          ...currentBanner, banner_image: {
            ...currentBanner.banner_image,
            [key]: ""
          }
        });
        return;
      }
      const file = banner.target?.files[0];
      setCurrentBanner({
        ...currentBanner,
        banner_image: { ...currentBanner.banner_image, [key]: file },
      });
    } else if (action === "update") {
      setIsUpdated({ ...isUpdated, banner_image: true });
      if (!banner) {
        setUpdateBanner({
          ...updateBanner, banner_image: {
            ...updateBanner.banner_image,
            [key]: ""
          }
        });
        return;
      }
      const file = banner.target?.files[0];
      setUpdateBanner({
        ...updateBanner,
        banner_image: { ...updateBanner.banner_image, [key]: file },
      });
    }

    if (banner) {
      /**
       * for resetting the value of the input field 
       * in case the same file is chosen again
      */
      banner.target.value = ""
    }

  };

  useEffect(() => {
    filters.page = currentPage;
    filters.page_limit = pageSize;
    getBannerList(filters);
  }, [currentPage, pageSize]);

  const validateFields = (action) => {
    const validationErrors = {};
    // let isParamErrors = false;
    let bannerObj = {};

    if (action === "create") {
      bannerObj = currentBanner;
    } else if (action === "update") {
      bannerObj = updateBanner;
    }
    if (bannerObj.title.length === 0) {
      validationErrors.title = "Required Title";
    } else if (bannerObj.title.length > maxTitleCharacters) {
      validationErrors.title = `Max ${maxTitleCharacters} Characters Allowed`;
    }

    if (bannerObj.name.length === 0) {
      validationErrors.name = "Required Name";
    }
    if (bannerObj.cta_text.length == 0) {
      validationErrors.cta_text = "Required Cta";
    }

    if (bannerObj.description.length === 0) {
      validationErrors.description = "Required Description";
    }

    if (size(bannerObj.placement) === 0) {
      validationErrors.placement = "Required Placement";
    }

    if (size(bannerObj.position) === 0) {
      validationErrors.position = "Required Position";
    }

    if (size(bannerObj.redirection_type) === 0) {
      validationErrors.redirection_type = "Required Redirection Type";
    }

    if (
      bannerObj.redirection_type === "in_app" &&
      size(bannerObj.redirection_in_app) === 0
    ) {
      validationErrors.redirection_in_app = "Required Redirection In App";
    }

    if (
      bannerObj.redirection_type === "external_link" &&
      size(bannerObj.redirection_url) === 0
    ) {
      validationErrors.redirection_url = "Required Redirection URL";
    }

    if (bannerObj.ui_version === "v2" && size(bannerObj.sub_placement) === 0) {
      validationErrors.sub_placement = "Required Sub Placement";
    }

    // eslint-disable-next-line max-len
    // if (bannerObj.redirection_type === 'in_app' && bannerObj.redirection_params.length !== 0) {
    //     const paramsError = redirectionParams.map((item) => {
    //         if (item.key === '' || item.value === '') {
    //             isParamErrors = true;
    //         }
    // eslint-disable-next-line max-len
    //         return ({ key: item.key === '' ? 'Required key' : '', value: item.value === '' ? 'Required Value' : '' });
    //     });
    //     validationErrors.redirection_params = paramsError;
    // }

    if (size(bannerObj.is_active) === 0) {
      validationErrors.is_active = "Required Is Active";
    }

    if (size(bannerObj.ui_version) === 0) {
      validationErrors.ui_version = "Required UI Version";
    }

    if (size(bannerObj.target_users) === 0) {
      validationErrors.target_users = "Required Target User";
    }

    /**
     * checking for both Create and Edit action
     */
    if (action === "create" || action === "update") {
      if (
        isValidImageUploadObject(bannerObj.banner_image["en"]) &&
        isValidImageUploadObject(bannerObj.banner_image["hi"]) &&
        isValidImageUploadObject(bannerObj.banner_image["mr"]) &&
        isValidImageUploadObject(bannerObj.banner_image["guj"]) &&
        isValidImageUploadObject(bannerObj.banner_image["ben"]) &&
        isValidImageUploadObject(bannerObj.banner_image["tam"]) &&
        isValidImageUploadObject(bannerObj.banner_image["tel"]) &&
        isValidImageUploadObject(bannerObj.banner_image["kan"])
      ) {
        if (
          bannerObj.banner_image.size &&
          bannerObj.banner_image.size / 1048576 > 10
        ) {
          validationErrors.banner_image =
            "Please upload minimum file size less than 10mb";
        }
      } else {
        validationErrors.banner_image = "Required Image";
      }
    }
    console.log(
      "🚀 ~ file: index.jsx:855 ~ validateFields ~ validationErrors:",
      validationErrors
    );

    // if (!isParamErrors && Object.keys(validationErrors).length === 1) {
    //     return {};
    // }
    return validationErrors;
  };
  const [createBannerImageObject, setCreateBannerImageObject] = useState({});

  useEffect(() => {
    console.log(
      "🚀 ~ file: index.jsx:866 ~ useEffect ~ createBannerImageObject:",
      createBannerImageObject
    );
    var size = Object.keys(createBannerImageObject).length;
    console.log("🚀 ~ file: index.jsx:866 ~ useEffect ~ size:", size);
    if (size == 8) {
      createBanner(createBannerImageObject);
    }
  }, [createBannerImageObject]);

  const handleCreateBannerSubmit = async (banner) => {
    banner.preventDefault();
    const validationErrors = validateFields("create");
    setErrors(validationErrors);
    if (isEmpty(validationErrors)) {
      if (
        isValidImageUploadObject(currentBanner.banner_image["en"]) &&
        isValidImageUploadObject(currentBanner.banner_image["hi"]) &&
        isValidImageUploadObject(currentBanner.banner_image["mr"]) &&
        isValidImageUploadObject(currentBanner.banner_image["guj"]) &&
        isValidImageUploadObject(currentBanner.banner_image["ben"]) &&
        isValidImageUploadObject(currentBanner.banner_image["tam"]) &&
        isValidImageUploadObject(currentBanner.banner_image["tel"]) &&
        isValidImageUploadObject(currentBanner.banner_image["kan"])
      ) {
        let obj = {};
        Object.keys(currentBanner.banner_image).forEach(
          async (banner_image_key) => {
            try {
              const createTimeNow = new Date();
              const val = {
                en: "english",
                hi: "hindi",
                mr: "marathi",
                guj: "gujrati",
                ben: "bengali",
                tam: "tamil",
                tel: "telegu",
                kan: "kannada",
              };
              let keyPrefix = `icons/feedCards/${val[banner_image_key]}`;
              keyPrefix += "-"+ currentBanner.banner_image[banner_image_key].name;
              let reader = new FileReader();
              reader.readAsDataURL(currentBanner.banner_image[banner_image_key]);
              reader.onload = function () {
                const base64img = reader.result.split(',')[1];
                const params = {  
                  filename: keyPrefix,
                  encodedimg: base64img,
                }
                GetUploadS3ImageApi(params)
                  .then((res) => {
                    if (res.status === 200) {
                      if (res.data) {
                        console.log("success in file upload", res.data);
                        const imgS3Param = {
                          location: res.data.data.url,
                          key: keyPrefix,
                        }
                        const image = getImageWithBannerCreds({
                          data: imgS3Param,
                        });
                        obj = {
                          ...obj,
                          [banner_image_key]: image,
                        };
                        setCreateBannerImageObject(obj);
                      }
                    }
                    else {
                      console.log("error in file upload", ((res.data || {}).data || {}).error_desc);
                    }
                  })
                  .catch((error) => {
                    let errorMessage = "error in file upload";
                    if (error.response) {
                      if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                      }
                    }
                    console.log("error in file upload", errorMessage);
                  });
              };
              reader.onerror = function (error) {
                console.log("Error in file reading: ", error);
              };


              // keyPrefix += "-";
              // const config = { ...s3Config, keyPrefix };
              // if (process.env.REACT_APP_AWS_ACL) {
              //   config.acl = process.env.REACT_APP_AWS_ACL;
              // }
              // const banner_image = currentBanner.banner_image[banner_image_key];
              // RNS3.put(banner_image, config).then((res) => {
              //   const image = getImageWithBannerCreds({
              //     data: (res.body || {}).postResponse,
              //   });
              //   obj = {
              //     ...obj,
              //     [banner_image_key]: image,
              //   };
              //   setCreateBannerImageObject(obj);
              // });
            } catch (err) {
              console.log("🚀 ~ file: index.jsx:950 ~ err:", err);
            }
          }
        );
      }
    }
  };

  const [updateBannerImageObject, setUpdateBannerImageObject] = useState({});

  useEffect(() => {
    var size = Object.keys(updateBannerImageObject).length;
    if (size == 8) {
      updateBannerApiCall(updateBannerImageObject);
    }
  }, [updateBannerImageObject]);

  const handleUpdateBannerSubmit = (banner) => {
    banner.preventDefault();
    const validationUpdateErrors = validateFields("update");
    setUpdateErrors(validationUpdateErrors);
    if (isEmpty(validationUpdateErrors)) {
      if (
        isUpdated.banner_image &&
        isValidImageUploadObject(updateBanner.banner_image["en"]) &&
        isValidImageUploadObject(updateBanner.banner_image["hi"]) &&
        isValidImageUploadObject(updateBanner.banner_image["mr"]) &&
        isValidImageUploadObject(updateBanner.banner_image["guj"]) &&
        isValidImageUploadObject(updateBanner.banner_image["ben"]) &&
        isValidImageUploadObject(updateBanner.banner_image["tam"]) &&
        isValidImageUploadObject(updateBanner.banner_image["tel"]) &&
        isValidImageUploadObject(updateBanner.banner_image["kan"])
      ) {
        // const updateTimeNow = new Date();
        // let keyPrefix = `banner/${
        //   auth.user_id
        // }/image/${updateTimeNow.toISOString()}`;
        let obj = {};
        let keysForUploading = [];
        const banners = updateBanner.banner_image

        for (let item in banners) {
          /** If the image is already uploaded then we will not upload it again */
          if (banners[item].pre_signed_url) {
            obj = {
              ...obj,
              [item]: banners[item],
            }
          } else {
            keysForUploading.push(item)
          }
        }


        keysForUploading.forEach(
          async (banner_image_key) => {
            try {
              const val = {
                en: "english",
                hi: "hindi",
                mr: "marathi",
                guj: "gujarati",
                ben: "bengali",
                tam: "tamil",
                tel: "telugu",
                kan: "kannada"
              };
              let keyPrefix = `icons/feedCards/${val[banner_image_key]}`;
              keyPrefix += "-"+ updateBanner.banner_image[banner_image_key].name;
              let reader = new FileReader();
              reader.readAsDataURL(updateBanner.banner_image[banner_image_key]);
              reader.onload = function () {
                const base64img = reader.result.split(',')[1];
                const params = {  
                  filename: keyPrefix,
                  encodedimg: base64img,
                }
                GetUploadS3ImageApi(params)
                  .then((res) => {
                    if (res.status === 200) {
                      if (res.data) {
                        console.log("success in file upload", res.data);
                        const imgS3Param = {
                          location: res.data.data.url,
                          key: keyPrefix,
                        }
                        const image = getImageWithBannerCreds({
                          data: imgS3Param,
                        });
                        obj = {
                          ...obj,
                          [banner_image_key]: image,
                        };
                        setUpdateBannerImageObject(obj);
                      }
                    }
                    else {
                      console.log("error in file upload", ((res.data || {}).data || {}).error_desc);
                    }
                  })
                  .catch((error) => {
                    let errorMessage = "error in file upload";
                    if (error.response) {
                      if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                      }
                    }
                    console.log("error in file upload", errorMessage);
                  });
              };
              reader.onerror = function (error) {
                console.log("Error in file reading: ", error);
              };
              // keyPrefix += "/";
              // const config = { ...s3Config, keyPrefix };
              // if (process.env.REACT_APP_AWS_ACL) {
              //   config.acl = process.env.REACT_APP_AWS_ACL;
              // }
              // const banner_image = updateBanner.banner_image[banner_image_key];
              // RNS3.put(banner_image, config)
              //   .progress((progress) => console.log("progress", progress))
              //   .then((res) => {
              //     const image = getImageWithBannerCreds({
              //       data: (res.body || {}).postResponse,
              //     });
              //     obj = {
              //       ...obj,
              //       [banner_image_key]: image,
              //     };
              //     setUpdateBannerImageObject(obj);
              //     // updateBannerApiCall(image);
              //   });
            } catch (err) {
              console.log("🚀 ~ file: index.jsx:985 ~ Object.keys ~ err:", err);
            }
          }
        );
        // updateBanner.banner_image.map((banner_image) => {
        //   RNS3.put(banner_image, config)
        //     .progress((progress) => console.log("progress", progress))
        //     .then((response) => {
        //       if (response.status !== 201) {
        //         console.log("error in file upload", response);
        //       } else {
        //         const image = getImageWithBannerCreds({
        //           data: (response.body || {}).postResponse,
        //         });
        //         updateBannerApiCall(image);
        //       }
        //     });
        // });
      } else {
        updateBannerApiCall(updateBanner.banner_image);
      }
    }
  };

  const handleFilterChange = (filters) => {
    // console.log(filters);
    getBannerList(filters);
  };

  const IsActive = {
    key: "is_active",
    data: [
      {
        id: "true",
        title: "True",
        selected: false,
      },
      {
        id: "false",
        title: "False",
        selected: true,
      },
    ],
  };

  const IsDeleted = {
    key: "is_deleted",
    data: [
      {
        id: "true",
        title: "True",
        selected: false,
      },
      {
        id: "false",
        title: "False",
        selected: true,
      },
    ],
  };

  const SubPlacement = {
    key: "sub_placement",
    data: [
      {
        id: "topScroll",
        title: "Top Scroll",
        selected: true,
      },
      {
        id: "playToWin",
        title: "Play To Win",
        selected: false,
      },
      {
        id: "openYourAccount",
        title: "Open Your Account",
        selected: false,
      },
      {
        id: "getLoans",
        title: "Get Loans",
        selected: false,
      },
      {
        id: "community",
        title: "Community",
        selected: false,
      },
      {
        id: "offersForYou",
        title: "Offers For You",
        selected: false,
      },
    ],
  };

  const Placement = {
    key: "placement",
    data: [
      {
        id: "Home",
        title: "Home",
        selected: true,
      },
      {
        id: "Community",
        title: "Community",
        selected: false,
      },
    ],
  };

  const TargetUsers = {
    key: "target_users",
    data: [
      {
        id: "all",
        title: "All",
        selected: true,
      },
      {
        id: "invested",
        title: "Invested",
        selected: false,
      },
      {
        id: "non_invested",
        title: "Non Invested",
        selected: false,
      },
      {
        id: "signed_up",
        title: "Signed Up",
        selected: false,
      },
    ],
  };

  const UIVersion = {
    key: "ui_version",
    data: [
      {
        id: "v1",
        title: "v1",
        selected: true,
      },
      {
        id: "v2",
        title: "v2",
        selected: false,
      },
    ],
  };

  const RedirectionInApp = {
    key: "redirection_in_app",
    data: [
      {
        id: " ",
        title: " ",
        selected: true,
      },
      {
        id: "Home",
        title: "Home",
        selected: false,
      },
      {
        id: "MyMoney",
        title: "MyMoney",
        selected: false,
      },
      {
        id: "More",
        title: "More",
        selected: false,
      },
      {
        id: "Profile",
        title: "Profile",
        selected: false,
      },
      {
        id: "About",
        title: "About",
        selected: false,
      },
      {
        id: "Faq",
        title: "Faq",
        selected: false,
      },
      {
        id: "Portfolio",
        title: "Portfolio",
        selected: false,
      },
      {
        id: "Invest",
        title: "Invest",
        selected: false,
      },
      {
        id: "InvestMore",
        title: "Invest More",
        selected: false,
      },
      {
        id: "InvestSummary",
        title: "Invest Summary",
        selected: false,
      },
      {
        id: "Learn",
        title: "Learn",
        selected: false,
      },
      {
        id: "Transactions",
        title: "Transactions",
        selected: false,
      },
      {
        id: "TransactionDetails",
        title: "Transaction Details",
        selected: false,
      },
      {
        id: "Notifications",
        title: "Notifications",
        selected: false,
      },
      {
        id: "MoneyJourneyPosts",
        title: "Money Journey Posts",
        selected: false,
      },
      {
        id: "Support",
        title: "Support",
        selected: false,
      },
      {
        id: "Community",
        title: "Community",
        selected: false,
      },
      {
        id: "Rewards",
        title: "Rewards",
        selected: false,
      },
      {
        id: "InvestmentProfileIntroduction",
        title: "Investment Profile Introduction",
        selected: false,
      },
      {
        id: "InvestmentProfileDetails",
        title: "Investment Profile Details",
        selected: false,
      },
      {
        id: "AccountOpeningPage",
        title: "Account Opening Page",
        selected: false,
      },
      {
        id: "PrivacyPolicy",
        title: "Privacy Policy",
        selected: false,
      },
      {
        id: "GoalCalculator",
        title: "Goal Calculator",
        selected: false,
      },
      {
        id: "RetirementCalculator",
        title: "Retirement Calculator",
        selected: false,
      },
      {
        id: "TaxSavingCalculator",
        title: "Tax Saving Calculator",
        selected: false,
      },
      {
        id: "PostDetailedView",
        title: "Post Detailed View",
        selected: false,
      },
      {
        id: "InvestmentWithdraw",
        title: "Retirement Calculator",
        selected: false,
      },
      {
        id: "LoanLending",
        title: "Loan Lending",
        selected: false,
      },
      {
        id: "Home-calculator",
        title: "Home Calculator",
        selected: false,
      },
      {
        id: "PPI",
        title: "PPI",
        selected: false,
      },
      {
        id: "Referral",
        title: "referral",
        selected: false,
      },
      {
        id: "OffersForYou",
        title: "Offers For You",
        selected: false,
      },
      {
        id: "DigitalGold",
        title: "Digital Gold",
        selected: false,
      },
      {
        id: "MyGold",
        title: "My Gold",
        selected: false,
      },
      {
        id: "RedeemGold",
        title: "Redeem Gold",
        selected: false,
      },
      {
        id: "IncomeScreen",
        title: "Income Screen",
        selected: false,
      },
    ],
  };

  const sortTypeList = {
    key: "sort_type",
    data: [
      {
        id: "asc",
        title: "Asc",
        selected: false,
      },
      {
        id: "desc",
        title: "Desc",
        selected: true,
      },
    ],
  };

  const sortByList = {
    key: "sort_by",
    data: [
      {
        id: "position",
        title: "Position",
        selected: true,
      },
    ],
  };

  const addParamsFunc = () => {
    const newArr = [...redirectionParams];
    newArr.push({ key: "", value: "" });
    setRedirectionParams(newArr);
  };

  const removeParamsFunc = () => {
    const newArr = [...redirectionParams];
    if (newArr.length > 1) {
      setRedirectionParams(newArr);
      newArr.pop();
    }
  };

  return (
    <Grid container spacing={2}>
      <CommonLoader
        isLoading={loading}
        size="large"
        sentences={["please wait ..."]}
        type="pacman"
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <StyledButton primary onClick={() => showCreateBannerModal()}>
          Create Banner
        </StyledButton>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{
          paddingRight: "1rem",
          paddingLeft: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
          <FilterTitleWrapper>Filters</FilterTitleWrapper>
          <CommonDropdown
            list={Placement}
            title="Placement"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          <CommonDropdown
            list={IsActive}
            title="Is Active"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          <CommonDropdown
            list={IsDeleted}
            title="Is Deleted"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          <CommonDropdown
            list={RedirectionType}
            title="Redirection Type"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          <CommonDropdown
            list={TargetUsers}
            title="Target Users"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          <CommonDropdown
            list={UIVersion}
            title="UI Version"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          <CommonDropdown
            list={sortByList}
            title="Sort By"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          <CommonDropdown
            list={sortTypeList}
            title="Sort Type"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
        </FilterGrid>
        <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
          <CommonTable
            data={data}
            columns={tableColumns}
            pagination={false}
            scroll={{ x: "overflow" }}
          />
          <Paginator
            totalRecords={total}
            pageLimit={pageSize}
            pageNeighbours={2}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Grid>
      </Grid>
      <Grid>
        {showCreateModal && (
          <StyleModal
            title="Create Banner"
            visible={showCreateModal}
            onOk={(e) => handleCreateBannerSubmit(e)}
            onCancel={() => handleCreateBannerModalCancel()}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={(e) => handleCreateBannerSubmit(e)}
                loading={loading}
              >
                Submit
              </Button>,
            ]}
          >
            <StyledDiv>
              <LabelInput>Title ({maxTitleCharacters} Characters)</LabelInput>
              <Input
                placeholder="title"
                name="title"
                value={currentBanner.title}
                onChange={(e) =>
                  setCurrentBanner({
                    ...currentBanner,
                    title: e.target.value,
                  })
                }
              />
              {errors.title && <ErrorText>{errors.title}</ErrorText>}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Name</LabelInput>
              <Input
                placeholder="name"
                name="name"
                value={currentBanner.name}
                onChange={(e) =>
                  setCurrentBanner({
                    ...currentBanner,
                    name: e.target.value,
                  })
                }
              />
              {errors.name && <ErrorText>{errors.name}</ErrorText>}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Description</LabelInput>
              <StyledInput
                placeholder="content"
                name="content"
                value={currentBanner.description}
                onChange={(e) =>
                  setCurrentBanner({
                    ...currentBanner,
                    description: e.target.value,
                  })
                }
              />
              {errors.description && (
                <ErrorText>{errors.description}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Position</LabelInput>
              <Input
                placeholder="Position"
                name="position"
                type="number"
                value={currentBanner.position}
                onChange={(e) =>
                  setCurrentBanner({
                    ...currentBanner,
                    position: e.target.value,
                  })
                }
              />
              {errors.position && <ErrorText>{errors.position}</ErrorText>}
            </StyledDiv>
            {/* <Grid container>
                            <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                            <StyledDiv>
                                <LabelInput>Position</LabelInput>
                                <Input
                                    placeholder="Position"
                                    name="position"
                                    value={currentBanner.position}
                                    onChange={(e) => setCurrentBanner({
                                            ...currentBanner,
                                            position: e.target.value,
                                        })}
                                />
                                {errors.position && <ErrorText>{errors.position}</ErrorText>}
                            </StyledDiv>
                            </LeftGridItem>
                            <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                                <StyledDiv>
                                    <LabelInput>CTA Text</LabelInput>
                                    <Input
                                        placeholder="CTA Text"
                                        name="cta_text"
                                        value={currentBanner.cta_text}
                                        onChange={(e) => setCurrentBanner({
                                                ...currentBanner,
                                                cta_text: e.target.value,
                                            })}
                                    />
                                    {errors.cta_text && <ErrorText>{errors.cta_text}</ErrorText>}
                                </StyledDiv>
                            </RightGridItem>
                        </Grid> */}
            <StyledDiv>
              <LabelInput>Redirection Type</LabelInput>
              <Select
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleSelectChange(e, "redirection_type", "create")
                }
                value={currentBanner.redirection_type}
              >
                {RedirectionType && createOptionList(RedirectionType.data)}
              </Select>
              {errors.redirection_type && (
                <ErrorText>{errors.redirection_type}</ErrorText>
              )}
            </StyledDiv>
            {currentBanner.redirection_type === "in_app" && (
              <StyledDiv>
                <LabelInput>Redirection In App</LabelInput>
                <Select
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleSelectChange(e, "redirection_in_app", "create")
                  }
                  value={currentBanner.redirection_in_app}
                >
                  {RedirectionInApp && createOptionList(RedirectionInApp.data)}
                </Select>
                {errors.redirection_in_app && (
                  <ErrorText>{errors.redirection_in_app}</ErrorText>
                )}
              </StyledDiv>
            )}
            {currentBanner.redirection_type === "external_link" && (
              <StyledDiv>
                <LabelInput>Redirection URL</LabelInput>
                <Input
                  placeholder="Redirection URL"
                  name="redirection_url"
                  value={currentBanner.redirection_url}
                  onChange={(e) =>
                    setCurrentBanner({
                      ...currentBanner,
                      redirection_url: e.target.value,
                    })
                  }
                />
                {errors.redirection_url && (
                  <ErrorText>{errors.redirection_url}</ErrorText>
                )}
              </StyledDiv>
            )}
            {currentBanner.redirection_type === "in_app" && (
              <LabelInput>Redirection Params</LabelInput>
            )}
            {currentBanner.redirection_type === "in_app" &&
              redirectionParams.map((item, index) => (
                <Grid container>
                  <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                    <StyledDiv>
                      <LabelInput>Key {index + 1}</LabelInput>
                      <Input
                        placeholder={`key ${index + 1}`}
                        name="key"
                        value={item.key}
                        onChange={(e) => {
                          const newArr = [...redirectionParams];
                          newArr[index].key = e.target.value;
                          setRedirectionParams(newArr);
                        }}
                      />
                      {/* {errors.redirection_params && (
                                        <ErrorText>
                                            {errors.redirection_params[index]?.key}
                                        </ErrorText>
                                        )} */}
                    </StyledDiv>
                  </LeftGridItem>
                  <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                    <StyledDiv>
                      <LabelInput>Value {index + 1}</LabelInput>
                      <Input
                        placeholder={`value ${index + 1}`}
                        name="value"
                        value={item.value}
                        onChange={(e) => {
                          const newArr = [...redirectionParams];
                          newArr[index].value = e.target.value;
                          setRedirectionParams(newArr);
                        }}
                      />
                      {/* {errors.redirection_params && (
                                        <ErrorText>
                                            {errors.redirection_params[index]?.value}
                                        </ErrorText>
                                        )} */}
                    </StyledDiv>
                  </RightGridItem>
                </Grid>
              ))}
            {currentBanner.redirection_type === "in_app" && (
              <div>
                <Button style={{ flex: 1 }} onClick={addParamsFunc}>
                  Add param
                </Button>
                <Button style={{ marginLeft: "3%" }} onClick={removeParamsFunc}>
                  Remove param
                </Button>
              </div>
            )}
            <StyledDiv>
              <LabelInput>CTA</LabelInput>
              <Input
                placeholder="cta"
                name="Cta"
                value={currentBanner.cta_text}
                onChange={(e) =>
                  setCurrentBanner({
                    ...currentBanner,
                    cta_text: e.target.value,
                  })
                }
              />
              {errors.cta_text && <ErrorText>{errors.cta_text}</ErrorText>}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>UI Version</LabelInput>
              <Select
                style={{ width: "100%" }}
                onChange={(e) => handleSelectChange(e, "ui_version", "create")}
                value={currentBanner.ui_version}
              >
                {UIVersion && createOptionList(UIVersion.data)}
              </Select>
              {errors.ui_version && <ErrorText>{errors.ui_version}</ErrorText>}
            </StyledDiv>
            <Grid container>
              <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>Is Active</LabelInput>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleSelectChange(e, "is_active", "create")
                    }
                    value={currentBanner.is_active}
                  >
                    {IsActive && createOptionList(IsActive.data)}
                  </Select>
                  {errors.is_active && (
                    <ErrorText>{errors.is_active}</ErrorText>
                  )}
                </StyledDiv>
              </LeftGridItem>
              <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>Placement</LabelInput>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleSelectChange(e, "placement", "create")
                    }
                    value={currentBanner.placement}
                  >
                    {Placement && createOptionList(Placement.data)}
                  </Select>
                  {errors.placement && (
                    <ErrorText>{errors.placement}</ErrorText>
                  )}
                </StyledDiv>
              </RightGridItem>
            </Grid>
            {/* <StyledDiv>
                            <LabelInput>Meta</LabelInput>
                            <Input
                                placeholder="Meta"
                                name="meta"
                                value={currentBanner.meta}
                                onChange={(e) => setCurrentBanner({
                                        ...currentBanner,
                                        meta: e.target.value,
                                    })}
                            />
                            {errors.meta && <ErrorText>{errors.meta}</ErrorText>}
                        </StyledDiv> */}
            <Grid container>
              <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>Target Users</LabelInput>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleSelectChange(e, "target_users", "create")
                    }
                    value={currentBanner.target_users}
                  >
                    {TargetUsers && createOptionList(TargetUsers.data)}
                  </Select>
                  {errors.target_users && (
                    <ErrorText>{errors.target_users}</ErrorText>
                  )}
                </StyledDiv>
              </LeftGridItem>
              <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                {currentBanner.ui_version === "v2" ? (
                  <StyledDiv>
                    <LabelInput>Sub Placement</LabelInput>
                    <Select
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        handleSelectChange(e, "sub_placement", "create")
                      }
                      value={currentBanner.sub_placement}
                    >
                      {SubPlacement && createOptionList(SubPlacement.data)}
                    </Select>
                    {errors.sub_placement && (
                      <ErrorText>{errors.sub_placement}</ErrorText>
                    )}
                  </StyledDiv>
                ) : null}
              </RightGridItem>
            </Grid>
            {/* {currentBanner.vendor === 'zoom' ? (
                            <Grid container>
                                <LeftGridItem
                                    item
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                >
                                    <StyledDiv>
                                        <LabelInput>Meeting ID</LabelInput>
                                        <Input
                                            placeholder="Meeting ID"
                                            name="meeting_id"
                                            value={currentBanner.meetingID}
                                            onChange={(e) => setCurrentBanner({
                                                    ...currentBanner,
                                                    meetingID: e.target.value,
                                                })}
                                        />
                                        {errors.meetingID && (
                                            <ErrorText>
                                                {errors.meetingID}
                                            </ErrorText>
                                        )}
                                    </StyledDiv>
                                </LeftGridItem>
                                <RightGridItem
                                    item
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                >
                                    <StyledDiv>
                                        <LabelInput>
                                            Meeting Password
                                        </LabelInput>
                                        <Input
                                            placeholder="Meeting Password"
                                            name="meeting_password"
                                            value={currentBanner.meetingPassword}
                                            onChange={(e) => setCurrentBanner({
                                                    ...currentBanner,
                                                    meetingPassword:
                                                        e.target.value,
                                                })}
                                        />
                                        {errors.meetingPassword && (
                                            <ErrorText>
                                                {errors.meetingPassword}
                                            </ErrorText>
                                        )}
                                    </StyledDiv>
                                </RightGridItem>
                            </Grid>
                        ) : null} */}
            {/* {currentBanner.locationType === 'physical' ? (
                            <StyledDiv>
                                <LabelInput>Location text</LabelInput>
                                <Input
                                    placeholder="Location"
                                    // name="title"
                                    value={currentBanner.locationText}
                                    onChange={(e) =>
                                        setCurrentBanner({
                                            ...currentBanner,
                                            locationText: e.target.value,
                                        })
                                    }
                                />
                                {errors.locationText && (
                                    <ErrorText>{errors.locationText}</ErrorText>
                                )}
                            </StyledDiv>
                        ) : null} */}

            {/* <StyledDiv>
                            <LabelInput>Paid</LabelInput>
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="NO"
                                defaultChecked
                                onClick={(e) => handleToggleChange(e)}
                            />
                        </StyledDiv>
                        {toggle ? (
                            <StyledDiv>
                                <LabelInput>Amount</LabelInput>
                                <Input
                                    placeholder="Amount"
                                    // name="title"
                                    value={currentBanner.amount}
                                    onChange={(e) => handleAmountChange(e)}
                                />
                                {errors.amount && (
                                    <ErrorText>{errors.amount}</ErrorText>
                                )}
                            </StyledDiv>
                        ) : null}
                        {toggle ? (
                            <StyledDiv>
                                <LabelInput>Discount</LabelInput>
                                <Input
                                    placeholder="Discount"
                                    // name="title"
                                    value={currentBanner.discount}
                                    onChange={(e) => handleDiscountChange(e)}
                                />
                                {errors.discount && (
                                    <ErrorText>{errors.discount}</ErrorText>
                                )}
                            </StyledDiv>
                        ) : null} */}
            {/* <StyledDiv>
                            <LabelInput>Select currentBanner Date and Time</LabelInput>
                            <CommonDateTimePicker
                                callbackcurrentBanner={(value) =>
                                    handlecurrentBannerTimeChange(value)
                                }
                            />
                            {errors.currentBannerTime && (
                                <ErrorText>{errors.currentBannerTime}</ErrorText>
                            )}
                        </StyledDiv> */}
            {/* <StyledDiv>
                            <LabelInput>
                                Select Registration Date and Time
                            </LabelInput>
                            <CommonDateTimePicker
                                callbackregiestration={(value) =>
                                    handleRegistrationTimeChange(value)
                                }
                            />
                            {errors.registrationTime && (
                                <ErrorText>{errors.registrationTime}</ErrorText>
                            )}
                        </StyledDiv> */}
            <LabelInput>Upload Image</LabelInput>
            {/* <StyledDiv>
              <LabelInput>In English</LabelInput>
              <Image
                value={currentBanner.banner_image?.english}
                type="create"
                language="english"
                onChange={onFileChange}
              />
              {errors.banner_image && (
                <ErrorText>{errors.banner_image}</ErrorText>
              )}
            </StyledDiv> */}
            {/* <StyledDiv>
              <LabelInput>In Hindi</LabelInput>
              <Image
                value={currentBanner.banner_image.hindi}
                type="create"
                language="hindi"
                onChange={onFileChange}
              />
              {errors.banner_image && (
                <ErrorText>{errors.banner_image}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>In Marathi</LabelInput>
              <Image
                value={currentBanner.banner_image.marathi}
                type="create"
                language="marathi"
                onChange={onFileChange}
              />
            </StyledDiv> */}
            {[
              { value: "en", title: "English" },
              { value: "hi", title: "Hindi" },
              { value: "mr", title: "Marathi" },
              { value: "guj", title: "Gujarati" },
              { value: "ben", title: "Bengali" },
              { value: "tam", title: "Tamil" },
              { value: "tel", title: "Telugu" },
              { value: "kan", title: "Kannada" },
            ].map((lang) => (
              <StyledDiv>
                <LabelInput>In {lang.title}</LabelInput>
                <Image
                  langKey={lang.value}
                  value={currentBanner.banner_image[lang.value]}
                  onChange={(e) => onFileChange(e, "create", lang.value)}
                />
                {errors.banner_image && currentBanner.banner_image[lang.value] == "" && (
                  <ErrorText>{errors.banner_image}</ErrorText>
                )}
              </StyledDiv>
            ))}
          </StyleModal>
        )}
      </Grid>
      <Grid>
        {showUpdateModal && (
          <StyleModal
            title="Update Banner"
            visible={showUpdateModal}
            onOk={(e) => handleUpdateBannerSubmit(e)}
            onCancel={() => handleUpdateModalCancel()}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={(e) => handleUpdateBannerSubmit(e)}
                loading={loading}
              >
                Update
              </Button>,
            ]}
          >
            <StyledDiv>
              <LabelInput>Title ({maxTitleCharacters} Characters)</LabelInput>
              <Input
                placeholder="title"
                name="title"
                value={updateBanner.title}
                onChange={(e) =>
                  setUpdateBanner({
                    ...updateBanner,
                    title: e.target.value,
                  })
                }
              />
              {updateErrors.title && (
                <ErrorText>{updateErrors.title}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Name</LabelInput>
              <Input
                placeholder="name"
                name="name"
                value={updateBanner.name}
                onChange={(e) =>
                  setUpdateBanner({
                    ...updateBanner,
                    name: e.target.value,
                  })
                }
              />
              {updateErrors.name && <ErrorText>{updateErrors.name}</ErrorText>}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Description</LabelInput>
              <StyledInput
                placeholder="content"
                name="content"
                value={updateBanner.description}
                onChange={(e) =>
                  setUpdateBanner({
                    ...updateBanner,
                    description: e.target.value,
                  })
                }
              />
              {updateErrors.description && (
                <ErrorText>{updateErrors.description}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Position</LabelInput>
              <Input
                placeholder="Position"
                type="number"
                name="position"
                value={updateBanner.position}
                onChange={(e) =>
                  setUpdateBanner({
                    ...updateBanner,
                    position: e.target.value,
                  })
                }
              />
              {updateErrors.position && (
                <ErrorText>{updateErrors.position}</ErrorText>
              )}
            </StyledDiv>
            {/* <Grid container>
                            <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                            <StyledDiv>
                                <LabelInput>Position</LabelInput>
                                <Input
                                    placeholder="Position"
                                    name="position"
                                    value={updateBanner.position}
                                    onChange={(e) => setUpdateBanner({
                                            ...updateBanner,
                                            position: e.target.value,
                                        })}
                                />
                                {updateErrors.position
                                && <ErrorText>{updateErrors.position}</ErrorText>}
                            </StyledDiv>
                            </LeftGridItem>
                            <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                                <StyledDiv>
                                    <LabelInput>CTA Text</LabelInput>
                                    <Input
                                        placeholder="CTA Text"
                                        name="cta_text"
                                        value={updateBanner.cta_text}
                                        onChange={(e) => setUpdateBanner({
                                                ...updateBanner,
                                                cta_text: e.target.value,
                                            })}
                                    />
                                    {updateErrors.cta_text
                                    && <ErrorText>{updateErrors.cta_text}</ErrorText>}
                                </StyledDiv>
                            </RightGridItem>
                        </Grid> */}
            <StyledDiv>
              <LabelInput>Redirection Type</LabelInput>
              <Select
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleSelectChange(e, "redirection_type", "update")
                }
                value={updateBanner.redirection_type}
              >
                {RedirectionType && createOptionList(RedirectionType.data)}
              </Select>
              {updateErrors.redirection_type && (
                <ErrorText>{updateErrors.redirection_type}</ErrorText>
              )}
            </StyledDiv>
            {updateBanner.redirection_type === "in_app" && (
              <StyledDiv>
                <LabelInput>Redirection In App</LabelInput>
                <Select
                  style={{ width: "100%" }}
                  onChange={(e) =>
                    handleSelectChange(e, "redirection_in_app", "update")
                  }
                  value={updateBanner.redirection_in_app}
                >
                  {RedirectionInApp && createOptionList(RedirectionInApp.data)}
                </Select>
                {updateErrors.redirection_in_app && (
                  <ErrorText>{updateErrors.redirection_in_app}</ErrorText>
                )}
              </StyledDiv>
            )}
            {updateBanner.redirection_type === "external_link" && (
              <StyledDiv>
                <LabelInput>Redirection URL</LabelInput>
                <Input
                  placeholder="Redirection URL"
                  name="redirection_url"
                  value={updateBanner.redirection_url}
                  onChange={(e) =>
                    setUpdateBanner({
                      ...updateBanner,
                      redirection_url: e.target.value,
                    })
                  }
                />
                {updateErrors.redirection_url && (
                  <ErrorText>{updateErrors.redirection_url}</ErrorText>
                )}
              </StyledDiv>
            )}
            {updateBanner.redirection_type === "in_app" && (
              <LabelInput>Redirection Params</LabelInput>
            )}
            {updateBanner.redirection_type === "in_app" &&
              redirectionParams.map((item, index) => (
                <Grid container>
                  <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                    <StyledDiv>
                      <LabelInput>Key {index + 1}</LabelInput>
                      <Input
                        placeholder={`key ${index + 1}`}
                        name="key"
                        value={item.key}
                        onChange={(e) => {
                          const newArr = [...redirectionParams];
                          newArr[index].key = e.target.value;
                          setRedirectionParams(newArr);
                        }}
                      />
                      {/* {updateErrors.redirection_params && (
                                        <ErrorText>
                                            {updateErrors.redirection_params[index]?.key}
                                        </ErrorText>
                                        )} */}
                    </StyledDiv>
                  </LeftGridItem>
                  <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                    <StyledDiv>
                      <LabelInput>Value {index + 1}</LabelInput>
                      <Input
                        placeholder={`value ${index + 1}`}
                        name="value"
                        value={item.value}
                        onChange={(e) => {
                          const newArr = [...redirectionParams];
                          newArr[index].value = e.target.value;
                          setRedirectionParams(newArr);
                        }}
                      />
                      {/* {updateErrors.redirection_params && (
                                        <ErrorText>
                                            {updateErrors.redirection_params[index]?.value}
                                        </ErrorText>
                                        )} */}
                    </StyledDiv>
                  </RightGridItem>
                </Grid>
              ))}
            {updateBanner.redirection_type === "in_app" && (
              <div>
                <Button style={{ flex: 1 }} onClick={addParamsFunc}>
                  Add param
                </Button>
                <Button style={{ marginLeft: "3%" }} onClick={removeParamsFunc}>
                  Remove param
                </Button>
              </div>
            )}
            <StyledDiv>
              <LabelInput>CTA</LabelInput>
              <Input
                placeholder="cta"
                name="Cta"
                value={updateBanner.cta_text}
                onChange={(e) =>
                  setUpdateBanner({
                    ...updateBanner,
                    cta_text: e.target.value,
                  })
                }
              />
              {updateErrors.cta_text && (
                <ErrorText>{updateErrors.cta_text}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>UI Version</LabelInput>
              <Select
                style={{ width: "100%" }}
                onChange={(e) => handleSelectChange(e, "ui_version", "update")}
                value={updateBanner.ui_version}
              >
                {UIVersion && createOptionList(UIVersion.data)}
              </Select>
              {updateErrors.ui_version && (
                <ErrorText>{updateErrors.ui_version}</ErrorText>
              )}
            </StyledDiv>
            <Grid container>
              <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>Is Active</LabelInput>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleSelectChange(e, "is_active", "update")
                    }
                    value={updateBanner.is_active}
                  >
                    {IsActive && createOptionList(IsActive.data)}
                  </Select>
                  {updateErrors.is_active && (
                    <ErrorText>{updateErrors.is_active}</ErrorText>
                  )}
                </StyledDiv>
              </LeftGridItem>
              <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>Placement</LabelInput>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleSelectChange(e, "placement", "update")
                    }
                    value={updateBanner.placement}
                  >
                    {Placement && createOptionList(Placement.data)}
                  </Select>
                  {updateErrors.placement && (
                    <ErrorText>{updateErrors.placement}</ErrorText>
                  )}
                </StyledDiv>
              </RightGridItem>
            </Grid>
            {/* <StyledDiv>
                            <LabelInput>Meta</LabelInput>
                            <Input
                                placeholder="Meta"
                                name="meta"
                                value={updateBanner.meta}
                                onChange={(e) => setUpdateBanner({
                                        ...updateBanner,
                                        meta: e.target.value === '' ? null : e.target.value,
                                    })}
                            />
                            {updateErrors.meta && <ErrorText>{updateErrors.meta}</ErrorText>}
                        </StyledDiv> */}
            <Grid container>
              <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>Target Users</LabelInput>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      handleSelectChange(e, "target_users", "update")
                    }
                    value={updateBanner.target_users}
                  >
                    {TargetUsers && createOptionList(TargetUsers.data)}
                  </Select>
                  {updateErrors.target_users && (
                    <ErrorText>{updateErrors.target_users}</ErrorText>
                  )}
                </StyledDiv>
              </LeftGridItem>
              <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                {updateBanner.ui_version === "v2" ? (
                  <StyledDiv>
                    <LabelInput>Sub Placement</LabelInput>
                    <Select
                      style={{ width: "100%" }}
                      onChange={(e) =>
                        handleSelectChange(e, "sub_placement", "update")
                      }
                      value={updateBanner.sub_placement}
                    >
                      {SubPlacement && createOptionList(SubPlacement.data)}
                    </Select>
                    {updateErrors.sub_placement && (
                      <ErrorText>{updateErrors.sub_placement}</ErrorText>
                    )}
                  </StyledDiv>
                ) : null}
              </RightGridItem>
            </Grid>
            <LabelInput>Update Image</LabelInput>
            {/* <Image
              value={updateBanner.banner_image}
              onChange={(e) => onFileChange(e, "update")}
            /> */}
            {[
              { value: "en", title: "English" },
              { value: "hi", title: "Hindi" },
              { value: "mr", title: "Marathi" },
              { value: "guj", title: "Gujarati" },
              { value: "ben", title: "Bengali" },
              { value: "tam", title: "Tamil" },
              { value: "tel", title: "Telugu" },
              { value: "kan", title: "Kannada" },
            ].map((lang) => (
              <StyledDiv>
                <LabelInput>In {lang.title}</LabelInput>
                <Image
                  langKey={lang.value}
                  update={true}
                  value={updateBanner.banner_image[lang.value]}
                  onChange={(e) => onFileChange(e, "update", lang.value)}
                />
                {updateErrors.banner_image && !updateBanner.banner_image[lang.value] && (
                  <ErrorText>{updateErrors.banner_image}</ErrorText>
                )}
              </StyledDiv>
            ))}
          </StyleModal>
        )}
      </Grid>
    </Grid>
  );
};

export default Banner;
