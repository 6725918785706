/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import swal from "sweetalert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Modal, Button, Select, Input } from "antd";
import { isEmpty, truncate, size } from "lodash";
import { RNS3 } from "react-native-aws3";
import styled from "styled-components";
import CommonTable from "../Common/Table";
import {
  FilterGrid,
  FilterTitleWrapper,
  StyledButton,
  StyledInput,
  LabelInput,
  ErrorText,
  IconContainer,
  StyledDiv,
  RightGridItem,
  LeftGridItem,
  StyledDateTimePicker,
} from "./styles";
import CommonLoader from "../Common/Loader";
import CommonDropdown from "../Common/Dropdown";
import Paginator from "../Common/Paginator";
import CommonDatePicker from "../Common/DatePicker";
import {
  formattedValue,
  snakeToUpperCase,
  dateFormating,
  isValidImageUploadObject,
  dateFormattingServerUTC,
} from "../../util/helper";
import { uploadFileToS3 } from "../../util/uploadUrltoDB";

import { buID } from "../../constant";
import {
  GetEventListApi,
  CreateEventApi,
  UpdateEventApi,
  DeleteEventApi,
} from "../../api/event";
import { GetUploadS3ImageApi } from "../../api/uploadImageS3";
import { getImageWithBucketCreds, s3Config } from "../../util/custom";
import Image from "../Image";

const StyleModal = styled(Modal)`
  .ant-modal-header {
    background: ${(props) => props.theme.dark};
    color: ${(props) => props.theme.dark};
  }

  .ant-modal-body {
    background: ${(props) => props.theme.dark};
  }

  .ant-modal-title {
    color: ${(props) => props.theme.text};
  }
  .ant-modal-footer {
    background: ${(props) => props.theme.dark};
  }
  .ant-modal-close-icon {
    color: ${(props) => props.theme.text};
  }

  .ant-modal .sc-AzgDb .jcGGxa {
    padding: 0px;
  }
`;

const Event = () => {
  const auth = useSelector((state) => state.auth);
  const maxTitleCharacters = 25;
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [data, setData] = useState([]);
  // const [authorList, setAuthorList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(1);
  const { Option } = Select;

  const [filters, setFilters] = useState({
    page: currentPage,
    page_limit: pageSize,
    sort_by: "updated_at",
    sort_type: "desc",
    is_deleted: "false",
  });

  const [eventFilters, setEventFilters] = useState({
    author_id: {
      key: "author_id",
      data: [],
    },
  });

  const defaultEvent = {
    title: "",
    description: "",
    hostName: "",
    type: "",
    vendor: "",
    status: "",
    locationType: "virtual",
    startTime: new Date(),
    endTime: new Date(),
    uploadfile: "",
    meetingURL: "",
  };

  const defaultIsUpdatedObject = {
    startTime: false,
    endTime: false,
    uploadfile: false,
  };

  const [currentEvent, setCurrentEvent] = useState(defaultEvent);
  const [errors, setErrors] = useState({});

  const [updateEvent, setUpdateEvent] = useState({});
  const [updateErrors, setUpdateErrors] = useState({});
  const [isUpdated, setIsUpdated] = useState(defaultIsUpdatedObject);

  // const handleToggleChange = (value) => {
  //     toggle ? setToggle(false) : setToggle(true);
  //     return toggle;
  // };

  // const handleAmountChange = (e) => {
  //     const re = /^[0-9\b]+$/;
  //     if (e.target.value === '' || re.test(e.target.value)) {
  //         setCurrentEvent({ ...currentEvent, amount: e.target.value });
  //     }
  // };

  // const handleDiscountChange = (e) => {
  //     const re = /^[0-9\b]+$/;
  //     if (e.target.value === '' || re.test(e.target.value)) {
  //         setCurrentEvent({ ...currentEvent, discount: e.target.value });
  //     }
  // };

  const getEventList = (params) => {
    setLoading(true);
    GetEventListApi(params)
      .then((res) => {
        let success = false;
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            const apiData = (res.data || {}).data || {};
            setData(apiData.results);
            setCurrentPage(apiData.page);
            setPageSize(apiData.page_limit);
            setTotal(apiData.total_count);
            setFilters(params);
            setLoading(false);
          }
        }
        if (!success) {
          setLoading(false);
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to fetch data";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  const deleteEvent = (id) => {
    swal({
      title: "Are you sure you want to delete this Event ?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((success) => {
      if (success) {
        setLoading(true);
        const deleteParams = {
          id,
        };
        DeleteEventApi(deleteParams)
          .then((res) => {
            let apiSuccess = false;
            setLoading(false);
            if (res.status === 200) {
              if ((res.data || {}).success) {
                apiSuccess = true;
                getEventList(filters);
                swal("success!", "Successfully Deleted Event", "success");
              }
            }
            if (!apiSuccess) {
              setLoading(false);
              swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
            }
          })
          .catch((error) => {
            setLoading(false);
            let errorMessage = "Failed to delete Event";
            if (error.response) {
              if (error.response.status === 400) {
                errorMessage = (error.response.data || {}).error_desc;
              }
            }
            swal("Oops!", errorMessage, "error");
          });
      }
    });
  };

  const showCreateEventModal = () => {
    setShowCreateModal(true);
  };

  const handleCreateEventModalCancel = () => {
    setShowCreateModal(false);
    setCurrentEvent(defaultEvent);
    setErrors({});
  };

  const showUpdateEventModal = (eventObj) => {
    const updateObject = Object.create(defaultEvent);
    updateObject.id = eventObj.id;
    updateObject.title = eventObj.title;
    updateObject.description = eventObj.description;
    updateObject.hostName = eventObj.host_name;
    updateObject.status = eventObj.status;
    updateObject.vendor = eventObj.vendor;
    updateObject.type = eventObj.type;
    updateObject.image = eventObj.images;
    updateObject.startTime = new Date(eventObj.start_time);
    updateObject.endTime = new Date(eventObj.end_time);
    updateObject.uploadfile = eventObj.images;
    updateObject.meetingURL = eventObj.meeting_url;
    setUpdateEvent(updateObject);
    setShowUpdateModal(true);
  };

  const handleUpdateModalCancel = () => {
    setShowUpdateModal(false);
    setUpdateEvent({});
    setUpdateErrors({});
    setIsUpdated(defaultIsUpdatedObject);
  };

  const renderMeetingDetails = (item) => {
    let renderSection = "";
    const meetingURL = item.meeting_url;
    if (formattedValue(meetingURL) === "-") {
      renderSection = formattedValue(meetingURL);
    } else {
      renderSection = (
        <>
          <div>
            <span>URL : </span>
            <span>{meetingURL}</span>
          </div>
        </>
      );
    }
    return renderSection;
  };

  const renderEdit = (item) => (
    <IconContainer>
      <EditIcon onClick={() => showUpdateEventModal(item)} />
    </IconContainer>
  );

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 10,
      render: (id) => <>{id}</>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 70,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      textWrap: "word-break",
      width: 150,
      render: (description) => (
        <div>
          {truncate(description || "", {
            length: 125,
            separator: "...",
          })}
        </div>
      ),
    },
    {
      title: "Host",
      dataIndex: "host_name",
      key: "host_name",
      width: 70,
      align: "center",
      render: (value) => <div>{formattedValue(value)}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 70,
      align: "center",
      render: (status) => <div>{snakeToUpperCase(formattedValue(status))}</div>,
    },
    {
      title: "Start time",
      dataIndex: "start_time",
      key: "start_time",
      textWrap: "word-break",
      width: 110,
      align: "center",
      render: (value) => <p>{dateFormating(value)}</p>,
    },
    {
      title: "End time",
      dataIndex: "end_time",
      key: "end_time",
      textWrap: "word-break",
      width: 110,
      align: "center",
      render: (value) => <p>{dateFormating(value)}</p>,
    },
    {
      title: "Participant Count",
      dataIndex: "participant_count",
      key: "participant_count",
      width: 20,
      align: "center",
      render: (value) => <div>{formattedValue(value)}</div>,
    },
    {
      title: "Meeting Vendor",
      dataIndex: "vendor",
      key: "vendor",
      width: 20,
      align: "center",
      render: (vendor) => <div>{snakeToUpperCase(formattedValue(vendor))}</div>,
    },
    {
      title: "Meeting Details",
      dataIndex: "meeting_details",
      key: "meeting_details",
      width: 180,
      align: "center",
      render: (text, item) => <div>{renderMeetingDetails(item)}</div>,
    },
    {
      title: "View Registrations",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (item) => (
        <IconContainer>
          <NavLink to={`/event/${item}`}>
            <VisibilityIcon />
          </NavLink>
        </IconContainer>
      ),
    },
  ];

  if (!JSON.parse(filters.is_deleted || false)) {
    tableColumns.push({
      title: "Edit",
      dataIndex: "id",
      key: "id",
      render: (text, item) => renderEdit(item),
      align: "center",
    });
    tableColumns.push({
      title: "Delete",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, item) =>
        item.is_deleted === false ? (
          <IconContainer>
            <DeleteIcon onClick={() => deleteEvent(item.id)} />
          </IconContainer>
        ) : null,
    });
  }

  const createOptionList = (list) => {
    const optionArray = [];
    // eslint-disable-next-line array-callback-return
    list.map((item) => {
      optionArray.push(
        <Option key={item.id} value={item.id}>
          {item.title}
        </Option>
      );
    });

    return optionArray;
  };

  const currentEventTypeList = {
    key: "currentEvent_type",
    data: [
      {
        id: "session",
        title: "Session",
        selected: false,
      },
    ],
  };

  // const LocationTypeList = {
  //     key: 'location_type',
  //     data: [
  //         {
  //             id: 'virtual',
  //             title: 'virtual',
  //             selected: true,
  //         },
  //         {
  //             id: 'physical',
  //             title: 'physical',
  //             selected: false,
  //         },
  //     ],
  // };

  const VendorTypeList = {
    key: "VendorType",
    data: [
      {
        id: "zoom",
        title: "Zoom",
        selected: false,
      },
      {
        id: "google_meet",
        title: "Google Meet",
        selected: false,
      },
    ],
  };

  const sortTypeList = {
    key: "sort_type",
    data: [
      {
        id: "asc",
        title: "Asc",
        selected: false,
      },
      {
        id: "desc",
        title: "Desc",
        selected: true,
      },
    ],
  };

  const sortByList = {
    key: "sort_by",
    data: [
      {
        id: "id",
        title: "id",
        selected: false,
      },
      {
        id: "created_at",
        title: "created_at",
        selected: false,
      },
      {
        id: "updated_at",
        title: "updated_at",
        selected: true,
      },
      {
        id: "content",
        title: "content",
        selected: false,
      },
      {
        id: "status",
        title: "status",
        selected: false,
      },
      {
        id: "start_time",
        title: "Start Time",
        selected: false,
      },
      {
        id: "end_time",
        title: "End Time",
        selected: false,
      },
    ],
  };

  const handleSelectChange = (value, key, action) => {
    if (action === "create") {
      if (key === "type") {
        setCurrentEvent({ ...currentEvent, type: value });
      } else if (key === "locationType") {
        setCurrentEvent({ ...currentEvent, locationType: value });
      } else if (key === "vendor") {
        setCurrentEvent({ ...currentEvent, vendor: value });
      } else if (key === "status") {
        setCurrentEvent({ ...currentEvent, status: value });
      } else if (key === "authorID") {
        setCurrentEvent({ ...currentEvent, authorID: value });
      }
    } else if (action === "update") {
      if (key === "type") {
        setUpdateEvent({ ...updateEvent, type: value });
      } else if (key === "locationType") {
        setUpdateEvent({ ...updateEvent, locationType: value });
      } else if (key === "vendor") {
        setUpdateEvent({ ...updateEvent, vendor: value });
      } else if (key === "status") {
        setUpdateEvent({ ...updateEvent, status: value });
      } else if (key === "authorID") {
        setUpdateEvent({ ...updateEvent, authorID: value });
      }
    }
  };

  const handleDateChange = (dateTime, key, action) => {
    if (dateTime.toString() === "Invalid Date") {
      // set error for invalid date
      return;
    }
    if (action === "create") {
      if (key === "startTime") {
        if (dateTime === null || dateTime === undefined) {
          setCurrentEvent({ ...currentEvent, startTime: new Date() });
        } else {
          setCurrentEvent({ ...currentEvent, startTime: dateTime });
        }
      } else if (key === "endTime") {
        if (dateTime === null || dateTime === undefined) {
          setCurrentEvent({ ...currentEvent, endTime: new Date() });
        } else {
          setCurrentEvent({ ...currentEvent, endTime: dateTime });
        }
      }
    } else if (action === "update") {
      if (key === "startTime") {
        setIsUpdated({ ...isUpdated, startTime: true });
        if (dateTime === null || dateTime === undefined) {
          setUpdateEvent({ ...updateEvent, startTime: new Date() });
        } else {
          setUpdateEvent({ ...updateEvent, startTime: dateTime });
        }
      } else if (key === "endTime") {
        setIsUpdated({ ...isUpdated, endTime: true });
        if (dateTime === null || dateTime === undefined) {
          setUpdateEvent({ ...updateEvent, endTime: new Date() });
        } else {
          setUpdateEvent({ ...updateEvent, endTime: dateTime });
        }
      }
    }
  };

  const onFileChange = (event, action) => {
    if (action === "create") {
      if (!event) {
        setCurrentEvent({ ...currentEvent, uploadfile: "" });
        return;
      }
      const file = event.target?.files[0];
      setCurrentEvent({ ...currentEvent, uploadfile: file });
    } else if (action === "update") {
      setIsUpdated({ ...isUpdated, uploadfile: true });
      if (!event) {
        setUpdateEvent({ ...updateEvent, uploadfile: "" });
        return;
      }
      const file = event.target?.files[0];
      setUpdateEvent({ ...updateEvent, uploadfile: file });
    }
  };

  // const createAuthorListOptions = (authorArr) => {
  //     const optionArray = [];
  //     // eslint-disable-next-line

  //     authorArr.map((author) => {
  //         optionArray.push(
  //             <Option key={author.id} value={author.id}>
  //                 {author.display_name}
  //             </Option>
  //         );
  //     });

  //     return optionArray;
  // };

  // const getAuthorList = () => {
  //     setLoading(true);
  //     GetAuthorListApi()
  //         .then((res) => {
  //             let success = false;
  //             setLoading(false);
  //             if (res.status === 200) {
  //                 if ((res.data || {}).success) {
  //                     success = true;
  //                     const apiData = (res.data || {}).data || {};
  //                     setAuthorList(apiData.result);
  //                 }
  //             }
  //             if (!success) {
  //                 swal(
  //                     'Oops!',
  //                     ((res.data || {}).data || {}).error_desc,
  //                     'error'
  //                 );
  //             }
  //         })
  //         .catch((error) => {
  //             setLoading(false);
  //             let errorMessage = 'Failed to fetch data';
  //             if (error.response) {
  //                 if (error.response.status === 400) {
  //                     errorMessage = (error.response.data || {}).error_desc;
  //                 }
  //             }
  //             swal('Oops!', errorMessage, 'error');
  //         });
  // };

  // useEffect(() => {
  //     getAuthorList();
  // }, []);

  useEffect(() => {
    filters.page = currentPage;
    filters.page_limit = pageSize;
    getEventList(filters);
  }, [currentPage, pageSize]);

  const generateEventParams = (eventObj, image, action) => {
    const params = {
      title: eventObj.title,
      description: eventObj.description,
      host_name: eventObj.hostName,
      type: eventObj.type,
      location_type: eventObj.locationType,
      vendor: eventObj.vendor,
      status: eventObj.status,
      meeting_url: eventObj.meetingURL,
      start_time: dateFormattingServerUTC(eventObj.startTime),
      end_time: dateFormattingServerUTC(eventObj.endTime),
      images: image,
    };
    if (action === "update") {
      params.id = eventObj.id;
    }

    return params;
  };

  const createEvent = (image) => {
    const params = generateEventParams(currentEvent, image, "create");
    setLoading(true);
    CreateEventApi(params)
      .then((res) => {
        let success = false;
        setLoading(false);
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            handleCreateEventModalCancel();
            getEventList(filters);
            swal("success!", "Successfully Created Event", "success");
          }
        }
        if (!success) {
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Event Creation Failed";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("OOPS!", errorMessage, "error");
      });
  };

  const updateEventApiCall = (image) => {
    const updateParams = generateEventParams(updateEvent, image, "update");
    setLoading(true);
    UpdateEventApi(updateParams)
      .then((res) => {
        setLoading(false);
        let success = false;
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            handleUpdateModalCancel();
            getEventList(filters);
            swal("Yayy!", "Successfully Updated Event", "success");
          }
        }
        if (!success) {
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to Update Event";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  const validateFields = (action) => {
    const validationErrors = {};
    let eventObj = {};

    if (action === "create") {
      eventObj = currentEvent;
    } else if (action === "update") {
      eventObj = updateEvent;
    }

    if (eventObj.title.length === 0) {
      validationErrors.title = "Required Title";
    } else if (eventObj.title.length > maxTitleCharacters) {
      validationErrors.title = `Max ${maxTitleCharacters} Characters Allowed`;
    }

    if (eventObj.hostName.length === 0) {
      validationErrors.hostName = "Required HostName";
    }

    if (eventObj.description.length === 0) {
      validationErrors.description = "Required Description";
    }

    if (size(eventObj.status) === 0) {
      validationErrors.status = "Required Status";
    }

    if (size(eventObj.type) === 0) {
      validationErrors.type = "Required Type";
    }

    if (size(eventObj.vendor) === 0) {
      validationErrors.vendor = "Required Vendor";
    }

    if (action === "create" || isUpdated.uploadfile) {
      if (isValidImageUploadObject(eventObj.uploadfile)) {
        if (
          eventObj.uploadfile.size &&
          eventObj.uploadfile.size / 1048576 > 10
        ) {
          validationErrors.uploadfile =
            "Please upload minimum file size less than 10mb";
        }
      } else {
        validationErrors.uploadfile = "Required Image";
      }
    }

    if (eventObj.meetingURL.length === 0) {
      validationErrors.meetingURL = "Required meetingURL";
    }

    if (action === "create" || isUpdated.startTime || isUpdated.endTime) {
      if (eventObj.startTime.getTime() >= eventObj.endTime.getTime()) {
        validationErrors.endTime = "End Time should be Greater than Start Time";
      }
    }

    return validationErrors;
  };

  const handleCreateEventSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateFields("create");
    setErrors(validationErrors);
    if (isEmpty(validationErrors)) {
      if (isValidImageUploadObject(currentEvent.uploadfile)) {
        const createTimeNow = new Date();
        let keyPrefix = `event/${
          auth.user_id
        }/image/${createTimeNow.toISOString()}`;
        keyPrefix += "-"+ currentEvent.uploadfile.name;
        let reader = new FileReader();
        reader.readAsDataURL(currentEvent.uploadfile);
        reader.onload = function () {
          const base64img = reader.result.split(',')[1];
          const params = {  
            filename: keyPrefix,
            encodedimg: base64img,
          }
          GetUploadS3ImageApi(params)
            .then((res) => {
              if (res.status === 200) {
                if (res.data) {
                  console.log("success in file upload", res.data);
                  const imgS3Param = {
                    location: res.data.data.url,
                    key: keyPrefix,
                  }
                  const image = getImageWithBucketCreds({
                    data: imgS3Param,
                  });
                  createEvent(image);
                }
              }
              else {
                console.log("error in file upload", ((res.data || {}).data || {}).error_desc);
              }
            })
            .catch((error) => {
              let errorMessage = "error in file upload";
              if (error.response) {
                if (error.response.status === 400) {
                  errorMessage = (error.response.data || {}).error_desc;
                }
              }
              console.log("error in file upload", errorMessage);
            });
        };
        reader.onerror = function (error) {
          console.log("Error in file reading: ", error);
        };
      }
    }
  };

  const handleUpdateEventSubmit = (event) => {
    event.preventDefault();
    const validationUpdateErrors = validateFields("update");
    setUpdateErrors(validationUpdateErrors);
    if (isEmpty(validationUpdateErrors)) {
      if (
        isUpdated.uploadfile &&
        isValidImageUploadObject(updateEvent.uploadfile)
      ) {
        const updateTimeNow = new Date();
        let keyPrefix = `event/${
          auth.user_id
        }/image/${updateTimeNow.toISOString()}`;
        keyPrefix += "-"+ updateEvent.uploadfile.name;
        let reader = new FileReader();
        reader.readAsDataURL(updateEvent.uploadfile);
        reader.onload = function () {
          const base64img = reader.result.split(',')[1];
          const params = {  
            filename: keyPrefix,
            encodedimg: base64img,
          }
          GetUploadS3ImageApi(params)
            .then((res) => {
              if (res.status === 200) {
                if (res.data) {
                  console.log("success in file upload", res.data);
                  const imgS3Param = {
                    location: res.data.data.url,
                    key: keyPrefix,
                  }
                  const image = getImageWithBucketCreds({
                    data: imgS3Param,
                  });
                  updateEventApiCall(JSON.stringify(image));
                }
              }
              else {
                console.log("error in file upload", ((res.data || {}).data || {}).error_desc);
              }
            })
            .catch((error) => {
              let errorMessage = "error in file upload";
              if (error.response) {
                if (error.response.status === 400) {
                  errorMessage = (error.response.data || {}).error_desc;
                }
              }
              console.log("error in file upload", errorMessage);
            });
        };
        reader.onerror = function (error) {
          console.log("Error in file reading: ", error);
        };


        // keyPrefix += "-";
        // const config = { ...s3Config, keyPrefix };
        // if (process.env.REACT_APP_AWS_ACL) {
        //   config.acl = process.env.REACT_APP_AWS_ACL;
        // }
        // RNS3.put(updateEvent.uploadfile, config)
        //   .progress((progress) => console.log("progress", progress))
        //   .then((response) => {
        //     if (response.status !== 201) {
        //       console.log("error in file upload", response);
        //     } else {
        //       const image = getImageWithBucketCreds({
        //         data: (response.body || {}).postResponse,
        //       });
        //       updateEventApiCall(image);
        //     }
        //   });
      } else {
        updateEventApiCall(JSON.stringify(updateEvent.uploadfile));
      }
    }
  };

  const handleFilterChange = (filters) => {
    getEventList(filters);
  };

  const IsDeleted = {
    key: "is_deleted",
    data: [
      {
        id: "true",
        title: "True",
        selected: false,
      },
      {
        id: "false",
        title: "False",
        selected: true,
      },
    ],
  };

  const StatusList = {
    key: "status",
    data: [
      {
        id: "published",
        title: "Published",
        selected: true,
      },
      {
        id: "draft",
        title: "Draft",
        selected: false,
      },
    ],
  };

  return (
    <Grid container spacing={2}>
      <CommonLoader
        isLoading={loading}
        size="large"
        sentences={["please wait ..."]}
        type="pacman"
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <StyledButton primary onClick={() => showCreateEventModal()}>
          Create Event
        </StyledButton>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{
          paddingRight: "1rem",
          paddingLeft: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
          <FilterTitleWrapper>Filters</FilterTitleWrapper>
          <CommonDropdown
            list={StatusList}
            title="Status"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          <CommonDropdown
            list={IsDeleted}
            title="Is Deleted"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          <CommonDropdown
            list={sortByList}
            title="Sort By"
            searchable={false}
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          <CommonDropdown
            list={sortTypeList}
            title="Sort Type"
            searchable={false}
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          <CommonDatePicker
            title="Created At LTE"
            type="inline"
            dateFormat="dd/MM/yyyy"
            filterKey="created_at_lte"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          <CommonDatePicker
            title="Created At GTE"
            type="inline"
            dateFormat="dd/MM/yyyy"
            filterKey="created_at_gte"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          <CommonDatePicker
            title="Updated At LTE"
            type="inline"
            dateFormat="dd/MM/yyyy"
            filterKey="updated_at_lte"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          <CommonDatePicker
            title="Updated At GTE"
            type="inline"
            dateFormat="dd/MM/yyyy"
            filterKey="updated_at_gte"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          {eventFilters.author_id.data.length > 0 && (
            <CommonDropdown
              list={eventFilters.author_id}
              title="Author"
              searchable
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
          )}
        </FilterGrid>
        <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
          <CommonTable
            data={data}
            columns={tableColumns}
            pagination={false}
            scroll={{ x: "overflow" }}
          />
          <Paginator
            totalRecords={total}
            pageLimit={pageSize}
            pageNeighbours={2}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Grid>
      </Grid>
      <Grid>
        {showCreateModal && (
          <StyleModal
            title="Create Event"
            visible={showCreateModal}
            onOk={(e) => handleCreateEventSubmit(e)}
            onCancel={() => handleCreateEventModalCancel()}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={(e) => handleCreateEventSubmit(e)}
                loading={loading}
              >
                Submit
              </Button>,
            ]}
          >
            <StyledDiv>
              <LabelInput>Title ({maxTitleCharacters} Characters)</LabelInput>
              <Input
                placeholder="title"
                name="title"
                value={currentEvent.title}
                onChange={(e) =>
                  setCurrentEvent({
                    ...currentEvent,
                    title: e.target.value,
                  })
                }
              />
              {errors.title && <ErrorText>{errors.title}</ErrorText>}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Host Name</LabelInput>
              <Input
                placeholder="name"
                name="host_name"
                value={currentEvent.hostName}
                onChange={(e) =>
                  setCurrentEvent({
                    ...currentEvent,
                    hostName: e.target.value,
                  })
                }
              />
              {errors.hostName && <ErrorText>{errors.hostName}</ErrorText>}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Description</LabelInput>
              <StyledInput
                placeholder="content"
                name="content"
                value={currentEvent.description}
                onChange={(e) =>
                  setCurrentEvent({
                    ...currentEvent,
                    description: e.target.value,
                  })
                }
              />
              {errors.description && (
                <ErrorText>{errors.description}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Status</LabelInput>
              <Select
                style={{ width: "100%" }}
                onChange={(e) => handleSelectChange(e, "status", "create")}
              >
                {StatusList && createOptionList(StatusList.data)}
              </Select>
              {errors.status && <ErrorText>{errors.status}</ErrorText>}
            </StyledDiv>

            <Grid container>
              <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>Event Type</LabelInput>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) => handleSelectChange(e, "type", "create")}
                  >
                    {currentEventTypeList &&
                      createOptionList(currentEventTypeList.data)}
                  </Select>
                  {errors.type && <ErrorText>{errors.type}</ErrorText>}
                </StyledDiv>
              </LeftGridItem>
              <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>Vendor</LabelInput>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) => handleSelectChange(e, "vendor", "create")}
                  >
                    {VendorTypeList && createOptionList(VendorTypeList.data)}
                  </Select>
                  {errors.vendor && <ErrorText>{errors.vendor}</ErrorText>}
                </StyledDiv>
              </RightGridItem>
            </Grid>
            <Grid container>
              <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>Start Time</LabelInput>
                  <StyledDateTimePicker
                    clearable="true"
                    margin="normal"
                    variant="inline"
                    showTodayButton
                    disablePast
                    id="create-start-time-picker"
                    value={currentEvent.startTime}
                    onChange={(e) => handleDateChange(e, "startTime", "create")}
                    KeyboardButtonProps={{
                      "aria-label": "select end date",
                    }}
                  />
                  {errors.startTime && (
                    <ErrorText>{errors.startTime}</ErrorText>
                  )}
                </StyledDiv>
              </LeftGridItem>
              <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>End Time</LabelInput>
                  <StyledDateTimePicker
                    clearable="true"
                    margin="normal"
                    variant="inline"
                    showTodayButton
                    disablePast
                    id="create-start-time-picker"
                    value={currentEvent.endTime}
                    onChange={(e) => handleDateChange(e, "endTime", "create")}
                    KeyboardButtonProps={{
                      "aria-label": "select end date",
                    }}
                  />
                  {errors.endTime && <ErrorText>{errors.endTime}</ErrorText>}
                </StyledDiv>
              </RightGridItem>
            </Grid>
            <StyledDiv>
              <LabelInput>Meeting URL</LabelInput>
              <Input
                placeholder="Meeting URL"
                name="meeting_url"
                value={currentEvent.meetingURL}
                onChange={(e) =>
                  setCurrentEvent({
                    ...currentEvent,
                    meetingURL: e.target.value,
                  })
                }
              />
              {errors.meetingURL && <ErrorText>{errors.meetingURL}</ErrorText>}
            </StyledDiv>
            {/* {currentEvent.vendor === 'zoom' ? (
                            <Grid container>
                                <LeftGridItem
                                    item
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                >
                                    <StyledDiv>
                                        <LabelInput>Meeting ID</LabelInput>
                                        <Input
                                            placeholder="Meeting ID"
                                            name="meeting_id"
                                            value={currentEvent.meetingID}
                                            onChange={(e) => setCurrentEvent({
                                                    ...currentEvent,
                                                    meetingID: e.target.value,
                                                })}
                                        />
                                        {errors.meetingID && (
                                            <ErrorText>
                                                {errors.meetingID}
                                            </ErrorText>
                                        )}
                                    </StyledDiv>
                                </LeftGridItem>
                                <RightGridItem
                                    item
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                >
                                    <StyledDiv>
                                        <LabelInput>
                                            Meeting Password
                                        </LabelInput>
                                        <Input
                                            placeholder="Meeting Password"
                                            name="meeting_password"
                                            value={currentEvent.meetingPassword}
                                            onChange={(e) => setCurrentEvent({
                                                    ...currentEvent,
                                                    meetingPassword:
                                                        e.target.value,
                                                })}
                                        />
                                        {errors.meetingPassword && (
                                            <ErrorText>
                                                {errors.meetingPassword}
                                            </ErrorText>
                                        )}
                                    </StyledDiv>
                                </RightGridItem>
                            </Grid>
                        ) : null} */}
            {/* {currentEvent.locationType === 'physical' ? (
                            <StyledDiv>
                                <LabelInput>Location text</LabelInput>
                                <Input
                                    placeholder="Location"
                                    // name="title"
                                    value={currentEvent.locationText}
                                    onChange={(e) =>
                                        setCurrentEvent({
                                            ...currentEvent,
                                            locationText: e.target.value,
                                        })
                                    }
                                />
                                {errors.locationText && (
                                    <ErrorText>{errors.locationText}</ErrorText>
                                )}
                            </StyledDiv>
                        ) : null} */}

            {/* <StyledDiv>
                            <LabelInput>Paid</LabelInput>
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="NO"
                                defaultChecked
                                onClick={(e) => handleToggleChange(e)}
                            />
                        </StyledDiv>
                        {toggle ? (
                            <StyledDiv>
                                <LabelInput>Amount</LabelInput>
                                <Input
                                    placeholder="Amount"
                                    // name="title"
                                    value={currentEvent.amount}
                                    onChange={(e) => handleAmountChange(e)}
                                />
                                {errors.amount && (
                                    <ErrorText>{errors.amount}</ErrorText>
                                )}
                            </StyledDiv>
                        ) : null}
                        {toggle ? (
                            <StyledDiv>
                                <LabelInput>Discount</LabelInput>
                                <Input
                                    placeholder="Discount"
                                    // name="title"
                                    value={currentEvent.discount}
                                    onChange={(e) => handleDiscountChange(e)}
                                />
                                {errors.discount && (
                                    <ErrorText>{errors.discount}</ErrorText>
                                )}
                            </StyledDiv>
                        ) : null} */}
            {/* <StyledDiv>
                            <LabelInput>Select currentEvent Date and Time</LabelInput>
                            <CommonDateTimePicker
                                callbackcurrentEvent={(value) =>
                                    handlecurrentEventTimeChange(value)
                                }
                            />
                            {errors.currentEventTime && (
                                <ErrorText>{errors.currentEventTime}</ErrorText>
                            )}
                        </StyledDiv> */}
            {/* <StyledDiv>
                            <LabelInput>
                                Select Registration Date and Time
                            </LabelInput>
                            <CommonDateTimePicker
                                callbackregiestration={(value) =>
                                    handleRegistrationTimeChange(value)
                                }
                            />
                            {errors.registrationTime && (
                                <ErrorText>{errors.registrationTime}</ErrorText>
                            )}
                        </StyledDiv> */}
            <LabelInput>Upload Image</LabelInput>
            <Image
              value={currentEvent.uploadfile}
              onChange={(e) => onFileChange(e, "create")}
            />
            {errors.uploadfile && <ErrorText>{errors.uploadfile}</ErrorText>}
          </StyleModal>
        )}
      </Grid>
      <Grid>
        {showUpdateModal && (
          <StyleModal
            title="Update Event"
            visible={showUpdateModal}
            onOk={(e) => handleUpdateEventSubmit(e)}
            onCancel={() => handleUpdateModalCancel()}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={(e) => handleUpdateEventSubmit(e)}
                loading={loading}
              >
                Update
              </Button>,
            ]}
          >
            <StyledDiv>
              <LabelInput>Title ({maxTitleCharacters} Characters)</LabelInput>
              <Input
                placeholder="title"
                name="update_title"
                value={updateEvent.title}
                onChange={(e) =>
                  setUpdateEvent({
                    ...updateEvent,
                    title: e.target.value,
                  })
                }
              />
              {updateErrors.title && (
                <ErrorText>{updateErrors.title}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Host Name</LabelInput>
              <Input
                placeholder="name"
                name="update_host_name"
                value={updateEvent.hostName}
                onChange={(e) =>
                  setUpdateEvent({
                    ...updateEvent,
                    hostName: e.target.value,
                  })
                }
              />
              {updateErrors.hostName && (
                <ErrorText>{updateErrors.hostName}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Description</LabelInput>
              <StyledInput
                placeholder="description"
                name="update_content"
                value={updateEvent.description}
                onChange={(e) =>
                  setUpdateEvent({
                    ...updateEvent,
                    description: e.target.value,
                  })
                }
              />
              {updateErrors.description && (
                <ErrorText>{updateErrors.description}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Status</LabelInput>
              <Select
                style={{ width: "100%" }}
                onChange={(e) => handleSelectChange(e, "status", "update")}
                value={updateEvent.status}
              >
                {StatusList && createOptionList(StatusList.data)}
              </Select>
              {updateErrors.status && (
                <ErrorText>{updateErrors.status}</ErrorText>
              )}
            </StyledDiv>
            <Grid container>
              <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>Event Type</LabelInput>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) => handleSelectChange(e, "type", "update")}
                    value={updateEvent.type}
                  >
                    {currentEventTypeList &&
                      createOptionList(currentEventTypeList.data)}
                  </Select>
                  {updateErrors.type && (
                    <ErrorText>{updateErrors.type}</ErrorText>
                  )}
                </StyledDiv>
              </LeftGridItem>
              <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>Vendor</LabelInput>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(e) => handleSelectChange(e, "vendor", "update")}
                    value={updateEvent.vendor}
                  >
                    {VendorTypeList && createOptionList(VendorTypeList.data)}
                  </Select>
                  {updateErrors.vendor && (
                    <ErrorText>{updateErrors.vendor}</ErrorText>
                  )}
                </StyledDiv>
              </RightGridItem>
            </Grid>
            <Grid container>
              <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>Start Time</LabelInput>
                  <StyledDateTimePicker
                    clearable="true"
                    margin="normal"
                    variant="inline"
                    showTodayButton
                    disablePast
                    id="create-start-time-picker"
                    value={updateEvent.startTime}
                    onChange={(e) => handleDateChange(e, "startTime", "update")}
                    KeyboardButtonProps={{
                      "aria-label": "select end date",
                    }}
                  />
                  {updateErrors.startTime && (
                    <ErrorText>{updateErrors.startTime}</ErrorText>
                  )}
                </StyledDiv>
              </LeftGridItem>
              <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                <StyledDiv>
                  <LabelInput>End Time</LabelInput>
                  <StyledDateTimePicker
                    clearable="true"
                    margin="normal"
                    variant="inline"
                    showTodayButton
                    disablePast
                    id="create-start-time-picker"
                    value={updateEvent.endTime}
                    onChange={(e) => handleDateChange(e, "endTime", "update")}
                    KeyboardButtonProps={{
                      "aria-label": "select end date",
                    }}
                  />
                  {updateErrors.endTime && (
                    <ErrorText>{updateErrors.endTime}</ErrorText>
                  )}
                </StyledDiv>
              </RightGridItem>
            </Grid>
            <StyledDiv>
              <LabelInput>Meeting URL</LabelInput>
              <Input
                placeholder="Meeting URL"
                name="update_meeting_url"
                value={updateEvent.meetingURL}
                onChange={(e) =>
                  setUpdateEvent({
                    ...updateEvent,
                    meetingURL: e.target.value,
                  })
                }
              />
              {updateErrors.meetingURL && (
                <ErrorText>{updateErrors.meetingURL}</ErrorText>
              )}
            </StyledDiv>
            {/* {updateEvent.vendor === 'zoom' ? (
                            <Grid container>
                                <LeftGridItem
                                    item
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                >
                                    <StyledDiv>
                                        <LabelInput>Meeting ID</LabelInput>
                                        <Input
                                            placeholder="Meeting ID"
                                            name="update_meeting_id"
                                            value={updateEvent.meetingID}
                                            onChange={(e) => setUpdateEvent({
                                                    ...updateEvent,
                                                    meetingID: e.target.value,
                                                })}
                                        />
                                        {updateErrors.meetingID && (
                                            <ErrorText>
                                                {updateErrors.meetingID}
                                            </ErrorText>
                                        )}
                                    </StyledDiv>
                                </LeftGridItem>
                                <RightGridItem
                                    item
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                >
                                    <StyledDiv>
                                        <LabelInput>
                                            Meeting Password
                                        </LabelInput>
                                        <Input
                                            placeholder="Meeting Password"
                                            name="update_meeting_password"
                                            value={updateEvent.meetingPassword}
                                            onChange={(e) => setUpdateEvent({
                                                    ...updateEvent,
                                                    meetingPassword:
                                                        e.target.value,
                                                })}
                                        />
                                        {updateErrors.meetingPassword && (
                                            <ErrorText>
                                                {updateErrors.meetingPassword}
                                            </ErrorText>
                                        )}
                                    </StyledDiv>
                                </RightGridItem>
                            </Grid>
                        ) : null} */}
            {/* {currentEvent.locationType === 'physical' ? (
                            <StyledDiv>
                                <LabelInput>Location text</LabelInput>
                                <Input
                                    placeholder="Location"
                                    // name="title"
                                    value={currentEvent.locationText}
                                    onChange={(e) =>
                                        setCurrentEvent({
                                            ...currentEvent,
                                            locationText: e.target.value,
                                        })
                                    }
                                />
                                {errors.locationText && (
                                    <ErrorText>{errors.locationText}</ErrorText>
                                )}
                            </StyledDiv>
                        ) : null} */}

            {/* <StyledDiv>
                            <LabelInput>Paid</LabelInput>
                            <Switch
                                checkedChildren="Yes"
                                unCheckedChildren="NO"
                                defaultChecked
                                onClick={(e) => handleToggleChange(e)}
                            />
                        </StyledDiv>
                        {toggle ? (
                            <StyledDiv>
                                <LabelInput>Amount</LabelInput>
                                <Input
                                    placeholder="Amount"
                                    // name="title"
                                    value={currentEvent.amount}
                                    onChange={(e) => handleAmountChange(e)}
                                />
                                {errors.amount && (
                                    <ErrorText>{errors.amount}</ErrorText>
                                )}
                            </StyledDiv>
                        ) : null}
                        {toggle ? (
                            <StyledDiv>
                                <LabelInput>Discount</LabelInput>
                                <Input
                                    placeholder="Discount"
                                    // name="title"
                                    value={currentEvent.discount}
                                    onChange={(e) => handleDiscountChange(e)}
                                />
                                {errors.discount && (
                                    <ErrorText>{errors.discount}</ErrorText>
                                )}
                            </StyledDiv>
                        ) : null} */}
            {/* <StyledDiv>
                            <LabelInput>Select currentEvent Date and Time</LabelInput>
                            <CommonDateTimePicker
                                callbackcurrentEvent={(value) =>
                                    handlecurrentEventTimeChange(value)
                                }
                            />
                            {errors.currentEventTime && (
                                <ErrorText>{errors.currentEventTime}</ErrorText>
                            )}
                        </StyledDiv> */}
            {/* <StyledDiv>
                            <LabelInput>
                                Select Registration Date and Time
                            </LabelInput>
                            <CommonDateTimePicker
                                callbackregiestration={(value) =>
                                    handleRegistrationTimeChange(value)
                                }
                            />
                            {errors.registrationTime && (
                                <ErrorText>{errors.registrationTime}</ErrorText>
                            )}
                        </StyledDiv> */}
            <LabelInput>Update Image</LabelInput>
            <Image
              value={updateEvent.uploadfile}
              onChange={(e) => onFileChange(e, "update")}
            />
            {updateErrors.uploadfile && (
              <ErrorText>{updateErrors.uploadfile}</ErrorText>
            )}
          </StyleModal>
        )}
      </Grid>
    </Grid>
  );
};

export default Event;
