import React from 'react';
import { withRouter } from 'react-router-dom';
import ModelPortfoliosDetail from '../../component/ModelPortfoliosDetail';
import { Wrapper } from './styles';

const ModelPortfoliosDetailPage = (props) => (
    <Wrapper>
        <ModelPortfoliosDetail pageProps={props} />
    </Wrapper>
);

export default withRouter(ModelPortfoliosDetailPage);
