/* eslint-disable */
/* eslint-disable no-shadow */
/* eslint-disable comma-dangle */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { RNS3 } from 'react-native-aws3';
import swal from 'sweetalert';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Button, Select } from 'antd';
import { useSelector } from 'react-redux';
import {
    forEach,
    size,
    map,
    toLower,
    startCase,
    trim,
    isEmpty,
    parseInt,
} from 'lodash';
import {
    GetCalculatorListApi,
    CreateCalculatorApi,
    DeleteCalculatorApi,
    UpdateCalculatorApi,
} from '../../api/calculatorList';
import { GetUploadS3ImageApi } from "../../api/uploadImageS3";
import {
    FilterGrid,
    FilterTitleWrapper,
    StyledButton,
    IconContainer,
    StyleModal,
    LabelInput,
    CalculatorType,
} from './styles';
import CommonDropdown from '../Common/Dropdown';
import CommonDatePicker from '../Common/DatePicker';
import CommonLoader from '../Common/Loader';
import { buID } from '../../constant';

import { getCalculatorsWithCreds, s3Config } from '../../util/custom';
import Paginator from '../Common/Paginator';
import GoalCalculator from '../GoalCalculator';
import CommonTable from '../Common/Table';
import {
    goalCalculatorObj,
    retirementCalculatorObj,
    taxSavingsCalculatorObj,
    calculatorTypes,
} from '../../constant/calculators';

const api = {
    create: CreateCalculatorApi,
    update: UpdateCalculatorApi,
};


const calculatorDefaultState = {
    goal: goalCalculatorObj,
    retirement: retirementCalculatorObj,
    tax: taxSavingsCalculatorObj,
};

const Calculators = () => {
    const Option = Select.Option;
    const { admin } = useSelector((state) => state.auth);
    const [currentCalculatorType, setCurrentCalculatorType] = useState('');
    const [calculatorState, setCalculatorState] = useState({});

    const [activeCalculator, setActiveCalculator] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [showModal, setModalVisibility] = useState(false);
    const [total, setTotal] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [calculatorData, setCalculatorData] = useState([]);
    const [filters, setFilters] = useState({
        page: currentPage,
        page_limit: pageSize,
        sort_by: 'updated_at',
        sort_type: 'desc',
        is_deleted: 'false',
    });


    const getCalculatorList = (params) => {
        setLoading(true);
        GetCalculatorListApi(params)
            .then((res) => {
                if ((res.data || {}).success) {
                    const apiData = (res.data || {}).data || {};
                    setCalculatorData(apiData.results);
                    setCurrentPage(apiData.page);
                    setPageSize(apiData.page_limit);
                    setTotal(apiData.total_count);
                    setFilters(params);
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    useEffect(() => {
        // filters.page = currentPage;
        // filters.page_limit = pageSize;
        getCalculatorList(filters);
    }, [currentPage, pageSize]);

    useEffect(() => {
        if (activeCalculator.id) {
            resetCalculatorState(activeCalculator);
        } else {
            resetCalculatorState();
        }
    }, [currentCalculatorType, activeCalculator]);

    const handleFilterChange = (filters) => {
        getCalculatorList(filters);
    };

    const onCalculatorDelete = (id) => {
        swal({
            title: 'Are you sure you want to delete this Calculator ?',
            text: '',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((success) => {
            if (success) {
                setLoading(true);
                const params = {
                    id,
                };
                DeleteCalculatorApi(params)
                    .then((res) => {
                        let success = false;
                        setLoading(false);
                        if (res.status === 200) {
                            if ((res.data || {}).success) {
                                success = true;
                                getCalculatorList(filters);
                                swal(
                                    'success!',
                                    'Successfully Delete Calculator',
                                    'success'
                                );
                            }
                        }
                        if (!success) {
                            setLoading(false);
                            swal(
                                'Oops!',
                                ((res.data || {}).data || {}).error_desc,
                                'error'
                            );
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        let errorMessage = 'Failed to delete post';
                        if (error.response) {
                            if (error.response.status === 400) {
                                errorMessage = (error.response.data || {})
                                    .error_desc;
                            }
                        }
                        swal('Oops!', errorMessage, 'error');
                    });
            }
        });
    };

    const tableColumns = [
        // {
        //     title: 'ID',
        //     dataIndex: 'id',
        //     key: 'id',
        //     width: 10,
        //     // render: (item) => setCurrentAuthorID(item),
        //     // setCurrentAuthorID
        // },
        {
            title: 'Calculator Name',
            dataIndex: 'name',
            key: 'name',
            width: 20,
            render: (name) => startCase(toLower(name)),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: 20,
            render: (type) => startCase(type),
            // align: 'center',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: 150,
            // align: 'center',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 180,
            // align: 'center',
        },
        {
            title: 'CTA Text',
            dataIndex: 'cta_text',
            key: 'cta_text',
            width: 20,
            // align: 'center',
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
            width: 20,
            align: 'center',
        },
    ];


    if (!JSON.parse(filters.is_deleted)){
        tableColumns.push({
            title: 'Edit',
            dataIndex: 'id',
            key: 'id',
            render: (text, item) => {
                if (item.is_deleted) {
                    return null;
                }
                return (
                    <IconContainer>
                        <EditIcon
                            size="small"
                            onClick={() => onModalShow(item)}
                        />
                    </IconContainer>
                );
            },
            width: 20,
            align: 'center',
        });
        tableColumns.push({
            title: 'Delete',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            render: (text, item) => (
                <>
                    {!item.is_deleted && (
                        <IconContainer>
                            <DeleteIcon
                                size="small"
                                onClick={() => onCalculatorDelete(item.id)}
                            />
                        </IconContainer>
                    )}
                </>
            ),
            width: 20,
        });

    }

    const sortTypeList = {
        key: 'sort_type',
        data: [
            {
                id: 'asc',
                title: 'Asc',
                selected: false,
            },
            {
                id: 'desc',
                title: 'Desc',
                selected: true,
            },
        ],
    };

    const sortByList = {
        key: 'sort_by',
        data: [
            {
                id: 'id',
                title: 'id',
                selected: false,
            },
            {
                id: 'created_at',
                title: 'created_at',
                selected: false,
            },
            {
                id: 'updated_at',
                title: 'updated_at',
                selected: true,
            },
            {
                id: 'title',
                title: 'title',
                selected: false,
            },
        ],
    };

    const IsDeleted = {
        key: 'is_deleted',
        data: [
            {
                id: 'true',
                title: 'True',
                selected: false,
            },
            {
                id: 'false',
                title: 'False',
                selected: true,
            },
        ],
    };

    const onChange = (data) => {
        setCalculatorState({ ...data });
    };

    const resetCalculatorState = (loadedCalculator = {}) => {
        const currentState = {};
        const currentCalculator = calculatorDefaultState[currentCalculatorType];
        forEach(currentCalculator, (val, key) => {
            if (currentCalculator[key].defaultValue) {
                currentState[key] = { defaultValue: {} };
                if (loadedCalculator.meta) {
                    if (loadedCalculator.meta.defaultValue) {
                        currentState[key] = loadedCalculator.meta;
                    } else {
                        currentState[key] = {
                            defaultValue: { ...loadedCalculator.meta },
                        };
                    }
                } else {
                    forEach(
                        Object.keys(currentCalculator[key].defaultValue),
                        (item) => {
                            currentState[key].defaultValue[item] = {
                                min: '',
                                max: '',
                                steps: '',
                            };
                        }
                    );
                }
            } else {
                let defaultValue = key === 'image' ? {} : '';
                if (loadedCalculator[key]) {
                    defaultValue = loadedCalculator[key];
                }
                if (key === 'image' && loadedCalculator.banner_image) {
                    defaultValue = loadedCalculator['banner_image'];
                }
                currentState[key] = {
                    ...currentCalculator[key],
                    value: defaultValue,
                };
            }
        });
        setCalculatorState({ ...currentState });
    };

    const handleCalculatorSelectChange = (value) => {
        setCurrentCalculatorType(value);
    };

    const renderCalculatorOptions = () => {
        return map(calculatorTypes, (item) => {
            return (
                <Option key={item.id} value={item.id}>
                    {item.title}
                </Option>
            );
        });
    };

    const validateFields = (state = calculatorState) => {
        const validationErrors = {};

        if (size(trim(state.title.value)) < 1) {
            validationErrors.title = 'Title is missing';
        }

        if (size(trim(state.description.value)) < 1) {
            validationErrors.description = 'Description is missing';
        }
        if (
            !parseInt(state.position.value) ||
            parseInt(state.position.value) < 1
        ) {
            validationErrors.position = 'Position is missing';
        }

        if (size(trim(state.cta_text.value)) < 1) {
            validationErrors.cta_text = 'CTA Text is missing';
        }
        if (!('bucket' in state.image.value)) {
            if (!state.image.value.size) {
                validationErrors.image = 'Image Required';
            } else if (
                (state.image.value || {}).size &&
                (state.image.value || {}).size / 1048576 > 10
            ) {
                validationErrors.image =
                    'Please upload file less than 10mb';
            }
        }

        forEach(Object.keys(state.metaData.defaultValue), (item) => {
            const { min, max, steps } = state.metaData.defaultValue[item];
            validationErrors[item] = {};
            if (max && min && steps > 0 && max > 0 && steps >= max) {
                validationErrors[item].main =
                    '*Steps cannot be greater than or equal to max value';
            }
            if (max && min && max > 0 && min > 0 && max <= min) {
                validationErrors[item].main =
                    '*Max value cannot be greater than or equal to min value';
            }
            if (!min || min < 0) {
                validationErrors[item].min = '*Required Field';
            }
            if (!max || max < 0) {
                validationErrors[item].max = '*Required Field';
            }
            if (!steps || steps < 0) {
                validationErrors[item].steps = '*Required Field';
            }
            if (isEmpty(validationErrors[item])) {
                delete validationErrors[item];
            }
        });
        if (isEmpty(validationErrors)) {
            setErrors({});
            return true;
        }
        setErrors({ ...validationErrors });
        return false;
    };

    const handleCalculatorSubmit = (media) => {
        let apiType = 'create';
        const {
            title,
            description,
            image,
            cta_text,
            position,
            metaData,
        } = calculatorState;
        const body = {
            name: size(currentCalculatorType) ? currentCalculatorType : 'goal',
            type: size(currentCalculatorType) ? currentCalculatorType : 'goal',
            title: trim(title.value),
            buid: buID,
            description: trim(description.value),
            cta_text: trim(cta_text.value),
            position: position.value,
            banner_image: media || image.value,
            meta: metaData,
        };
        if (activeCalculator.id) {
            body.id = activeCalculator.id;
            apiType = 'update';
        }
        setLoading(true);
        api[apiType](body)
            .then((res) => {
                setLoading(false);
                if ((res.data || {}).success) {
                    getCalculatorList(filters);
                    handleModalCancel();
                    swal(
                        'Success!',
                        `Successfully ${toLower(apiType)}d the calculator`,
                        'success'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to Update Calculator';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const onCalculatorSubmit = () => {
        const { image } = calculatorState;
        if ('size' in image.value) {
            const keyPrefix = `${admin.id}/calculators/photo/${image.value.name}`;
            let reader = new FileReader();
            reader.readAsDataURL(image.value);
            reader.onload = function () {
            const base64img = reader.result.split(',')[1];
            const params = {  
                filename: keyPrefix,
                encodedimg: base64img,
            }
            GetUploadS3ImageApi(params)
                .then((res) => {
                if (res.status === 200) {
                    if (res.data) {
                    console.log("success in file upload", res.data);
                    const imgS3Param = {
                        location: res.data.data.url,
                        key: keyPrefix,
                    }
                    const image = getCalculatorsWithCreds({
                        data: imgS3Param,
                    });
                    handleCalculatorSubmit(image);
                    }
                }
                else {
                    console.log("error in file upload", ((res.data || {}).data || {}).error_desc);
                }
                })
                .catch((error) => {
                let errorMessage = "error in file upload";
                if (error.response) {
                    if (error.response.status === 400) {
                    errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                console.log("error in file upload", errorMessage);
                });
            };
            reader.onerror = function (error) {
            console.log("Error in file reading: ", error);
            };
            // const keyPrefix = `${admin.id}/calculators/photo/${image.value.name}`;
            // const config = { ...s3Config, keyPrefix };
            // if (process.env.REACT_APP_AWS_ACL) {
            //     config.acl = process.env.REACT_APP_AWS_ACL;
            // }

            // RNS3.put(image.value, config)
            //     .progress((progress) => console.log('progress', progress))
            //     .then((response) => {
            //         if (response.status !== 201) {
            //             console.log('error in file upload', response);
            //         } else {
            //             const image = getCalculatorsWithCreds({
            //                 data: (response.body || {}).postResponse,
            //             });
            //             handleCalculatorSubmit(image);
            //         }
            //     });
        } else if (activeCalculator.id) {
            handleCalculatorSubmit();
        }
    };

    const renderCalculator = () => {
        if (isEmpty(currentCalculatorType) || isEmpty(calculatorState)) {
            return null;
        }
        const params = {
            errors,
            onChange,
            calculatorState,
        };
        return <GoalCalculator {...params} />;
    };

    const onModalShow = (item) => {
        if (item) {
            setActiveCalculator(item);
            setCurrentCalculatorType(item.type);
        }
        setModalVisibility(true);
    };
    const handleModalCancel = () => {
        setActiveCalculator({});
        setCurrentCalculatorType('');
        resetCalculatorState();
        setModalVisibility(false);
        setErrors({});
    };
    const handleSubmit = () => {
        if (validateFields()) {
            onCalculatorSubmit();
        }
    };

    return (
        <Grid container spacing={2}>
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <StyledButton onClick={() => setModalVisibility(true)}>
                    Add Calculator
                </StyledButton>
            </Grid>
            <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
                <FilterTitleWrapper>Filters</FilterTitleWrapper>
                <CommonDropdown
                    list={IsDeleted}
                    title="Is Deleted"
                    onChange={(filters) => handleFilterChange(filters)}
                    filters={filters}
                />
                <CommonDropdown
                    list={sortByList}
                    title="Sort By"
                    searchable={false}
                    onChange={(filters) => handleFilterChange(filters)}
                    filters={filters}
                />
                <CommonDropdown
                    list={sortTypeList}
                    title="Sort Type"
                    searchable={false}
                    onChange={(filters) => handleFilterChange(filters)}
                    filters={filters}
                />
                {/* <CommonDatePicker
                    title="Created At LTE"
                    type="inline"
                    dateFormat="dd/MM/yyyy"
                    filterKey="created_at_lte"
                    onChange={(filters) => handleFilterChange(filters)}
                    filters={filters}
                />
                <CommonDatePicker
                    title="Created At GTE"
                    type="inline"
                    dateFormat="dd/MM/yyyy"
                    filterKey="created_at_gte"
                    onChange={(filters) => handleFilterChange(filters)}
                    filters={filters}
                /> */}
                {/* <CommonDatePicker
                    title="Updated At LTE"
                    type="inline"
                    dateFormat="dd/MM/yyyy"
                    filterKey="updated_at_lte"
                    onChange={(filters) => handleFilterChange(filters)}
                    filters={filters}
                />
                <CommonDatePicker
                    title="Updated At GTE"
                    type="inline"
                    dateFormat="dd/MM/yyyy"
                    filterKey="updated_at_gte"
                    onChange={(filters) => handleFilterChange(filters)}
                    filters={filters}
                /> */}
            </FilterGrid>
            <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                <CommonTable
                    data={calculatorData}
                    columns={tableColumns}
                    pagination={false}
                    scroll={{ x: 'overflow' }}
                    rowKey="id"
                />
                <Paginator
                    totalRecords={total}
                    pageLimit={pageSize}
                    pageNeighbours={2}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </Grid>
            <StyleModal
                key="id"
                title={`${activeCalculator.id ? 'Update': 'Add'} Calculator`}
                visible={showModal}
                onOk={handleSubmit}
                onCancel={handleModalCancel}
                footer={[
                    <Button type="primary" onClick={handleSubmit}>
                        Submit
                    </Button>,
                ]}
            >
                <CalculatorType>
                    <LabelInput>Select Calculator Type</LabelInput>
                    <Select
                        style={{ width: '100%' }}
                        onChange={handleCalculatorSelectChange}
                        value={currentCalculatorType}
                        placeholder={'Select Calculator Type'}
                    >
                        {renderCalculatorOptions()}
                    </Select>
                </CalculatorType>
                {renderCalculator()}
            </StyleModal>
        </Grid>
    );
};

export default Calculators;