import React from 'react';
import { withRouter } from 'react-router-dom';
import UserMandate from '../../component/UserMandate';
import { Wrapper } from './styles';

const UserMandatePage = () => (
    <Wrapper>
        <UserMandate />
    </Wrapper>
);

export default withRouter(UserMandatePage);
