const lightTheme = {
    black: '#000',
    dark: '#FAFAFA',
    background: '#f8f7f7',
    likedark: '#FAFAFA',
    light: '#FAFAFA',
    pinklogo: '#e9137c',
    validationColor: '#FF0000',
    text: '#000',
    contentBackground: '#dac7c7',
    nestedTableBackground: '#dac7c78c',
    nestedTableHead: '#000',
    nestedTableText: '#fff',
    postHeader: '#dac7c78c',
    lightGray: '#f8f7f7',
};

export default lightTheme;
