import styled from 'styled-components';
import { Input } from 'antd';

export const Wrapper = styled.div`
    width: 100%;
    display: flex;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const FormWrapper = styled.div`
    width: 30rem;
    max-width: 100%;
    padding: 1.25rem 2.5rem;
    margin: 1rem 0;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.2rem 2.25rem #222;
    background-color: #fefefe;
    @media (max-width: 767px) {
        max-width: 100%;
        width: 100%;
        padding: 1rem 1rem;
        margin: 1.5rem 0;
    }
`;

export const InputWrapper = styled.div`
    margin: 0.5rem;
`;

export const BoxWrapper = styled.div`
    align-items: center;
`;

export const StyleInput = styled(Input)`
    margin: auto;
    align-items: center;
`;

export const StyleLabel = styled.div`
    padding: 0.5rem 0;
    align-items: center;
    font-weight: 600;
    font-size: 1rem;
    @media (max-width: 767px) {
        max-width: 100%;
        width: 100%;
    }
`;

export const SubmitButton = styled.button`
    background: #f3b6d4;
    align-items: center;
    color: #2c3338;
    float: right;
    font-size: 1rem;
    font-weight: 600;
    margin: auto;
    margin-top: 2rem;
    padding: 0.25em 1em;
    outline: none;
    border: 1px solid #f3b6d4;
    border-radius: 3px;
    cursor: pointer;
    &:hover {
        background-color: #e9137c;
        color: white;
        border: 1px solid #e9137c;
    }
    @media (max-width: 767px) {
        max-width: 100%;
        width: 100%;
    }
`;

export const PasswordInput = styled(Input.Password)`
    margin: auto;
    align-items: center;
`;

export const HeadingText = styled.div`
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
    font-size: 2.3rem;
    padding: 1rem 0;
    text-align: center;
    @media (max-width: 767px) {
        margin: 0px;
        font-size: 1.5rem;
        padding: 0.7rem 0;
    }
`;

export const ErrorText = styled.p`
    color: #ff0000;
`;
