import styled from 'styled-components';
import { Avatar, Skeleton } from 'antd';

export const StyledAvatar = styled(Avatar)`
    margin-right: 1rem;
    width: 3rem;
    height: 3rem;
`;

export const Author = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const StlyedSkeleton = styled(Skeleton)`
    padding: 1rem 2rem;
`;

export const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    color: ${(props) => props.theme.text};
    padding: 1rem 2rem;
    background: ${(props) => props.theme.postHeader};
`;

export const AuhtorName = styled.span`
    font-size: 0.8rem;
    font-weight: 600;
    color: ${(props) => props.theme.text};
`;

export const CreatedAt = styled.div`
    font-size: 0.7rem;
    color: ${(p) => p.theme.text};
    border-bottom: 1px solid rgba(0, 0, 0, 0.54);
    border: 0;
`;
