/* eslint-disable no-tabs */
/* eslint-disable no-shadow */
import styled from 'styled-components';
import {
    Accordion as MuiExpansionPanel,
    AccordionSummary as MuiExpansionPanelSummary,
    AccordionDetails as MuiExpansionPanelDetails,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';

export const NavbarStyled = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: ${(props) => props.theme.black};
    height: 70px;
    max-height: 70px;
    box-shadow: 0px 0px 14px 0px black;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid ${(props) => props.theme.text};
`;

export const NavAction = styled.a`
    display: flex;
    padding: 10px 5px;
    cursor: pointer;
    img {
        pointer-events: none;
    }
`;
export const StyledMenuIcon = styled(MenuOutlinedIcon)`
    display: flex;
    cursor: pointer;
    img {
        pointer-events: none;
    }
    margin-right: 10px;
    color: ${(props) => props.theme.light};
`;

export const Overlay = styled.span`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
    opacity: ${(props) => (props.visible ? 1 : 0)};
    z-index: 2;
    transition: 0.2s linear opacity;
`;

export const NavMenu = styled.div`
    position: fixed;
    top: 0;
    left: ${(props) => (props.visible ? '0' : '-300px')};
    bottom: 0;
    width: 290px;
    background-color: ${(props) => props.theme.black};
    box-shadow: -2px 0px 12px -1px white;
    z-index: 2;
    transition: 0.2s linear left;
`;

export const MenuList = styled.div`
    height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

export const MenuListItem = styled.a`
    position: ${(props) => (props.submenu ? 'relative' : '')};
    font-size: 16px;
    line-height: 20px;
    padding: ${(props) => (props.submenu ? '15px 35px' : '20px')};
    display: block;
    color: ${(props) => props.theme.light};
    background-color: ${(props) => props.theme.black};
    text-decoration: none;
    width: 100%;
    &.active {
        background-color: ${(props) => props.theme.light};
        color: ${(props) => props.theme.black};
    }

    ${(props) => props.submenu
        && `
			&::before {
				content: '';
				display: inline-block;
				position: absolute;
				width: 4px;
				height: 4px;
				background-color: #fff;
				left: 25px;
				top: 50%;
				transform: translateY(-50%);
				border-radius: 50%;
			}
		`}
`;

export const MenuListItemDark = styled(MenuListItem)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: ${(props) => props.theme.black};
    color: ${(props) => props.theme.pinklogo};
    background-color: ${(props) => props.theme.black};
`;

export const MenuItemDarkLabel = styled.span`
    color: ${(props) => props.theme.light};
    font-size: 10px;
`;

// Customizing expansion panel, summary and details styles using withStyles
export const Accordion = withStyles({
    root: {
        padding: 0,
        margin: 0,
        '&.Mui-expanded': {
            padding: 0,
            margin: 0,
        },
    },
})(MuiExpansionPanel);

export const AccordionSummary = withStyles({
    root: {
        margin: 0,
        padding: 0,
        backgroundColor: '#e7e7e7',
    },
    content: {
        margin: 0,
        padding: 0,
        borderWidth: 0,
    },
    expanded: {
        padding: '0px !important',
        margin: '0px !important',
    },
})(MuiExpansionPanelSummary);

export const AccordionDetails = withStyles(() => ({
    root: {
        padding: 0,
        margin: 0,
        backgroundColor: '#e7e7e7',
        flexDirection: 'column',
    },
}))(MuiExpansionPanelDetails);

export const NavbarRightItems = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    flex: 1 1 100%;
    margin-left: 5px;
`;

export const UserInfoItem = styled.div`
    font-size: 14px;
    cursor: ${(props) => (props.showcursor ? 'pointer' : null)};
    white-space: nowrap;
    color: ${(props) => props.theme.light};
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 768px) {
        font-size: 12px;
        max-width: 200px;
    }
    @media (max-width: 425px) {
        max-width: 150px;
        display: none;
    }
`;
