/* eslint-disable max-len */
import {
    GetAvailablePinnedSlotApiURL,
    GetPinnedEntityDetailsApiURL,
    AddPinnedEntityApiURL,
    RemovePinnedEntityApiURL,
    UpdatePinnedEntityApiURL,
} from './apiConfig';
import axios from './instance';

export const GetAvailablePinnedSlotApi = (params) => axios.get(GetAvailablePinnedSlotApiURL, { params });

export const GetPinnedEntityDetailsApi = (params) => axios.get(GetPinnedEntityDetailsApiURL, { params });

export const AddPinnedEntityApi = (params) => axios.post(AddPinnedEntityApiURL, params);

export const RemovePinnedEntityApi = (params) => axios.delete(RemovePinnedEntityApiURL, {
        data: { ...params },
    });

export const UpdatePinnedEntityApi = (params) => axios.put(UpdatePinnedEntityApiURL, params);
