import styled from 'styled-components';

export const Wrapper = styled.div`
    margin: 0 auto;
    width: 60%;
    padding: 2rem 0;
`;
export const DetailWrapper = styled.div`
    width: 100%;
    margin: auto;
    border-radius: 0.3rem;
    padding-bottom: 1rem;
    background-color: ${(props) => props.theme.dark};
    border: 1px solid #e0e0e0;
`;
