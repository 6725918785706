/* eslint-disable no-shadow */
import React, { useState, useEffect, memo } from 'react';
import Grid from '@material-ui/core/Grid';
import swal from 'sweetalert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Select, Input } from 'antd';
import isEmpty from 'lodash/isEmpty';
import {
    size,
    trim,
} from 'lodash';
import { RNS3 } from 'react-native-aws3';

import { HexColorPicker } from 'react-colorful';
import CommonTable from '../Common/Table';
import { getRewardImageWithBucketCreds, s3Config } from '../../util/custom';
import MentionInput from '../MentionInput';

import {
    StyledButton,
    LabelInput,
    ErrorText,
    IconContainer,
    StyleModal,
    FiltersStyle,
    LeftGridItem,
    RightGridItem,
    InputStyle,
} from './styles';

import {
    getRewardListApi,
    createRewardApi,
    updateRewardApi,
    deleteRewardApi,
} from '../../api/rewardList';
import { GetUploadS3ImageApi } from "../../api/uploadImageS3";
import CommonLoader from '../Common/Loader';
import Paginator from '../Common/Paginator';
import Image from '../Image';

import {
    dateFormating,
    isValidImageUploadObject,
} from '../../util/helper';

import { PinEntityModal } from '../PinEntityModal';

export const CreateTypeList = {
    key: 'type',
    data: [
        {
            id: 'limited',
            title: 'limited',
            selected: true,
        },
        {
            id: 'unlimited',
            title: 'unlimited',
            selected: false,
        },
    ],
};

export const MaxLimitUnitList = {
    key: 'unit',
    data: [
        {
            id: 'day',
            title: 'day',
        },
        {
            id: 'week',
            title: 'week',
        },
        {
            id: 'month',
            title: 'month',
        },
        {
            id: 'year',
            title: 'year',
        },
    ],
};

const Reward = () => {
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(1);
    const { Option } = Select;
    const [maxLimit, setMaxLimit] = useState('');
    const [maxLimitUnit, setMaxLimitUnit] = useState('');

    const [filters, setFilters] = useState({
        page: currentPage,
        page_limit: pageSize,
        sort_by: 'updated_at',
        sort_type: 'desc',
        is_deleted: 'false',
    });

    const [rewardobject, setRewardobject] = useState({});
    const [imageFile, setImageFile] = useState('');
    const [errors, setErrors] = useState({});
    const [currentPinObject, setCurrentPinObject] = useState({});

    const getRewardList = (params) => {
        setLoading(true);
        getRewardListApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setData(apiData.results);
                        console.log('apiData.results', apiData.results);
                        setCurrentPage(apiData.page);
                        setPageSize(apiData.page_limit);
                        setTotal(apiData.total_count);
                        setFilters(params);
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error',
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const showUpdateRewardModal = (rewardObj) => {
        setRewardobject(rewardObj);
        setMaxLimit(rewardObj.max_limit.replace(/\D/g, ''));
        setMaxLimitUnit(rewardObj.max_limit.replace(/[^a-zA-Z]/g, ''));
        setImageFile(rewardObj.image);
        setShowUpdateModal(true);
    };

    const handleUpdateModalCancel = () => {
        setImageFile('');
        setMaxLimit('');
        setMaxLimitUnit('');
        setRewardobject({});
        setShowUpdateModal(false);
    };

    const createStatusList = (d) => {
        const statusArray = [];

        d.map((item) => {
            statusArray.push(
                <Option key={item.id} value={item.id}>
                    {item.title}
                </Option>,
            );
            return 0;
        });

        return statusArray;
    };

    const deleteReward = (id) => {
        swal({
            title: 'Are you sure you want to delete this reward ?',
            text: '',
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true,
        }).then((success) => {
            if (success) {
                setLoading(true);
                const params = {
                    id,
                };
                deleteRewardApi(params)
                    .then((res) => {
                        let success = false;
                        setLoading(false);
                        if (res.status === 200) {
                            if ((res.data || {}).success) {
                                success = true;
                                getRewardList(filters);
                                swal(
                                    'success!',
                                    'Successfully Deleted Reward',
                                    'success',
                                );
                            }
                        }
                        if (!success) {
                            setLoading(false);
                            swal(
                                'Oops!',
                                ((res.data || {}).data || {}).error_desc,
                                'error',
                            );
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        let errorMessage = 'Failed to delete reward';
                        if (error.response) {
                            if (error.response.status === 400) {
                                errorMessage = (error.response.data || {})
                                    .error_desc;
                            }
                        }
                        swal('Oops!', errorMessage, 'error');
                    });
            }
        });
    };

    const renderEdit = (data) => (
            <div>
                    <IconContainer>
                        <EditIcon
                            size="small"
                            onClick={() => showUpdateRewardModal(data)}
                        />
                    </IconContainer>
            </div>
        );

    const renderDelete = (data) => {
        const item = data;
        return (
            <div>
                {item.is_deleted === false ? (
                    <IconContainer>
                        <DeleteIcon
                            size="small"
                            onClick={() => deleteReward(item.id)}
                        />
                    </IconContainer>
                ) : null}
            </div>
        );
    };

    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 10,
        },
        {
            title: 'Display Name',
            dataIndex: 'display_name',
            key: 'display_name',
            width: 10,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 10,
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: 10,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 10,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            width: 10,
        },
        {
            title: 'Max Limit',
            dataIndex: 'max_limit',
            key: 'max_limit',
            width: 10,
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position',
            width: 10,
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value) => <p>{dateFormating(value)}</p>,
            width: 110,
            align: 'center',
        },
        {
            title: 'Updated At',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (value) => <p>{dateFormating(value)}</p>,
            width: 110,
            align: 'center',
        },
        {
            title: 'Background Color',
            dataIndex: 'background_color',
            key: 'background_color',
            width: 10,
            render: (item) => <p>{item || '-'}</p>,
        },
        {
            title: 'Edit',
            dataIndex: 'id',
            key: 'id',
            render: (text, item) => renderEdit(item),
            align: 'center',
            width: 10,
        },
        {
            title: 'Delete',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 10,
            render: (text, item) => renderDelete(item),
        },
    ];

    const updateReward = (media) => {
        const params = {
            id: rewardobject.id,
            display_name: trim(rewardobject.display_name),
            name: trim(rewardobject.name),
            title: trim(rewardobject.title),
            description: trim(rewardobject.description),
            type: rewardobject.type,
            max_limit: `${maxLimit} | ${maxLimitUnit}`,
            cta_text: trim(rewardobject.ctatext),
            redirection_type: trim(rewardobject.redirection_type),
            redirection_url: trim(rewardobject.redirection_url),
            background_color: rewardobject.background_color,
            image: media || {},
        };

        setLoading(true);
        updateRewardApi(params)
            .then((res) => {
                setLoading(false);
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        handleUpdateModalCancel();
                        getRewardList(filters);
                        swal('Yayy!', 'Reward Updated Sucessfully', 'success');
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error',
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to Update reward';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    useEffect(() => {
        filters.page = currentPage;
        filters.page_limit = pageSize;
        getRewardList(filters);
    }, [currentPage, pageSize]);

    const showCreateRewardModal = () => {
        setShowCreateModal(true);
    };

    const handleCreateRewardModalCancel = () => {
        setRewardobject({});
        setImageFile('');
        setShowCreateModal(false);
        setMaxLimitUnit('');
        setMaxLimit('');
   };

    const createReward = (media) => {
        const params = {
            display_name: trim(rewardobject.display_name),
            name: trim(rewardobject.name),
            title: trim(rewardobject.title),
            description: trim(rewardobject.description),
            type: rewardobject.type,
            max_limit: `${maxLimit} | ${maxLimitUnit}`,
            cta_text: trim(rewardobject.cta_text),
            redirection_type: trim(rewardobject.redirection_type),
            redirection_url: trim(rewardobject.redirection_url),
            background_color: rewardobject.background_color,
            image: media || {},
        };
        setLoading(true);
        createRewardApi(params)
            .then((res) => {
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        handleCreateRewardModalCancel();
                        getRewardList(filters);
                        swal('success!', 'Successfully Create Reward', 'success');
                    }
                } else {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error',
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Reward Creation Failed';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('OOPS!', errorMessage, 'error');
            });
    };

    const validateFields = (values) => {
        const validationErrors = {};

        if (size(trim(values.display_name)) < 1) {
            validationErrors.displayname = 'Required Display Name';
        }

        if (size(trim(values.name)) < 1) {
            validationErrors.name = 'Required Name';
        }

        if (size(trim(values.description)) < 1) {
            validationErrors.description = 'Required Description';
        }

        if ((trim(values.type) === 'limited') && size(trim(maxLimit)) < 1) {
            validationErrors.maxlimit = 'Max limit required for limited type of reward';
        }

        if ((trim(values.type) === 'limited') && size(trim(maxLimitUnit)) < 1) {
            validationErrors.maxLimitUnit = 'Max limit Unit required for limited type of reward';
        }

        if (size(trim(values.title)) < 1) {
            validationErrors.title = 'Required Title';
        }

        if (size(trim(values.background_color)) < 1) {
            validationErrors.background_color = 'Required Background Color';
        }

        if (size(trim(values.uploadfile)) < 1) {
            validationErrors.uploadfile = 'Required Image';
        }

        return validationErrors;
    };

    const handleCreateRewardSubmit = (event) => {
        event.preventDefault();
        const uploadfile = imageFile;
        const values = {
            ...rewardobject,
            uploadfile,
        };
        const validationErrors = validateFields(values);
        setErrors(validationErrors);

        if (isEmpty(validationErrors)) {
            if (isValidImageUploadObject(uploadfile)) {
                const createTimeNow = new Date();
                let keyPrefix = `icons/wheelIcons/${createTimeNow.toISOString()}`;
                keyPrefix += "-"+ uploadfile.name;
                let reader = new FileReader();
                reader.readAsDataURL(uploadfile);
                reader.onload = function () {
                const base64img = reader.result.split(',')[1];
                const params = {  
                    filename: keyPrefix,
                    encodedimg: base64img,
                }
                GetUploadS3ImageApi(params)
                    .then((res) => {
                    if (res.status === 200) {
                        if (res.data) {
                        console.log("success in file upload", res.data);
                        const imgS3Param = {
                            location: res.data.data.url,
                            key: keyPrefix,
                        }
                        const image = getRewardImageWithBucketCreds({
                            data: imgS3Param,
                        });
                        createReward(image);
                        }
                    }
                    else {
                        console.log("error in file upload", ((res.data || {}).data || {}).error_desc);
                    }
                    })
                    .catch((error) => {
                    let errorMessage = "error in file upload";
                    if (error.response) {
                        if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                        }
                    }
                    console.log("error in file upload", errorMessage);
                    });
                };
                reader.onerror = function (error) {
                console.log("Error in file reading: ", error);
                };
                // keyPrefix += '-';
                // const config = { ...s3Config, keyPrefix };
                // if (process.env.REACT_APP_AWS_ACL) {
                //     config.acl = process.env.REACT_APP_AWS_ACL;
                // }
                // RNS3.put(uploadfile, config)
                //     .progress((progress) => console.log('progress', progress))
                //     .then((response) => {
                //         if (response.status !== 201) {
                //             console.log('error in file upload', response);
                //         } else {
                //             const image = getRewardImageWithBucketCreds({
                //                 data: (response.body || {}).postResponse,
                //             });
                //             createReward(image);
                //         }
                //     });
            } else {
                createReward();
            }
        }
    };

    const handleFilterChange = (filters) => {
        getRewardList(filters);
    };

    const handlePinModalClose = () => {
        setCurrentPinObject({});
    };

    const handleUpdateSubmit = (event) => {
        event.preventDefault();
        const uploadfile = imageFile;
        const values = {
            ...rewardobject,
            uploadfile,
        };
        const validationUpdateErrors = validateFields(values);
        setErrors(
            isEmpty(validationUpdateErrors) ? {} : { ...validationUpdateErrors },
        );

        if (isEmpty(validationUpdateErrors)) {
            if (isValidImageUploadObject(uploadfile)) {
                const createTimeNow = new Date();
                let keyPrefix = `icons/wheelIcons/${createTimeNow.toISOString()}`;
                keyPrefix += "-"+ uploadfile.name;
                let reader = new FileReader();
                reader.readAsDataURL(uploadfile);
                reader.onload = function () {
                const base64img = reader.result.split(',')[1];
                const params = {  
                    filename: keyPrefix,
                    encodedimg: base64img,
                }
                GetUploadS3ImageApi(params)
                    .then((res) => {
                    if (res.status === 200) {
                        if (res.data) {
                        console.log("success in file upload", res.data);
                        const imgS3Param = {
                            location: res.data.data.url,
                            key: keyPrefix,
                        }
                        const image = getRewardImageWithBucketCreds({
                            data: imgS3Param,
                        });
                        updateReward(image);
                        }
                    }
                    else {
                        console.log("error in file upload", ((res.data || {}).data || {}).error_desc);
                    }
                    })
                    .catch((error) => {
                    let errorMessage = "error in file upload";
                    if (error.response) {
                        if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                        }
                    }
                    console.log("error in file upload", errorMessage);
                    });
                };
                reader.onerror = function (error) {
                console.log("Error in file reading: ", error);
                };
                // keyPrefix += '-';
                // const config = { ...s3Config, keyPrefix };
                // if (process.env.REACT_APP_AWS_ACL) {
                //     config.acl = process.env.REACT_APP_AWS_ACL;
                // }

                // RNS3.put(uploadfile, config)
                //     .progress((progress) => console.log('progress', progress))
                //     .then((response) => {
                //         if (response.status !== 201) {
                //             console.log('error in file upload', response);
                //         } else {
                //             const image = getRewardImageWithBucketCreds({
                //                 data: (response.body || {}).postResponse,
                //             });
                //             updateReward(image);
                //         }
                //     });
            } else {
                updateReward(uploadfile);
            }
        }
    };

    const onFileChange = (event) => {
        if (!event) {
            setImageFile('');
            return;
        }
        const file = event.target?.files[0];
        setImageFile(file);
    };

    const handleRewardbject = (val, key) => {
        rewardobject[key] = val;
        setRewardobject({ ...rewardobject });
    };

    return (
        <Grid container spacing={2}>
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <StyledButton primary onClick={() => showCreateRewardModal()}>
                    Add Reward
                </StyledButton>
            </Grid>
            <Grid
                container
                spacing={2}
                style={FiltersStyle}
            >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <CommonTable
                        rowKey="id"
                        data={data}
                        columns={tableColumns}
                        pagination={false}
                        scroll={{ x: 'overflow' }}
                    />
                    <Paginator
                        totalRecords={total}
                        pageLimit={pageSize}
                        pageNeighbours={2}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </Grid>
            </Grid>
            <Grid>
                {showCreateModal && (
                    <StyleModal
                        title="Add Reward"
                        visible={showCreateModal}
                        onOk={(e) => handleCreateRewardSubmit(e)}
                        onCancel={() => handleCreateRewardModalCancel()}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                onClick={(e) => handleCreateRewardSubmit(e)}
                                loading={loading}
                            >
                                Submit
                            </Button>,
                        ]}
                    >
                        <LabelInput>Display Name</LabelInput>
                        <Input
                                placeholder="Display Name"
                                name="display_name"
                                value={rewardobject.display_name}
                                onChange={(e) => handleRewardbject(e.target.value, 'display_name')}
                        />
                        {errors.displayname && (
                                <ErrorText>{errors.displayname}</ErrorText>
                        )}

                        <LabelInput>Name</LabelInput>
                        <Input
                                placeholder="Name"
                                name="name"
                                value={rewardobject.name}
                                maxLength={30}
                                onChange={(e) => handleRewardbject(e.target.value, 'name')}
                        />
                        {errors.name && (
                                <ErrorText>{errors.name}</ErrorText>
                        )}

                        <LabelInput>Title</LabelInput>
                        <Input
                                placeholder="Title"
                                name="title"
                                value={rewardobject.title}
                                onChange={(e) => handleRewardbject(e.target.value, 'title')}
                        />
                        {errors.title && (
                                <ErrorText>{errors.title}</ErrorText>
                        )}
                        <LabelInput>Description</LabelInput>
                        <MentionInput
                            value={rewardobject.description}
                            placeholder="Description"
                            onChange={(e) => handleRewardbject(e.target.value, 'description')}
                        />
                        {errors.description && (
                            <ErrorText>{errors.description}</ErrorText>
                        )}
                        <br />

                        <LabelInput>Type</LabelInput>
                        <Select
                            value={rewardobject.type}
                            style={{ width: '100%' }}
                            onChange={(e) => handleRewardbject(e, 'type')}
                        >
                            {CreateTypeList
                                && createStatusList(CreateTypeList.data)}
                        </Select>
                        {errors.type && (
                                <ErrorText>{errors.type}</ErrorText>
                        )}
                        <Grid container>
                        <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                        <LabelInput>Max Limit</LabelInput>
                        <Input
                                type="number"
                                placeholder="Max Limit"
                                name="max_limit"
                                value={maxLimit}
                                onChange={(e) => setMaxLimit(e.target.value)}
                        />
                        {errors.maxlimit && (
                                <ErrorText>{errors.maxlimit}</ErrorText>
                        )}
                        </LeftGridItem>
                        <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                        <LabelInput>Max Limit Unit</LabelInput>
                            <Select
                                value={maxLimitUnit}
                                style={{ width: '100%' }}
                                onChange={(e) => setMaxLimitUnit(e)}
                            >
                            {MaxLimitUnitList
                                && createStatusList(MaxLimitUnitList.data)}
                            </Select>
                            {errors.maxLimitUnit && (
                                <ErrorText>{errors.maxLimitUnit}</ErrorText>
                            )}
                        </RightGridItem>
                        </Grid>
                        <Grid container>
                        <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                            <LabelInput>Background Color</LabelInput>
                            <Input
                                style={InputStyle}
                                disabled
                                name="background_color"
                                value={rewardobject.background_color}
                            />
                            {errors.background_color && (
                                <ErrorText>{errors.background_color}</ErrorText>
                        )}
                        </LeftGridItem>
                        <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                        <LabelInput>Pick Background Color Here</LabelInput>
                        <HexColorPicker
                            color={rewardobject.background_color}
                            onChange={(e) => handleRewardbject(e, 'background_color')}
                        />
                        </RightGridItem>
                        </Grid>
                        <LabelInput>
                            Upload Images
                        </LabelInput>
                        <Image value={imageFile} onChange={onFileChange} />
                        {errors.uploadfile && (
                            <ErrorText>{errors.uploadfile}</ErrorText>
                        )}
                    </StyleModal>
                )}
            </Grid>
            {/* end */}
            <Grid>
                {showUpdateModal && (
                    <StyleModal
                        title="Edit Reward"
                        visible={showUpdateModal}
                        onOk={(e) => handleUpdateSubmit(e)}
                        onCancel={handleUpdateModalCancel}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                onClick={(e) => handleUpdateSubmit(e)}
                                loading={loading}
                            >
                                Update
                            </Button>,
                        ]}
                    >
                        <LabelInput>Display Name</LabelInput>
                        <Input
                                placeholder="Display Name"
                                name="display_name"
                                value={rewardobject.display_name}
                                onChange={(e) => handleRewardbject(e.target.value, 'display_name')}
                        />
                        {errors.displayname && (
                                <ErrorText>{errors.displayname}</ErrorText>
                        )}

                        <LabelInput>Name</LabelInput>
                        <Input
                                placeholder="Name"
                                name="name"
                                value={rewardobject.name}
                                maxLength={30}
                                onChange={(e) => handleRewardbject(e.target.value, 'name')}
                        />
                        {errors.name && (
                                <ErrorText>{errors.name}</ErrorText>
                        )}

                        <LabelInput>Title</LabelInput>
                        <Input
                                placeholder="Title"
                                name="title"
                                value={rewardobject.title}
                                onChange={(e) => handleRewardbject(e.target.value, 'title')}
                        />
                        {errors.title && (
                                <ErrorText>{errors.title}</ErrorText>
                        )}
                        <LabelInput>Description</LabelInput>
                        <MentionInput
                            value={rewardobject.description}
                            placeholder="Description"
                            onChange={(e) => handleRewardbject(e.target.value, 'description')}
                        />
                        {errors.description && (
                            <ErrorText>{errors.description}</ErrorText>
                        )}
                        <br />

                        <LabelInput>Type</LabelInput>
                        <Select
                            value={rewardobject.type}
                            style={{ width: '100%' }}
                            onChange={(e) => handleRewardbject(e, 'type')}
                        >
                            {CreateTypeList
                                && createStatusList(CreateTypeList.data)}
                        </Select>
                        {errors.type && (
                                <ErrorText>{errors.type}</ErrorText>
                        )}
                        <Grid container>
                        <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                        <LabelInput>Max Limit</LabelInput>
                        <Input
                                type="number"
                                placeholder="Max Limit"
                                name="max_limit"
                                value={maxLimit}
                                onChange={(e) => setMaxLimit(e.target.value)}
                        />
                        {errors.maxlimit && (
                                <ErrorText>{errors.maxlimit}</ErrorText>
                        )}
                        </LeftGridItem>
                        <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                        <LabelInput>Max Limit Unit</LabelInput>
                            <Select
                                value={maxLimitUnit}
                                style={{ width: '100%' }}
                                onChange={(e) => setMaxLimitUnit(e)}
                            >
                            {MaxLimitUnitList
                                && createStatusList(MaxLimitUnitList.data)}
                            </Select>
                            {errors.maxLimitUnit && (
                                <ErrorText>{errors.maxLimitUnit}</ErrorText>
                            )}
                        </RightGridItem>
                        </Grid>

                        <Grid container>
                            <LeftGridItem item lg={6} md={6} sm={12} xs={12}>
                                <LabelInput>Background Color</LabelInput>
                                <Input
                                    style={InputStyle}
                                    disabled
                                    name="background_color"
                                    value={rewardobject.background_color}
                                />
                                {errors.background_color && (
                                    <ErrorText>{errors.background_color}</ErrorText>
                            )}
                            </LeftGridItem>
                            <RightGridItem item lg={6} md={6} sm={12} xs={12}>
                                <LabelInput>Pick Background Color Here</LabelInput>
                                <HexColorPicker
                                    value={rewardobject.background_color}
                                    onChange={(e) => handleRewardbject(e, 'background_color')}
                                />
                            </RightGridItem>
                        </Grid>

                        <LabelInput>
                            Upload Images
                        </LabelInput>
                        <Image value={imageFile} onChange={onFileChange} />
                        {errors.uploadfile && (
                            <ErrorText>{errors.uploadfile}</ErrorText>
                        )}

                    </StyleModal>
                )}
            </Grid>
            <Grid>
                {size(currentPinObject) > 0 && (
                    <PinEntityModal
                        entityObj={currentPinObject}
                        entityType="reward"
                        onClose={handlePinModalClose}
                        filters={filters}
                        fetchData={(filters) => handleFilterChange(filters)}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default memo(Reward);
