import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Tag, Input, Upload } from 'antd';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { DeleteFilled, SyncOutlined } from '@ant-design/icons';

const { TextArea } = Input;

export const FilterGrid = styled(Grid)`
    background-color: ${(props) => props.theme.background};
    margin-top: 0.5rem;
    margin-bottom: 4.5rem;

    @media (max-width: 767px) {
        margin-bottom: 1rem;
    }
`;

export const FilterTitleWrapper = styled.div`
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center !important;
    margin: 1rem 0 2rem 0;
    font-family: 'Titillium Web';
    border-bottom: 1px dashed ${(props) => props.theme.text};
    padding-bottom: 2rem;
    color: ${(props) => props.theme.text};
    @media (max-width: 1024px) {
        font-size: 1.4rem;
    }
`;

export const StyledTag = styled(Tag)`
    color: ${(p) => p.theme.text};
    background: ${(p) => p.theme.background};
`;

export const StyledButton = styled.button`
    background: ${(props) => props.theme.pinklogo};
    color: ${(props) => props.theme.light};
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    outline: none;
    border: 2px solid ${(props) => props.theme.pinklogo};
    border-radius: 3px;
    float: right;
    cursor: pointer;
`;

export const StyledInput = styled(TextArea)`
    margin-bottom: 0.5rem;
`;

export const LabelInput = styled.div`
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 0.8rem;
    margin-top: 0.5rem;
    color: ${(props) => props.theme.text};
`;

export const StyleUpload = styled(Upload)`
    margin-top: 0.5rem;
`;

export const ErrorText = styled.p`
    color: #ff0000;
    margin-bottom: 0;
`;

export const YesIcon = styled(CheckCircleRoundedIcon)`
    color: green;
`;

export const NoIcon = styled(CancelRoundedIcon)`
    color: red;
`;

export const IconContainer = styled.div`
    cursor: pointer;
`;

export const IamgeInput = styled.input`
    color: ${(props) => props.theme.text};
    display: inline-block;
`;

export const DeleteFilledStyle = styled(DeleteFilled)`
    color: ${(props) => props.theme.text};
    display: inline-block;
    cursor: pointer;
`;

export const SyncOutlinedStyle = styled(SyncOutlined)`
    color: ${(props) => props.theme.text};
    display: inline-block;
    cursor: pointer;
`;

export const Parent = styled.div`
    display: flex;
`;

export const StyleImage = styled.img`
    height: '15rem';
    background-color: 'red';
    cursor: 'pointer';
`;

export const ImageLabel = styled.label``;
