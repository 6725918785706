import {
    AUTHENTICATE,
    AUTHENTICATE_SUCCESS,
    AUTHENTICATE_FAILED,
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_FAILED,
    SET_PROFILE,
    CLEAR_PROFILE,
} from './types';

export function loginAuth(username, password) {
    return {
        type: AUTHENTICATE,
        username,
        password,
    };
}

export function loginSuccess(payload) {
    return {
        type: AUTHENTICATE_SUCCESS,
        payload,
    };
}

export function loginAuthFailed(error) {
    return {
        type: AUTHENTICATE_FAILED,
        error,
    };
}
export function logout() {
    return {
        type: LOGOUT,
    };
}
export function logoutSuccess(payload) {
    return {
        type: LOGOUT_SUCCESS,
        payload,
    };
}

export function logoutFailed(error) {
    return {
        type: LOGOUT_FAILED,
        error,
    };
}

export const setProfile = (payload) => ({
    type: SET_PROFILE,
    payload,
});

export const clearProfile = () => ({
    type: CLEAR_PROFILE,
});
