/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
import React from 'react';
import Error from '../Common/Error';
import {
    GENERAL_ERROR_TITLE_MSG,
    GENERAL_ERROR_DESCRIPTION_MSG,
    GENERAL_ERROR_FOOTER_MSG,
} from '../../constant/message';
import PublicHeader from '../PublicHeader';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error,
            errorInfo,
        });
        // You can also log error messages to an error reporting service here
    }

    render() {
        // eslint-disable-next-line react/prop-types
        const { children } = this.props;
        const { hasError } = this.state;

        if (hasError) {
            // Error path
            return (
                <>
                    <PublicHeader />
                    <Error
                        title={GENERAL_ERROR_TITLE_MSG}
                        description={GENERAL_ERROR_DESCRIPTION_MSG}
                        footer={GENERAL_ERROR_FOOTER_MSG}
                    />
                </>
            );
        }
        // Normally, just render children
        return children;
    }
}

export default ErrorBoundary;
