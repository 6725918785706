/* eslint-disable no-shadow */
/* eslint-disable comma-dangle */

import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Grid from '@material-ui/core/Grid';
// import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { Modal, Button } from 'antd';
import styled from 'styled-components';
import {
    // truncate,
    size,
    trim,
} from 'lodash';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {
    GetAuditEventListRequest,
    CreateAuditEventRequest,
} from '../../api/auditEvent';
import CommonTable from '../Common/Table';
import CommonDropdown from '../Common/Dropdown';
import Paginator from '../Common/Paginator';

import {
    FilterTitleWrapper,
    StyledButton,
    LabelInput,
    SpanValidation,
    StyledInput,
    FilterGrid,
    IconWrapper,
    ContentStyle,
} from './styles';
import { buID, subBuID } from '../../constant';
import { dateFormating } from '../../util/helper';

const StyleModal = styled(Modal)`
    .ant-modal-header {
        background: ${(props) => props.theme.dark};
        color: ${(props) => props.theme.dark};
    }

    .ant-modal-body {
        background: ${(props) => props.theme.dark};
    }

    .ant-modal-title {
        color: ${(props) => props.theme.text};
    }
    .ant-modal-footer {
        background: ${(props) => props.theme.dark};
    }
    .ant-modal-close-icon {
        color: ${(props) => props.theme.text};
    }

    .ant-modal .sc-AzgDb .jcGGxa {
        padding: 0px;
    }
`;

const AuditEvent = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(1);
    const [eventName, setEventName] = useState('');
    const [invalidEventName, setInvalidEventName] = useState(false);
    const [maxLengthEventName, setMaxLengthEventName] = useState(false);
    const [filters, setFilters] = useState({
        page: currentPage,
        page_limit: pageSize,
        sort_by: 'created_at',
        sort_type: 'desc',
        buid: buID,
        sub_buid: subBuID,
        is_deleted: false,
    });
    const [isModalVisible, setIsModalVisible] = useState(false);

    const renderDelete = (val) => (
        <IconWrapper>
            {val ? (
                <CheckCircleOutlineIcon size="small" color="primary" />
            ) : (
                <CancelOutlinedIcon size="small" color="red" />
            )}
        </IconWrapper>
    );

    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'inc_id',
            key: 'inc_id',
            align: 'center',
            width: 10,
        },
        {
            title: 'Event Name',
            dataIndex: 'event_name',
            key: 'event_name',
            width: 20,
            render: (content) => <ContentStyle>{content}</ContentStyle>,
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            align: 'center',
            width: 20,
            render: (val) => <ContentStyle>{dateFormating(val)}</ContentStyle>,
        },
        {
            title: 'Updated At',
            dataIndex: 'updated_at',
            key: 'updated_at',
            align: 'center',
            width: 20,
            render: (val) => <p>{dateFormating(val)}</p>,
        },
        {
            title: 'Is Deleted',
            dataIndex: 'is_deleted',
            key: 'is_deleted',
            align: 'center',
            width: 20,
            render: (val) => renderDelete(val),
        },
    ];

    const fetchData = (queryParams) => {
        setLoading(true);
        GetAuditEventListRequest(queryParams)
            .then((res) => {
                let success = false;
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const dat = (res.data || {}).data || {};
                        setData(dat.results);
                        setCurrentPage(dat.page);
                        setPageSize(dat.page_limit);
                        setTotal(dat.total_count);
                        setFilters(queryParams);
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    errorMessage = (error.response.data || {}).error_desc;
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    useEffect(() => {
        filters.page = currentPage;
        filters.page_limit = pageSize;
        fetchData(filters);
        // eslint-disable-next-line
    }, [currentPage, pageSize]);

    const handleFilterChange = (filters) => {
        fetchData(filters);
    };

    const IsDeleted = {
        key: 'is_deleted',
        data: [
            {
                id: 'true',
                title: 'True',
                selected: false,
            },
            {
                id: 'false',
                title: 'False',
                selected: true,
            },
        ],
    };

    const sortTypeList = {
        key: 'sort_type',
        data: [
            {
                id: 'asc',
                title: 'Asc',
                selected: false,
            },
            {
                id: 'desc',
                title: 'Desc',
                selected: true,
            },
        ],
    };

    const sortByList = {
        key: 'sort_by',
        data: [
            {
                id: 'event_name',
                title: 'event_name',
                selected: false,
            },
            {
                id: 'created_at',
                title: 'created_at',
                selected: true,
            },
            {
                id: 'updated_at',
                title: 'updated_at',
                selected: false,
            },
            {
                id: 'inc_id',
                title: 'inc_id',
                selected: false,
            },
            {
                id: 'is_deleted',
                title: 'is_deleted',
                selected: false,
            },
        ],
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setEventName('');
        setInvalidEventName(false);
    };

    const createAuditEvent = () => {
        setLoading(true);
        const params = {
            event_name: eventName,
            buid: buID,
            sub_buid: subBuID,
        };

        CreateAuditEventRequest(params)
            .then((res) => {
                let success = false;
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        setIsModalVisible(false);
                        fetchData(filters);
                        setEventName('');
                        swal(
                            'success!',
                            'Audit Event successfully Added',
                            'success'
                        );
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Audit Event Creation Failed';
                if (error.response) {
                    errorMessage = (error.response.data || {}).error_desc;
                }
                swal('OOPS!', errorMessage, 'error');
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (size(trim(eventName)) === 0) {
            setInvalidEventName(true);
            setMaxLengthEventName(false);
        } else if (size(trim(eventName)) > 256) {
            setMaxLengthEventName(true);
            setInvalidEventName(false);
        } else {
            setMaxLengthEventName(false);
            setInvalidEventName(false);
            createAuditEvent();
        }
    };

    const handleInputChange = (event) => {
        event.persist();
        setEventName(event.target.value);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <StyledButton primary onClick={showModal}>
                        Create Audit Event
                    </StyledButton>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={2}
                style={{
                    paddingRight: '15px',
                    paddingLeft: '15px',
                }}
            >
                <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
                    <FilterTitleWrapper>Filters</FilterTitleWrapper>
                    <CommonDropdown
                        list={sortByList}
                        title="Sort By"
                        searchable={false}
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={sortTypeList}
                        title="Sort Type"
                        searchable={false}
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={IsDeleted}
                        title="Is Deleted"
                        searchable={false}
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                </FilterGrid>

                <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                    <CommonTable
                        data={data}
                        columns={tableColumns}
                        pagination={false}
                        loading={loading}
                        scroll={{ x: 'overflow' }}
                        rowKey="id"
                    />
                    <Paginator
                        totalRecords={total}
                        pageLimit={pageSize}
                        pageNeighbours={2}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </Grid>
            </Grid>
            <Grid>
                {isModalVisible && (
                    <StyleModal
                        title="Create Audit Event"
                        visible={isModalVisible}
                        onOk={(e) => handleSubmit(e)}
                        onCancel={() => handleCancel()}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                onClick={(e) => handleSubmit(e)}
                                loading={loading}
                            >
                                Submit
                            </Button>,
                        ]}
                    >
                        <LabelInput>Event Name</LabelInput>
                        <StyledInput
                            id="event_name"
                            placeholder="Event Name"
                            name="event_name"
                            value={eventName}
                            onChange={(e) => handleInputChange(e)}
                        />
                        {invalidEventName && (
                            <SpanValidation id="event-name-error">
                                *Please Enter a Valid Event Name
                            </SpanValidation>
                        )}
                        {maxLengthEventName && (
                            <SpanValidation id="event-name-error">
                                *Maximum length for this field is 255 characters
                            </SpanValidation>
                        )}
                    </StyleModal>
                )}
            </Grid>
        </>
    );
};

export default withRouter(AuditEvent);
