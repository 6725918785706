import React from 'react';
import { withRouter } from 'react-router-dom';
import UserHolding from '../../component/UserHolding';
import { Wrapper } from './styles';

const UserHodingPage = () => (
    <Wrapper>
        <UserHolding />
    </Wrapper>
);

export default withRouter(UserHodingPage);
