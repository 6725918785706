/* eslint-disable */
/* eslint-disable import/prefer-default-export */
import {
    GetHoldingUserListApiURL,
    GetHoldingUserDetialApiURL,
    GetRefreshUserHoldingsApiURL,
} from './apiConfig';
import axios from './instance';

export const GetHoldingUserListApi = (params) =>
    axios.get(GetHoldingUserListApiURL, { params });

export const GetHoldingUserDetialApi = (params) =>
    axios.get(GetHoldingUserDetialApiURL, { params });

export const GetRefreshUserHoldingsApi = (params) =>
    axios.get(GetRefreshUserHoldingsApiURL, { params });
