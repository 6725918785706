import React from 'react';
import { lxmeLogo } from '../../constant/imageVariables';
// import ThemeToggle from "../Common/ThemeToggle";
import {
    NavAction, HeaderStyled, StyledImg, NavbarRightItems,
    } from './styles';

const PublicHeader = () => (
    <>
        <HeaderStyled>
            <NavAction title="LXME" to="/">
                <StyledImg src={lxmeLogo} alt="LXME logo" />
            </NavAction>
            <NavbarRightItems>{/* <ThemeToggle /> */}</NavbarRightItems>
        </HeaderStyled>
    </>
);

export default PublicHeader;
