import React from 'react';
import { withRouter } from 'react-router-dom';
import UserHoldingDetail from '../../component/UserHoldingDetail';
import { Wrapper } from './styles';

const UserHoldingDetailPage = (props) => (
    <Wrapper>
        <UserHoldingDetail pageProps={props} />
    </Wrapper>
);

export default withRouter(UserHoldingDetailPage);
