/* eslint-disable */
/* eslint-disable no-param-reassign*/
/* eslint-disable comma-dangle */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import swal from 'sweetalert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { NavLink } from 'react-router-dom';
import { Modal, Button, Select } from 'antd';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CommonTable from '../Common/Table';
import {
    FilterGrid,
    FilterTitleWrapper,
    StyledTag,
    StyledButton,
    StyledInput,
    LabelInput,
    ErrorText,
    IconContainer,
    OptionInput,
} from './styles';
import {
    GetPollListApi,
    GetPollFiltersApi,
    DeletePollApi,
    CreatePollApi,
    UpdatePollApi,
} from '../../api/pollList';
import CommonLoader from '../Common/Loader';
import CommonDropdown from '../Common/Dropdown';
import Paginator from '../Common/Paginator';

import {
    formattedValue,
    snakeToUpperCase,
    dateFormating,
    getPayloadTime,
    minuteToDays,
    fetchObjectArrFromKeyArr,
    dateFormattingServerUTC,
} from '../../util/helper';
import { buID } from '../../constant';
import { GetAuthorListApi } from '../../api/authorList';
import CommonDatePicker from '../Common/DatePicker';
import { forEach, compact, trim, filter, size, map } from 'lodash';
import IconTooltip from '../Common/IconTooltip';
import { generatePostDeepLink } from '../../util/deeplink';
import CopyToClipboard from '../Common/CopyToClipboard';
import MentionInput from '../MentionInput';
import {
    PostStatusList,
    UpdateStatusList,
    CreateStatusList,
} from '../../util/post';
import { GetTagListApi } from '../../api/postList';
import { PushpinFilled } from '@ant-design/icons';
import { PinEntityModal } from '../PinEntityModal';
import MentionText from '../MentionText';
import { getTaggedUsers, getTruncatedContent } from '../../util/mention';
import ScheduleField from '../ScheduleField';

const StyleModal = styled(Modal)`
    .ant-modal-header {
        background: ${(props) => props.theme.dark};
        color: ${(props) => props.theme.dark};
    }

    .ant-modal-body {
        background: ${(props) => props.theme.dark};
    }

    .ant-modal-title {
        color: ${(props) => props.theme.text};
    }
    .ant-modal-footer {
        background: ${(props) => props.theme.dark};
    }
    .ant-modal-close-icon {
        color: ${(props) => props.theme.text};
    }

    .ant-modal .sc-AzgDb .jcGGxa {
        padding: 0px;
    }
`;

const Poll = () => {
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [data, setData] = useState([]);
    const [authorList, setAuthorList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(1);
    const { Option } = Select;
    const [filters, setFilters] = useState({
        page: currentPage,
        page_limit: pageSize,
        sort_by: 'updated_at',
        sort_type: 'desc',
        is_deleted: 'false',
    });
    const [createContent, setCreateContent] = useState('');
    const [option1, setOption1] = useState('');
    const [option2, setOption2] = useState('');
    const [option3, setOption3] = useState('');
    const [option4, setOption4] = useState('');

    const [currentStatusID, setCurrentStatusID] = useState('draft');
    const [currentAuthorID, setCurrentAuthorID] = useState('');
    const [errors, setErrors] = useState({});
    const [updateErrors, setUpdateErrors] = useState({});
    const [updateContent, setUpdateContent] = useState('');
    const [updateOption1, setUpdateOption1] = useState('');
    const [updateOption2, setUpdateOption2] = useState('');
    const [updateOption3, setUpdateOption3] = useState('');
    const [updateOption4, setUpdateOption4] = useState('');
    const [showUpdateOption3, setShowUpdateOption3] = useState(false);
    const [showUpdateOption4, setShowUpdateOption4] = useState(false);
    const [currentUpdatePoll, setCurrentUpdatePoll] = useState({});
    const [tagList, setTagList] = useState([]);
    const [createTagList, setCreateTagList] = useState([]);
    const [updateTagList, setUpdateTagList] = useState([]);
    const [currentPinObject, setCurrentPinObject] = useState({});
    const [publishDate, setPublishDate] = useState(null);
    const [updatePublishDate, setUpdatePublishDate] = useState(null);
    const [PollFilters, setPollFilters] = useState({
        author_id: {
            key: 'author_id',
            data: [],
        },
        id: {
            key: 'id',
            data: [],
        },
    });

    const sortTypeList = {
        key: 'sort_type',
        data: [
            {
                id: 'asc',
                title: 'Asc',
                selected: false,
            },
            {
                id: 'desc',
                title: 'Desc',
                selected: true,
            },
        ],
    };

    const sortByList = {
        key: 'sort_by',
        data: [
            {
                id: 'id',
                title: 'id',
                selected: false,
            },
            {
                id: 'created_at',
                title: 'created_at',
                selected: false,
            },
            {
                id: 'updated_at',
                title: 'updated_at',
                selected: true,
            },
            {
                id: 'content',
                title: 'content',
                selected: false,
            },
            {
                id: 'status',
                title: 'status',
                selected: false,
            },
        ],
    };

    const createTags = (tags = []) => {
        const tagArray = [];

        if (tags != null) {
            tags.map((tag) => {
                tagArray.push(<StyledTag key={tag.id}>{tag.title}</StyledTag>);
                return 0;
            });
        }
        return tagArray;
    };

    const createPollOptionsTags = (poll) => {
        const optionsArray = [];
        const optionList = poll.options || [];

        if (optionList != null) {
            optionList.map((option) => {
                optionsArray.push(
                    <StyledTag key={option.id}>
                        {snakeToUpperCase(option.value)}
                    </StyledTag>
                );
                return 0;
            });
        }
        return optionsArray;
    };

    const getPollList = (params) => {
        setLoading(true);
        GetPollListApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setData(apiData.results);
                        setCurrentPage(apiData.page);
                        setPageSize(apiData.page_limit);
                        setTotal(apiData.total_count);
                        setFilters(params);
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const showUpdatePollModal = (pollObj) => {
        setCurrentUpdatePoll(pollObj);
        setUpdateContent(pollObj.content);
        setCurrentStatusID(pollObj.status);
        const pollObjOptions = (pollObj.poll || {}).options || [];
        setUpdateOption1(pollObjOptions[0].value);
        setUpdateOption2(pollObjOptions[1].value);
        if (pollObjOptions[2] && pollObjOptions[2].value) {
            setUpdateOption3(pollObjOptions[2].value);
            setShowUpdateOption3(true);
        }
        if (pollObjOptions[3] && pollObjOptions[3].value) {
            setShowUpdateOption4(true);
            setUpdateOption4(pollObjOptions[3].value);
        }
        setUpdateTagList(
            ((pollObj.tags || {}).level_one_tags || []).map((item) => item.id)
        );
        setShowUpdateModal(true);
        setUpdatePublishDate(pollObj.schedule_publish_at || null);
    };

    const handleUpdateModalCancel = () => {
        setShowUpdateModal(false);
        setCurrentUpdatePoll({});
        setUpdateOption1('');
        setUpdateOption2('');
        setUpdateOption3('');
        setUpdateOption4('');
        setShowUpdateOption3(false);
        setShowUpdateOption4(false);
        setErrors({});
        setCurrentAuthorID('');
        setUpdateTagList([]);
        setUpdatePublishDate(null);
    };

    const showPinPostModal = (postObj) => {
        setCurrentPinObject(postObj);
    };

    const deletePoll = (id) => {
        swal({
            title: 'Are you sure you want to delete this poll ?',
            text: '',
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true,
        }).then((success) => {
            if (success) {
                setLoading(true);
                const params = {
                    id,
                };
                DeletePollApi(params)
                    .then((res) => {
                        let success = false;
                        if (res.status === 200) {
                            if ((res.data || {}).success) {
                                success = true;
                                setLoading(false);
                                getPollList(filters);
                                swal(
                                    'success!',
                                    'Successfully Deleted Poll',
                                    'success'
                                );
                            }
                        }
                        if (!success) {
                            setLoading(false);
                            swal(
                                'Oops!',
                                ((res.data || {}).data || {}).error_desc,
                                'error'
                            );
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        let errorMessage = 'Failed to delete poll';
                        if (error.response) {
                            if (error.response.status === 400) {
                                errorMessage = (error.response.data || {})
                                    .error_desc;
                            }
                        }
                        swal('Oops!', errorMessage, 'error');
                    });
            }
        });
    };

    const renderEdit = (data) => {
        const item = data;
        let showColumn = false;
        if (item.source === 'workstation' || item.source === 'client_app') {
            showColumn = true;
        }
        return (
            <div>
                {showColumn && item.is_deleted === false ? (
                    <IconContainer>
                        <EditIcon onClick={() => showUpdatePollModal(item)} />
                    </IconContainer>
                ) : null}
            </div>
        );
    };

    const renderDelete = (data) => {
        const item = data;
        return (
            <div>
                {item.is_deleted === false ? (
                    <IconContainer>
                        <DeleteIcon onClick={() => deletePoll(item.poll.id)} />
                    </IconContainer>
                ) : null}
            </div>
        );
    };

    const renderIsPinned = (data) => (
        <div>
            {data.is_deleted === false && data.status === 'published' ? (
                <IconContainer>
                    <PushpinFilled
                        style={{ fontSize: '22px' }}
                        onClick={() => showPinPostModal(data)}
                    />
                </IconContainer>
            ) : null}
        </div>
    );

    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 10,
            render: (id) => <>{id}</>,
        },
        {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
            textWrap: 'word-break',
            width: 180,
            render: (content) => (
                <MentionText
                    text={getTruncatedContent(content)}
                />
            ),
        },
        {
            title: 'Options',
            dataIndex: 'poll',
            key: 'poll',
            render: (poll) => <>{createPollOptionsTags(poll)}</>,
            align: 'center',
            width: 180,
        },
        {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            render: (author) => <div>{formattedValue(author.name)}</div>,
            width: 20,
            align: 'center',
        },

        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <div>{snakeToUpperCase(status)}</div>,
            align: 'center',
            width: 20,
        },
        {
            title: 'Moderation',
            dataIndex: 'is_moderation_required',
            key: 'is_moderation_required',
            render: (text, item) => (
                <IconContainer>
                    {item.is_moderation_required === true ? (
                        <IconTooltip
                            content={item.moderation_reason}
                            icon="redFlag"
                        />
                    ) : (
                        <div>-</div>
                    )}
                </IconContainer>
            ),
            align: 'center',
            width: 20,
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value) => <p>{dateFormating(value)}</p>,
            width: 110,
            align: 'center',
        },
        {
            title: 'Updated At',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (value) => <p>{dateFormating(value)}</p>,
            width: 110,
            align: 'center',
        },
        {
            title: 'Schedule Publish At',
            dataIndex: 'schedule_publish_at',
            key: 'schedule_publish_at',
            render: (value) => <p>{dateFormating(value)}</p>,
            width: 110,
            align: 'center',
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: (tags) => (
                <>{createTags((tags || {}).level_one_tags || [])}</>
            ),
            align: 'center',
        },
        {
            title: 'View',
            dataIndex: 'poll',
            key: 'poll',
            align: 'center',
            render: (item) => (
                <IconContainer>
                    <NavLink to={`/community/poll/${item.id}`}>
                        <VisibilityIcon />
                    </NavLink>
                </IconContainer>
            ),
        },
    ];

    if (!JSON.parse(filters.is_deleted || false)) {
        tableColumns.push({
            title: 'Edit',
            dataIndex: 'id',
            key: 'id',
            render: (text, item) => renderEdit(item),
            align: 'center',
            width: 10,
        });
        tableColumns.push({
            title: 'Pin',
            dataIndex: 'id',
            key: 'id',
            render: (text, item) => renderIsPinned(item),
            align: 'center',
            width: 10,
        });
        tableColumns.push({
            title: 'Pin Position',
            dataIndex: 'pinned_position',
            key: 'pinned_position',
            render: (text, item) => <div>{formattedValue(text)}</div>,
            align: 'center',
            width: 10,
        });
        tableColumns.push({
            title: 'Delete',
            dataIndex: 'poll',
            key: 'poll',
            width: 10,
            align: 'center',
            render: (text, item) => renderDelete(item),
        });
        tableColumns.push({
            title: 'DeepLink',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 10,
            render: (id) => (
                <CopyToClipboard content={generatePostDeepLink(id)} />
            ),
        });
    }

    const createAuthorListOptions = (authorArr) => {
        const optionArray = [];

        authorArr.map((author) => {
            optionArray.push(
                <Option key={author.id} value={author.id}>
                    {author.display_name}
                </Option>
            );
            return 0;
        });

        return optionArray;
    };

    const createStatusList = (data) => {
        const statusArray = [];

        data.map((item) => {
            statusArray.push(
                <Option key={item.id} value={item.id}>
                    {item.title}
                </Option>
            );
            return 0;
        });

        return statusArray;
    };

    const updatePoll = (params) => {
        setLoading(true);
        UpdatePollApi(params)
            .then((res) => {
                setLoading(false);
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        handleUpdateModalCancel();
                        getPollList(filters);
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to Update polls';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const getPollFilters = () => {
        setLoading(true);
        GetPollFiltersApi()
            .then((res) => {
                let success = false;
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setPollFilters({
                            author_id: {
                                key: 'author_id',
                                data: apiData.author_list,
                            },
                            id: {
                                key: 'id',
                                data: apiData.id_list,
                            },
                        });
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const getAuthorList = () => {
        setLoading(true);
        GetAuthorListApi()
            .then((res) => {
                let success = false;
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setAuthorList(apiData.result);
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const getTagList = () => {
        setLoading(true);
        GetTagListApi()
            .then((res) => {
                let success = false;
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        const levelOneTags = apiData.level_one_tags || [];
                        setTagList(levelOneTags);
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch tag list';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    useEffect(() => {
        getAuthorList();
        getPollFilters();
        getTagList();
    }, []);

    useEffect(() => {
        filters.page = currentPage;
        filters.page_limit = pageSize;
        getPollList(filters);
    }, [currentPage, pageSize]);

    const showCreatePollModal = () => {
        setShowCreateModal(true);
    };

    const handlePinModalClose = () => {
        setCurrentPinObject({});
    };

    const handleCreatePollModalCancel = () => {
        setShowCreateModal(false);
        setCreateContent('');
        setCurrentAuthorID('');
        setCurrentStatusID('');
        setOption1('');
        setOption2('');
        setOption3('');
        setOption4('');
        setErrors({});
        setCreateTagList([]);
        setPublishDate(null);
    };

    const generatePollOptions = (action) => {
        let pollOptions = [];
        if (action === 'create') {
            pollOptions = filter(
                [option1, option2, option3, option4],
                (item) => {
                    return size(trim(item)) > 0;
                }
            );
        } else if (action === 'update') {
            const existingOptions = (currentUpdatePoll.poll || {}).options;
            pollOptions = map(
                [updateOption1, updateOption2, updateOption3, updateOption4],
                (item, index) => {
                    if (existingOptions[index]) {
                        return {
                            id: existingOptions[index].id,
                            value: trim(item),
                            entity_id: existingOptions[index].entity_id,
                            entity_type: existingOptions[index].entity_type,
                        };
                    }
                    return null;
                }
            );
        }

        return compact(pollOptions);
    };

    const createPoll = () => {
        const params = {
            content: trim(createContent),
            author_id: currentAuthorID,
            buid: buID,
            poll: {
                start_time: getPayloadTime(new Date()),
                valid_duration: '4320',
                options: generatePollOptions('create'),
                valid_till: getPayloadTime(
                    new Date().setDate(
                        new Date().getDate() + minuteToDays(4320)
                    )
                ),
            },
            status: currentStatusID === '' ? 'published' : currentStatusID,
            level_one_tags: fetchObjectArrFromKeyArr(
                tagList,
                createTagList,
                'id'
            ),
            tagged_users: getTaggedUsers(createContent),
        };

        if (publishDate && currentStatusID === 'draft') {
            params.schedule_publish_at = dateFormattingServerUTC(publishDate);
        }

        setLoading(true);
        CreatePollApi(params)
            .then((res) => {
                let success = false;
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        handleCreatePollModalCancel();
                        getPollList(filters);
                        swal('success!', 'Successfully Create Poll', 'success');
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Poll Creation Failed';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('OOPS!', errorMessage, 'error');
            });
    };

    const validateFields = (values) => {
        const validationErrors = {};
        // Content
        if (size(trim(createContent)) === 0) {
            validationErrors.content = 'Required Content';
        }

        // Author
        if (!values.id && !values.currentAuthorID) {
            validationErrors.currentAuthorID = 'Required Author Name';
        }

        const invalidOptions = filter(
            [option1, option2, option3, option4],
            (item) => size(trim(item)) < 1
        );

        if (size(invalidOptions) > 2) {
            forEach([option1, option2, option3, option4], (item, index) => {
                if (size(item) > 0 && size(trim(item)) < 1) {
                    validationErrors[`option${index + 1}`] =
                        'Only spaces are not allowed in options';
                } else if (size(trim(item)) < 1) {
                    validationErrors[
                        `option${index + 1}`
                    ] = `Please enter option ${index + 1}`;
                }
            });
        }

        return validationErrors;
    };

    const validateUpdateFields = () => {
        const validationUpdateErrors = {};
        // Content
        if (size(trim(updateContent)) === 0) {
            validationUpdateErrors.content = 'Required Content';
        }

        const optionsList = [
            updateOption1,
            updateOption2,
            updateOption3,
            updateOption4,
        ];

        const invalidOptions = filter(optionsList, (item) => {
            return !!size(trim(item)) < 1;
        });

        if (size(invalidOptions) > 2) {
            forEach(optionsList, (item, index) => {
                if (size(item) > 0 && size(trim(item)) < 1) {
                    validationUpdateErrors[`option${index + 1}`] =
                        'Only spaces are not allowed in options';
                } else if (size(trim(item)) < 1) {
                    validationUpdateErrors[
                        `option${index + 1}`
                    ] = `Please enter option ${index + 1}`;
                }
            });
        }
        return validationUpdateErrors;
    };

    const handleCreatePollSubmit = (event) => {
        event.preventDefault();
        const values = {
            content: createContent,
            currentAuthorID,
            currentStatusID,
            option1,
            option2,
            option3,
            option4,
        };
        setErrors({});
        let validationErrors = {};
        validationErrors = validateFields(values);
        setErrors(validationErrors);
        if (isEmpty(validationErrors)) {
            createPoll();
        }
    };

    const handleFilterChange = (filters) => {
        getPollList(filters);
    };

    const handleAuthorSelectChange = (value) => {
        setCurrentAuthorID(value);
    };
    const handleStatusSelectChange = (value) => {
        setCurrentStatusID(value);
    };

    const handleUpdateSubmit = (event) => {
        event.preventDefault();
        const schedulePublishAt = updatePublishDate && currentStatusID === 'draft'
        ? dateFormattingServerUTC(updatePublishDate) : null;
        const values = {
            id: (currentUpdatePoll.poll || {}).id,
            content: updateContent,
            poll: {
                start_time: getPayloadTime(new Date()),
                valid_duration: '4320',
                options: generatePollOptions('update'),
                valid_till: getPayloadTime(
                    new Date().setDate(
                        new Date().getDate() + minuteToDays(4320)
                    )
                ),
            },
            status: currentStatusID === '' ? 'published' : currentStatusID,
            level_one_tags: fetchObjectArrFromKeyArr(
                tagList,
                updateTagList,
                'id'
            ),
            tagged_users: getTaggedUsers(updateContent),
            schedule_publish_at: schedulePublishAt,
        };
        const validationErrors = validateUpdateFields(values);
        setErrors(validationErrors);
        if (isEmpty(validationErrors)) {
            updatePoll(values);
        }
    };

    const IsDeleted = {
        key: 'is_deleted',
        data: [
            {
                id: 'true',
                title: 'True',
                selected: false,
            },
            {
                id: 'false',
                title: 'False',
                selected: true,
            },
        ],
    };

    const isModerationRequiredList = {
        key: 'is_moderation_required',
        data: [
            {
                id: true,
                title: 'True',
                selected: false,
            },
            {
                id: false,
                title: 'False',
                selected: false,
            },
            {
                id: undefined,
                title: 'None',
                selected: false,
            },
        ],
    };

    const createTagOptionList = () => {
        const optionsArray = [];
        tagList.map((item) => {
            optionsArray.push(
                <Option key={item.id} value={item.id}>
                    {item.title}
                </Option>
            );
        });
        return optionsArray;
    };

    const handleTagChange = (key, mode) => {
        if (mode === 'create') {
            setCreateTagList(key);
        } else if (mode === 'update') {
            setUpdateTagList(key);
        }
    };

    const IsPinned = {
        key: 'is_pinned',
        data: [
            {
                id: 'true',
                title: 'True',
                selected: false,
            },
            {
                id: 'false',
                title: 'False',
                selected: true,
            },
        ],
    };

    // const StatusListOption = (StatusList) => {
    //     <div>{StatusList && createStatusList(StatusList.data)}</div>;
    // };
    return (
        <Grid container spacing={2}>
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <StyledButton primary onClick={() => showCreatePollModal()}>
                    Create Poll
                </StyledButton>
            </Grid>
            <Grid
                container
                spacing={2}
                style={{
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                    paddingBottom: '1rem',
                }}
            >
                <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
                    <FilterTitleWrapper>Filters</FilterTitleWrapper>
                    <CommonDropdown
                        list={PostStatusList}
                        title="Status"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={IsDeleted}
                        title="Is Deleted"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={sortByList}
                        title="Sort By"
                        currentAuthorID
                        searchable={false}
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={sortTypeList}
                        title="Sort Type"
                        searchable={false}
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={isModerationRequiredList}
                        title="Moderation Required"
                        searchable={false}
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={IsPinned}
                        title="Is Pinned"
                        searchable={false}
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="From Date"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="created_at_lte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="To Date"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="created_at_gte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="To Date"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="updated_at_lte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Updated At GTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="updated_at_gte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    {PollFilters.author_id.data.length > 0 && (
                        <CommonDropdown
                            list={PollFilters.author_id}
                            title="Author"
                            searchable
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}
                    {PollFilters.id.data.length > 0 && (
                        <CommonDropdown
                            list={PollFilters.id}
                            title="ID"
                            searchable
                            onChange={handleFilterChange}
                            filters={filters}
                        />
                    )}
                </FilterGrid>
                <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                    <CommonTable
                        rowKey="id"
                        data={data}
                        columns={tableColumns}
                        pagination={false}
                        scroll={{ x: 'overflow' }}
                    />
                    <Paginator
                        totalRecords={total}
                        pageLimit={pageSize}
                        pageNeighbours={2}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </Grid>
            </Grid>
            <Grid>
                {showCreateModal && (
                    <StyleModal
                        title="Create Poll"
                        visible={showCreateModal}
                        onOk={(e) => handleCreatePollSubmit(e)}
                        onCancel={() => handleCreatePollModalCancel()}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                onClick={(e) => handleCreatePollSubmit(e)}
                                loading={loading}
                            >
                                Submit
                            </Button>,
                        ]}
                    >
                        <LabelInput>Content</LabelInput>
                        <MentionInput
                            value={createContent}
                            placeholder="Content"
                            onChange={(e) =>
                                setCreateContent(e.target.value)}
                        />
                        {errors.content && (
                            <ErrorText>{errors.content}</ErrorText>
                        )}
                        <br />
                        <LabelInput>Author </LabelInput>
                        <Select
                            style={{ width: '100%' }}
                            value={currentAuthorID}
                            onChange={(e) => handleAuthorSelectChange(e)}
                        >
                            {authorList && createAuthorListOptions(authorList)}
                        </Select>
                        {errors.currentAuthorID && (
                            <ErrorText>{errors.currentAuthorID}</ErrorText>
                        )}

                        <LabelInput>Status </LabelInput>
                        <Select
                            value={currentStatusID}
                            style={{ width: '100%' }}
                            onChange={(e) => handleStatusSelectChange(e)}
                        >
                            {CreateStatusList &&
                                createStatusList(CreateStatusList.data)}
                        </Select>
                        {currentStatusID === 'draft' && (
                            <ScheduleField
                                publishDate={publishDate}
                                setPublishDate={(date) => setPublishDate(date)}
                            />
                        )}
                        <LabelInput>Tags</LabelInput>
                        <Select
                            style={{ width: '100%' }}
                            mode="multiple"
                            allowClear
                            defaultValue={createTagList}
                            onChange={(e) => handleTagChange(e, 'create')}
                        >
                            {tagList.length > 0 && createTagOptionList()}
                        </Select>
                        {errors.createTagList && (
                            <ErrorText>{errors.createTagList}</ErrorText>
                        )}
                        <LabelInput>Option 1</LabelInput>
                        <OptionInput
                            id="option1"
                            placeholder="Option 1"
                            name="option1"
                            value={option1}
                            onChange={(e) => setOption1(e.target.value)}
                        />
                        {errors.option1 && (
                            <ErrorText>{errors.option1}</ErrorText>
                        )}
                        <LabelInput>Option 2</LabelInput>
                        <OptionInput
                            id="option2"
                            placeholder="Option 2"
                            name="option2"
                            value={option2}
                            onChange={(e) => setOption2(e.target.value)}
                        />
                        {errors.option2 && (
                            <ErrorText>{errors.option2}</ErrorText>
                        )}
                        <LabelInput>Option 3</LabelInput>
                        <OptionInput
                            id="option3"
                            placeholder="option 3"
                            name="Option3"
                            value={option3}
                            onChange={(e) => setOption3(e.target.value)}
                        />
                        {errors.option3 && (
                            <ErrorText>{errors.option3}</ErrorText>
                        )}
                        <LabelInput>Option 4</LabelInput>
                        <OptionInput
                            id="option4"
                            placeholder="Option 4"
                            name="option4"
                            value={option4}
                            onChange={(e) => setOption4(e.target.value)}
                        />
                        {errors.option4 && (
                            <ErrorText>{errors.option4}</ErrorText>
                        )}
                    </StyleModal>
                )}
            </Grid>
            <Grid>
                {showUpdateModal && (
                    <StyleModal
                        title="Update Poll"
                        visible={showUpdateModal}
                        onOk={(e) => handleUpdateSubmit(e)}
                        onCancel={() => handleUpdateModalCancel()}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                onClick={(e) => handleUpdateSubmit(e)}
                                loading={loading}
                            >
                                Update
                            </Button>,
                        ]}
                    >
                        {currentUpdatePoll.source === 'workstation' && (
                            <>
                                <LabelInput>Content</LabelInput>
                                <MentionInput
                                    value={updateContent}
                                    placeholder="Content"
                                    onChange={(e) =>
                                        setUpdateContent(e.target.value)}
                                />
                                {updateErrors.content && (
                                    <ErrorText>
                                        {updateErrors.content}
                                    </ErrorText>
                                )}
                            </>
                        )}
                        {(currentUpdatePoll.status === 'draft' ||
                            currentUpdatePoll.status === 'review_pending') && (
                                <>
                                    <LabelInput>Status </LabelInput>
                                    <Select
                                        value={currentStatusID}
                                        style={{ width: '100%' }}
                                        onChange={handleStatusSelectChange}
                                    >
                                        {/* {StatusListOption(StatusList)} */}
                                        {createStatusList(UpdateStatusList.data)}
                                    </Select>
                                </>
                            )}
                        
                            {currentStatusID === 'draft' && (
                                <ScheduleField
                                    publishDate={updatePublishDate}
                                    setPublishDate={(date) => setUpdatePublishDate(date)}
                                />
                            )}

                        <>
                            <LabelInput>Tags</LabelInput>
                            <Select
                                style={{ width: '100%' }}
                                mode="multiple"
                                allowClear
                                defaultValue={updateTagList}
                                onChange={(e) => handleTagChange(e, 'update')}
                            >
                                {tagList.length > 0 && createTagOptionList()}
                            </Select>
                            {errors.updateTagList && (
                                <ErrorText>{errors.updateTagList}</ErrorText>
                            )}
                        </>
                        {currentUpdatePoll.source === 'workstation' && (
                            <>
                                <LabelInput>Option 1</LabelInput>
                                <OptionInput
                                    id="updateoption1"
                                    placeholder="Option 1"
                                    name="updateoption1"
                                    value={updateOption1}
                                    onChange={(e) =>
                                        setUpdateOption1(e.target.value)
                                    }
                                />
                                {errors.option1 && (
                                    <ErrorText>{errors.option1}</ErrorText>
                                )}
                                <LabelInput>Option 2</LabelInput>
                                <OptionInput
                                    id="updateoption2"
                                    placeholder="Option 2"
                                    name="updateoption2"
                                    value={updateOption2}
                                    onChange={(e) =>
                                        setUpdateOption2(e.target.value)
                                    }
                                />
                                {errors.option2 && (
                                    <ErrorText>{errors.option2}</ErrorText>
                                )}
                            </>
                        )}

                        {showUpdateOption3 &&
                            currentUpdatePoll.source === 'workstation' && (
                                <>
                                    <LabelInput>Option 3</LabelInput>
                                    <OptionInput
                                        id="option3"
                                        placeholder="option 3"
                                        name="Option3"
                                        value={updateOption3}
                                        onChange={(e) =>
                                            setUpdateOption3(e.target.value)
                                        }
                                    />
                                    {errors.option3 && (
                                        <ErrorText>{errors.option3}</ErrorText>
                                    )}
                                </>
                            )}

                        {showUpdateOption4 &&
                            currentUpdatePoll.source === 'workstation' && (
                                <>
                                    <LabelInput>Option 4</LabelInput>
                                    <OptionInput
                                        id="option4"
                                        placeholder="option 4"
                                        name="Option4"
                                        value={updateOption4}
                                        onChange={(e) =>
                                            setUpdateOption4(e.target.value)
                                        }
                                    />
                                    {errors.option4 && (
                                        <ErrorText>{errors.option4}</ErrorText>
                                    )}
                                </>
                            )}
                    </StyleModal>
                )}
            </Grid>
            <Grid>
                {size(currentPinObject) > 0 && (
                    <PinEntityModal
                        entityObj={currentPinObject}
                        entityType="post"
                        onClose={handlePinModalClose}
                        filters={filters}
                        fetchData={(filters) => handleFilterChange(filters)}
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default Poll;
