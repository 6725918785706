const validateAuth = (values) => {
    const errors = {};
    // Employee Code Errors
    if (!values.email) {
        errors.email = 'Required Employee Code';
    } else if (values.email.length !== 6) {
        errors.email = 'Please Enter Valid Employee Code';
    } else if (!/^[0-9]{2,}$/i.test(values.email)) {
        errors.email = 'Please Enter Valid Employee Code';
    }

    // Password Errors
    if (!values.password) {
        errors.password = 'Required Password';
    } else if (values.password.length < 8) {
        errors.password = 'Password must be at least 8 characters';
    }
    return errors;
};
export default validateAuth;
