/* eslint-disable */
/* eslint-disable comma-dangle */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';
import swal from 'sweetalert';
import Avatar from '@material-ui/core/Avatar';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CommonTable from '../Common/Table';
import { FilterGrid, FilterTitleWrapper, IconContainer } from './styles';
import {
    GetHoldingUserListApi,
} from '../../api/getHoldingList';
import {GetAppUserFiltersApi} from '../../api/appUserList';
import { dateFormating } from '../../util/helper';
import Paginator from '../Common/Paginator';
import CommonLoader from '../Common/Loader';
import { getUserAvatar } from '../../util/custom';
import { startCase, toLower } from 'lodash';
import CommonDropdown from '../Common/Dropdown';

const UserHolding = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(1);
    const [filters, setFilters] = useState({
        page: currentPage,
        page_limit: pageSize,
    });
    const [userFilters, setUserFilters] = useState({
        display_name: {
            key: 'display_name',
            data: [],
        },
        email: {
            key: 'email',
            data: [],
        },
        id: {
            key: 'id',
            data: [],
        },
        mobile_number: {
            key: 'mobile_number',
            data: [],
        },
        party_id: {
            key: 'party_id',
            data: [],
        },
    });

    const getHoldingUserList = (params) => {
        setLoading(true);
        GetHoldingUserListApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setData(apiData.results);
                        setCurrentPage(apiData.page);
                        setPageSize(apiData.page_limit);
                        setTotal(apiData.total_count);
                        setFilters(params);
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const getUserFilterList = () => {
        setLoading(true);
        GetAppUserFiltersApi()
            .then((res) => {
                let success = false;
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setUserFilters({
                            display_name: {
                                key: 'display_name',
                                data: apiData.display_name_list,
                            },
                            email: {
                                key: 'email',
                                data: apiData.email_list,
                            },
                            id: {
                                key: 'id',
                                data: apiData.id_list,
                            },
                            mobile_number: {
                                key: 'mobile_number',
                                data: apiData.mobile_number_list,
                            },
                            party_id: {
                                key: 'party_id',
                                data: apiData.party_id_list,
                            },
                        });
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    useEffect(() => {
        filters.page = currentPage;
        filters.page_limit = pageSize;
        getHoldingUserList(filters);
    }, [currentPage, pageSize]);

    useEffect(() => {
        getUserFilterList();
    }, []);

    const handleFilterChange = (filters) => {
        getHoldingUserList(filters);
    };

    const tableColumns = [
        {
            title: 'User',
            dataIndex: 'profile_picture',
            key: 'id',
            width: 10,
            align: 'center',
            render: (profile) => (
                <Avatar
                    style={{ backgroundColor: '#b59a9a52' }}
                    alt="Remy Sharp"
                    src={getUserAvatar(profile)}
                />
            ),
        },
        // {
        //     title: 'ID',
        //     dataIndex: 'id',
        //     key: '1',
        //     width: 10,
        //     align: 'center',
        // },
        {
            title: 'User Name',
            dataIndex: 'display_name',
            key: '2',
            render: (name) => startCase(toLower(name)),
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile_number',
            key: '3',
        },
        {
            title: 'Invested Value',
            dataIndex: 'overall_holding',
            key: '4',
            render: (item) => <p>{item.invested_value.toLocaleString('en')}</p>,
        },
        {
            title: 'Current Value',
            dataIndex: 'overall_holding',
            key: '5',
            render: (item) => <p>{item.current_value.toLocaleString('en')}</p>,
        },
        {
            title: 'Gain Loss',
            dataIndex: 'overall_holding',
            key: 'overall_holding',
            render: (item) => (
                <p>{item.overall_gain_loss.toLocaleString('en')}</p>
            ),
        },
        {
            title: 'Gain Loss %',
            dataIndex: 'overall_holding',
            key: '6',
            render: (item) => <p>{item.overall_gain_loss_percentage}</p>,
        },
        {
            title: 'Effective Date',
            dataIndex: 'overall_holding',
            key: '7',
            align: 'center',
            render: (item) => <p>{dateFormating(item.effective_date)}</p>,
        },
        // {
        //     title: 'Level',
        //     dataIndex: 'overall_holding',
        //     key: '8',
        //     align: 'center',
        //     render: (item) => <p>{item.type}</p>,
        // },
        {
            title: 'View',
            dataIndex: 'id',
            key: '9',
            align: 'center',
            render: (item) => (
                <IconContainer>
                    <NavLink to={`/investment/user-holding/${item}`}>
                        <VisibilityIcon />
                    </NavLink>
                </IconContainer>
            ),
        },
    ];

    return (
        <Grid
            container
            spacing={2}
            style={{
                paddingTop: '1rem',
                paddingRight: '1rem',
                paddingLeft: '1rem',
                paddingBottom: '1rem',
            }}
        >
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />
            <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
                <FilterTitleWrapper>Filters</FilterTitleWrapper>
                {userFilters.id.data.length > 0 && (
                    <CommonDropdown
                        list={userFilters.id}
                        title="ID"
                        searchable
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                )}
                {userFilters.display_name.data.length > 0 && (
                    <CommonDropdown
                        list={userFilters.display_name}
                        title="Display Name"
                        searchable
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                )}
                {userFilters.email.data.length > 0 && (
                    <CommonDropdown
                        list={userFilters.email}
                        title="Email"
                        searchable
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                )}
                {userFilters.mobile_number.data.length > 0 && (
                    <CommonDropdown
                        list={userFilters.mobile_number}
                        title="Mobile Number"
                        searchable
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                )}
                {userFilters.party_id.data.length > 0 && (
                    <CommonDropdown
                        list={userFilters.party_id}
                        title="Party ID"
                        searchable
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                )}
            </FilterGrid>
            <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                <CommonTable
                    data={data}
                    columns={tableColumns}
                    pagination={false}
                    scroll={{ x: 'overflow' }}
                    rowKey="id"
                />
                <Paginator
                    totalRecords={total}
                    pageLimit={pageSize}
                    pageNeighbours={2}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            </Grid>
        </Grid>
    );
};
export default UserHolding;
