import React from 'react';
import { withRouter } from 'react-router-dom';
import BlogDetail from '../../component/BlogDetail';
import { Wrapper } from './styles';

const BlogDetailPage = (props) => (
    <Wrapper>
        <BlogDetail pageProps={props} />
    </Wrapper>
);

export default withRouter(BlogDetailPage);
