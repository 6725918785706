import React from 'react';
import { withRouter } from 'react-router-dom';
import PostDetail from '../../component/PostDetail';
import { Wrapper } from './styles';

const PostDetailPage = (props) => (
    <Wrapper>
    <PostDetail pageProps={props} />
    </Wrapper>
);
export default withRouter(PostDetailPage);
