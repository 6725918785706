import React from 'react';
import { withRouter } from 'react-router-dom';
import ReportedDetail from '../../component/ReportedDetail';
import { Wrapper } from './styles';

const ReportedDetailPage = (props) => (
    <Wrapper>
        <ReportedDetail pageProps={props} />
    </Wrapper>
);

export default withRouter(ReportedDetailPage);
