/* eslint-disable implicit-arrow-linebreak */
import axios from './instance';
import {
    GetEventListApiURL,
    CreateEventApiURL,
    DeleteEventApiURL,
    GetEventDetailApiURL,
    UpdateEventApiURL,
    GetEventRegistrationListApiURL,
} from './apiConfig';

export const GetEventListApi = (params) =>
    axios.get(GetEventListApiURL, { params });

export const CreateEventApi = (params) => axios.post(CreateEventApiURL, params);

export const GetEventDetailApi = (params) =>
    axios.get(GetEventDetailApiURL, { params });

export const DeleteEventApi = (params) =>
    axios.delete(DeleteEventApiURL, { data: { ...params } });

export const UpdateEventApi = (params) => axios.put(UpdateEventApiURL, params);

export const GetEventRegistrationListApi = (params) =>
    axios.get(GetEventRegistrationListApiURL, { params });