/* eslint-disable */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { StyledTable, IconWrapper } from './styles';

const CommonTable = (props) => {
    const {
        data,
        columns,
        pagination,
        onChange,
        loading,
        scroll,
        rowKey,
        expandable,
    } = props;

    const theme = useSelector((state) => state.theme);

    const renderExpandableIcon = ({ expanded, onExpand, record }) => {
        if (expanded){
            return (
                <IconWrapper>
                    <KeyboardArrowUpIcon
                        size="small"
                        onClick={(e) => onExpand(record, e)}
                    />
                </IconWrapper>
            );
        }

        return (
            <IconWrapper>
                <KeyboardArrowDownIcon
                    size="small"
                    onClick={(e) => onExpand(record, e)}
                />
            </IconWrapper>
        );
    };


    let expandableProps = {};
    if (expandable){
        expandableProps = {
            expandable: {
                ...expandable,
                expandIcon: renderExpandableIcon,
            },

        };
    }

    return (
        <StyledTable
            currentTheme={theme.currentMode}
            dataSource={data}
            columns={columns}
            pagination={pagination}
            onChange={() => onChange()}
            loading={loading}
            rowKey={rowKey}
            scroll={scroll}
            // expandable={expandable}
            {...expandableProps}
        />
    );
};

CommonTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    onChange: PropTypes.func,
    pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    loading: PropTypes.bool,
    scroll: PropTypes.shape({}),
    rowKey: PropTypes.string,
    expandable: PropTypes.shape({}),
};

CommonTable.defaultProps = {
    onChange: () => {},
    pagination: false,
    loading: false,
    scroll: {},
    rowKey: '',
};

export default CommonTable;
