export const goalCalculatorObj = {
    title: {
        grid: 12,
        value: '',
        type: 'text',
        placeholder: 'Enter title of goal calculator',
    },
    description: {
        grid: 12,
        value: '',
        type: 'text',
        placeholder: 'Enter description text',
    },
    // type: {
    //     value: '',
    //     type: 'text',
    //     placeholder: 'enter type of goal calculator',
    // },
    position: {
        value: '',
        type: 'number',
        placeholder: 'enter position',
    },

    cta_text: {
        value: '',
        type: 'text',
        placeholder: 'enter cta text',
    },

    metaData: {
        defaultValue: {
            target_amount: {
                max: 0,
                min: 0,
                steps: 0,
            },
            target_time: {
                max: 0,
                min: 0,
                steps: 0,
            },
        },
    },
    image: {
        value: {},
        type: 'image',
    },
};

export const retirementCalculatorObj = {
    title: {
        grid: 12,
        value: '',
        type: 'text',
        placeholder: 'enter title of Retirement Calculator',
    },
    description: {
        grid: 12,
        value: '',
        type: 'text',
        placeholder: 'enter description text',
    },
    // type: {
    //     value: '',
    //     type: 'text',
    //     placeholder: 'enter type of goal calculator',
    // },
    position: {
        value: '',
        type: 'number',
    },

    cta_text: {
        value: '',
        type: 'text',
        placeholder: 'enter cta text',
    },

    metaData: {
        defaultValue: {
            current_age: {
                max: 0,
                min: 0,
                steps: 0,
            },
            retirement_age: {
                max: 0,
                min: 0,
                steps: 0,
            },
            life_expectancy: {
                max: 0,
                min: 0,
                steps: 0,
            },
            monthly_expenses: {
                max: 0,
                min: 0,
                steps: 0,
            },
        },
    },
    image: {
        value: {},
        type: 'image',
    },
};

export const taxSavingsCalculatorObj = {
    title: {
        grid: 12,
        value: '',
        type: 'text',
        placeholder: 'enter title of Tax Savings Calculator',
    },

    description: {
        grid: 12,
        value: '',
        type: 'text',
        placeholder: 'enter description text',
    },
    // type: {
    //     value: '',
    //     type: 'text',
    //     placeholder: 'enter type of goal calculator',
    // },
    position: {
        value: '',
        type: 'number',
    },
    cta_text: {
        value: '',
        type: 'text',
        placeholder: 'enter cta text',
    },
    metaData: {
        defaultValue: {
            annual_income: {
                max: 0,
                min: 0,
                steps: 0,
            },
            monthly_rent: {
                max: 0,
                min: 0,
                steps: 0,
            },
            monthly_houseRent_allowance: {
                max: 0,
                min: 0,
                steps: 0,
            },
            tution_fees_of_children: {
                max: 0,
                min: 0,
                steps: 0,
            },
            interest_paid_on_education_loan: {
                max: 0,
                min: 0,
                steps: 0,
            },
            home_loan_amount: {
                max: 0,
                min: 0,
                steps: 0,
            },
            home_loan_emi: {
                max: 0,
                min: 0,
                steps: 0,
            },
            home_loan_interest_rate: {
                max: 0,
                min: 0,
                steps: 0,
            },
            home_loan_duration: {
                max: 0,
                min: 0,
                steps: 0,
            },
            epf: {
                max: 0,
                min: 0,
                steps: 0,
            },
            lic: {
                max: 0,
                min: 0,
                steps: 0,
            },
            ppf: {
                max: 0,
                min: 0,
                steps: 0,
            },
            tax_savings_mfs: {
                max: 0,
                min: 0,
                steps: 0,
            },
            tax_savings_fd: {
                max: 0,
                min: 0,
                steps: 0,
            },
            nps: {
                max: 0,
                min: 0,
                steps: 0,
            },
            health_insurance_self: {
                max: 0,
                min: 0,
                steps: 0,
            },
            health_insurance_parents: {
                max: 0,
                min: 0,
                steps: 0,
            },
        },
    },
    image: {
        value: {},
        type: 'image',
    },
};

export const calculatorTypes = [
    {
        id: 'goal',
        title: 'Goal Calculator',
    },
    {
        id: 'retirement',
        title: 'Retirement',
    },
    {
        id: 'tax',
        title: 'Tax Savings',
    },
];
