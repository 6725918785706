import styled from 'styled-components';

const Wrapper = styled.div`
    z-index: 1500;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    
`;

export { Wrapper };
