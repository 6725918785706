import styled from 'styled-components';

export const Title = styled.div`
    font-weight: 600;
    font-size: 1rem;
    margin: 1rem 0 1rem 0;
    font-family: 'Titillium Web';
    color: ${(props) => props.theme.text};
    @media (max-width: 1024px) {
        text-align: center;
    }
`;

export const BottomWrapper = styled.div`
    margin: 2rem 0 2rem 0;

    @media (max-width: 1024px) {
        margin: 1rem 0 1rem 0;
    }
`;
