/* eslint-disable */
import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import ReplyIcon from '@material-ui/icons/Reply';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import FavoriteIcon from '@material-ui/icons/Favorite';
import {size, snakeCase } from 'lodash';
import { getUserProfilePicture } from '../../util/custom';
import { ReactComponent as BookMarkIcon } from '../../assets/icons/bookmarks.svg';
import { ReactComponent as CommentIcon } from '../../assets/icons/comment.svg';
import Paginator from '../Common/Paginator';
import {
    FooterActionSection,
    FooterActionCountSection,
    FooterActionIconSection,
    StlyedSkeleton,
    StyleModal,
    StyledAvatar,
    Author,
    StyledScrollbar,
    BorderTop,
    DeleteCommentIcon,
    FooterWrapper,
    AuthorName,
    CreatedAt,
    LikeWrapper,
    CommentReplySection,
    CommentWrapper,
    CommentText,
    CommentsWrapper,
    MainGrid,
    SubCommentWrapper,
    CommentGrid,
    AuthorInfo,
    CommentArea,
    CommentFooterArea,
    CommentSection,
    CommentContentWrapper,
    DeleteLink,
    ReplyLink,
    ReplyText,
    ReplyCount,
    LikeSection,
    LikeCount,
    ChildCommentWrapper,
    IconTooltipWrapper,
} from './styles';
import ReadMore from '../Common/ReadMore';
import { GetCommentListApi, DeleteCommentApi } from '../../api/commentList';
import { GetReactionAuthorListApi } from '../../api/authorList';
import { getFormattedShortDate } from '../../util/helper';
import CommentReplyBox from '../Common/CommentBox';
import { buID as buid } from '../../constant';
import { GetAuthorListApi } from '../../api/authorList';
import IconTooltip from '../Common/IconTooltip';
import MentionText from '../MentionText';
import { getMentionsListApi } from '../../api/mentionsData';

const PostDetailFooter = (props) => {
    const {
        likeCount,
        authorName,
        commentCount,
        bookMarkCount,
        postID,
        buID,
        postType,
        loading,
        fetchGetPostDetail,
        isModerationRequired,
        moderationReason,
    } = props;

    const [showText, hideText] = useState(true);
    const [reactionLikeData, setReactionLikeData] = useState([]);
    const [likeCurrentPage, setLikeCurrentPage] = useState(1);
    const [likePageSize, setLikePageSize] = useState(5);
    const [likeTotalCount, setLikeTotalCount] = useState(1);
    const [showLikeModal, setShowLikeModal] = useState(false);
    const [likeModalLoader, setLikeModalLoader] = useState(false);
    const [authorList, setAuthorList] = useState([]);
    const [likefilters, setLikeFilters] = useState({
        sort_by: 'updated_at',
        sort_type: 'desc',
        is_deleted: 'false',
    });

    const [active, setActive] = useState('');

    const [authorLoading, setAuthorLoading] = useState(false);
    const [commentLoading, setCommentLoading] = useState(true);
    const [commentTotal, setCommentTotal] = useState(1);
    const [commentData, setCommentData] = useState([]);
    const [commentCurrentPage, setCommentCurrentPage] = useState(0);
    const [commentPageSize, setCommentPageSize] = useState(20);
    const [commentFilters, setCommentFilters] = useState({
        post_id: postID,
        page: commentCurrentPage,
        page_limit: commentPageSize,
    });
    const [initialContent, setInitialContent] = useState('')
    
    const getCommentList = (params = commentFilters) => {
        setCommentLoading(true);
        GetCommentListApi(params)
            .then((res) => {
                let success = false;
                setCommentLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setCommentData(apiData.results);
                        setCommentCurrentPage(apiData.page);
                        setCommentPageSize(apiData.page_limit);
                        setCommentTotal(apiData.total_count);
                        setCommentFilters(params);
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setCommentLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const getAuthorList = () => {
        setAuthorLoading(true);
        GetAuthorListApi()
            .then((res) => {
                setAuthorLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        const apiData = (res.data || {}).data || {};
                        setAuthorList(apiData.result);
                    }
                }
            })
            .catch((error) => {
                setAuthorLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    useEffect(() => {
        if (postID) {
            commentFilters.post_id = postID;
            commentFilters.page = commentCurrentPage;
            commentFilters.page_limit = commentPageSize;
            getCommentList(commentFilters);
        }
    }, [commentCurrentPage, commentPageSize, postID]);

    useEffect(() => {
        if (!authorLoading) {
            getAuthorList();
        }
    }, []);

    const getReactionLikeList = (params) => {
        setLikeModalLoader(true);
        GetReactionAuthorListApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setReactionLikeData(apiData.results);
                        setLikeCurrentPage(apiData.page);
                        setLikePageSize(apiData.page_limit);
                        setLikeTotalCount(apiData.total_count);
                        setLikeFilters(params);
                        setLikeModalLoader(false);
                    }
                }
                if (!success) {
                    setLikeModalLoader(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLikeModalLoader(false);
                let errorMessage = 'Failed to fetch reaction data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const openLikeModal = (entityID, entityType, reactionType) => {
        setShowLikeModal(true);
        const params = {
            entity_id: entityID,
            entity_type: entityType,
            reaction_type: reactionType,
            page: likeCurrentPage,
            page_limit: likePageSize,
        };
        getReactionLikeList(params);
    };

    const closeLikeModal = () => {
        setShowLikeModal(false);
    };

    useEffect(() => {
        if (
            !isEmpty(likefilters.entity_id) &&
            !isEmpty(likefilters.entity_type) &&
            !isEmpty(likefilters.reaction_type)
        ) {
            likefilters.page = likeCurrentPage;
            likefilters.page_limit = likePageSize;
            getReactionLikeList(likefilters);
        }
    }, [likeCurrentPage, likePageSize]);

    const generateReactionAuthorList = () => {
        const authorArr = [];

        if (reactionLikeData !== null) {
            reactionLikeData.map((reaction) => {
                const user = reaction.user || {};
                authorArr.push(
                    <LikeWrapper>
                        <Author id={user.id}>
                            <StyledAvatar
                                src={getUserProfilePicture(
                                    user.profile_picture
                                )}
                            />
                            <span>
                                <AuthorName>{user.display_name}</AuthorName>
                                <CreatedAt>
                                    {getFormattedShortDate(reaction.updated_at)}
                                </CreatedAt>
                            </span>
                        </Author>
                    </LikeWrapper>
                );
            });
        }

        authorArr.push(
            <Paginator
                totalRecords={likeTotalCount}
                pageLimit={likePageSize}
                pageNeighbours={2}
                currentPage={likeCurrentPage}
                setCurrentPage={setLikeCurrentPage}
            />
        );

        return authorArr;
    };

    const parentComment = (id, user) => {
        if (active === id) {
            setActive('');
            return;
        }
        getMentionList(id, user);
    };

    const getMentionList = (id, user) => {
        getMentionsListApi({
            search_key: user.name,
        })
        .then((response) => {
                if (response.status === 200
                    && Array.isArray(response.data?.data)) {
                    const result = response.data?.data.find((element) => {
                        return element.id == user.id;
                    });
                    if(size(result)){
                        setInitialContent(`@[${snakeCase(result.display_name)}](${snakeCase(result.id)})`);
                    } else{
                        setInitialContent('');
                    }
                    setActive(id);
                }
        })
        .catch((error)=>{
            setActive(id); 
        })
    };

    
    const commentDelete = (id) => {
        swal({
            title: 'Are you sure you want to delete this comment ?',
            text: '',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((success) => {
            if (success) {
                setCommentLoading(true);
                const params = { id };
                DeleteCommentApi(params)
                    .then((res) => {
                        fetchGetPostDetail();
                        getCommentList(commentFilters);
                        setCommentLoading(false);
                        if ((res.data || {}).success) {
                            swal(
                                'success!',
                                'Successfully Deleted Comment',
                                'success'
                            );
                        }
                    })
                    .catch((error) => {
                        setCommentLoading(false);
                        let errorMessage = 'Failed to delete comment';
                        if (error.response) {
                            if (error.response.status === 400) {
                                errorMessage = (error.response.data || {})
                                    .error_desc;
                            }
                        }
                        swal('Oops!', errorMessage, 'error');
                    });
            }
        });
    };

    const renderChildComments = (props) => {
        const { reply } = props;
        return (
            <>
                {!size(reply)
                    ? null
                    : map(reply || [], (item, index) => {
                          return (
                              <CommentWrapper
                                  key={index}
                                  isFirstChild={index === 0}
                              >
                                  <Author>
                                      <StyledAvatar
                                          isChild
                                          src={getUserProfilePicture(
                                              item.user.profile_picture
                                          )}
                                      />
                                  </Author>
                                  <CommentSection>
                                      <CommentArea>
                                          <AuthorInfo>
                                              <AuthorName>
                                                  {item.user.name}
                                              </AuthorName>
                                              <CreatedAt>
                                                  {getFormattedShortDate(
                                                      item.created_at
                                                  )}
                                              </CreatedAt>
                                          </AuthorInfo>
                                          <CommentContentWrapper>
                                              <ReadMore>
                                                  <ReadMore>
                                                    <MentionText text={item.content}/>
                                                </ReadMore>
                                              </ReadMore>
                                          </CommentContentWrapper>
                                      </CommentArea>
                                      <CommentFooterArea>
                                          <DeleteLink
                                              onClick={() =>
                                                  commentDelete(item.id)
                                              }
                                              addLeftMargin
                                          >
                                              Delete
                                          </DeleteLink>
                                          <DeleteCommentIcon
                                              style={{ width: '16px' }}
                                              size="small"
                                              onClick={() =>
                                                  commentDelete(item.id)
                                              }
                                          />
                                          {item.is_moderation_required && (
                                              <IconTooltipWrapper>
                                                  <IconTooltip
                                                      content={
                                                          item.moderation_reason
                                                      }
                                                      icon="redFlag"
                                                      iconSize="small"
                                                  />
                                              </IconTooltipWrapper>
                                          )}
                                      </CommentFooterArea>
                                      <LikeSection>
                                          <FavoriteIcon
                                              style={{ color: 'red' }}
                                              onClick={() => {
                                                  item.like_count === 0
                                                      ? ''
                                                      : openLikeModal(
                                                            item.parent_comment_id,
                                                            'comment',
                                                            'like'
                                                        );
                                              }}
                                          />
                                          <LikeCount>
                                              {item.like_count}
                                          </LikeCount>
                                      </LikeSection>
                                  </CommentSection>
                              </CommentWrapper>
                          );
                      })}
                <CommentReplySection xs={11}>
                    <CommentReplyBox
                        buID={buID}
                        postID={postID}
                        parentCommentID={props.id}
                        getComments={getCommentList}
                        authorList={authorList}
                        getPostDetails={fetchGetPostDetail}
                        commentReplyInitialContent={initialContent}
                    />
                </CommentReplySection>
            </>
        );
    };

    const renderCommentReplyBox = () => {
        return (
            <CommentReplySection lg={12} sx={12} sx={12}>
                <CommentReplyBox
                    authorList={authorList}
                    getPostDetails={fetchGetPostDetail}
                    getComments={getCommentList}
                    buID={buID}
                    postID={postID}
                />
            </CommentReplySection>
        );
    };

    const renderPostComments = (data) => {
        return map(data || [], (item, index) => {
            return (
                <CommentWrapper key={index}>
                    <Author>
                        <StyledAvatar
                            src={getUserProfilePicture(
                                item.user.profile_picture
                            )}
                        />
                    </Author>
                    <CommentSection>
                        <CommentArea>
                            <AuthorInfo>
                                <AuthorName>{item.user.name}</AuthorName>
                                <CreatedAt>
                                    {getFormattedShortDate(item.created_at)}
                                </CreatedAt>
                            </AuthorInfo>
                            <CommentContentWrapper>
                                <ReadMore>
                                    <MentionText text={item.content}/>
                                </ReadMore>
                            </CommentContentWrapper>
                        </CommentArea>
                        <CommentFooterArea>
                            <ReplyLink onClick={() => parentComment(item.id, item.user)}>
                                <ReplyIcon size="small" />
                                {size(item.reply) > 0 && (
                                    <ReplyCount>{size(item.reply)}</ReplyCount>
                                )}
                                <ReplyText>
                                    {size(item.reply) > 1 ? 'Replies' : 'Reply'}
                                </ReplyText>
                            </ReplyLink>
                            <DeleteLink>Delete</DeleteLink>
                            <DeleteCommentIcon
                                style={{ width: '16px' }}
                                size="small"
                                onClick={() => commentDelete(item.id)}
                            />
                            {item.is_moderation_required && (
                                <IconTooltipWrapper>
                                    <IconTooltip
                                        content={item.moderation_reason}
                                        icon="redFlag"
                                        iconSize="small"
                                    />
                                </IconTooltipWrapper>
                            )}
                        </CommentFooterArea>
                        <LikeSection>
                            <FavoriteIcon
                                style={{ color: 'red' }}
                                onClick={() => {
                                    item.like_count === 0
                                        ? ''
                                        : openLikeModal(
                                              item.id,
                                              'comment',
                                              'like'
                                          );
                                }}
                            />
                            <LikeCount>{item.like_count}</LikeCount>
                        </LikeSection>
                    </CommentSection>
                    <ChildCommentWrapper>
                        {active === item.id ? renderChildComments(item) : null}
                    </ChildCommentWrapper>
                </CommentWrapper>
            );
        });
    };

    return loading ? (
        <StlyedSkeleton paragraph={{ rows: 0 }} />
    ) : (
        <MainGrid container spacing={2}>
            <FooterWrapper>
                <FooterActionSection
                    onClick={() => {
                        likeCount !== 0
                            ? openLikeModal(postID, 'post', 'like')
                            : '';
                    }}
                >
                    <FooterActionCountSection>
                        {likeCount}
                    </FooterActionCountSection>
                    <FooterActionIconSection>
                        <FavoriteIcon style={{ color: 'red' }} />
                    </FooterActionIconSection>
                </FooterActionSection>
                <FooterActionSection
                    onClick={() => {
                        hideText(!showText);
                    }}
                >
                    <FooterActionCountSection>
                        {commentCount}
                    </FooterActionCountSection>
                    <FooterActionIconSection>
                        <CommentIcon />
                    </FooterActionIconSection>
                </FooterActionSection>
                <FooterActionSection>
                    <FooterActionCountSection>
                        {bookMarkCount}
                    </FooterActionCountSection>
                    <FooterActionIconSection>
                        <BookMarkIcon />
                    </FooterActionIconSection>
                </FooterActionSection>
                {isModerationRequired && (
                    <FooterActionSection>
                        <FooterActionIconSection>
                            <IconTooltip
                                content={moderationReason}
                                icon="redFlag"
                            />
                        </FooterActionIconSection>
                    </FooterActionSection>
                )}
            </FooterWrapper>
            {showText && (
                <CommentGrid>
                    <CommentsWrapper>
                        <BorderTop>
                            <StyledScrollbar>
                                {renderCommentReplyBox()}
                                {renderPostComments(commentData)}
                                <Paginator
                                    totalRecords={commentTotal}
                                    pageLimit={commentPageSize}
                                    pageNeighbours={2}
                                    currentPage={commentCurrentPage}
                                    setCurrentPage={setCommentCurrentPage}
                                />
                            </StyledScrollbar>
                        </BorderTop>
                    </CommentsWrapper>
                </CommentGrid>
            )}
            <Grid>
                {showLikeModal && (
                    <StyleModal
                        title={`${likeCount} Likes`}
                        visible={showLikeModal}
                        onCancel={() => closeLikeModal()}
                        footer={null}
                    >
                        <StyledScrollbar
                            style={{
                                overflowY: 'auto',
                            }}
                        >
                            <>
                                {likeModalLoader && reactionLikeData <= 0 && (
                                    <StlyedSkeleton
                                        avatar
                                        paragraph={{ rows: 0 }}
                                    />
                                )}
                                {reactionLikeData &&
                                    generateReactionAuthorList()}
                                <Paginator
                                    totalRecords={likeTotalCount}
                                    pageLimit={likePageSize}
                                    pageNeighbours={2}
                                    currentPage={likeCurrentPage}
                                    setCurrentPage={setLikeCurrentPage}
                                />
                            </>
                        </StyledScrollbar>
                    </StyleModal>
                )}
            </Grid>
        </MainGrid>
    );
};

PostDetailFooter.propTypes = {
    likeCount: PropTypes.number.isRequired,
    authorName: PropTypes.number.isRequired,
    commentCount: PropTypes.number.isRequired,
    bookMarkCount: PropTypes.number.isRequired,
    postID: PropTypes.string.isRequired,
    buID: PropTypes.string,
    postType: PropTypes.string.isRequired,
    loading: PropTypes.bool,
    isModerationRequired: PropTypes.bool,
    moderationReason: PropTypes.string,
};

PostDetailFooter.defaultProps = {
    loading: false,
    buID: buid,
    isModerationRequired: false,
    moderationReason: '',
};

export default PostDetailFooter;
