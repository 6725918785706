/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Button as But } from "@material-ui/core";
import swal from "sweetalert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Modal, Button, Select, Input } from "antd";
import { isEmpty, truncate, size } from "lodash";
import { RNS3 } from "react-native-aws3";
import styled from "styled-components";
import CommonTable from "../Common/Table";
import { Button as ButtonUpload } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  FilterGrid,
  FilterTitleWrapper,
  StyledButton,
  StyledInput,
  LabelInput,
  ErrorText,
  IconContainer,
  StyledDiv,
  RightGridItem,
  LeftGridItem,
  StyledDateTimePicker,
  useStyles,
  ImageContainer,
  DeleteMedia,
} from "./styles";
import CommonLoader from "../Common/Loader";
import CommonDropdown from "../Common/Dropdown";
import CommonDatePicker from "../Common/DatePicker";
import CommonInput from "../Common/Input";
import Paginator from "../Common/Paginator";
import {
  formattedValue,
  snakeToUpperCase,
  isValidImageUploadObject,
  dateFormating,
} from "../../util/helper";
import {
  GetBannerListApi,
  CreateBannerApi,
  UpdateBannerApi,
  DeleteBannerApi,
} from "../../api/banner";
import {
  getBannerImageUrl,
  getImageWithBannerCreds,
  s3Config,
} from "../../util/custom";
import Image from "../Image";
import { PreviewImage } from "../Image/styles";
import {
  BulkCreateSIPUpdate,
  CreateSIPUpdate,
  DeleteSipUpdate,
  GetSipClientCode,
  GetSipUpdate,
  UpdateSipUpdate,
} from "../../api/getUpdateList";
import {
  GetFdApi,
  UpdateFdApi
} from "../../api/fd";

const StyleModal = styled(Modal)`
  .ant-modal-header {
    background: ${(props) => props.theme.dark};
    color: ${(props) => props.theme.dark};
  }

  .ant-modal-body {
    background: ${(props) => props.theme.dark};
  }

  .ant-modal-title {
    color: ${(props) => props.theme.text};
  }
  .ant-modal-footer {
    background: ${(props) => props.theme.dark};
  }
  .ant-modal-close-icon {
    color: ${(props) => props.theme.text};
  }

  .ant-modal .sc-AzgDb .jcGGxa {
    padding: 0px;
  }
`;

const FixedDeposit = () => {
  const [data, setData] = useState([]);
  // const [authorList, setAuthorList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(1);
  // const [customerNumber, setCustomerNumber] = useState([]);
  // const [issuerName, setIssuerName] = useState([]);
  const [filters, setFilters] = useState({
    page: currentPage,
    page_limit: pageSize,
    is_deleted: "false",
    order_date: "",
    customer_phone_number: "",
    issuer_name: "",
  });

  const getFDList = (params) => {
    setLoading(true);
    GetFdApi(params).then((res) => {
      if (res.status === 200) {
        const apiData = res.data || {};
        setData(apiData.results);
        // let data1 = [...new Set(apiData.results?.map((item)=>{
        //   return item.customer_phone_number
        // }))]
        // let customer_phone_number = data1?.map((item)=>({id: item, title: item}))
        // setCustomerNumber(customer_phone_number)

        // let data2 = [...new Set(apiData.results?.map((item)=>{
        //   return item.issuer_name
        // }))]
        // let issuer_name = data2?.map((item)=>({id: item, title: item}))
        // setIssuerName(issuer_name)
        setCurrentPage(apiData.page);
        setPageSize(apiData.page_limit);
        setTotal(apiData.total_count);
        setFilters(params);
        setLoading(false);
      }
    })
    .catch((error) => {
      setLoading(false);
      let errorMessage = "Failed to fetch data";
      if (error.response) {
        if (error.response.status === 400) {
          errorMessage = (error.response.data || {}).error_desc;
        }
      }
      swal("Oops!", errorMessage, "error");
    });
  };

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 10,
      render: (id) => <>{id}</>,
    },
    {
      title: "Customer ID",
      dataIndex: "customer_id",
      key: "customer_id",
      width: 200,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      width: 70,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Customer Email",
      dataIndex: "customer_email",
      key: "customer_email",
      width: 200,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Customer PAN",
      dataIndex: "customer_pan",
      key: "customer_pan",
      width: 70,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Country Code",
      dataIndex: "country_code",
      key: "country_code",
      width: 70,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Customer Phone Number",
      dataIndex: "customer_phone_number",
      key: "customer_phone_number",
      width: 70,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Issuer Id",
      dataIndex: "issuer_id",
      key: "issuer_id",
      width: 200,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Issuer Name",
      dataIndex: "issuer_name",
      key: "issuer_name",
      render: (value) => <p>{formattedValue(value)}</p>,
      width: 200,
      align: "center",
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
      render: (value) => <p>{dateFormating(value)}</p>,
      width: 200,
      align: "center",
    },
    {
      title: "Order Id",
      dataIndex: "order_id",
      key: "order_id",
      width: 200,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Order Type",
      dataIndex: "order_type",
      key: "order_type",
      width: 200,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Coupon",
      dataIndex: "coupon",
      key: "coupon",
      render: (value) => <p>{formattedValue(value)}</p>,
      width: 200,
      align: "center",
    },
    {
      title: "Tenure",
      dataIndex: "tenure",
      key: "tenure",
      width: 200,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Call Date",
      dataIndex: "call_date",
      key: "call_date",
      render: (value) => <p>{dateFormating(value)}</p>,
      width: 200,
      align: "center",
    },
    {
      title: "Maturity Date",
      dataIndex: "maturity_date",
      key: "maturity_date",
      render: (value) => <p>{dateFormating(value)}</p>,
      width: 200,
      align: "center",
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
      width: 200,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 200,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Invested Amount",
      dataIndex: "invested_amount",
      key: "invested_amount",
      width: 200,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Earned Commission",
      dataIndex: "earned_commission",
      key: "earned_commission",
      width: 200,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Maturity Amount",
      dataIndex: "maturity_amount",
      key: "maturity_amount",
      width: 200,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => <p>{dateFormating(value)}</p>,
      width: 200,
      align: "center",
    },
  ];

  // if (!JSON.parse(filters.is_deleted || false)) {
  //   tableColumns.push({
  //     title: "Edit",
  //     dataIndex: "id",
  //     key: "id",
  //     render: (text, item) => renderEdit(item),
  //     align: "center",
  //   });
  //   tableColumns.push({
  //     title: "Delete",
  //     dataIndex: "id",
  //     key: "id",
  //     align: "center",
  //     render: (text, item) =>
  //       item.is_deleted === false ? (
  //         <IconContainer>
  //           <DeleteIcon onClick={() => deleteBanner(item.id)} />
  //         </IconContainer>
  //       ) : null,
  //   });
  // }


  useEffect(() => {
    filters.page = currentPage;
    filters.page_limit = pageSize;
    getFDList(filters);
  }, [currentPage, pageSize]);


  const handleFilterChange = (filters) => {
    getFDList(filters);
  };

  const IsDeleted = {
    key: "is_deleted",
    data: [
      {
        id: "true",
        title: "True",
        selected: false,
      },
      {
        id: "false",
        title: "False",
        selected: true,
      },
    ],
  };


  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  const handleFileInputChange = async (e) => {
    try {
      setLoading(true);
      console.log(e.target.files[0]);
      let file = e.target.files[0];
      const response = await getBase64(file);
      file["base64"] = await response;
      let param = {
        fileBase64: file["base64"].split(",")[1],
      };
      await UpdateFdApi(param);
      await getFDList(filters);
      swal("Success", "Excel Sheet Uploaded", "success");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(
        "🚀 ~ file: index.jsx:1076 ~ handleFileInputChange ~ err:",
        err.response.data.errors
      );
      if (Array.isArray(err.response.data.errors))
        swal("Oops!", err.response.data.errors[0], "error");
      else swal("Oops!", err.response.data.errors, "error");
    }
  };

  const classes = useStyles();
  const fileuploadref = useRef();

  return (
    <Grid container spacing={2}>
      <CommonLoader
        isLoading={loading}
        size="large"
        sentences={["please wait ..."]}
        type="pacman"
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {!loading && (
          <input
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            className={classes.input}
            ref={fileuploadref}
            id={"Fileupload"}
            onChange={handleFileInputChange}
            type="file"
          />
        )}
        <StyledButton
          onClick={() => {
            fileuploadref.current.click();
          }}
        >
          Upload
        </StyledButton>
      </Grid>

      <Grid
        container
        spacing={2}
        style={{
          paddingRight: "1rem",
          paddingLeft: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
          <FilterTitleWrapper>Filters</FilterTitleWrapper>
          {/* <CommonDropdown
            list={IsDeleted}
            title="Is Deleted"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          /> */}
          <CommonDatePicker
            title="Order Date"
            type="inline"
            dateFormat="dd/MM/yyyy"
            filterKey="order_date"
            onChange={(filters) => {
              if(filters.order_date != undefined && filters.order_date != null){
                const dateObj = new Date(filters.order_date)
                const date = dateObj.getDate()
                const month = dateObj.getMonth()+1
                const year = dateObj.getFullYear()
                const newDate = `${year}-${month < 10 ? "0"+month : month}-${date < 10 ? "0"+date : date}`;
                filters.order_date = newDate;
              }else{
                filters.order_date = ""
              }
              handleFilterChange(filters)
            }}
            filters={filters}
          />
          {/* {customerNumber.length > 0 && (
            <CommonDropdown
              list={{
                key: "customer_phone_number",
                data: customerNumber,
              }}
              title="Customer Phone Number"
              searchable
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
          )} */}
          <CommonInput
            title="Customer Phone Number"
            id="customer_phone_number"
            type="number"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          {/* {issuerName.length > 0 && (
            <CommonDropdown
              list={{
                key: "issuer_name",
                data: issuerName,
              }}
              title="Issuer Name"
              searchable
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
          )} */}
          {/* <CommonInput
            title="Issuer Name"
            id="issuer_name"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          /> */}
        </FilterGrid>
        <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
          <CommonTable
            data={data}
            columns={tableColumns}
            pagination={false}
            scroll={{ x: "overflow" }}
          />
          <Paginator
            totalRecords={total}
            pageLimit={pageSize}
            pageNeighbours={2}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FixedDeposit;
