/* eslint-disable */
/* eslint-disable import/prefer-default-export */
import {
    GetOrderListApiURL,
    GetOrderFiltersApiURL,
    UpdateOrderAPIURL
} from './apiConfig';
import axios from './instance';


export const GetOrderListApi = (params) =>
    axios.get(GetOrderListApiURL, { params });

export const GetOrderFilterApi = (params) =>
    axios.get(GetOrderFiltersApiURL, { params });

export const UpdateOrderAPI = (params) =>
    axios.put(UpdateOrderAPIURL, params);