import { size } from "lodash";
import axios from "../api/instance";
import { GET_ADMIN_DETAILS } from "../api/apiConfig";
import { clearAccessRefreshToken, clearAdmin, getAccessToken, getRefreshToken } from "./storage";
import {
  goalCalculatorObj,
  retirementCalculatorObj,
  taxSavingsCalculatorObj,
} from "../constant/calculators";

// eslint-disable-next-line consistent-return
export const isNumberKey = (event) => {
  const charCode = event.which ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return event.preventDefault();
  }
};

export const dateFormating = (date) => {
  if (date) {
    const convertedDate = new Date(date.split("Z")[0]);
    const day = String(convertedDate.getDate()).padStart(2, 0);
    const month = String(convertedDate.getMonth() + 1).padStart(2, 0);
    const year = String(convertedDate.getFullYear());
    let hours = String(convertedDate.getHours()).padStart(2, 0);
    let minutes = String(convertedDate.getMinutes()).padStart(2, 0);
    const seconds = convertedDate.getSeconds();

    if (Number(seconds) > 0) {
      if (Number(minutes) + 1 === 60) {
        minutes = "00";
        if (Number(hours) + 1 === 24) {
          hours = "00";
        } else {
          hours = String(Number(hours) + 1).padStart(2, 0);
        }
      } else {
        minutes = String(Number(minutes) + 1).padStart(2, 0);
      }
    }
    const formatedDate = `${day}-${month}-${year} ${hours}:${minutes}`;
    return formatedDate;
  }
  return "-";
};

export const dateFormattingServer = (date) => {
  if (date) {
    const convertedDate = new Date(date);
    const day = String(convertedDate.getDate()).padStart(2, 0);
    const month = String(convertedDate.getMonth() + 1).padStart(2, 0);
    const year = String(convertedDate.getFullYear());
    const hours = String(convertedDate.getHours()).padStart(2, 0);
    const minutes = String(convertedDate.getMinutes()).padStart(2, 0);
    const seconds = String(convertedDate.getSeconds()).padStart(2, 0);

    const formatedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formatedDate;
  }
  return "-";
};

export const isLogedIn = () => Boolean(localStorage.getItem("access_token"));

export const formattedValue = (val) => {
  if (
    val === null ||
    val === undefined ||
    val === "" ||
    val === "{}" ||
    val === "null" ||
    val === {} ||
    val === "undefined"
  ) {
    return "-";
  }
  return val.toString();
};

/* eslint-disable no-multiple-empty-lines */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable function-paren-newline */
export const snakeToUpperCase = (val) =>
  val
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", " ").replace("_", " ")
    )
    .toUpperCase();

export const toSnakeAndLowerCase = (val) =>
  val.replace(/ /g, "_").toLowerCase();

export const downloadFile = (url) => {
  const downloadLink = document.createElement("a");
  downloadLink.setAttribute("href", url);
  downloadLink.setAttribute("rel", "nofollow noopener");
  downloadLink.setAttribute("download", "file");
  downloadLink.setAttribute("target", "_blank");
  downloadLink.click();
};
// Table sort functions

export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function extractQueryParamJSON(queryParamsStr) {
  const paramJSON = {};

  if (queryParamsStr !== "" || queryParamsStr !== undefined) {
    // eslint-disable-next-line no-param-reassign
    queryParamsStr = queryParamsStr.substring(1, queryParamsStr.length);
    const queryParamsList = queryParamsStr.split("&");
    queryParamsList.forEach((item) => {
      const splitArray = item.split("=");
      // eslint-disable-next-line prefer-destructuring
      paramJSON[splitArray[0]] = splitArray[1];
    });
  }

  return paramJSON;
}

export function searchFilter(arr = [], query = "", keys = []) {
  const q = query.trim();
  const rx = new RegExp(q, "i");
  const result = [];
  if (q) {
    if (keys.length === 0) {
      // eslint-disable-next-line no-plusplus
      for (let j = 0; j < arr.length; j++) {
        if (rx.test(arr[j])) {
          if (arr[j].toLowerCase() === q.toLowerCase()) {
            result.unshift(arr[j]);
          } else {
            result.push(arr[j]);
          }
        }
      }
    } else {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < arr.length; i++) {
        let score = 0;
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < keys.length; j++) {
          if (rx.test(arr[i][keys[j]])) {
            if (arr[i][keys[j]].toLowerCase() === q.toLowerCase()) {
              //   result.unshift(arr[i]);
              score = 1;
            } else if (score !== 1) {
              score = 0;
              //   result.push(arr[i]);
            }
            if (score === 1) {
              result.unshift(arr[i]);
              break;
            } else {
              result.push(arr[i]);
              break;
            }
          }
        }
      }
    }

    return result;
  }
  return arr;
}

export const checkloggedin = async (callback) => {
  const accessToken = await getAccessToken();
  const refreshToken = await getRefreshToken();
  if (!size(accessToken) || !size(refreshToken)) {
    callback({ success: false });
    return;
  }
  axios
    .get(GET_ADMIN_DETAILS, {
      params: {
        access_token: accessToken,
        refresh_token: refreshToken,
      },
    })
    .then((res) => {
      callback({ success: true, data: res.data });
    })
    .catch(() => callback({ success: false }));
};

export const getFormattedShortDate = (date = new Date()) => {
  const newDate = new Date(date);
  return `${newDate.getDate()} ${newDate.toLocaleString("default", {
    month: "short",
  })}`;
};

export const getCalculatorState = (type) => {
  if (type === "retirement") {
    return { ...retirementCalculatorObj };
  }
  if (type === "taxSavings") {
    return { ...taxSavingsCalculatorObj };
  }
  return { ...goalCalculatorObj };
};

export const loadCalculatorWithInitialState = (item) => {
  const calculatorObj = getCalculatorState(item.type);

  // eslint-disable-next-line no-debugger

  calculatorObj.title.value = item.title;
  calculatorObj.image.value = item.banner_image;
  calculatorObj.position.value = item.position;
  calculatorObj.ctaText.value = item.cta_text;
  calculatorObj.description.value = item.description;
  if (item.meta) {
    if (item.meta && item.meta.defaultValue) {
      calculatorObj.metaData = item.meta;
    } else {
      calculatorObj.metaData = {};
      calculatorObj.metaData.defaultValue = item.meta;
    }
  }
  return calculatorObj;
};

export const calculatorStateCancel = (item) => {
  const calculatorObj = getCalculatorState(item.type);

  calculatorObj.title.value = "";
  calculatorObj.image.value = "";
  calculatorObj.position.value = "";
  calculatorObj.ctaText.value = "";
  calculatorObj.type.value = "";
  calculatorObj.description.value = "";

  return calculatorObj;
};

/* eslint-disable no-param-reassign */
export const getPayloadTime = (date) => {
  date = new Date(date);
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, 0);
  const currentDate = date.getUTCDate().toString().padStart(2, 0);
  const hours = date.getUTCHours().toString().padStart(2, 0);
  const minutes = date.getUTCMinutes().toString().padStart(2, 0);
  const seconds = date.getUTCSeconds().toString().padStart(2, 0);
  return `${year}-${month}-${currentDate} ${hours}:${minutes}:${seconds}`;
};

export const minuteToDays = (minutes) => {
  if (minutes > 0) {
    const hours = minutes / 60;
    return Math.floor(hours / 24);
  }
  return 0;
};

export const isValidImageUploadObject = (uploadFile) => {
  let isValid = false;
  if (uploadFile === null || uploadFile === "" || uploadFile === undefined) {
    isValid = false;
  } else if (uploadFile) {
    /**
     * If the file is from local (checking size) 
     * or from the server (checking url)
     */
    if(uploadFile.size ||uploadFile.url ){
      isValid = true;
    }
  }
  return isValid;
};

export const fetchObjectArrFromKeyArr = (objectArr, valueArr, searchKey) => {
  const arr = [];
  valueArr.forEach((value) => {
    const obj = objectArr.find((object) => object[searchKey] === value);
    if (obj !== null && obj !== undefined) {
      arr.push(obj);
    }
  });
  return arr;
};

export const dateFormattingServerUTC = (date) => {
  if (date) {
    let convertedDate = new Date(date);
    convertedDate = convertedDate.toISOString();
    const convertedDateArr = convertedDate.split("T");
    const timeSectionArr = convertedDateArr[1].split(".");

    const formatedDate = `${convertedDateArr[0]} ${timeSectionArr[0]}`;
    return formatedDate;
  }
  return "-";
};

export const extractDataFromZoomJoinLink = (link) => {
  const url = new URL(link);

  const meetingPassword = url.searchParams.get("pwd");
  const meetingNumber = url.pathname.replace("/j/", "") || null;

  return {
    meetingNumber,
    meetingPassword,
  };
};

export const clearSession = () => {
  clearAccessRefreshToken();
  clearAdmin();
  window.location.reload();
}
