/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { size } from 'lodash';
import { ReadMoreText, ReadHide } from './styles';

const ReadMore = ({ children }) => {
    const text = children;
    const maxTextChars = 250;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <ReadMoreText>
            {size(text) > maxTextChars ? (
                <>
                    {isReadMore ? text.slice(0, maxTextChars) : text}
                    <ReadHide onClick={toggleReadMore}>
                        {isReadMore ? '...read more' : '...show less'}
                    </ReadHide>
                </>
            ) : (
                text
            )}
        </ReadMoreText>
    );
};

export default ReadMore;
