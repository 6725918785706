/* eslint-disable implicit-arrow-linebreak */

import {
    GetQuestionListApiURL,
    CreateQuestionApiURL,
    DeleteQuestionApiURL,
    UpdateQuestionApiURL,
    GetQuestionDetialApiURL,
    GetQuestionFiltersApiURL,
} from './apiConfig';
import axios from './instance';

export const GetQuestionListApi = (params) =>
    axios.get(GetQuestionListApiURL, { params });

export const CreateQuestionApi = (params) =>
    axios.post(CreateQuestionApiURL, params);

export const DeleteQuestionApi = (params) =>
    axios.delete(DeleteQuestionApiURL, { data: { ...params } });

export const UpdateQuestionApi = (params) =>
    axios.put(UpdateQuestionApiURL, params, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const GetQuestionDetialApi = (params) =>
    axios.get(GetQuestionDetialApiURL, { params });

export const GetQuestionFiltersApi = () => axios.get(GetQuestionFiltersApiURL);
