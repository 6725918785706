import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 110vh;
`;

export const LoadingWrapper = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: calc(100% - 60px);
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.3);
`;
