const darkTheme = {
    black: '#000',
    dark: '#141414',
    likedark: '#292828',
    background: '#000000',
    light: '#FAFAFA',
    pinklogo: '#e9137c',
    validationColor: '#FF0000',
    text: '#FFF',
    contentBackground: '#7b7a7a',
    nestedTableBackground: '#2f2727',
    nestedTableHead: '#fff',
    nestedTableText: '#000',
    postHeader: '#2f2727',
    lightGray: '#f8f7f7',
};

export default darkTheme;
