import React from "react";
import { withRouter } from "react-router-dom";
import { Wrapper } from "./styles";
import FixedDeposite from "../../component/FixedDeposite";

const FixedDepositePage = () => (
  <Wrapper>
    <FixedDeposite />
  </Wrapper>
);

export default withRouter(FixedDepositePage);
