/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import { Tooltip } from '@material-ui/core';
import { map, snakeCase } from 'lodash';
import { parseValue } from '../../util/mention';
import { TaggedText } from './styles';

const MentionText = ({ text }) => {
    const { parts } = parseValue(text, [{ trigger: '@' }]);

    return map(parts, (content, index) => {
                if (content.partType) {
                    return (
                        <Tooltip key={index} title={content.text.substring(1)}>
                            <TaggedText>
                                 &nbsp;
                                 {`@${snakeCase(content.text)}`}
                            </TaggedText>
                        </Tooltip>
                    );
                }

                return (
                <React.Fragment key={index}>
                    {`${content.text}`}
                </React.Fragment>
                );
            });
};

export default MentionText;
