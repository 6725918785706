import React from 'react';
import { withRouter } from 'react-router-dom';
import Blog from '../../component/Blog';
import { Wrapper } from './styles';

const BlogPage = () => (
    <Wrapper>
        <Blog />
    </Wrapper>
);

export default withRouter(BlogPage);
