import React from 'react';
import { withRouter } from 'react-router-dom';
import ApplicationVersionHistory from '../../component/ApplicationVersionHistory';
import { Wrapper } from './styles';

const ApplicationVersionHistoryPage = () => (
    <Wrapper>
        <ApplicationVersionHistory />
    </Wrapper>
);

export default withRouter(ApplicationVersionHistoryPage);
