/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Form } from 'antd';
import isEmpty from 'lodash/isEmpty';
import {
    ForgotPasswordApi,
    ConfirmForgotPasswordApi,
} from '../../api/forgotpassword';
import CommonLoader from '../Common/Loader';
import {
    Wrapper,
    BoxWrapper,
    FormWrapper,
    StyleInput,
    SubmitButton,
    StyleLabel,
    PasswordInput,
    InputWrapper,
    HeadingText,
    SpanValidation,
    ErrorText,
} from './styles';
import { getKey, setKey } from '../../util/storage';

const ForgotPassword = (props) => {
    const [showResults, setShowResults] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userNameValidation, setUserNameValidation] = useState(false);
    const [username, setUserName] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [errors, setErrors] = useState({});
    const { pageProps } = props;
    const { history } = pageProps;

    const resetPasswordEvent = () => {
        setLoading(true);
        const params = {
            username: getKey('username'),
            new_password: newPassword,
            confirm_new_password: confirmNewPassword,
            verification_code: verificationCode,
        };
        ConfirmForgotPasswordApi(params)
            .then((res) => {
                let success = false;
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        swal(
                            'success!',
                            'Password has been changed successfully',
                            'success',
                        );
                        history.push('/');
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error',
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to change password';
                if (error.response) {
                    errorMessage = (error.response.data || {}).error_desc;
                }
                swal('OOPS!', errorMessage, 'error');
            });
    };

    const validateFields = (values) => {
        const validationErrors = {};
        // Verification Code Errors
        if (!values.verificationCode) {
            validationErrors.verificationCode = 'Required Verification Code';
        } else if (values.verificationCode.length !== 6) {
            validationErrors.verificationCode = 'Please Enter Valid Verification Code';
        } else if (!/^[0-9]{2,}$/i.test(values.verificationCode)) {
            validationErrors.verificationCode = 'Please Enter Valid Verification Code ';
        }

        // New Password Errors
        if (!values.newPassword) {
            validationErrors.newPassword = 'Required Password';
        } else if (values.newPassword.length < 8) {
            validationErrors.newPassword = 'Password must be at least 8 characters';
        }
        // Confirm New Password
        if (!values.confirmNewPassword) {
            validationErrors.confirmNewPassword = 'Required Password';
        } else if (values.confirmNewPassword.length < 8) {
            validationErrors.confirmNewPassword = 'Password must be at least 8 characters';
        } else if (values.confirmNewPassword !== values.newPassword) {
            validationErrors.confirmNewPassword = '*passwords doesnot match';
        }

        return validationErrors;
    };

    const resetPassword = (event) => {
        event.preventDefault();
        const values = {
            verificationCode,
            newPassword,
            confirmNewPassword,
        };
        const validationErrors = validateFields(values);
        setErrors(validationErrors);
        if (isEmpty(validationErrors)) {
            resetPasswordEvent();
        }
    };

    const forgotPasswordEvent = () => {
        setLoading(true);
        const params = {
            username,
        };
        ForgotPasswordApi(params)
            .then((res) => {
                let success = false;
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        setShowResults(true);
                        setKey('username', username);
                        swal(
                            'success!',
                            ' successfully sent verification code',
                            'success',
                        );
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error',
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to send verification code';
                if (error.response) {
                    errorMessage = (error.response.data || {}).error_desc;
                }
                swal('OOPS!', errorMessage, 'error');
            });
    };

    const verification = (event) => {
        event.preventDefault();
        if (username.length === 0 || username.length < 6) {
            setUserNameValidation(true);
        } else {
            setUserNameValidation(false);
            forgotPasswordEvent();
        }
    };

    const handleInputChange = (event) => {
        event.persist();
        setUserName(event.target.value);
    };

    return (
        <Wrapper>
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />
            <HeadingText>Forgot Password</HeadingText>
            <FormWrapper>
                <Form>
                    {showResults ? (
                        <BoxWrapper>
                            <InputWrapper>
                                <StyleLabel>Verification-code</StyleLabel>
                                <StyleInput
                                    placeholder="Verification-code"
                                    id="verification_code"
                                    name="verification_code"
                                    value={verificationCode}
                                    onChange={(e) => setVerificationCode(e.target.value)}
                                />
                                {errors.verificationCode && (
                                    <ErrorText>
                                        {errors.verificationCode}
                                    </ErrorText>
                                )}
                            </InputWrapper>
                            <InputWrapper>
                                <StyleLabel>Enter-new-password</StyleLabel>
                                <PasswordInput
                                    placeholder="Enter-new-password"
                                    name="password"
                                    iconRender={(visible) => (visible ? (
                                            <EyeTwoTone />
                                        ) : (
                                            <EyeInvisibleOutlined />
                                        ))}
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                                {errors.newPassword && (
                                    <ErrorText>{errors.newPassword}</ErrorText>
                                )}
                            </InputWrapper>
                            <InputWrapper>
                                <StyleLabel>Re-enter-new-password</StyleLabel>
                                <PasswordInput
                                    iconRender={(visible) => (visible ? (
                                            <EyeTwoTone />
                                        ) : (
                                            <EyeInvisibleOutlined />
                                        ))}
                                    placeholder="Re-enter-new-password"
                                    name="confirm_new_password"
                                    id="confirm_new_password"
                                    value={confirmNewPassword}
                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                />
                                {errors.confirmNewPassword && (
                                    <ErrorText>
                                        {errors.confirmNewPassword}
                                    </ErrorText>
                                )}
                            </InputWrapper>

                            <SubmitButton onClick={resetPassword}>
                                Reset Password
                            </SubmitButton>
                        </BoxWrapper>
                    ) : (
                        <BoxWrapper>
                            <StyleLabel>Employee ID</StyleLabel>
                            <StyleInput
                                placeholder="Employee ID"
                                id="username"
                                name="username"
                                value={username}
                                onChange={(e) => handleInputChange(e)}
                            />
                            {userNameValidation && (
                                <SpanValidation id="event-name-error">
                                    *please enter a valid Id.
                                </SpanValidation>
                            )}
                            <SubmitButton onClick={(e) => verification(e)}>
                                Send Verification Code
                            </SubmitButton>
                        </BoxWrapper>
                    )}
                </Form>
            </FormWrapper>
        </Wrapper>
    );
};

ForgotPassword.propTypes = {
    pageProps: PropTypes.shape({}).isRequired,
};

export default ForgotPassword;
