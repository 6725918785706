/* eslint-disable no-unused-expressions */
/* eslint-disable comma-dangle */
/* eslint-disable no-shadow */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import swal from "sweetalert";
import { NavLink } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Modal, Button, Select } from "antd";
import styled from "styled-components";
import isEmpty from "lodash/isEmpty";
import { size, trim } from "lodash";
import { RNS3 } from "react-native-aws3";
import { PushpinFilled } from "@ant-design/icons";

import CommonTable from "../Common/Table";
import { getImageWithBucketCreds, s3Config } from "../../util/custom";
import IconTooltip from "../Common/IconTooltip";
import { generatePostDeepLink } from "../../util/deeplink";
import CopyToClipboard from "../Common/CopyToClipboard";
import MentionInput from "../MentionInput";

import {
  FilterGrid,
  FilterTitleWrapper,
  StyledTag,
  StyledButton,
  StyledInput,
  LabelInput,
  ErrorText,
  // YesIcon,
  // NoIcon,
  IconContainer,
  // IamgeInput,
  // DeleteFilledStyle,
  // Parent,
  // ImageLabel,
  // StyleImage,
} from "./styles";
import {
  GetPostFiltersApi,
  GetPostListApi,
  GetTagListApi,
} from "../../api/postList";
import { GetUploadS3ImageApi } from "../../api/uploadImageS3";
import CommonLoader from "../Common/Loader";
import CommonDropdown from "../Common/Dropdown";
import Paginator from "../Common/Paginator";
import CommonDatePicker from "../Common/DatePicker";
import Image from "../Image";

import {
  formattedValue,
  snakeToUpperCase,
  dateFormating,
  isValidImageUploadObject,
  fetchObjectArrFromKeyArr,
  dateFormattingServerUTC,
  clearSession,
} from "../../util/helper";
import {
  CreatePostApi,
  DeletePostApi,
  UpdatePostApi,
} from "../../api/getPostDetail";
import { buID } from "../../constant";
import { GetAuthorListApi } from "../../api/authorList";
import {
  PostStatusList,
  UpdateStatusList,
  CreateStatusList,
} from "../../util/post";
import { PinEntityModal } from "../PinEntityModal";
import MentionText from "../MentionText";
import { getTaggedUsers, getTruncatedContent } from "../../util/mention";
import ScheduleField from "../ScheduleField";
import { uploadFileToS3, uploadFiletoS3 } from "../../util/uploadUrltoDB";

const StyleModal = styled(Modal)`
  .ant-modal-header {
    background: ${(props) => props.theme.dark};
    color: ${(props) => props.theme.dark};
  }

  .ant-modal-body {
    background: ${(props) => props.theme.dark};
  }

  .ant-modal-title {
    color: ${(props) => props.theme.text};
  }
  .ant-modal-footer {
    background: ${(props) => props.theme.dark};
  }
  .ant-modal-close-icon {
    color: ${(props) => props.theme.text};
  }

  .ant-modal .sc-AzgDb .jcGGxa {
    padding: 0px;
  }
`;

const Post = () => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [data, setData] = useState([]);
  const [authorList, setAuthorList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(1);
  const { Option } = Select;

  const [filters, setFilters] = useState({
    page: currentPage,
    page_limit: pageSize,
    sort_by: "updated_at",
    sort_type: "desc",
    is_deleted: "false",
  });

  const [content, setContent] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [currentAuthorID, setCurrentAuthorID] = useState("");
  const [currentStatusID, setCurrentStatusID] = useState("draft");
  const [errors, setErrors] = useState({});
  // const [updateErrors, setUpdateErrors] = useState({});
  const [updateContent, setUpdateContent] = useState("");
  const [currentUpdatePost, setCurrentUpdatePost] = useState({});
  const [tagList, setTagList] = useState([]);
  const [createTagList, setCreateTagList] = useState([]);
  const [updateTagList, setUpdateTagList] = useState([]);
  const [currentPinObject, setCurrentPinObject] = useState({});
  const [publishDate, setPublishDate] = useState(null);
  const [updatePublishDate, setUpdatePublishDate] = useState(null);
  const [postFilters, setPostFilters] = useState({
    author_id: {
      key: "author_id",
      data: [],
    },
    id: {
      key: "id",
      data: [],
    },
  });
  const sortTypeList = {
    key: "sort_type",
    data: [
      {
        id: "asc",
        title: "Asc",
        selected: false,
      },
      {
        id: "desc",
        title: "Desc",
        selected: true,
      },
    ],
  };

  const sortByList = {
    key: "sort_by",
    data: [
      {
        id: "id",
        title: "id",
        selected: false,
      },
      {
        id: "created_at",
        title: "created_at",
        selected: false,
      },
      {
        id: "updated_at",
        title: "updated_at",
        selected: true,
      },
      {
        id: "content",
        title: "content",
        selected: false,
      },
      {
        id: "status",
        title: "status",
        selected: false,
      },
    ],
  };

  const isModerationRequiredList = {
    key: "is_moderation_required",
    data: [
      {
        id: true,
        title: "True",
        selected: false,
      },
      {
        id: false,
        title: "False",
        selected: false,
      },
      {
        id: undefined,
        title: "None",
        selected: false,
      },
    ],
  };

  const createTags = (tags = []) => {
    const tagArray = [];
    if (tags != null) {
      tags.map((tag) => {
        tagArray.push(<StyledTag key={tag.id}>{tag.title}</StyledTag>);
        return 0;
      });
    }
    return tagArray;
  };

  const getPostList = (params) => {
    setLoading(true);
    GetPostListApi(params)
      .then((res) => {
        let success = false;
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            const apiData = (res.data || {}).data || {};
            setData(apiData.results);
            setCurrentPage(apiData.page);
            setPageSize(apiData.page_limit);
            setTotal(apiData.total_count);
            setFilters(params);
            setLoading(false);
          }
        }
        if (!success) {
          setLoading(false);
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to fetch data";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  const showUpdatePostModal = (postObj) => {
    setCurrentUpdatePost(postObj);
    setUpdateContent(postObj.content);
    setImageFile(postObj.images);
    setCurrentAuthorID(postObj.author_id);
    setCurrentStatusID(postObj.status);
    setUpdateTagList(
      ((postObj.tags || {}).level_one_tags || []).map((item) => item.id)
    );
    setShowUpdateModal(true);
    setUpdatePublishDate(postObj.schedule_publish_at || null);
  };

  const handleUpdateModalCancel = () => {
    setShowUpdateModal(false);
    setCurrentUpdatePost({});
    setUpdateContent("");
    setImageFile("");
    setCurrentAuthorID("");
    // setFile('');
    setErrors({});
    setUpdateTagList([]);
    setUpdatePublishDate(null);
  };

  const createStatusList = (d) => {
    const statusArray = [];

    d.map((item) => {
      statusArray.push(
        <Option key={item.id} value={item.id}>
          {item.title}
        </Option>
      );
      return 0;
    });

    return statusArray;
  };

  const showPinPostModal = (postObj) => {
    setCurrentPinObject(postObj);
  };

  const deletePost = (id) => {
    swal({
      title: "Are you sure you want to delete this post ?",
      text: "",
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then((success) => {
      if (success) {
        setLoading(true);
        const params = {
          id,
        };
        DeletePostApi(params)
          .then((res) => {
            let success = false;
            setLoading(false);
            if (res.status === 200) {
              if ((res.data || {}).success) {
                success = true;
                getPostList(filters);
                swal("success!", "Successfully Deleted Post", "success");
              }
            }
            if (!success) {
              setLoading(false);
              swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
            }
          })
          .catch((error) => {
            setLoading(false);
            let errorMessage = "Failed to delete post";
            if (error.response) {
              if (error.response.status === 400) {
                errorMessage = (error.response.data || {}).error_desc;
              }
            }
            swal("Oops!", errorMessage, "error");
          });
      }
    });
  };

  // const renderImage = (data) => {
  //     <div>{formattedValue(data) !== '-' ? <YesIcon /> : <NoIcon />}</div>;
  // };

  // const renderVideos = (data) => {
  //     <div>{formattedValue(data) !== '-' ? <YesIcon /> : <NoIcon />}</div>;
  // };

  const renderEdit = (data) => {
    const item = data;
    let showColumn = false;
    if (item.source === "workstation" || item.source === "client_app") {
      showColumn = true;
    }
    return (
      <div>
        {showColumn && (
          <IconContainer>
            <EditIcon size="small" onClick={() => showUpdatePostModal(item)} />
          </IconContainer>
        )}
      </div>
    );
  };

  const renderDelete = (data) => {
    const item = data;
    return (
      <div>
        {item.is_deleted === false ? (
          <IconContainer>
            <DeleteIcon size="small" onClick={() => deletePost(item.id)} />
          </IconContainer>
        ) : null}
      </div>
    );
  };

  const renderIsPinned = (data) => (
    <div>
      {data.is_deleted === false && data.status === "published" ? (
        <IconContainer>
          <PushpinFilled
            style={{ fontSize: "22px" }}
            onClick={() => showPinPostModal(data)}
          />
        </IconContainer>
      ) : null}
    </div>
  );

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 10,
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      textWrap: "word-break",
      width: 250,
      render: (content) => <MentionText text={getTruncatedContent(content)} />,
    },
    {
      title: "Author",
      dataIndex: "author",
      key: "author",
      render: (author) => <div>{formattedValue(author.name)}</div>,
      width: 20,
    },

    // {
    //     title: 'Image',
    //     dataIndex: 'images',
    //     key: 'images',
    //     render: (images) => renderImage(images),
    //     align: 'center',
    // },
    // {
    //     title: 'Video',
    //     dataIndex: 'videos',
    //     key: 'videos',
    //     render: (videos) => renderVideos(videos),
    //     // formattedValue(videos) !== '-' ? <YesIcon /> : <NoIcon />,
    //     align: 'center',
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <div>{snakeToUpperCase(status)}</div>,
      align: "center",
      width: 40,
    },
    {
      title: "Moderation",
      dataIndex: "is_moderation_required",
      key: "is_moderation_required",
      render: (text, item) => (
        <IconContainer>
          {item.is_moderation_required === true ? (
            <IconTooltip content={item.moderation_reason} icon="redFlag" />
          ) : (
            <div>-</div>
          )}
        </IconContainer>
      ),
      align: "center",
      width: 20,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (value) => <p>{dateFormating(value)}</p>,
      width: 110,
      align: "center",
    },
    {
      title: "Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (value) => <p>{dateFormating(value)}</p>,
      width: 110,
      align: "center",
    },
    {
      title: "Schedule Publish At",
      dataIndex: "schedule_publish_at",
      key: "schedule_publish_at",
      render: (value) => <p>{dateFormating(value)}</p>,
      width: 110,
      align: "center",
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tags) => <>{createTags((tags || {}).level_one_tags || [])}</>,
      align: "center",
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 10,
      render: (item) => (
        <IconContainer>
          <NavLink to={`/community/post/${item}`}>
            <VisibilityIcon />
          </NavLink>
        </IconContainer>
      ),
    },
  ];

  if (!JSON.parse(filters.is_deleted || false)) {
    tableColumns.push({
      title: "Edit",
      dataIndex: "id",
      key: "id",
      render: (text, item) => renderEdit(item),
      align: "center",
      width: 10,
    });
    tableColumns.push({
      title: "Pin",
      dataIndex: "id",
      key: "id",
      render: (text, item) => renderIsPinned(item),
      align: "center",
      width: 10,
    });
    tableColumns.push({
      title: "Pin Position",
      dataIndex: "pinned_position",
      key: "pinned_position",
      render: (text, item) => <div>{formattedValue(text)}</div>,
      align: "center",
      width: 10,
    });
    tableColumns.push({
      title: "Delete",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 10,
      render: (text, item) => renderDelete(item),
    });
    tableColumns.push({
      title: "DeepLink",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 10,
      render: (id) => <CopyToClipboard content={generatePostDeepLink(id)} />,
    });
  }
  const createAuthorListOptions = (authorArr) => {
    const optionArray = [];
    // eslint-disable-next-line

    authorArr.map((author) => {
      optionArray.push(
        <Option key={author.id} value={author.id}>
          {author.display_name}
        </Option>
      );
      return 0;
    });

    return optionArray;
  };

  const updatePost = (media) => {
    const schedulePublishAt =
      updatePublishDate && currentStatusID === "draft"
        ? dateFormattingServerUTC(updatePublishDate)
        : null;
    const values = {
      id: currentUpdatePost.id,
      content: updateContent,
      status: currentStatusID === "" ? "published" : currentStatusID,
      images: media || {},
      level_one_tags: fetchObjectArrFromKeyArr(tagList, updateTagList, "id"),
      tagged_users: getTaggedUsers(updateContent),
      schedule_publish_at: schedulePublishAt,
    };

    setLoading(true);
    UpdatePostApi(values)
      .then((res) => {
        setLoading(false);
        let success = false;
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            handleUpdateModalCancel();
            getPostList(filters);
            swal("Yayy!", "Post Updated Sucessfully", "success");
          }
        }
        if (!success) {
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to Update post";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  const getPostFilters = () => {
    setLoading(true);
    GetPostFiltersApi()
      .then((res) => {
        let success = false;
        setLoading(false);
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            const apiData = (res.data || {}).data || {};
            setPostFilters({
              author_id: {
                key: "author_id",
                data: apiData.author_list,
              },
              id: {
                key: "id",
                data: apiData.id_list,
              },
            });
          }
        }
        if (!success) {
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to fetch data";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  const getTagList = () => {
    setLoading(true);
    GetTagListApi()
      .then((res) => {
        let success = false;
        setLoading(false);
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            const apiData = (res.data || {}).data || {};
            const levelOneTags = apiData.level_one_tags || [];
            setTagList(levelOneTags);
          }
        }
        if (!success) {
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to fetch tag list";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  const getAuthorList = () => {
    setLoading(true);
    GetAuthorListApi()
      .then((res) => {
        let success = false;
        setLoading(false);
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            const apiData = (res.data || {}).data || {};
            setAuthorList(apiData.result);
          }
        }
        if (!success) {
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to fetch data";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  useEffect(() => {
    getPostFilters();
    getAuthorList();
    getTagList();
  }, []);

  useEffect(() => {
    filters.page = currentPage;
    filters.page_limit = pageSize;
    getPostList(filters);
  }, [currentPage, pageSize]);

  const showCreatePostModal = () => {
    setShowCreateModal(true);
  };

  // implicit - arrow - linebreak;
  const handleCreatePostModalCancel = () => {
    setShowCreateModal(false);
    setContent("");
    setCurrentAuthorID("");
    setImageFile("");
    setErrors({});
    setCreateTagList([]);
    setPublishDate(null);
  };

  const createPost = (media) => {
    const params = {
      content: trim(content),
      author_id: currentAuthorID,
      buid: buID,
      status: currentStatusID === "" ? "published" : currentStatusID,
      images: media || {},
      level_one_tags: fetchObjectArrFromKeyArr(tagList, createTagList, "id"),
      tagged_users: getTaggedUsers(content),
    };

    if (publishDate && currentStatusID === "draft") {
      params.schedule_publish_at = dateFormattingServerUTC(publishDate);
    }

    setLoading(true);
    CreatePostApi(params)
      .then((res) => {
        let success = false;
        setLoading(false);
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            handleCreatePostModalCancel();
            getPostList(filters);
            swal("success!", "Successfully Create Post", "success");
          }
        }
        if (!success) {
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Post Creation Failed";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("OOPS!", errorMessage, "error");
      });
  };

  const validateFields = (values) => {
    const validationErrors = {};
    // Content
    if (size(trim(values.content)) < 1) {
      validationErrors.content = "Required Content";
    }

    // Author
    if (!values.id && !values.currentAuthorID) {
      validationErrors.currentAuthorID = "Required Author Name";
    }

    if (isValidImageUploadObject(values.uploadfile)) {
      if (values.uploadfile.size && values.uploadfile.size / 1048576 > 10) {
        validationErrors.uploadfile =
          "Please upload minimum file size less than 10mb";
      }
    }

    return validationErrors;
  };

  const handleCreatePostSubmit = (event) => {
    event.preventDefault();
    const uploadfile = imageFile;
    const values = {
      content: trim(content),
      currentAuthorID,
      uploadfile,
    };
    const validationErrors = validateFields(values);
    setErrors(validationErrors);
    if (isEmpty(validationErrors)) {
      if (isValidImageUploadObject(uploadfile)) {
        const createTimeNow = new Date();
        let keyPrefix = `${currentAuthorID}/post/photo/${createTimeNow.toISOString()}`;
        keyPrefix += "-"+ uploadfile.name;
        let reader = new FileReader();
        reader.readAsDataURL(uploadfile);
        reader.onload = function () {
          const base64img = reader.result.split(',')[1];
          const params = {  
            filename: keyPrefix,
            encodedimg: base64img,
          }
          GetUploadS3ImageApi(params)
            .then((res) => {
              if (res.status === 200) {
                if (res.data) {
                  console.log("success in file upload", res.data);
                  const imgS3Param = {
                    location: res.data.data.url,
                    key: keyPrefix,
                  }
                  const image = getImageWithBucketCreds({
                    data: imgS3Param,
                  });
                  createPost(image);
                }
              }
              else {
                console.log("error in file upload", ((res.data || {}).data || {}).error_desc);
              }
            })
            .catch((error) => {
              let errorMessage = "error in file upload";
              if (error.response) {
                if (error.response.status === 400) {
                  errorMessage = (error.response.data || {}).error_desc;
                }
              }
              console.log("error in file upload", errorMessage);
            });
        };
        reader.onerror = function (error) {
          console.log("Error in file reading: ", error);
        };
        // keyPrefix += "-";
        // const config = { ...s3Config, keyPrefix };
        // if (process.env.REACT_APP_AWS_ACL) {
        //   config.acl = process.env.REACT_APP_AWS_ACL;
        // }
        // RNS3.put(uploadfile, config)
        //   .progress((progress) => console.log("progress", progress))
        //   .then((response) => {
        //     if (response.status !== 201) {
        //       console.log("error in file upload", response);
        //     } else {
        //       const image = getImageWithBucketCreds({
        //         data: (response.body || {}).postResponse,
        //       });
        //       createPost(image);
        //     }
        //   });
      } else {
        createPost();
      }
    }
  };

  const handleFilterChange = (filters) => {
    getPostList(filters);
  };

  const handlePinModalClose = () => {
    setCurrentPinObject({});
  };

  const handleAuthorSelectChange = (value) => {
    setCurrentAuthorID(value);
  };

  const handleUpdateSubmit = (event) => {
    event.preventDefault();
    const uploadfile = imageFile;
    const values = {
      id: currentUpdatePost.id,
      content: trim(updateContent),
      status: currentStatusID === "" ? "published" : currentStatusID,
      uploadfile,
    };

    const validationUpdateErrors = validateFields(values);
    setErrors(
      isEmpty(validationUpdateErrors) ? {} : { ...validationUpdateErrors }
    );
    if (isEmpty(validationUpdateErrors)) {
      if (isValidImageUploadObject(uploadfile)) {
        const updateTimeNow = new Date();
        let keyPrefix = `${currentAuthorID}/post/photo/${updateTimeNow.toISOString()}`;
        keyPrefix += "-"+ uploadfile.name;
        let reader = new FileReader();
        reader.readAsDataURL(uploadfile);
        reader.onload = function () {
          const base64img = reader.result.split(',')[1];
          const params = {  
            filename: keyPrefix,
            encodedimg: base64img,
          }
          GetUploadS3ImageApi(params)
            .then((res) => {
              if (res.status === 200) {
                if (res.data) {
                  console.log("success in file upload", res.data);
                  const imgS3Param = {
                    location: res.data.data.url,
                    key: keyPrefix,
                  }
                  const image = getImageWithBucketCreds({
                    data: imgS3Param,
                  });
                  updatePost(image);
                }
              }
              else {
                console.log("error in file upload", ((res.data || {}).data || {}).error_desc);
              }
            })
            .catch((error) => {
              let errorMessage = "error in file upload";
              if (error.response) {
                if (error.response.status === 400) {
                  errorMessage = (error.response.data || {}).error_desc;
                }
              }
              console.log("error in file upload", errorMessage);
            });
        };
        reader.onerror = function (error) {
          console.log("Error in file reading: ", error);
        };

        // keyPrefix += "-";
        // const config = { ...s3Config, keyPrefix };
        // if (process.env.REACT_APP_AWS_ACL) {
        //   config.acl = process.env.REACT_APP_AWS_ACL;
        // }
        // RNS3.put(uploadfile, config)
        //   .progress((progress) => console.log("progress", progress))
        //   .then((response) => {
        //     if (response.status !== 201) {
        //       console.log("error in file upload", response);
        //     } else {
        // const image = getImageWithBucketCreds({
        //   data: (response.body || {}).postResponse,
        // });
        //       updatePost(image);
        //     }
        //   });
      } else {
        updatePost(uploadfile);
      }
    }
  };

  const handleStatusSelectChange = (value) => {
    setCurrentStatusID(value);
  };

  const IsDeleted = {
    key: "is_deleted",
    data: [
      {
        id: "true",
        title: "True",
        selected: false,
      },
      {
        id: "false",
        title: "False",
        selected: true,
      },
    ],
  };

  const IsPinned = {
    key: "is_pinned",
    data: [
      {
        id: "true",
        title: "True",
        selected: false,
      },
      {
        id: "false",
        title: "False",
        selected: true,
      },
    ],
  };

  const onFileChange = (event) => {
    if (!event) {
      setImageFile("");
      return;
    }
    const file = event.target?.files[0];
    setImageFile(file);
  };

  const createTagOptionList = () => {
    const optionsArray = [];
    tagList.map((item) => {
      optionsArray.push(
        <Option key={item.id} value={item.id}>
          {item.title}
        </Option>
      );
    });
    return optionsArray;
  };

  const handleTagChange = (key, mode) => {
    if (mode === "create") {
      setCreateTagList(key);
    } else if (mode === "update") {
      setUpdateTagList(key);
    }
  };

  return (
    <Grid container spacing={2}>
      <CommonLoader
        isLoading={loading}
        size="large"
        sentences={["please wait ..."]}
        type="pacman"
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <StyledButton primary onClick={() => showCreatePostModal()}>
          Add Post
        </StyledButton>
      </Grid>
      {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FilterButton onClick={() => setHideFilters(!hideFilters)}>
                    Filter Show&Hide
                </FilterButton>
            </Grid> */}
      <Grid
        container
        spacing={2}
        style={{
          paddingRight: "1rem",
          paddingLeft: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
          {/* {hideFilters && ( */}
          <div>
            <FilterTitleWrapper>Filters</FilterTitleWrapper>
            <CommonDropdown
              list={PostStatusList}
              title="Status"
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
            <CommonDropdown
              list={IsDeleted}
              title="Is Deleted"
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
            <CommonDropdown
              list={sortByList}
              title="Sort By"
              searchable={false}
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
            <CommonDropdown
              list={sortTypeList}
              title="Sort Type"
              searchable={false}
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
            <CommonDropdown
              list={isModerationRequiredList}
              title="Moderation Required"
              searchable={false}
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
            <CommonDatePicker
              title="From Date"
              type="inline"
              dateFormat="dd/MM/yyyy"
              filterKey="created_at_lte"
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
            <CommonDatePicker
              title="To Date"
              type="inline"
              dateFormat="dd/MM/yyyy"
              filterKey="created_at_gte"
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
            <CommonDatePicker
              title="Updated At LTE"
              type="inline"
              dateFormat="dd/MM/yyyy"
              filterKey="updated_at_lte"
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
            <CommonDatePicker
              title="Updated At GTE"
              type="inline"
              dateFormat="dd/MM/yyyy"
              filterKey="updated_at_gte"
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
            <CommonDropdown
              list={IsPinned}
              title="Is Pinned"
              searchable={false}
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
            {postFilters.author_id.data.length > 0 && (
              <CommonDropdown
                list={postFilters.author_id}
                title="Author"
                searchable
                onChange={handleFilterChange}
                filters={filters}
              />
            )}
            {postFilters.id.data.length > 0 && (
              <CommonDropdown
                list={postFilters.id}
                title="ID"
                searchable
                onChange={handleFilterChange}
                filters={filters}
              />
            )}
          </div>
          {/* )} */}
        </FilterGrid>
        <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
          <CommonTable
            rowKey="id"
            data={data}
            columns={tableColumns}
            pagination={false}
            scroll={{ x: "overflow" }}
          />
          <Paginator
            totalRecords={total}
            pageLimit={pageSize}
            pageNeighbours={2}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Grid>
      </Grid>
      <Grid>
        {showCreateModal && (
          <StyleModal
            title="Add Post"
            visible={showCreateModal}
            onOk={(e) => handleCreatePostSubmit(e)}
            onCancel={() => handleCreatePostModalCancel()}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={(e) => handleCreatePostSubmit(e)}
                loading={loading}
              >
                Submit
              </Button>,
            ]}
          >
            <LabelInput>Content</LabelInput>
            <MentionInput
              value={content}
              placeholder="Content"
              onChange={(e) => setContent(e.target.value)}
            />
            {errors.content && <ErrorText>{errors.content}</ErrorText>}
            <br />
            <LabelInput>Author </LabelInput>
            <Select
              style={{ width: "100%" }}
              value={currentAuthorID}
              onChange={(e) => handleAuthorSelectChange(e)}
            >
              {authorList && createAuthorListOptions(authorList)}
            </Select>
            {errors.currentAuthorID && (
              <ErrorText>{errors.currentAuthorID}</ErrorText>
            )}
            <LabelInput>Status</LabelInput>
            <Select
              value={currentStatusID}
              style={{ width: "100%" }}
              onChange={(e) => handleStatusSelectChange(e)}
            >
              {CreateStatusList && createStatusList(CreateStatusList.data)}
            </Select>
            {currentStatusID === "draft" && (
              <ScheduleField
                publishDate={publishDate}
                setPublishDate={(date) => setPublishDate(date)}
              />
            )}
            <LabelInput>Tags</LabelInput>
            <Select
              style={{ width: "100%" }}
              mode="multiple"
              allowClear
              defaultValue={createTagList}
              onChange={(e) => handleTagChange(e, "create")}
            >
              {tagList.length > 0 && createTagOptionList()}
            </Select>
            {errors.createTagList && (
              <ErrorText>{errors.createTagList}</ErrorText>
            )}
            <LabelInput>Upload Images, Videos or Documents</LabelInput>
            <Image value={imageFile} onChange={onFileChange} />
            {errors.uploadfile && <ErrorText>{errors.uploadfile}</ErrorText>}
          </StyleModal>
        )}
      </Grid>
      {/* end */}
      <Grid>
        {showUpdateModal && (
          <StyleModal
            title="Update Post"
            visible={showUpdateModal}
            onOk={(e) => handleUpdateSubmit(e)}
            onCancel={handleUpdateModalCancel}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={(e) => handleUpdateSubmit(e)}
                loading={loading}
              >
                Update
              </Button>,
            ]}
          >
            {currentUpdatePost.source === "workstation" && (
              <>
                <LabelInput>Content</LabelInput>
                <MentionInput
                  value={updateContent}
                  placeholder="Content"
                  onChange={(e) => setUpdateContent(e.target.value)}
                />
                {errors.content && <ErrorText>{errors.content}</ErrorText>}
              </>
            )}

            {(currentUpdatePost.status === "draft" ||
              currentUpdatePost.status === "review_pending") && (
              <>
                <LabelInput>Status </LabelInput>
                <Select
                  style={{ width: "100%" }}
                  value={currentStatusID}
                  onChange={handleStatusSelectChange}
                >
                  {createStatusList(UpdateStatusList.data)}
                </Select>
              </>
            )}

            {currentStatusID === "draft" && (
              <ScheduleField
                publishDate={updatePublishDate}
                setPublishDate={(date) => setUpdatePublishDate(date)}
              />
            )}

            <>
              <LabelInput>Tags</LabelInput>
              <Select
                style={{ width: "100%" }}
                mode="multiple"
                allowClear
                defaultValue={updateTagList}
                onChange={(e) => handleTagChange(e, "update")}
              >
                {tagList.length > 0 && createTagOptionList()}
              </Select>
              {errors.updateTagList && (
                <ErrorText>{errors.updateTagList}</ErrorText>
              )}
            </>

            {currentUpdatePost.source === "workstation" && (
              <>
                <LabelInput for="files" className="btn">
                  Update Images
                </LabelInput>
                <Image value={imageFile} onChange={onFileChange} />
                {errors.uploadfile && (
                  <ErrorText>{errors.uploadfile}</ErrorText>
                )}
              </>
            )}
          </StyleModal>
        )}
      </Grid>
      <Grid>
        {size(currentPinObject) > 0 && (
          <PinEntityModal
            entityObj={currentPinObject}
            entityType="post"
            onClose={handlePinModalClose}
            filters={filters}
            fetchData={(filters) => handleFilterChange(filters)}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default Post;
