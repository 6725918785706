import React from 'react';
import PropTypes from 'prop-types';
import {
    Img,
    ErrorTitleText,
    ErrorDescriptionText,
    ErrorFooterText,
} from './styles';

const ErrorComponent = (props) => {
    const { description, title, footer } = props;

    return (
        <>
            <Img src="https://arwealth-tinyurl-assets.s3.ap-south-1.amazonaws.com/pinpng.com-error-png-1594430.png" />
            {title.length > 0 && <ErrorTitleText>{title}</ErrorTitleText>}
            {description.length > 0 && (
                <ErrorDescriptionText>{description}</ErrorDescriptionText>
            )}
            {footer.length > 0 && <ErrorFooterText>{footer}</ErrorFooterText>}
        </>
    );
};

ErrorComponent.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    footer: PropTypes.string,
};

ErrorComponent.defaultProps = {
    title: '',
    description: '',
    footer: '',
};

export default ErrorComponent;
