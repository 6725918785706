
import {
    GetReportListApiURL,
    UpdateReportApiURL,
    DeleteReportApiURL,
    GetReportDetialApiURL,
    GetReportFiltersApiURL,
    BlockUserApiURL,
} from './apiConfig';
import axios from './instance';

/* eslint-disable implicit-arrow-linebreak */
export const GetReportListApi = (params) =>
    axios.get(GetReportListApiURL, { params });

export const UpdateReportApi = (params) =>
    axios.put(UpdateReportApiURL, params);

export const DeleteReportApi = (params) =>
    axios.delete(DeleteReportApiURL, { data: { ...params } });

export const GetReportDetialApi = (params) =>
    axios.get(GetReportDetialApiURL, { params });

export const GetReportFiltersApi = () => axios.get(GetReportFiltersApiURL);

export const BlockUserApi = (params) => axios.put(BlockUserApiURL, params);
