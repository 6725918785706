/* eslint-disable */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {isEmpty} from 'lodash';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { GetPollDetialApi } from '../../api/pollList';
import CommonLoader from '../Common/Loader';
import { Wrapper } from './styles';
import { getFormattedShortDate } from '../../util/helper';
import PostDetailFooter from '../PostDetailFooter';
import PostDetailHeader from '../PostDetailHeader';
import PollDetailBody from '../PollDetailBody';


const PostDetailWrapper = styled.div`
    width: 100%;
    margin: auto;
    border-radius: 0.3rem;
    padding-bottom: 1rem;
    background-color: ${(props) => props.theme.dark};
    border: 1px solid #e0e0e0;
`;

const PollDetail = (props) => {
    const { pageProps } = props;
    const { id: currentPollId } = ((pageProps || {}).match || {}).params || {};
    const [loading, setLoading] = useState(false);
    const [pollData, setPollData] = useState({
        authorName: '',
        profilePicture: '',
        content: '',
        images: '',
        videos: '',
        likeCount: '',
        commentCount: '',
        createdAt: '',
        bookMarkCount: '',
        postID: '',
        pollOption: '',
        poll: '',
        isModerationRequired: false,
        moderationReason: '',
    });

    const postType = 'Post';

    const fetchGetPostDetail = () => {
        setLoading(true);
        setLoading(true);
        const params = {
            id: currentPollId,
        };

        GetPollDetialApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setPollData({
                            postID: apiData.id,
                            authorName: (apiData.author || {}).name,
                            profilePicture: (apiData.author || {})
                                .profile_picture,
                            content: apiData.content,
                            images: null,
                            likeCount: apiData.like_count,
                            commentCount: apiData.comment_count,
                            createdAt: getFormattedShortDate(
                                apiData.created_at
                            ),
                            bookMarkCount: apiData.bookmark_count,
                            pollOption: apiData.poll.options,
                            poll: apiData.poll,
                            isModerationRequired: apiData.is_moderation_required,
                            moderationReason: apiData.moderation_reason,
                        });
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    errorMessage = (error.response.data || {}).error_desc;
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    useEffect(() => {
        fetchGetPostDetail();
    }, []);

    return (
        <Wrapper>
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />
            <PostDetailWrapper>
                <PostDetailHeader
                    postID={pollData.postID}
                    postType={postType}
                    authorName={pollData.authorName}
                    authorImageURL={pollData.profilePicture}
                    createdAt={pollData.createdAt}
                />
                <PollDetailBody
                    option={pollData.pollOption}
                    content={pollData.content}
                    poll={pollData.poll}
                    loading={loading && isEmpty(pollData)}
                />
                <PostDetailFooter
                    postID={pollData.postID}
                    postType={postType}
                    likeCount={pollData.likeCount}
                    bookMarkCount={pollData.bookMarkCount}
                    commentCount={pollData.commentCount}
                    loading={loading}
                    fetchGetPostDetail={fetchGetPostDetail}
                    isModerationRequired={pollData.isModerationRequired}
                    moderationReason={pollData.moderationReason}
                />
            </PostDetailWrapper>
        </Wrapper>
    );
};

PollDetail.propTypes = {
    pageProps: PropTypes.shape({}).isRequired,
};

export default PollDetail;
