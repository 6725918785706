/* eslint-disable */
import axios from 'axios';
import size from 'lodash/size';
import {
    getRefreshToken,
    setToken,
    clearAdmin,
    getAccessToken,
    clearAccessRefreshToken,
} from '../util/storage';
import { RefreshTokenApiURL } from './apiConfig';

// create an axios instance with default headers and other 
//necessary configs which needs
// to be attached inside headers
const instance = axios.create({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});

// use Interceptors request for preprocessing a request.
instance.interceptors.request.use(
    (config) => {
        const token = getAccessToken();
        if (token && token.length) {
            // eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// use Interceptors response for postprocessing a request (generally on error handling).
instance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        const refreshToken = getRefreshToken();
        if (!originalRequest.retry && size(refreshToken)) {
            if (((error || {}).response || {}).status === 401) {
                originalRequest.retry = true;

                if (size(refresh_token) < 1) {
                    clearAccessRefreshToken();
                    clearAdmin();
                    return Promise.reject(error);
                } else {
                    axios.post(RefreshTokenApiURL, {
                        last_generated_refresh_token: `${refreshToken}`,
                    }).then(res => {
                        let data = res.data;
                        if (data.success && res.status === 200) {
                            let { data } = res;
                            if (data.data) {
                                data = data.data;
                            }
                            setToken(data);
                            originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
                            return instance(originalRequest);
                        }else {
                            clearAccessRefreshToken();
                            clearAdmin();
                            window.location.reload();
s                        }
                    }).catch(() => {
                        clearAccessRefreshToken();
                        clearAdmin();
                        window.location.reload();
                    })
                }
            }
        }
        return Promise.reject(error);
    }
);

export default instance;
