import styled from 'styled-components';

export const Img = styled.img`
    height: 30px;
    width: 30px;
    border-radius: 15px;
    object-fit: cover;
    background-image: url('https://d35u5jwboagvu7.cloudfront.net/default-profile-image.svg');
    '&focused': {
        background-color: '#d4ecff',
    },
`;

export const Suggestion = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const Text = styled.label`
    font-weight: 600;
`;

export const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    padding: 5px 11px;
`;
