import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import styled from 'styled-components';

export const Button = styled.div`
    background-color: Transparent;
    color: #fff;
    font-size: 2rem;
    cursor: pointer;
    margin: 0 1rem;
    &:hover {
        color: ${(props) => props.theme.pinklogo};
    }
`;

export const LightThemeIcon = styled(Brightness7Icon)`
    font-size: '25px';
    margin-top: '15px';
    margin-left: '10px';
`;

export const DarkThemeIcon = styled(Brightness4Icon)`
    font-size: '25px';
    margin-top: '15px';
    margin-left: '10px';
`;
