/* eslint-disable */
import { forEach, head, isEmpty, size } from "lodash";

export const getImageWithBucketCreds = ({ data }) => {
  return {
    bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    photo: [
      {
        url: data.location,
        key: data.key,
        presigned_url: `${process.env.REACT_APP_CLOUDFRONT_URL}/${data.key}`,
      },
    ],
  };
};

export const getImageWithBannerCreds = ({ data }) => {
  return {
    bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    url: data.location,
    key: data.key,
    pre_signed_url: `${process.env.REACT_APP_CLOUDFRONT_URL}/${data.key}`,
  };
};

export const getCalculatorsWithCreds = ({ data }) => {
  return {
    url: data.location,
    key: data.key,
    bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    pre_signed_url: `${process.env.REACT_APP_CLOUDFRONT_URL}/${data.key}`,
  };
};

export const getImageUrlKey = (image, authorId) => {
  if (image[authorId] && image[authorId].photo) {
    image = image[authorId].photo;
  }
  if (size(image)) {
    image = image[0];
  }
  return size((image || {}).presigned_url)
    ? (image || {}).presigned_url
    : (image || {}).url && (image || {}).key;
};

export const getUserAvatar = (profile) => {
  if (!profile) return "";
  if (isEmpty(profile)) {
    return "";
  }
  if (size(getUserProfilePicture(profile))) {
    return getUserProfilePicture(profile);
  }
  if (size(profile.presigned_url)) {
    return profile.presigned_url;
  }
  return profile.url;
};

const getUrl = (profilePic) => {
  if (!profilePic) return "";
  if (isEmpty(profilePic)) {
    return "";
  }
  if (size(profilePic.presigned_url)) {
    return profilePic.presigned_url;
  }
  if (size(profilePic.pre_signed_url)) {
    return profilePic.pre_signed_url;
  }
  return profilePic.url;
};

export const getImageUrl = (image) => {
  if (!image) return "";
  if (isEmpty(image)) {
    return "";
  }
  let photo = image.photo ? image.photo : image;
  if (size(getUrl(photo))) {
    return getUrl(photo);
  }
  photo = head(photo);

  if (size(photo.presigned_url)) {
    return photo.presigned_url;
  }
  return photo.url;
};

export const getBannerImageUrl = (img) => {
  const image = img?.en ? img?.en : img?.english ? img?.english : img;
  if (!image) return "";
  if (isEmpty(image)) {
    return "";
  }
  let photo = image.photo ? image.photo : image;
  if (size(getUrl(photo))) {
    return getUrl(photo);
  }
  photo = head(photo);

  if (size(photo.pre_signed_url)) {
    return photo.pre_signed_url;
  }
  return photo.url;
};

export const getUserProfilePicture = (profilePic = {}) => {
  if (!profilePic) return "";
  if (isEmpty(profilePic)) {
    return "";
  }
  if (profilePic && profilePic.photo && size(profilePic.photo)) {
    profilePic = head(profilePic.photo);
    return getUrl(profilePic);
  } else if (profilePic.url || profilePic.presigned_url) {
    return getUrl(profilePic);
  }
  return "";
};

export const getBannerImage = (bannerImage = {}) => {
  if (!bannerImage) return "";
  if (isEmpty(bannerImage)) {
    return "";
  }
  if (bannerImage && bannerImage.photo && size(bannerImage.photo)) {
    bannerImage = head(bannerImage.photo);
    return getUrl(bannerImage);
  } else if (bannerImage.url || bannerImage.presigned_url) {
    return getUrl(bannerImage);
  }
  return "";
};

export const s3Config = {
  bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
  region: "ap-south-1",
  accessKey: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
  secretKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
  successActionStatus: 201,
};

export const getRewardImageWithBucketCreds = ({ data }) => {
  return {
    bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    url: data.location,
    key: data.key,
    presigned_url: `${process.env.REACT_APP_CLOUDFRONT_URL}/${data.key}`,
  };
};
