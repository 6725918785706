import styled from 'styled-components';

export const HeaderStyled = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: ${(props) => props.theme.black};
    height: 70px;
    max-height: 70px;
    box-shadow: 0px 0px 14px 0px black;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #fff;
`;

export const NavAction = styled.a`
    display: flex;
    padding: 10px 5px;
    cursor: pointer;
    img {
        pointer-events: none;
    }
`;

export const StyledImg = styled.img`
    margin-left: 1.5rem;
`;

export const NavbarRightItems = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    flex: 1 1 100%;
    margin-left: 5px;
`;
