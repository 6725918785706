import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

export const FilterGrid = styled(Grid)`
    background-color: ${(props) => props.theme.background};
    margin-top: 0.5rem;
    margin-bottom: 4.5rem;

    @media (max-width: 767px) {
        margin-bottom: 1rem;
    }
`;
export const FilterTitleWrapper = styled.div`
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center !important;
    margin: 1rem 0 2rem 0;
    font-family: 'Titillium Web';
    border-bottom: 1px dashed ${(props) => props.theme.text};
    padding-bottom: 2rem;
    color: ${(props) => props.theme.text};
    @media (max-width: 1024px) {
        font-size: 1.4rem;
    }
`;

export const IconContainer = styled.div`
    cursor: pointer
`;
