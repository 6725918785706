/* eslint-disable implicit-arrow-linebreak */
import {
    GetUserMandateListApiURL,
    GetUserMandateFilterApiURL,
    DeleteUserMandateApiURL,
} from './apiConfig';
import axios from './instance';

export const GetUserMandateListApi = (params) =>
    axios.get(GetUserMandateListApiURL, { params });

export const DeleteUserMandateApi = (params) =>
    axios.delete(DeleteUserMandateApiURL, { data: { ...params } });

export const GetUserMandateFilterApi = () =>
    axios.get(GetUserMandateFilterApiURL);
