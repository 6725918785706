import React from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import LogoutButton from './sytles';
import { logout } from '../../../actions/auth';

const Logout = () => {
    const dispatch = useDispatch();

    const logoutUser = () => {
        dispatch(logout());
    };

    return (
        <>
            <Tooltip placement="bottomRight" title="Logout">
                <LogoutButton onClick={logoutUser}>Logout</LogoutButton>
            </Tooltip>
        </>
    );
};

export default Logout;
