import React, { useState, useEffect, Suspense } from "react";
import PropTypes from "prop-types";
import { Switch, useHistory, BrowserRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import { checkloggedin } from "../util/helper";
import { setProfile, clearProfile } from "../actions/auth";
import { isEmpty } from "lodash";
import {
  AuditEventPage,
  PostPage,
  LoginPage,
  PostDetailPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  QuestionsPage,
  QuestionDetailPage,
  PollPage,
  PollDetailPage,
  BlogPage,
  AppUsersPage,
  UserHoldingPage,
  UserHoldingDetailPage,
  CalculatorsPage,
  ModelPortfoliosPage,
  ReportedPage,
  ReportedDetailPage,
  ApiAnalyticsPage,
  ApplicationVersionHistoryPage,
  OrdersPage,
  ModelPortfoliosDetailPage,
  UserMandatePage,
  RewardPage,
} from "./components-path";
import CommonLoader from "../component/Common/Loader";
import BlogDetailPage from "../page/BlogDetailPage";
import EventPage from "../page/EventPage";
import BannerPage from "../page/BannerPage";
import EventDetailPage from "../page/EventDetailPage";
import GeneralUpdatePage from "../page/GeneralUpdatePage";
import SipUpdatePage from "../page/SipUpdatePage";
import ReferralPage from "../page/ReferralPage";
import FixedDepositPage from "../page/FixedDepositPage";

const AppRoutes = (props) => {
  const { appProps } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, status, isLoading } = auth;

  useEffect(() => {
    setLoading(true);
    checkloggedin((res) => {
      if (res.success) {
        dispatch(setProfile(res.data.data ? res.data.data : res.data));
      } else {
        dispatch(clearProfile());
        history.push("/");
      }
      setLoading(false);
      setIsLoggedIn(res.success);
    });
  }, []);

  useEffect(() => {
    if (!isAuthenticated && isEmpty(auth.admin.id)) {
      setIsLoggedIn(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (status === "profileCleared") {
      setIsLoggedIn(false);
      history.push("/");
    }
  }, [status]);

  if (loading) {
    return (
      <CommonLoader
        isLoading={true}
        size="large"
        sentences={["please wait ..."]}
        type="pacman"
      />
    );
  }

  return (
    <Suspense
      fallback={
        <CommonLoader
          isLoading={isLoading}
          size="large"
          sentences={["please wait ..."]}
          type="pacman"
        />
      }
    >
      <BrowserRouter>
        <CommonLoader
          isLoading={isLoading}
          size="large"
          sentences={["please wait ..."]}
          type="pacman"
        />
        <Switch>
          {/* <PublicRoute
                    exact
                    path={["/compliance/:id/:action"]}
                    component={Compliance}
                />
                <PublicRoute
                    exact
                    path={["/:type/compliance/:client/:id/:action"]}
                    component={TransactionCompliance}
                /> */}
          <PublicRoute
            exact
            restricted={isAuthenticated || isLoggedIn}
            path={["/"]}
            redirect="/community/post"
            component={LoginPage}
          />
          <PublicRoute
            exact
            path="/forgot-password"
            component={ForgotPasswordPage}
          />
          <PublicRoute
            exact
            path="/reset-password"
            component={ResetPasswordPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/audit-event"
            component={AuditEventPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/community/post"
            component={PostPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/community/reward"
            component={RewardPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/community/post/:id"
            component={PostDetailPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/community/question"
            component={QuestionsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/community/question/:id"
            component={QuestionDetailPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/community/poll"
            component={PollPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/community/poll/:id"
            component={PollDetailPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/community/blog"
            component={BlogPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/community/blog/:id"
            component={BlogDetailPage}
          />
           <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/community/referral"
            component={ReferralPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/users/app-user"
            component={AppUsersPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/investment/user-holding"
            component={UserHoldingPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/investment/user-holding/:id"
            component={UserHoldingDetailPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/investment/model-portfolio"
            component={ModelPortfoliosPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/investment/model-portfolio/:id"
            component={ModelPortfoliosDetailPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/investment/order"
            component={OrdersPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/calculator"
            component={CalculatorsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/community/report"
            component={ReportedPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/community/reported/:id"
            component={ReportedDetailPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/analytics/api"
            component={ApiAnalyticsPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/application/application-version-history"
            component={ApplicationVersionHistoryPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/event"
            component={EventPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/banner"
            component={BannerPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/event/:id"
            component={EventDetailPage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/investment/user-mandate"
            component={UserMandatePage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/updates/general"
            component={GeneralUpdatePage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/updates/sip"
            component={SipUpdatePage}
          />
          <PrivateRoute
            isAuthenticated={isAuthenticated || isLoggedIn}
            exact
            path="/fd"
            component={FixedDepositPage}
          />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

AppRoutes.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  appProps: PropTypes.object.isRequired,
};

export default AppRoutes;
