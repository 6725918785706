import React from 'react';
import { withRouter } from 'react-router-dom';
import Post from '../../component/Post';
import { Wrapper } from './styles';

const PostPage = () => (
    <Wrapper>
        <Post />
    </Wrapper>
);

export default withRouter(PostPage);
