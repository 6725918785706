/* eslint-disable */
/* eslint-disable comma-dangle */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import swal from 'sweetalert';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CommonTable from '../Common/Table';
import Paginator from '../Common/Paginator';
import {
    formattedValue,
    snakeToUpperCase,
    dateFormating,
    fetchObjectArrFromKeyArr,
} from '../../util/helper';
import CommonDatePicker from '../Common/DatePicker';
import CommonDropdown from '../Common/Dropdown';
import {
    FilterGrid,
    FilterTitleWrapper,
    StyledTag,
    IconContainer,
    LabelInput,
} from './styles';
import { GetBlogListApi } from '../../api/blogList';
import { DeleteBlogApi } from '../../api/getBlogDetail';
import CommonLoader from '../Common/Loader';
import { generatePostDeepLink } from '../../util/deeplink';
import CopyToClipboard from '../Common/CopyToClipboard';
import IconTooltip from '../Common/IconTooltip';
import { PushpinFilled } from '@ant-design/icons';
import { PinEntityModal } from '../PinEntityModal';
import { size } from 'lodash';
import { GetTagListApi } from '../../api/postList';
import { Modal, Button, Select } from 'antd';
import { UpdatePostApi } from '../../api/getPostDetail';

const StyleModal = styled(Modal)`
    .ant-modal-header {
        background: ${(props) => props.theme.dark};
        color: ${(props) => props.theme.dark};
    }

    .ant-modal-body {
        background: ${(props) => props.theme.dark};
    }

    .ant-modal-title {
        color: ${(props) => props.theme.text};
    }
    .ant-modal-footer {
        background: ${(props) => props.theme.dark};
    }
    .ant-modal-close-icon {
        color: ${(props) => props.theme.text};
    }

    .ant-modal .sc-AzgDb .jcGGxa {
        padding: 0px;
    }
`;

const Blog = () => {
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(1);
    const [data, setData] = useState([]);
    const [currentPinObject, setCurrentPinObject] = useState({});
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [currentUpdateBlog, setCurrentUpdateBlog] = useState({});
    const [tagList, setTagList] = useState([]);
    const [updateTagList, setUpdateTagList] = useState([]);

    const [filters, setFilters] = useState({
        page: currentPage,
        page_limit: pageSize,
        sort_by: 'updated_at',
        sort_type: 'desc',
        is_deleted: 'false',
    });

    const sortTypeList = {
        key: 'sort_type',
        data: [
            {
                id: 'asc',
                title: 'Asc',
                selected: false,
            },
            {
                id: 'desc',
                title: 'Desc',
                selected: true,
            },
        ],
    };

    const sortByList = {
        key: 'sort_by',
        data: [
            {
                id: 'id',
                title: 'id',
                selected: false,
            },
            {
                id: 'created_at',
                title: 'created_at',
                selected: false,
            },
            {
                id: 'updated_at',
                title: 'updated_at',
                selected: true,
            },
            {
                id: 'content',
                title: 'content',
                selected: false,
            },
            {
                id: 'status',
                title: 'status',
                selected: false,
            },
        ],
    };

    const getBlogList = (params) => {
        setLoading(true);
        GetBlogListApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setData(apiData.results);
                        setCurrentPage(apiData.page);
                        setPageSize(apiData.page_limit);
                        setTotal(apiData.total_count);
                        setFilters(params);
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const getTagList = () => {
        setLoading(true);
        GetTagListApi()
            .then((res) => {
                let success = false;
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        const levelOneTags = apiData.level_one_tags || [];
                        setTagList(levelOneTags);
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch tag list';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const createTags = (tags = []) => {
        const tagArray = [];
        if (tags != null) {
            tags.map((tag) => {
                tagArray.push(<StyledTag key={tag.id}>{tag.title}</StyledTag>);
                return 0;
            });
        }
        return tagArray;
    };

    const showPinPostModal = (postObj) => {
        setCurrentPinObject(postObj);
    };

    const showUpdateBlogModal = (blogObj) => {
        setCurrentUpdateBlog(blogObj);
        setUpdateTagList(
            ((blogObj.tags || {}).level_one_tags || []).map((item) => item.id)
        );
        setShowUpdateModal(true);
    };

    const handleUpdateModalCancel = () => {
        setShowUpdateModal(false);
        setCurrentUpdateBlog({});
        setUpdateTagList([]);
    };

    const deleteBlog = (id) => {
        swal({
            title: 'Are you sure you want to delete this Blog ?',
            text: '',
            icon: 'warning',
            buttons: ['No', 'Yes'],
            dangerMode: true,
        }).then((success) => {
            if (success) {
                setLoading(true);
                const deleteParams = {
                    post_id: id,
                };
                DeleteBlogApi(deleteParams)
                    .then((res) => {
                        let success = false;
                        setLoading(false);
                        if (res.status === 200) {
                            if ((res.data || {}).success) {
                                success = true;
                                getBlogList(filters);
                                swal(
                                    'success!',
                                    'Successfully Deleted Blog',
                                    'success'
                                );
                            }
                        }
                        if (!success) {
                            setLoading(false);
                            swal(
                                'Oops!',
                                ((res.data || {}).data || {}).error_desc,
                                'error'
                            );
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        let errorMessage = 'Failed to delete blog';
                        if (error.response) {
                            if (error.response.status === 400) {
                                errorMessage = (error.response.data || {})
                                    .error_desc;
                            }
                        }
                        swal('Oops!', errorMessage, 'error');
                    });
            }
        });
    };

    const renderIsPinned = (data) => (
        <div>
            {data.is_deleted === false && data.status === 'published' ? (
                <IconContainer>
                    <PushpinFilled
                        style={{ fontSize: '22px' }}
                        onClick={() => showPinPostModal(data)}
                    />
                </IconContainer>
            ) : null}
        </div>
    );

    const renderEdit = (data) => {
        const item = data;
        return (
            <div>
                {item.is_deleted === false ? (
                    <IconContainer>
                        <EditIcon onClick={() => showUpdateBlogModal(item)} />
                    </IconContainer>
                ) : null}
            </div>
        );
    };

    const tableColumns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 10,
        },
        {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            render: (author) => <div>{formattedValue(author.name)}</div>,
            width: 50,
            align: 'center',
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            textWrap: 'word-break',
            width: 180,
        },
        // {
        //     title: 'Image',
        //     dataIndex: 'images',
        //     key: 'images',
        //     render: (images) => (formattedValue(images) !== '-' ? <YesIcon /> : <NoIcon />),
        //     align: 'center',
        // },
        // {
        //     title: 'Video',
        //     dataIndex: 'videos',
        //     key: 'videos',
        //     render: (videos) => (formattedValue(videos) !== '-' ? <YesIcon /> : <NoIcon />),
        //     align: 'center',
        // },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <div>{snakeToUpperCase(status)}</div>,
            align: 'center',
        },
        {
            title: 'Moderation',
            dataIndex: 'is_moderation_required',
            key: 'is_moderation_required',
            render: (text, item) => (
                <IconContainer>
                    {item.is_moderation_required === true ? (
                        <IconTooltip
                            content={item.moderation_reason}
                            icon="redFlag"
                        />
                    ) : (
                        <div>-</div>
                    )}
                </IconContainer>
            ),
            align: 'center',
            width: 20,
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value) => <p>{dateFormating(value)}</p>,
            width: 110,
            align: 'center',
        },
        {
            title: 'Last Updated At',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (value) => <p>{dateFormating(value)}</p>,
            width: 110,
            align: 'center',
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: (tags) => (
                <>{createTags((tags || {}).level_one_tags || [])}</>
            ),
            align: 'center',
        },
        {
            title: 'View',
            dataIndex: 'blog',
            key: 'blog',
            align: 'center',
            render: (item) => (
                <IconContainer>
                    <NavLink to={`/community/blog/${item.id}`}>
                        <VisibilityIcon />
                    </NavLink>
                </IconContainer>
            ),
        },
    ];

    if (!JSON.parse(filters.is_deleted || false)) {
        tableColumns.push({
            title: 'Edit',
            dataIndex: 'id',
            key: 'id',
            render: (text, item) => renderEdit(item),
            align: 'center',
            width: 10,
        });
        tableColumns.push({
            title: 'Pin',
            dataIndex: 'id',
            key: 'id',
            render: (text, item) => renderIsPinned(item),
            align: 'center',
            width: 10,
        });
        tableColumns.push({
            title: 'Pin Position',
            dataIndex: 'pinned_position',
            key: 'pinned_position',
            render: (text, item) => <div>{formattedValue(text)}</div>,
            align: 'center',
            width: 10,
        });
        tableColumns.push({
            title: 'Delete',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            render: (text, item) =>
                item.is_deleted === false ? (
                    <IconContainer>
                        <DeleteIcon onClick={() => deleteBlog(text)} />
                    </IconContainer>
                ) : null,
        });
        tableColumns.push({
            title: 'DeepLink',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 10,
            render: (id) => (
                <CopyToClipboard content={generatePostDeepLink(id)} />
            ),
        });
    }

    const IsDeleted = {
        key: 'is_deleted',
        data: [
            {
                id: 'true',
                title: 'True',
                selected: false,
            },
            {
                id: 'false',
                title: 'False',
                selected: true,
            },
        ],
    };

    const StatusList = {
        key: 'status',
        data: [
            {
                id: 'published',
                title: 'Published',
                selected: true,
            },
            {
                id: 'draft',
                title: 'Draft',
                selected: false,
            },
        ],
    };

    const isModerationRequiredList = {
        key: 'is_moderation_required',
        data: [
            {
                id: true,
                title: 'True',
                selected: false,
            },
            {
                id: false,
                title: 'False',
                selected: false,
            },
            {
                id: undefined,
                title: 'None',
                selected: false,
            },
        ],
    };

    const IsPinned = {
        key: 'is_pinned',
        data: [
            {
                id: 'true',
                title: 'True',
                selected: false,
            },
            {
                id: 'false',
                title: 'False',
                selected: true,
            },
        ],
    };

    const handleFilterChange = (filters) => {
        getBlogList(filters);
    };

    const handlePinModalClose = () => {
        setCurrentPinObject({});
    };

    useEffect(() => {
        filters.page = currentPage;
        filters.page_limit = pageSize;
        getBlogList(filters);
    }, [currentPage, pageSize]);

    useEffect(() => {
        getTagList();
    }, []);

    const createTagOptionList = () => {
        const optionsArray = [];
        tagList.map((item) => {
            optionsArray.push(
                <Option key={item.id} value={item.id}>
                    {item.title}
                </Option>
            );
        });
        return optionsArray;
    };

    const handleTagChange = (key, mode) => {
        if (mode === 'update') {
            setUpdateTagList(key);
        }
    };

    const handleUpdateSubmit = (event) => {
        event.preventDefault();
        const params = {
            id: currentUpdateBlog.id,
            level_one_tags: fetchObjectArrFromKeyArr(
                tagList,
                updateTagList,
                'id'
            ),
        };
        setLoading(true);
        UpdatePostApi(params)
            .then((res) => {
                setLoading(false);
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        handleUpdateModalCancel();
                        getBlogList(filters);
                        swal('Yayy!', 'Blog Updated Sucessfully', 'success');
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to Update blog';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    return (
        <Grid container spacing={2}>
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} />
            <Grid
                container
                spacing={2}
                style={{
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                    paddingBottom: '1rem',
                }}
            >
                <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
                    <FilterTitleWrapper>Filters</FilterTitleWrapper>
                    <CommonDropdown
                        list={StatusList}
                        title="Status"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={IsDeleted}
                        title="Is Deleted"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={sortByList}
                        title="Sort By"
                        currentAuthorID
                        searchable={false}
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={sortTypeList}
                        title="Sort Type"
                        searchable={false}
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={isModerationRequiredList}
                        title="Moderation Required"
                        searchable={false}
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={IsPinned}
                        title="Is Pinned"
                        searchable={false}
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Created At LTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="created_at_lte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Created At GTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="created_at_gte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Updated At LTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="updated_at_lte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Updated At GTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="updated_at_gte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                </FilterGrid>
                <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                    <CommonTable
                        data={data}
                        columns={tableColumns}
                        pagination={false}
                        scroll={{ x: 'overflow' }}
                    />
                    <Paginator
                        totalRecords={total}
                        pageLimit={pageSize}
                        pageNeighbours={2}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </Grid>
                <Grid>
                    {showUpdateModal && (
                        <StyleModal
                            title="Update Blog"
                            visible={showUpdateModal}
                            onOk={(e) => handleUpdateSubmit(e)}
                            onCancel={handleUpdateModalCancel}
                            footer={[
                                <Button
                                    key="submit"
                                    type="primary"
                                    onClick={(e) => handleUpdateSubmit(e)}
                                    loading={loading}
                                >
                                    Update
                                </Button>,
                            ]}
                        >
                            <LabelInput>Tags</LabelInput>
                            <Select
                                style={{ width: '100%' }}
                                mode="multiple"
                                allowClear
                                defaultValue={updateTagList}
                                onChange={(e) => handleTagChange(e, 'update')}
                            >
                                {tagList.length > 0 && createTagOptionList()}
                            </Select>
                        </StyleModal>
                    )}
                </Grid>
                <Grid>
                    {size(currentPinObject) > 0 && (
                        <PinEntityModal
                            entityObj={currentPinObject}
                            entityType="post"
                            onClose={handlePinModalClose}
                            filters={filters}
                            fetchData={(filters) => handleFilterChange(filters)}
                        />
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
export default Blog;
