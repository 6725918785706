import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import reducers from '../reducers';
import RootSagas from '../sagas';

const sagaMiddleware = createSagaMiddleware();

function getMiddleware() {
    if (process.env.NODE_ENV === 'development') {
        return applyMiddleware(logger, sagaMiddleware);
    }
    return applyMiddleware(sagaMiddleware);
}

const configureStore = (preloadedState = {}) => {
    const store = createStore(reducers, preloadedState, getMiddleware());
    if (module.hot) {
        module.hot.accept(() => {
            // eslint-disable-next-line global-require
            store.replaceReducer(require('../reducers').default);
        });
    }
    sagaMiddleware.run(RootSagas);
    return store;
};
const store = configureStore();
export default store;
