import React from 'react';
import { withRouter } from 'react-router-dom';
import Login from '../../component/Login';
import { Wrapper } from './styles';

const LoginPage = () => (
    <>
        <Wrapper>
            <Login />
        </Wrapper>
    </>
);
export default withRouter(LoginPage);
