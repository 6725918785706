/* eslint-disable comma-dangle */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import swal from 'sweetalert';

import isEmpty from 'lodash/isEmpty';
import { GetPostDetailApi } from '../../api/getPostDetail';
import CommonLoader from '../Common/Loader';
import { Wrapper } from './styles';
import { getFormattedShortDate } from '../../util/helper';
import PostDetailFooter from '../PostDetailFooter';
import PostDetailHeader from '../PostDetailHeader';
import PostDetailBody from '../PostDetailBody';
import { getImageUrl } from '../../util/custom';

const PostDetailWrapper = styled.div`
    width: 100%;
    margin: auto;
    border-radius: 0.3rem;
    padding-bottom: 1rem;
    background-color: ${(props) => props.theme.dark};
    border: 1px solid #e0e0e0;
`;

const PostDetail = (props) => {
    const { pageProps } = props;
    const { id: currentPostID } = ((pageProps || {}).match || {}).params || {};
    const [loading, setLoading] = useState(false);
    const [postData, setPostData] = useState({
        authorName: '',
        profilePicture: '',
        content: '',
        images: '',
        videos: '',
        likeCount: '',
        commentCount: '',
        createdAt: '',
        bookMarkCount: '',
        postID: '',
        buid: '',
        isModerationRequired: false,
        moderationReason: '',
    });

    const postType = 'Post';

    const fetchGetPostDetail = () => {
        setLoading(true);
        const params = {
            id: currentPostID,
        };

        GetPostDetailApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setPostData({
                            postID: apiData.id,
                            authorName: (apiData.author || {}).name,
                            profilePicture:
                                (apiData.author || {}).profile_picture || {},
                            content: apiData.content,
                            images: getImageUrl(apiData.images),
                            likeCount: apiData.like_count,
                            commentCount: apiData.comment_count,
                            createdAt: getFormattedShortDate(
                                apiData.created_at
                            ),
                            bookMarkCount: apiData.bookmark_count,
                            buid: apiData.buid,
                            isModerationRequired:
                                apiData.is_moderation_required,
                            moderationReason: apiData.moderationReason,
                        });
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    errorMessage = (error.response.data || {}).error_desc;
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    useEffect(() => {
        fetchGetPostDetail();
    }, [pageProps]);

    return (
        <Wrapper>
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />
            <PostDetailWrapper>
                <PostDetailHeader
                    postID={postData.postID}
                    postType={postType}
                    authorName={postData.authorName}
                    authorImageURL={postData.profilePicture}
                    createdAt={postData.createdAt}
                    loading={loading}
                />
                <PostDetailBody
                    postID={postData.postID}
                    postType={postType}
                    content={postData.content}
                    contentImageURL={postData.images}
                    loading={loading && isEmpty(postData)}
                />
                <PostDetailFooter
                    postID={postData.postID}
                    buID={postData.buid}
                    postType={postType}
                    likeCount={postData.likeCount}
                    bookMarkCount={postData.bookMarkCount}
                    commentCount={postData.commentCount}
                    loading={loading}
                    fetchGetPostDetail={fetchGetPostDetail}
                    isModerationRequired={postData.isModerationRequired}
                    moderationReason={postData.moderationReason}
                />
            </PostDetailWrapper>
        </Wrapper>
    );
};

PostDetail.propTypes = {
    pageProps: PropTypes.shape({}).isRequired,
};

export default PostDetail;
