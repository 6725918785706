import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import AppRoutes from './router';
import { lightTheme, darkTheme } from './theme';

function App(props) {
    const theme = useSelector((state) => state.theme);
    return (
        <Router>
            <ThemeProvider
                theme={theme.currentMode === 'light' ? lightTheme : darkTheme}
            >
                <AppRoutes isAuthenticated appProps={props} />
            </ThemeProvider>
        </Router>
    );
}

export default App;
