
import {
    GetPostListApiURL,
    GetPostFiltersApiURL,
    GetTagListApiURL,
} from './apiConfig';
import axios from './instance';

export const GetPostListApi = (params) => axios.get(GetPostListApiURL, { params });

export const GetPostFiltersApi = () => axios.get(GetPostFiltersApiURL);

export const GetTagListApi = () => axios.get(GetTagListApiURL);
