/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint no-use-before-define: 2 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../SingleDropDown';
import DropdownMultiple from '../MultipleDropDown';
import './customStyles.scss';
import { Title, BottomWrapper } from './styles';

class CommonDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: props.type,
            title: props.title,
            list: props.list.data,
            onChange: props.onChange,
            key: props.list.key,
            searchable: props.searchable,
            filters: props.filters,
            titleHelper: props.titleHelper,
            titleHelperPlural: props.titleHelperPlural,
            titleKey: props.titleKey,
        };
    }

    resetThenSet = (id) => {
        const {
            key, list, filters, onChange,
        } = this.state;
        const tempList = JSON.parse(JSON.stringify(list));
        let status = false;
        tempList.forEach((item) => {
            if (item.id === id) {
                status = !item.selected;
                item.selected = !item.selected;
            } else {
                item.selected = false;
            }
        });
        this.setState(
            {
                list: tempList,
            },
            () => {
                if (status) {
                    filters[key] = id;
                } else {
                    delete filters[key];
                }
                onChange(filters);
            },
        );
    };

    toggleItem = (id) => {
        const {
 key, list, filters, onChange,
 } = this.state;
        const tempList = JSON.parse(JSON.stringify(list));
        let status = false;
        tempList.forEach((item) => {
            if (item.id === id) {
                status = !item.selected;
                item.selected = !item.selected;
            }
        });
        this.setState(
            {
                list: tempList,
            },
            () => {
                let filterVal = filters[key];
                if (filterVal === undefined || filterVal === '') {
                    filterVal = [];
                }
                if (status) {
                    filterVal.push(id);
                    filters[key] = filterVal;
                } else {
                    for (let i = 0; i < filterVal.length; i++) {
                        if (filterVal[i] === id) {
                            filterVal.splice(i, 1);
                            i--;
                        }
                    }
                    if (filterVal === []) {
                        delete filters[key];
                    }
                }
                onChange(filters);
            },
        );
    };

    render() {
        const {
            type,
            title,
            list,
            searchable,
            titleHelper,
            titleHelperPlural,
            titleKey,
        } = this.state;
        return (
            <>
                {type === 'multiple' ? (
                    <>
                        <Title>{title}</Title>
                        <DropdownMultiple
                            title={title}
                            list={list}
                            titleKey={titleKey}
                            toggleItem={this.toggleItem}
                            searchable={searchable}
                            titleHelper={titleHelper}
                            titleHelperPlural={titleHelperPlural}
                        />
                        <BottomWrapper />
                    </>
                ) : (
                    <>
                        <Title>{title}</Title>
                        <Dropdown
                            title={title}
                            titleKey={titleKey}
                            list={list}
                            resetThenSet={this.resetThenSet}
                            searchable={searchable}
                            titleHelper={titleHelper}
                        />
                        <BottomWrapper />
                    </>
                )}
            </>
        );
    }
}

CommonDropDown.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    list: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    titleHelper: PropTypes.string,
    titleHelperPlural: PropTypes.string,
};

CommonDropDown.defaultProps = {
    type: '',
    onChange: () => {},
    titleHelper: '',
    titleHelperPlural: '',
};

export default CommonDropDown;
