import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    StyledLabel,
    StyledCheckbox,
    ScheduleCheckboxWrapper,
    Wrapper,
    PublishDatePickerWrapper,
    StyledDateTimePicker,
    ErrorText,
} from './styles';

const ScheduleField = (props) => {
    const { setPublishDate, publishDate } = props;
    const [checked, setChecked] = useState(Boolean(publishDate));
    const [errors, setErrors] = useState({});
    const handleCheckboxChange = () => {
        setChecked((state) => {
            if (state === true) {
                setPublishDate(null);
            } else {
                setPublishDate(new Date());
            }
            return !state;
        });
    };

    const handleDateChange = (dateTime) => {
        if (dateTime.toString() === 'Invalid Date') {
            // set error for invalid date
            setErrors({ publishAt: 'Invalid Date' });
            return;
        }

        setErrors({});
        setPublishDate(dateTime);
    };

    return (
        <Wrapper>
            <ScheduleCheckboxWrapper>
                <StyledLabel>Enable Schedule</StyledLabel>
                <StyledCheckbox onChange={handleCheckboxChange} checked={checked} />
            </ScheduleCheckboxWrapper>
            {checked && (
                <PublishDatePickerWrapper>
                    <StyledDateTimePicker
                        clearable="true"
                        margin="normal"
                        variant="inline"
                        showTodayButton
                        disablePast
                        id="create-start-time-picker"
                        value={publishDate}
                        onChange={(e) => handleDateChange(
                                e,
                                'startTime',
                                'create',
                            )}
                        KeyboardButtonProps={{
                            'aria-label': 'select end date',
                        }}
                    />
                    {errors.publishAt && (
                        <ErrorText>
                            {errors.publishAt}
                        </ErrorText>
                    )}
                </PublishDatePickerWrapper>
            )}
        </Wrapper>
    );
};

ScheduleField.propTypes = {
    setPublishDate: PropTypes.func.isRequired,
    publishDate: PropTypes.string.isRequired,
};

export default ScheduleField;
