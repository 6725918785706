import React from 'react';
import { withRouter } from 'react-router-dom';
import ForgotPassword from '../../component/ForgotPassword';
import { Wrapper } from './styles';

const ForgotPasswordPage = (props) => (
    <Wrapper>
        <ForgotPassword pageProps={props} />
    </Wrapper>
);

export default withRouter(ForgotPasswordPage);
