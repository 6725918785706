import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import swal from 'sweetalert';
import { getFormattedShortDate } from '../../util/helper';
import CommonLoader from '../Common/Loader';
import { Wrapper, BlogDetailWrapper } from './styles';
import BlogDetailBody from '../BlogDetailBody';
import { GetBlogDetailApi } from '../../api/getBlogDetail';
import PostDetailFooter from '../PostDetailFooter';
import PostDetailHeader from '../PostDetailHeader';

const BlogDetail = (props) => {
    const { pageProps } = props;
    const { id: postID } = ((pageProps || {}).match || {}).params || {};

    const [loading, setLoading] = useState(false);
    const [blogData, setBlogData] = useState({
        authorName: '',
        profilePicture: '',
        content: '',
        images: '',
        videos: '',
        likeCount: '',
        commentCount: '',
        createdAt: '',
        bookMarkCount: '',
        postID: '',
    });

    const postType = 'Blog';

    const fetchGetBlogDetail = () => {
        setLoading(true);
        const params = {
            id: postID,
        };
        GetBlogDetailApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setBlogData({
                            postID: apiData.id,
                            authorName: (apiData.author || {}).name,
                            profilePicture: (
                                (apiData.author || {}).profile_picture || {}
                            ),
                            content: apiData.content,
                            images: (apiData.images || {}).url,
                            likeCount: apiData.like_count,
                            commentCount: apiData.comment_count,
                            createdAt: getFormattedShortDate(
                                apiData.created_at,
                            ),
                            bookMarkCount: apiData.bookmark_count,
                        });
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error',
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    errorMessage = (error.response.data || {}).error_desc;
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    useEffect(() => {
        if (postID.length) {
            fetchGetBlogDetail();
        }
    }, [postID]);

    return (
        <Wrapper>
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />
            <BlogDetailWrapper>
                <PostDetailHeader
                    postID={blogData.postID}
                    postType={postType}
                    authorName={blogData.authorName}
                    authorImageURL={blogData.profilePicture}
                    createdAt={blogData.createdAt}
                    loading={loading}
                />
                <BlogDetailBody
                    postID={blogData.postID}
                    postType={postType}
                    content={blogData.content}
                    contentImageURL={blogData.images}
                    loading={loading && isEmpty(blogData)}
                />
                <PostDetailFooter
                    postID={blogData.postID}
                    buID={blogData.buid}
                    postType={postType}
                    likeCount={blogData.likeCount}
                    bookMarkCount={blogData.bookMarkCount}
                    commentCount={blogData.commentCount}
                    loading={loading}
                    fetchGetPostDetail={fetchGetBlogDetail}
                />
            </BlogDetailWrapper>
        </Wrapper>
    );
};

BlogDetail.propTypes = {
    pageProps: PropTypes.shape({}).isRequired,
};

export default BlogDetail;
