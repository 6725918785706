import React from 'react';
import PropTypes from 'prop-types';
import MyComponent from 'react-fullpage-custom-loader';
import { Wrapper } from './styles';

const CommonLoader = (props) => {
    const {
        sentences, isLoading, size, type, color,
    } = props;

    if (!isLoading) {
        return null;
    }

    return (
        <Wrapper>
            <MyComponent
                sentences={sentences}
                loaderSize={size}
                loaderType={type}
                color={color}
            />
        </Wrapper>
    );
};

CommonLoader.propTypes = {
    sentences: PropTypes.arrayOf(PropTypes.string),
    isLoading: PropTypes.bool,
    size: PropTypes.string,
    type: PropTypes.string,
    color: PropTypes.string,
};

CommonLoader.defaultProps = {
    sentences: [],
    isLoading: false,
    size: 'big',
    type: 'ball-spin-clockwise',
    color: 'white',
};

export default CommonLoader;
