const baseurl = 'https://common-arwealth-images.s3.ap-south-1.amazonaws.com';
export const eye = `${baseurl}/eye.svg`;
export const eyeSlash = `${baseurl}/eye-slash.svg`;
export const logo = `${baseurl}/arwm-dark-logo.png`;
export const menu = `${baseurl}/bars-menu.svg`;
export const login = `${baseurl}/login.svg`;
export const lock = `${baseurl}/lock.svg`;
export const lxmeLogo = 'https://d2j99f6syf87tg.cloudfront.net/lxme/lxme_web/mobile_logo.png';
export const search = `${baseurl}/search.svg`;
export const infoBlack = `${baseurl}/info-black.png`;
export const filterIcon = `${baseurl}/filter.svg`;
