import React from 'react';
import { withRouter } from 'react-router-dom';
import Calculators from '../../component/Calculators';
import { Wrapper } from './styles';

const CalculatorsPage = () => (
    // const userRedux = useSelector((state) => state.user);

    // useEffect(() => {
    //     if (Boolean(userRedux.userData)) {
    //         if (!Boolean(userRedux.userData.report_upload_scope)) {
    //             props.history.push('/');
    //         }
    //     }
    //     // eslint-disable-next-line
    // }, [Boolean(userRedux.userData)]);

    <Wrapper>
        <Calculators />
    </Wrapper>
);

export default withRouter(CalculatorsPage);
