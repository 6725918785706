import {
  BulkCreateSipUpdateURL,
  CreateGeneralUpdateURL,
  CreateSipUpdateURl,
  DeleteGenralUpdateURL,
  DeleteSipURL,
  GetSipClientCodeURL,
  UpdateGeneralUpdateURL,
  UpdateSipURL,
  ViewGeneralUpdateURL,
  ViewSipURL,
  deleteSelectedUserUpdates,
  generateRandomeUserUpdate,
  getRandomUpdateCategory,
} from "./apiConfig";
import axios from "./instance";

export const CreateGeneralUpdate = (params) => {
  return axios.post(CreateGeneralUpdateURL, params);
};

export const GetGeneralUpdate = (params) => {
  const {
    is_deleted,
    page,
    page_limit,
    is_active,
    created_at_lte = "",
    target_users = "",
    category = ""
  } = params;
  return axios.get(
    `${ViewGeneralUpdateURL}?page=${page}&page_limit=${page_limit}&is_deleted=${is_deleted}&is_active=${is_active}&created_at_lte=${created_at_lte}&target_users=${target_users}&category=${category}`
  );
};

export const DeleteGeneralUpdate = (params) => {
  return axios.delete(DeleteGenralUpdateURL, { data: { ...params } });
};

export const UpdateGeneralUpdate = (params) =>
  axios.put(UpdateGeneralUpdateURL, params, {
    headers: {
      "Content-Type": "application/json",
    },
    // data: params,
  });

export const randomGeneralUserUpdateApi = (params) => {
  return axios.post(generateRandomeUserUpdate, params)
}

export const CreateSIPUpdate = (params) => {
  return axios.post(CreateSipUpdateURl, params);
};

export const BulkCreateSIPUpdate = (params) => {
  return axios.post(BulkCreateSipUpdateURL, params);
};

export const BulkDeleteSIPUpdate = () => {
  return axios.delete(BulkCreateSipUpdateURL)
}

export const GetSipUpdate = (params) => {
  const {
    is_deleted,
    page,
    page_limit,
    is_active,
    client_code,
    sort_by,
    sort_type,
    sip_sue_date,
  } = params;
  return axios.get(
    `${ViewSipURL}?page=${page}&page_limit=${page_limit}&is_deleted=${is_deleted}&is_active=${is_active}&client_code=${client_code}&sort_by=${sort_by}&sort_type=${sort_type}&sip_due_date=${sip_sue_date}`
  );
};

export const DeleteSipUpdate = (params) => {
  return axios.delete(DeleteSipURL, { data: { ...params } });
};

export const UpdateSipUpdate = (params) =>
  axios.put(UpdateSipURL, params, {
    headers: {
      "Content-Type": "application/json",
    },
    // data: params,
  });

export const GetSipClientCode = () => {
  return axios.get(GetSipClientCodeURL);
};

export const GetRandomUpdateCategory = () => {
  return axios.get(getRandomUpdateCategory);
};

export const BulkDeleteUserUpdate = () => {
  return axios.delete(deleteSelectedUserUpdates);
};
