/* eslint-disable */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { map } from 'lodash';
import Poll from 'react-polls';
import {
    StyledView,
    PollOptionWrapper,
    SelectionBackground,
    ContentWrapper,
    StyleTitle,
    Wrapper,
    PollPercent,
    PollOption,
    PollBackground,
} from './styles';
import MentionText from '../MentionText';

const PollDetailBody = (props) => {
    const {
        option,
        submitPoll,
        isExpired = false,
        pollVoteStatus,
        content,
        poll,
    } = props;

    return (
        <Wrapper>
            <StyleTitle>
                <MentionText text={content} />
            </StyleTitle>
            {map(option, (item) => {
                return (
                    <PollOptionWrapper key={item.id}>
                        <PollBackground
                            width={item.poll_percent}
                            active={item.id === poll.winning_option_id}
                        />
                        <PollOption>{item.value}</PollOption>
                        {/* <SelectionBackground>
                            {item.option_poll_count}
                        </SelectionBackground> */}
                        <PollPercent>{item.poll_percent} %</PollPercent>
                    </PollOptionWrapper>
                );
            })}
        </Wrapper>
    );
};

export default PollDetailBody;
