/* eslint-disable */
/* eslint-disable comma-dangle */
/* eslint-disable no-shadow */
// comment test
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import BlockIcon from '@material-ui/icons/Block';
import { truncate, startCase , size, trim} from 'lodash';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import swal from 'sweetalert';
import { NavLink } from 'react-router-dom';
import CommonTable from '../Common/Table';
import Paginator from '../Common/Paginator';
import CommonLoader from '../Common/Loader';
import {
    GetReportListApi,
    UpdateReportApi,
    DeleteReportApi,
    GetReportFiltersApi,
    BlockUserApi,
} from '../../api/reported';

import CommonDropdown from '../Common/Dropdown';
import CommonDatePicker from '../Common/DatePicker';
import { dateFormating , formattedValue} from '../../util/helper';
import { FilterGrid, FilterTitleWrapper, IconContainer } from './styles';

const Reported = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(1);
    const [filters, setFilters] = useState({
        page: currentPage,
        page_limit: pageSize,
        sort_by: 'updated_at',
        sort_type: 'desc',
        is_deleted: 'false',
    });
    const [reportFilters, setReportFilters] = useState({
        action_taken: {
            key: 'action_taken',
            data: [],
        },
        report_by_id: {
            key: 'report_by_id',
            data: [],
        },
        user_id: {
            key: 'user_id',
            data: [],
        },
        reason: {
            key: 'reason',
            data: [],
        },
        entity_type: {
            key: 'entity_type',
            data: [],
        },
    });

    const GetReportFilters = () => {
        setLoading(true);
        GetReportFiltersApi()
            .then((res) => {
                let success = false;
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setReportFilters({
                            action_taken: {
                                key: 'action_taken',
                                data: apiData.action_taken,
                            },
                            report_by_id: {
                                key: 'report_by_id',
                                data: apiData.report_by_user,
                            },
                            user_id: {
                                key: 'user_id',
                                data: apiData.reported_user,
                            },
                            reason: {
                                key: 'reason',
                                data: apiData.reason,
                            },
                            entity_type: {
                                key: 'entity_type',
                                data: apiData.content_type,
                            },
                        });
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    useEffect(() => {
        GetReportFilters();
    }, []);

    const sortTypeList = {
        key: 'sort_type',
        data: [
            {
                id: 'asc',
                title: 'Asc',
                selected: false,
            },
            {
                id: 'desc',
                title: 'Desc',
                selected: true,
            },
        ],
    };

    const sortByList = {
        key: 'sort_by',
        data: [
            {
                id: 'id',
                title: 'id',
                selected: false,
            },
            {
                id: 'created_at',
                title: 'created_at',
                selected: false,
            },
            {
                id: 'updated_at',
                title: 'updated_at',
                selected: true,
            },
        ],
    };

    const getReport = (params) => {
        setLoading(true);
        GetReportListApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setData(apiData.results);
                        setCurrentPage(apiData.page);
                        setPageSize(apiData.page_limit);
                        setTotal(apiData.total_count);
                        setFilters(params);
                        setLoading(false);
                    }
                }
                /* eslint-disable comma-dangle */
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const funRelease = (item) => {
        if(item.entity_type === "user"){
            swal(
                'Release Functinality is disabled for this entity?',
            );
           return;
        }
        swal({
            title: 'Are you sure you want to Release ?',
            text: '',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((success) => {
            if (success) {
                setLoading(true);
                const params = {
                    id: item.id,
                    is_valid: 'false',
                };
                UpdateReportApi(params)
                    .then((res) => {
                        getReport();
                        let success = false;
                        setLoading(false);
                        if (res.status === 200) {
                            if ((res.data || {}).success) {
                                success = true;
                                swal(
                                    'success!',
                                    'Successfully Release',
                                    'success'
                                );
                            }
                        }
                        if (!success) {
                            setLoading(false);
                            swal(
                                'Oops!',
                                ((res.data || {}).data || {}).error_desc,
                                'error'
                            );
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        let errorMessage = 'Failed to Release';
                        if (error.response) {
                            if (error.response.status === 400) {
                                errorMessage = (error.response.data || {})
                                    .error_desc;
                            }
                        }
                        swal('Oops!', errorMessage, 'error');
                    });
            }
        });
    };

    const funDelete = (item) => {
        if(item.entity_type === "user"){
            swal(
                'Delete Functinality is disabled for this entity',
            );
           return;
        }
        
        swal({
            title: 'Are you sure you want to delete this post ?',
            text: '',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((success) => {
            if (success) {
                setLoading(true);
                const params = {
                    entity_id: item.entity_id,
                    entity_type: item.entity_type,
                };
                DeleteReportApi(params)
                    .then((res) => {
                        getReport();
                        let success = false;
                        setLoading(false);
                        if (res.status === 200) {
                            if ((res.data || {}).success) {
                                success = true;
                                swal(
                                    'success!',
                                    'Successfully Deleted Post',
                                    'success'
                                );
                            }
                        }
                        if (!success) {
                            setLoading(false);
                            swal(
                                'Oops!',
                                ((res.data || {}).data || {}).error_desc,
                                'error'
                            );
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        let errorMessage = 'Failed to delete post';
                        if (error.response) {
                            if (error.response.status === 400) {
                                errorMessage = (error.response.data || {})
                                    .error_desc;
                            }
                        }
                        swal('Oops!', errorMessage, 'error');
                    });
            }
        });
    };

    const funBlockUser = (item) => {
        if(item.entity_type === "user"){
            swal(
                'Block Functinality is disabled for this entity',
            );
           return;
        }
        swal({
            title: 'Are you sure you want to Block user  ?',
            text: '',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((success) => {
            if (success) {
                setLoading(true);
                const params = { user_id: item.user_id };
                BlockUserApi(params)
                    .then((res) => {
                        let success = false;
                        setLoading(false);
                        if (res.status === 200) {
                            if ((res.data || {}).success) {
                                success = true;
                                swal(
                                    'success!',
                                    'Successfully Block User',
                                    'success'
                                );
                            }
                        }
                        if (!success) {
                            setLoading(false);
                            swal(
                                'Oops!',
                                ((res.data || {}).data || {}).error_desc,
                                'error'
                            );
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        let errorMessage = 'Failed to Block User';
                        if (error.response) {
                            if (error.response.status === 400) {
                                errorMessage = (error.response.data || {})
                                    .error_desc;
                            }
                        }
                        swal('Oops!', errorMessage, 'error');
                    });
            }
        });
    };

    const renderViewIcon = (record) => {
        if(record.entity_type === "user"){
            return null;
        } else{
            let contentID = record.content.id;
            let contentType = record.content.type;

            if (record.content.type === 'poll') {
                contentID = record.content.poll.id;
            }
            
            if (record.entity_type === 'comment'){
                contentType = record.content.post_details.type;
                contentID = record.content.post_details.id;
            }
            
            return (
                <IconContainer>
                    <NavLink to={`/community/${contentType}/${contentID}`}>
                        <VisibilityIcon />
                    </NavLink>
                </IconContainer>
            );    
        }
    };

    const renderReportedUser = (item) => {
        return (
            <>
            <p>Name : {formattedValue(item.name)}</p>
            <p>User ID : {formattedValue(item.id)}</p>
            </>
        );
    }

    const renderReportedContent = (item) => {
        let content = item.entity_type === "post" ? item.content.content : item.action_taken;
        let title = (item.content || {}).title 
        if(size(trim(content)) > 0 ){
            content = trim(content)
        } else if(size(trim(title)) > 0){
            content = trim(title)
        }
        content =  truncate(content, {
            length: 125,
            separator: '...',
        });
        return content;
    }
    
    const tableColumns = [
        {
            title: 'Reported Content',
            dataIndex: '',
            key: '',
            width: 180,
            render: (item) => renderReportedContent(item),
        },
        {
            title: 'Reported User',
            dataIndex: 'reported_user',
            key: 'reported_user',
            width: 50,
            render: (item) => renderReportedUser(item),
        },
        {
            title: 'Reported By',
            dataIndex: 'report_by_user',
            key: 'report_by_user',
            render: ({ name }) => <p>{name}</p>,
            width: 50,
        },
        {
            title: 'Type',
            dataIndex: 'entity_type',
            key: 'entity_type',
            width: 10,
        },
        {
            title: 'Reason',
            dataIndex: 'reason',
            key: 'reason',
            width: 30,
        },
        {
            title: 'Action',
            dataIndex: 'action_taken',
            key: 'action_taken',
            width: 50,
            render: (item) => <p>{startCase(item)}</p>,
        },
        {
            title: 'Reported At',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 120,
            render: (item) => <p>{dateFormating(item)}</p>,
        },
        {
            title: 'Release',
            dataIndex: 'id',
            key: 'id',
            width: 2,
            render: (text, item) => {
                if (item.action_taken !== 'pending') {
                    return null;
                }
                return (
                    <IconContainer>
                        <NewReleasesIcon  style={{ color: item.entity_type == "user" ? "gray" : "white" }} onClick={() => funRelease(item)} />
                    </IconContainer>
                );
            },
        },
        {
            title: 'Delete',
            dataIndex: 'id',
            key: 'id',
            width: 2,
            render: (text, item) => {
                if (item.action_taken === 'content_deleted') {
                    return null;
                }
                return (
                    <IconContainer>
                        <DeleteIcon style={{ color: item.entity_type == "user" ? "gray" : "white" }} onClick={() => funDelete(item)} />
                    </IconContainer>
                );
            },
        },
        {
            title: 'Block',
            dataIndex: 'id',
            key: 'id',
            width: 2,
            render: (text, item) => (
                <IconContainer>
                    <BlockIcon style={{ color: item.entity_type == "user" ? "gray" : "white" }}  onClick={() => funBlockUser(item)} />
                </IconContainer>
            ),
        },
        {
            title: 'Hidden',
            dataIndex: 'is_hidden',
            key: 'is_hidden',
            width: 50,
            render: (text, item) => {
                return (
                    <IconContainer>
                        {item.entity_type == "user" ? <CheckBoxOutlineBlank/> : (item.content.is_hidden ? <CheckBox/> : <CheckBoxOutlineBlank/>)}
                    </IconContainer>
                );
            },
        },
        {
            title: 'View',
            dataIndex: 'view',
            key: 'view',
            width: 50,
            render: (text, item) => renderViewIcon(item),
        },
        
    ];

    const IsDeleted = {
        key: 'is_deleted',
        data: [
            {
                id: 'true',
                title: 'True',
                selected: false,
            },
            {
                id: 'false',
                title: 'False',
                selected: true,
            },
        ],
    };

    useEffect(() => {
        filters.page = currentPage;
        filters.page_limit = pageSize;
        getReport(filters);
    }, [currentPage, pageSize]);

    const handleFilterChange = (filters) => {
        getReport(filters);
    };

    return (
        <Grid container spacing={2} style={{ paddingTop: '5rem' }}>
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />
            <Grid
                container
                spacing={2}
                style={{
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                    paddingBottom: '1rem',
                }}
            >
                <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
                    <FilterTitleWrapper>Filters</FilterTitleWrapper>
                    <CommonDropdown
                        list={IsDeleted}
                        title="Is Deleted"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={sortByList}
                        title="Sort By"
                        currentAuthorID
                        searchable={false}
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={sortTypeList}
                        title="Sort Type"
                        searchable={false}
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Created At LTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="created_at_lte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Created At GTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="created_at_gte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Updated At LTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="updated_at_lte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Updated At GTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="updated_at_gte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    {reportFilters.action_taken.data.length > 0 && (
                        <CommonDropdown
                            list={reportFilters.action_taken}
                            title="Action"
                            searchable
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}

                    {reportFilters.report_by_id.data.length > 0 && (
                        <CommonDropdown
                            list={reportFilters.report_by_id}
                            title="Report by User"
                            searchable
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}
                    {reportFilters.user_id.data.length > 0 && (
                        <CommonDropdown
                            list={reportFilters.user_id}
                            title="Reported User"
                            searchable
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}
                    {reportFilters.reason.data.length > 0 && (
                        <CommonDropdown
                            list={reportFilters.reason}
                            title="Reason"
                            searchable
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}
                    {reportFilters.entity_type.data.length > 0 && (
                        <CommonDropdown
                            list={reportFilters.entity_type}
                            title="Content Type"
                            searchable
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}
                </FilterGrid>
                <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                    <CommonTable
                        data={data}
                        columns={tableColumns}
                        pagination={false}
                        scroll={{ x: 'overflow' }}
                        rowKey="id"
                    />
                    <Paginator
                        totalRecords={total}
                        pageLimit={pageSize}
                        pageNeighbours={2}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
export default Reported;
