import styled from 'styled-components';
import { DateTimePicker } from '@material-ui/pickers';

export const LabelInput = styled.div`
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 0.8rem;
    margin-top: 0.5rem;
    color: ${(props) => props.theme.text};
`;

export const StyledButton = styled.button`
    background: ${(props) => props.theme.pinklogo};
    color: ${(props) => props.theme.light};
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    outline: none;
    border: 2px solid ${(props) => props.theme.pinklogo};
    border-radius: 3px;
    float: right;
    cursor: pointer;
`;

export const ErrorText = styled.p`
    color: #ff0000;
    margin-bottom: 0;
`;

export const StyledDatePicker = styled(DateTimePicker)`
    margin: 0 !important;
    background: white !important;
    padding: 0.5rem !important;
    border: 1px solid #dfdfdf !important;
    border-radius: 3px !important;
    width: -webkit-fill-available !important;
`;
