import styled from 'styled-components';
import { Checkbox } from 'antd';
import { DateTimePicker } from '@material-ui/pickers';

export const Wrapper = styled.div`
    margin-bottom: 0.5rem;
    margin-top: 0.8rem;
`;

export const StyledLabel = styled.label`
    font-size: 0.8rem;
    font-weight: 600;
    color: ${(props) => props.theme.text};
`;

export const StyledCheckbox = styled(Checkbox)`
    margin: 0 10px;
`;

export const ScheduleCheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const PublishDatePickerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 0.8rem;
    margin-top: 0.5rem;
`;

export const StyledDateTimePicker = styled(DateTimePicker)`
    margin: 0 !important;
    background: white !important;
    padding: 0.5rem !important;
    border: 1px solid #dfdfdf !important;
    border-radius: 3px !important;
    width: -webkit-fill-available !important;
`;

export const ErrorText = styled.p`
    color: #ff0000;
    margin-bottom: 0;
`;
