/* eslint-disable */
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Modal, Button, Select} from 'antd';
import { Tag, Input, Upload } from 'antd';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { DeleteFilled, SyncOutlined } from '@ant-design/icons';


export const FilterGrid = styled(Grid)`
    background-color: ${(props) => props.theme.background};
    margin-top: 0.5rem;
    margin-bottom: 4.5rem;

    @media (max-width: 767px) {
        margin-bottom: 1rem;
    }
`;

export const FilterTitleWrapper = styled.div`
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center !important;
    margin: 1rem 0 2rem 0;
    font-family: 'Titillium Web';
    border-bottom: 1px dashed ${(props) => props.theme.text};
    padding-bottom: 2rem;
    color: ${(props) => props.theme.text};
    @media (max-width: 1024px) {
        font-size: 1.4rem;
    }
`;

export const StyledButton = styled.button`
    background: ${(props) => props.backgroundColor ? props.backgroundColor : props.theme.pinklogo};
    color: ${(props) => props.theme.light};
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    outline: none;
    border: 2px solid ${(props) => props.backgroundColor ? props.backgroundColor : props.theme.pinklogo};
    border-radius: 3px;
    float: right;
    cursor: pointer;
`;

export const IconContainer = styled.div`
    cursor: pointer;
`;

export const StyleModal = styled(Modal)`
    .ant-modal-header {
        background: ${(props) => props.theme.dark};
        color: ${(props) => props.theme.dark};
    }

    .ant-modal-body {
        background: ${(props) => props.theme.dark};
    }

    .ant-modal-title {
        color: ${(props) => props.theme.text};
    }
    .ant-modal-footer {
        background: ${(props) => props.theme.dark};
    }
    .ant-modal-close-icon {
        color: ${(props) => props.theme.text};
    }

    .ant-modal .sc-AzgDb .jcGGxa {
        padding: 0px;
    }
`;

export const LabelInput = styled.div`
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 0.8rem;
    margin-top: 0.5rem;
    color: ${(props) => props.color ? props.color : props.theme.text};
`;

export const SubLabelInput = styled.div`
    font-size: 0.6rem;
    font-weight: 600;
    margin-bottom: 0.3rem;
    margin-top: 0.4rem;
    color: ${(props) => props.theme.text};
`;

export const ErrorText = styled.p`
    color: #ff0000;
    margin-bottom: 0;
    /* flex-flow: row-reverse wrap; */
`;

export const SchemePortfolio = styled.div`
    .ant-table-thead > tr > th {
        color: ${(props) => props.theme.nestedTableText}!important;
        background-color: ${(props) => props.theme.nestedTableHead}!important;
    }
`;

 

export const StyledTag = styled(Tag)`
    color: ${(p) => p.theme.text};
    background: ${(p) => p.theme.background};
    min-width: 50px;
    margin-bottom: 5px
`;

export const StyledInput = styled(Input)`
    margin-top: 10px;
    margin-bottom: 10px;
`;