
import {
    GetRewardListApiURL,
    CreateRewardApiURL,
    UpdateRewardApiURL,
    DeleteRewardURL,
} from './apiConfig';

import axios from './instance';

export const getRewardListApi = (params) => axios.get(GetRewardListApiURL, { params });

export const createRewardApi = (params) => axios.post(CreateRewardApiURL, params);

export const updateRewardApi = (params) => axios.put(UpdateRewardApiURL, params, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

export const deleteRewardApi = (params) => axios.delete(DeleteRewardURL, { data: { ...params } });
