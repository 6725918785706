/* eslint-disable */
/* eslint-disable comma-dangle */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import swal from 'sweetalert';
import CommonTable from '../Common/Table';

import {
    FilterGrid, FilterTitleWrapper, SchemePortfolio, IconContainer,
    StyleModal,
    LabelInput,
    ErrorText, 
    StyledTag
} from './styles';

import EditIcon from '@material-ui/icons/Edit';
import {
    GetOrderListApi,
    GetOrderFilterApi,
    UpdateOrderAPI
} from '../../api/getOrderList';

import CommonDatePicker from '../Common/DatePicker';
import { snakeToUpperCase, dateFormating, formattedValue } from '../../util/helper';
import Paginator from '../Common/Paginator';
import CommonLoader from '../Common/Loader';
import CommonDropdown from '../Common/Dropdown';
import { useToasts } from 'react-toast-notifications';
import { isEmpty } from 'lodash';
import { Button, Input, Select } from 'antd';
import { size, trim } from 'lodash';


const Orders = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sortBy, setSortBy] = useState('created_at');
    const [sortType, seSortTypet] = useState('desc');
    const { addToast } = useToasts();
    const [folioNumber, setFolioNumber] = useState('');
    const [schemeName, setSchemeName] = useState('');
    const [amount, setAmount] = useState('');
    const [transactionDetailID, setTransactionDetailID] = useState('');
    const [status, setStatus] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [paymentStatusDisabled, setPaymentStatusDisabled] = useState(false);
    const [remarks, setRemarks] = useState('');
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [updateErrors, setUpdateErrors] = useState({});
    const [transactionType, setTransactionType] = useState('');
    
    const PaymentStatusList = {
        key: 'payment_status',
        data: [
            {
                id: 'success',
                title: 'success',
            },
            {
                id: 'failed',
                title: 'failed',
            }
        ],
    };

    const FreshPurchaseOrderStatus = {
        key: 'status',
        data: [
            {
                id: 'failed_to_place_order',
                title: 'FAILED TO PLACE ORDER',
            },
            {
                id: 'under_process',
                title: 'UNDER PROCESS',
            },
            {
                id: 'allotment_done',
                title: 'ALLOTMENT DONE',
            },
        ],
    };

    const RedemptionOrderStatus = {
        key: 'status',
        data: [
            {
                id: 'payment_credited',
                title: 'PAYMENT CREDITED',
            },
            {
                id: 'failed_to_place_order',
                title: 'FAILED TO PLACE ORDER',
            },
        ],
    };

    const [orderStatusList, setOrderStatusList] = useState(FreshPurchaseOrderStatus);
    const [total, setTotal] = useState(1);
    const [filters, setFilters] = useState({
        page: currentPage,
        page_limit: pageSize,
        sort_by: sortBy,
        sort_type: sortType,
        is_deleted: false
    });

    const [transactionFilters, setTransactionFilters] = useState({
        folio_list: {
            key: 'folio',
            data: [],
        },
        order_id: {
            key: 'order_id',
            data: [],
        },
        portfolio_list: {
            key: 'portfolio_id',
            data: [],
        },
        status_list: {
            key: 'status',
            data: [],
        },
        transaction_mode_list: {
            key: 'transaction_mode',
            data: [],
        },
        transaction_type_list: {
            key: 'transaction_type',
            data: [],
        },
        user_id_list: {
            key: 'user_id',
            data: [],
        },
        vendor_order_id: {
            key: 'vendor_order_id',
            data: [],
        },
        is_deleted: {
            key: 'is_deleted',
            data: [
                {
                "id": "true",
                "title": "true"
            },
            {
                "id": "false",
                "title": "false"
            }],
        },
    }
    )
    
    const getOrdersList = (params) => {
        setLoading(true);
        GetOrderListApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setData(apiData.results);
                        setCurrentPage(apiData.page);
                        setPageSize(apiData.page_limit);
                        setTotal(apiData.total_count);
                        setFilters(params);
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const getUserFilterList = () => {
        setLoading(true);
        GetOrderFilterApi()
            .then((res) => {
                let success = false;
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setTransactionFilters({
                            folio_list: {
                                key: 'folio',
                                data: apiData.folio_list,
                            },
                            order_id: {
                                key: 'id',
                                data: apiData.order_id_list,
                            },
                            portfolio_list: {
                                key: 'portfolio_id',
                                data: apiData.portfolio_list,
                            },
                            transaction_mode_list: {
                                key: 'transaction_mode',
                                data: apiData.transaction_mode_list,
                            },
                            transaction_type_list: {
                                key: 'transaction_type',
                                data: apiData.transaction_type_list,
                            },
                            user_id_list: {
                                key: 'user_id',
                                data: apiData.user_id_list,
                            },
                            vendor_order_id: {
                                key: 'vendor_order_id',
                                data: apiData.vendor_order_id_list,
                            },
                            status_list: {
                                key: 'status',
                                data: apiData.status_list,
                            },
                            is_deleted: {
                                key: 'is_deleted',
                                data: [
                                    {
                                    "id": "true",
                                    "title": "true"
                                },
                                {
                                    "id": "false",
                                    "title": "false"
                                }],
                            }
                        });
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    useEffect(() => {
        filters.page = currentPage;
        filters.page_limit = pageSize;
        getOrdersList(filters);
    }, [currentPage, pageSize]);

    useEffect(() => {
        getUserFilterList();
    }, []);

    const handleFilterChange = (filters) => {
        getOrdersList(filters);
    };

    const copyContent = async (content) => {
        try {
            await navigator.clipboard.writeText(content).then(() => {
                addToast(`Copied: ${content}`, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 1500,
                });
            });
        } catch (err) {
            addToast(`Failed To Copy: ${content}`, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 2500,
            });
        }
    };


    const renderVendorOrder = (item) => {
        const tagArray = [];
        const tags = item.split(",");
        if (!isEmpty(item)) {
            if (tags.length > 0) {
                tags.map((tag) => {
                    tagArray.push(
                        <StyledTag onClick={() => { copyContent(tag) }} key={tag}>{tag}</StyledTag>
                    );
                    return 0;
                });
            }
        } else {
            return (<p>-</p>)
        }
        return tagArray;
    }

    const tableColumns = [
        {
            title: 'Order ID',
            dataIndex: 'id',
            key: '2',
        },
        {
            title: 'User Name',
            dataIndex: 'username',
            key: '3',
            render: (item, text) => <p>{(text.user_details || {}).display_name}</p>,
        },
        {
            title: 'UCC',
            dataIndex: 'ucc',
            key: '4',
            render: (item, text) => <p>{(text.user_details || {}).ucc}</p>,
        },
        {
            title: 'Portfolio Name',
            dataIndex: 'portfolio_id',
            key: '5',
            render: (item, text) => <p>{(text.portfolio_details || {}).name}</p>,
        },
        {
            title: 'Amount ₹',
            dataIndex: 'transaction_amount',
            key: '6',
            align: 'center',
        },
        {
            title: 'Transaction Type',
            dataIndex: 'transaction_type',
            key: '9',
            align: 'center',
            render: (item) => <p>{snakeToUpperCase(item)}</p>,
        },
        {
            title: 'Transaction Mode',
            dataIndex: 'transaction_mode',
            key: '10',
            align: 'center',
            render: (item) => <p>{snakeToUpperCase(item)}</p>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: '11',
            align: 'center',
            render: (item) => <p>{snakeToUpperCase(item)}</p>,
        },
        {
            title: 'Vendor Order ID',
            dataIndex: 'vendor_order_id',
            key: 'vendor_order_id',
            align: 'center',
            render: (item) => renderVendorOrder(item),
        },
        {
            title: 'Transaction Date',
            dataIndex: 'transaction_date',
            key: '12',
            align: 'center',
            render: (item) => <p>{dateFormating(item)}</p>,
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            align: 'center',
            render: (item) => <p>{formattedValue(item)}</p>,
        },
        {
            title: 'Updated At',
            dataIndex: 'updated_at',
            key: '13',
            align: 'center',
            render: (item) => <p>{dateFormating(item)}</p>,
        },
        {
            title: 'SIP Details',
            key: '14',
            align: 'center',
            render: (item) => renderSIPDetails(item),
        },
        {
            title: 'Withdraw Details',
            key: '15',
            align: 'center',
            render: (item) => renderWithdrawDetails(item),
        },
    ];
    

    const showUpdateOrderModal = (obj, record) => {
        setFolioNumber(formattedValue(obj.folio));
        setSchemeName((obj.scheme_details || {}).name);
        setAmount(obj.transaction_amount);
        // status
        setOrderStatusList(record.transaction_type === 'redemption' ? RedemptionOrderStatus : FreshPurchaseOrderStatus);
        setStatus(snakeToUpperCase(obj.status));
        // payment status
        setPaymentStatus(snakeToUpperCase(obj.payment_status));
        setPaymentStatusDisabled(obj.status === 'allotment_done');
        setTransactionType(record.transaction_type);
        // remarks
        setRemarks(snakeToUpperCase(obj.remarks));
        setTransactionDetailID(obj.id);
        setShowUpdateModal(true);
    };

    const handleUpdateModalCancel = () => {
        setFolioNumber('');
        setSchemeName('');
        setAmount('');
        // status
        setOrderStatusList(FreshPurchaseOrderStatus);
        setStatus('');
        // payment status
        setPaymentStatus('');
        setPaymentStatusDisabled(false);
        setTransactionType('');
        // remarks
        setRemarks('');
        setTransactionDetailID('');
        setShowUpdateModal(false);

    };

    const validateFields = (values) => {
        const validationErrors = {};
        if (size(trim(values.status)) === 0) {
            validationErrors.status = 'Required Status';
        }
        
        if(transactionType === 'fresh_purchase' || transactionType === 'additional_purchase'){
            if (size(trim(values.paymentStatus)) === 0) {
                validationErrors.paymentStatus = 'Required Payment Status';
            }
        }
        
        if (size(trim(values.remarks)) === 0) {
            validationErrors.remarks = 'Required Remarks';
        }
        return validationErrors;
    };


    const handleUpdateSubmit = (event) => {
        event.preventDefault();
        const values = {
            status,
            paymentStatus,
            remarks,
        };
        setUpdateErrors({});
        const validationErrors = validateFields(values);
        setUpdateErrors(validationErrors);
        
        if (isEmpty(validationErrors)) {
            updateOrder();
        }
    };


   
    // update Order
    const updateOrder = (media) => {
        const params = {
        transaction_detail_id: transactionDetailID,
        status: status,
        payment_status: paymentStatus,
        remarks: trim(remarks),
    };
    
    setLoading(true);
    UpdateOrderAPI(params)
        .then((res) => {
            setLoading(false);
            let success = false;
            if (res.status === 200) {
                if ((res.data || {}).success) {
                    success = true;
                    handleUpdateModalCancel();
                    getOrdersList(filters);
                }
            }
            if (!success) {
                swal(
                    'Oops!',
                    ((res.data || {}).data || {}).error_desc,
                    'error'
                );
            }
        })
        .catch((error) => {
            setLoading(false);
            let errorMessage = 'Failed to Update Order';
            if (error.response) {
                if (error.response.status === 400) {
                    errorMessage = (error.response.data || {}).error_desc;
                }
            }
            swal('Oops!', errorMessage, 'error');
        });
};



    const renderSIPDetails = (item) => {
        if (
            (item.transaction_type === 'fresh_purchase' || item.transaction_type === 'additional_purchase')
            &&
            (item.transaction_mode === 'sip')
        ) {
            return (
                <div>
                    <p>Sip Date: {item.sip_date}</p>
                    <p>Sip Frequency: {snakeToUpperCase(item.sip_frequency)}</p>
                    <p>Tenure: {item.tenure + " " + item.tenure_duration}</p>
                    <p>Start Date: {dateFormating(item.sip_start_date)}</p>
                    <p>End Date: {dateFormating(item.sip_end_date)}</p>
                </div>
            );
        } else {
            return (<p>-</p>)
        }
    };

    const renderWithdrawDetails = (item) => {
        if (item.transaction_type === 'redemption') {
            return (
                <>
                    <p>EXIT LOAD: {formattedValue(item.total_exit_load)}</p>
                    <p>LTCG: {formattedValue(item.total_ltcg)}</p>
                    <p>STCG: {formattedValue(item.total_stcg)}</p>
                </>
            );
        } else {
            return (<p>-</p>)
        }
    };

    const renderChildWithdrawDetails = (item, transactionType) => {
        if (transactionType === 'redemption') {
            return (
                <>
                    <p>EXIT LOAD: {formattedValue(item.exit_load)}</p>
                    <p>LTCG: {formattedValue(item.ltcg)}</p>
                    <p>STCG: {formattedValue(item.stcg)}</p>
                </>
            );
        } else {
            return (<p>-</p>)
        }
    };

    const expandable = {
        expandedRowRender: (record) => {
            const schemes = record.transaction_order_details;
            for (let elem in schemes) {
                schemes[elem];
            }

            const columns = [
                {
                    title: 'BSE Order ID',
                    dataIndex: 'bse_order_id',
                    key: '0',
                    align: 'center',
                    render: (item) => <p>{formattedValue(item)}</p>,
                },
                {
                    title: 'Folio Number',
                    key: '1',
                    align: 'center',
                    render: (item) => <p>{formattedValue(item.folio)}</p>,
                },
                {
                    title: 'Scheme code',
                    dataIndex: 'scheme_code',
                    key: '2',
                    align: 'center',
                },
                {
                    title: 'Scheme name',
                    dataIndex: 'scheme_details',
                    key: '3',
                    align: 'center',
                    render: (item) => <p>{item.name}</p>,
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: '4',
                    align: 'center',
                    render: (item) => <p>{snakeToUpperCase(item)}</p>,
                },
                {
                    title: 'Amount',
                    dataIndex: 'transaction_amount',
                    key: '5',
                    align: 'center',
                },
                {
                    title: 'Remarks',
                    dataIndex: 'remarks',
                    key: '6',
                    align: 'center',
                    render: (item) => <p>{formattedValue(item)}</p>,
                },
                {
                    title: 'Transaction Date',
                    dataIndex: 'transaction_date',
                    key: '7',
                    align: 'center',
                    render: (item) => <p>{dateFormating(item)}</p>,
                },
            ];

            if (record.transaction_type === 'redemption') {
                columns.push({
                    title: 'Withdraw Details',
                    key: '15',
                    align: 'center',
                    render: (item) => renderChildWithdrawDetails(item, record.transaction_type),
                })
            }

            columns.push({
                title: 'Edit',
                dataIndex: 'id',
                key: 'id',
                render: (text, item) => (
                    <IconContainer>
                        <EditIcon onClick={() => showUpdateOrderModal(item, record)} />
                    </IconContainer>
                ),
                align: 'center',
            })

            return (
                <SchemePortfolio>
                    <CommonTable
                        columns={columns}
                        data={schemes}
                        pagination={false}
                        rowKey="id"
                    />
                </SchemePortfolio>
            );
        },
        expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
                <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
            ) : (
                <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
            ),
    };

    const createOptionList = (list) => {
        const optionArray = [];
        // eslint-disable-next-line array-callback-return
        list.map((item) => {
            optionArray.push(
                <Option key={item.id} value={item.id}>
                    {item.title}
                </Option>,
            );
        });

        return optionArray;
    };

    return (
        <Grid>
            <Grid
                container
                spacing={2}
                style={{
                    paddingTop: '1rem',
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                    paddingBottom: '1rem',
                }}
            >
                <CommonLoader
                    isLoading={loading}
                    size="large"
                    sentences={['please wait ...']}
                    type="pacman"
                />
                <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
                    <FilterTitleWrapper>Filters</FilterTitleWrapper>
                    {transactionFilters.user_id_list.data.length > 0 && (
                        <CommonDropdown
                            list={transactionFilters.user_id_list}
                            title="User Name"
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}

                    {transactionFilters.user_id_list.data.length > 0 && (
                        <CommonDropdown
                            list={transactionFilters.user_id_list}
                            title="User ID"
                            titleKey="id"
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}

                    {transactionFilters.user_id_list.data.length > 0 && (
                        <CommonDropdown
                            list={transactionFilters.user_id_list}
                            title="Mobile Number"
                            titleKey="mobile_number"
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}


                    {transactionFilters.user_id_list.data.length > 0 && (
                        <CommonDropdown
                            list={transactionFilters.user_id_list}
                            title="UCC"
                            titleKey="ucc"
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                            searchable
                        />
                    )}

                    {transactionFilters.portfolio_list.data.length > 0 && (
                        <CommonDropdown
                            list={transactionFilters.portfolio_list}
                            title="Portfolio Name"
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                            searchable
                        />
                    )}

                    {transactionFilters.transaction_type_list.data.length > 0 && (
                        <CommonDropdown
                            list={transactionFilters.transaction_type_list}
                            title="Transaction Type"
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                            searchable
                        />
                    )}

                    {transactionFilters.transaction_mode_list.data.length > 0 && (
                        <CommonDropdown
                            list={transactionFilters.transaction_mode_list}
                            title="Transaction Mode"
                            searchable
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}

                    {transactionFilters.status_list.data.length > 0 && (
                        <CommonDropdown
                            list={transactionFilters.status_list}
                            title="Transaction Status"
                            searchable
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}

                    {transactionFilters.folio_list.data.length > 0 && (
                        <CommonDropdown
                            list={transactionFilters.folio_list}
                            title="Folio"
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                            searchable
                        />
                    )}

                    {transactionFilters.vendor_order_id.data.length > 0 && (
                        <CommonDropdown
                            list={transactionFilters.vendor_order_id}
                            title="Vendor Order ID"
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                            searchable
                        />
                    )}

                    {transactionFilters.order_id.data.length > 0 && (
                        <CommonDropdown
                            list={transactionFilters.order_id}
                            title="ID"
                            searchable
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}

                    {transactionFilters.is_deleted.data.length > 0 && (
                        <CommonDropdown
                            list={transactionFilters.is_deleted}
                            title="Is Deleted"
                            searchable
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}
                    <CommonDatePicker
                        title="Updated At LTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="created_at_lte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />

                    <CommonDatePicker
                        title="Updated At GTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="created_at_gte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                </FilterGrid>

                <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                    <CommonTable
                        rowKey={'id'}
                        data={data}
                        columns={tableColumns}
                        pagination={false}
                        scroll={{ x: 'overflow' }}
                        expandable={expandable}
                    />

                    <Paginator
                        totalRecords={total}
                        pageLimit={pageSize}
                        pageNeighbours={2}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </Grid>
            </Grid>
            <Grid>
                {showUpdateModal && (
                    <StyleModal
                        type="number"
                        title="Update Order"
                        visible={showUpdateModal}
                        onOk={(e) => handleUpdateSubmit(e)}
                        onCancel={() => handleUpdateModalCancel()}
                        footer={[
                            <Button
                                key="submit"
                                type="primary"
                                onClick={(e) => handleUpdateSubmit(e)}
                            >
                                Update
                            </Button>,
                        ]}
                    >
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Folio Number</LabelInput>
                                <Input
                                    id="folionumber"
                                    placeholder="Folio Number"
                                    name="folionumber"
                                    disabled
                                    value={folioNumber}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Scheme Name</LabelInput>
                                <Input
                                    id="schemename"
                                    placeholder="Scheme Name"
                                    name="schemename"
                                    disabled
                                    value={schemeName}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                                <LabelInput>Amount</LabelInput>
                                <Input
                                    id="Amount"
                                    placeholder="Amount"
                                    name="Amount"
                                    disabled
                                    value={amount}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={5}>
                            <Grid item xs={12} md={6}>
                            <LabelInput>Status</LabelInput>
                                <Select
                                    placeholder="Status"
                                    style={{ width: '100%' }}
                                    value={status}
                                    onChange={(e) => setStatus(e)}
                                >
                                    {orderStatusList
                                        && createOptionList(orderStatusList.data)}
                                </Select>
                                {updateErrors.status && (
                                    <ErrorText>{updateErrors.status}</ErrorText>
                                )}
                            </Grid>
                            {
                            (transactionType === 'fresh_purchase' || transactionType === 'additional_purchase') && (
                            <Grid item xs={12} md={6}>                                
                                <LabelInput>Payment Status</LabelInput>
                                <Select
                                    placeholder="Payment Status"
                                    style={{ width: '100%' }}
                                    value={paymentStatus}
                                    onChange={(e) => setPaymentStatus(e)}
                                    disabled={paymentStatusDisabled}
                                >
                                    {PaymentStatusList
                                        && createOptionList(PaymentStatusList.data)}
                                </Select>
                                {updateErrors.paymentStatus && (
                                    <ErrorText>{updateErrors.paymentStatus}</ErrorText>
                                )}
                            </Grid>
                            )}
                        </Grid>
                        <LabelInput>Remarks</LabelInput>
                                <Input
                                    id="remarks"
                                    placeholder="Remarks"
                                    name="Remarks"
                                    value={remarks}
                                    onChange={(e) => setRemarks(e.target.value)}
                                />                                            
                                {updateErrors.remarks && (
                                    <ErrorText>{updateErrors.remarks}</ErrorText>
                                )}
                    </StyleModal>
                )}
            </Grid>
        </Grid>
    );
};
export default Orders;
