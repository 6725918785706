import { call, put, takeEvery } from 'redux-saga/effects';
import swal from 'sweetalert';
import { AUTHENTICATE, LOGOUT } from '../actions/types';
import { UserLoginApi, UserLogoutApi } from '../api/loginUser';
import {
    loginSuccess,
    loginAuthFailed,
    logoutSuccess,
    logoutFailed,
} from '../actions/auth';
import { setKey } from '../util/storage';
import { clearSession } from '../util/helper';

function* login({ username, password }) {
    try {
        const response = yield call(UserLoginApi, { username, password });
        yield put(loginSuccess(response.data.data || response.data));
        setKey('session', response.data.data.session);
    } catch (error) {
        let errorMessage = 'Login Failed!!!';
        if (error.response) {
            errorMessage = (error.response.data || {}).error_desc;
        }
        swal('OOPS!', errorMessage, 'error');
        yield put(loginAuthFailed(error.response.data || error.response));
    }
}

function* logout() {
    try {
        const response = yield call(UserLogoutApi);
        yield put(logoutSuccess(response.data.data || response.data));
        clearSession();
    } catch (error) {
        clearSession();
        // let errorMessage = 'Logout Failed!!! please try again ';
        // if (error.response) {
        //     if (error.response.status === 400) {
        //         errorMessage = (error.response.data || {}).error_desc;
        //     }
        // }
        // swal('OOPS!', errorMessage, 'error');
        yield put(logoutFailed(error.response.data || error.response));
    }
}

export function* watchUserLogin() {
    yield takeEvery(AUTHENTICATE, login);
    yield takeEvery(LOGOUT, logout);
}
