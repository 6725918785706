import React from 'react';
import { withRouter } from 'react-router-dom';
import PollDetail from '../../component/PollDetail';
import { Wrapper } from './styles';

const PollDetailPage = (props) => (
    <Wrapper>
        <PollDetail pageProps={props} />
    </Wrapper>
);

export default withRouter(PollDetailPage);
