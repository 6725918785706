/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Button as But } from "@material-ui/core";
import swal from "sweetalert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/RadioButtonUncheckedRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Modal, Button, Select, Input } from "antd";
import { isEmpty, truncate, size } from "lodash";
import { RNS3 } from "react-native-aws3";
import styled from "styled-components";
import CommonTable from "../Common/Table";
import { Button as ButtonUpload } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  FilterGrid,
  FilterTitleWrapper,
  StyledButton,
  StyledInput,
  LabelInput,
  ErrorText,
  IconContainer,
  StyledDiv,
  RightGridItem,
  LeftGridItem,
  StyledDateTimePicker,
  useStyles,
  ImageContainer,
  DeleteMedia,
} from "./styles";
import CommonLoader from "../Common/Loader";
import CommonDropdown from "../Common/Dropdown";
import CommonDatePicker from "../Common/DatePicker";
import Paginator from "../Common/Paginator";
import {
  formattedValue,
  snakeToUpperCase,
  isValidImageUploadObject,
  dateFormating,
} from "../../util/helper";
import {
  GetBannerListApi,
  CreateBannerApi,
  UpdateBannerApi,
  DeleteBannerApi,
} from "../../api/banner";
import {
  getBannerImageUrl,
  getImageWithBannerCreds,
  s3Config,
} from "../../util/custom";
import Image from "../Image";
import { PreviewImage } from "../Image/styles";
import {
  BulkCreateSIPUpdate,
  BulkDeleteSIPUpdate,
  CreateSIPUpdate,
  DeleteSipUpdate,
  GetSipClientCode,
  GetSipUpdate,
  UpdateSipUpdate,
} from "../../api/getUpdateList";

const StyleModal = styled(Modal)`
  .ant-modal-header {
    background: ${(props) => props.theme.dark};
    color: ${(props) => props.theme.dark};
  }

  .ant-modal-body {
    background: ${(props) => props.theme.dark};
  }

  .ant-modal-title {
    color: ${(props) => props.theme.text};
  }
  .ant-modal-footer {
    background: ${(props) => props.theme.dark};
  }
  .ant-modal-close-icon {
    color: ${(props) => props.theme.text};
  }

  .ant-modal .sc-AzgDb .jcGGxa {
    padding: 0px;
  }
`;

const SipUpdate = () => {
  const auth = useSelector((state) => state.auth);
  const maxTitleCharacters = 25;
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [data, setData] = useState([]);
  // const [authorList, setAuthorList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(1);
  const { Option } = Select;
  const [redirectionParams, setRedirectionParams] = useState([
    { key: "", value: "" },
  ]);
  const [redirectionParams2, setRedirectionParams2] = useState([
    { key: "", value: "" },
  ]);
  const [filters, setFilters] = useState({
    page: currentPage,
    page_limit: pageSize,
    sort_by: "updated_at",
    sort_type: "desc",
    is_deleted: "false",
    is_active: "",
    client_code: "",
    sip_sue_date: "",
  });

  const defaultBanner = {
    client_code: "",
    client_name: "",
    sip_regn_nos: "",
    sub_broker_code: "",
    portfolio_name: "",
    portfolio_id: "",
    reg_dates: "",
    sip_amount: "",
    sip_due_date: "",
  };

  const defaultIsUpdatedObject = {
    startTime: false,
    endTime: false,
    banner_image: false,
  };

  const [currentBanner, setCurrentBanner] = useState(defaultBanner);
  const [errors, setErrors] = useState({});

  const [updateBanner, setUpdateBanner] = useState({});
  const [updateErrors, setUpdateErrors] = useState({});
  const [isUpdated, setIsUpdated] = useState(defaultIsUpdatedObject);
  const [client_code, setClientCodes] = useState([]);
  const getClientCode = () => {
    setLoading(true);
    GetSipClientCode()
      .then((res) => {
        let success = false;
        setLoading(false);
        if (res.status === 200) {
          if ((res.data || {}).success) {
            console.log("🚀 ~ file: index.jsx:156 ~ .then ~ res:", res);
            success = true;
            const apiData = (res.data || {}).data || {};
            setClientCodes(apiData);
          }
        }
        if (!success) {
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to fetch data";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  useEffect(() => {
    getClientCode();
  }, []);

  useEffect(() => {
    console.log(
      "🚀 ~ file: index.jsx:183 ~ SipUpdate ~ client_code:",
      client_code
    );
  }, [client_code]);


  const getBannerList = (params) => {
    setLoading(true);
    GetSipUpdate(params)
      .then((res) => {
        console.log("🚀 ~ file: index.jsx:156 ~ .then ~ res:", res);
        let success = false;
        if (res.status === 200) {
          success = true;
          const apiData = res.data;
          console.log("🚀 ~ file: index.jsx:154 ~ .then ~ apiData:", apiData);
          setData(apiData.results);
          setCurrentPage(apiData.page);
          setPageSize(apiData.page_limit);
          setTotal(apiData.total_count);
          setFilters(params);
          setLoading(false);
        }
        if (!success) {
          setLoading(false);
          swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to fetch data";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  const bulkDeleteSIP = ()=>{
    setLoading(true);
    BulkDeleteSIPUpdate().then(res=>res.data).then((res) => {
      if(res.success){
        swal("success!", "Successfully Deleted All SIP", "success");
        getBannerList(filters)
      }else {
        swal("Oops!", "Something went wrong", "error");
      }
    }).finally(()=>setLoading(false))
  }

  const renderIsPinned = (data) => (
    <div>
      {data.is_deleted === false && data.status === "published" ? (
        <IconContainer>
          <PushpinFilled
            style={{ fontSize: "22px" }}
            onClick={() => showPinPostModal(data)}
          />
        </IconContainer>
      ) : null}
    </div>
  );

  const deleteBanner = (id) => {
    swal({
      title: "Are you sure you want to delete this Update ?",
      text: "",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((success) => {
      if (success) {
        setLoading(true);
        const deleteParams = {
          id,
        };
        DeleteSipUpdate(deleteParams)
          .then((res) => {
            let apiSuccess = false;
            setLoading(false);
            if (res.status === 200) {
              if ((res.data || {}).success) {
                apiSuccess = true;
                getBannerList(filters);
                swal("success!", "Successfully Deleted Update", "success");
              }
            }
            if (!apiSuccess) {
              setLoading(false);
              swal("Oops!", ((res.data || {}).data || {}).error_desc, "error");
            }
          })
          .catch((error) => {
            setLoading(false);
            let errorMessage = "Failed to delete Update";
            if (error.response) {
              if (error.response.status === 400) {
                errorMessage = (error.response.data || {}).error_desc;
              }
            }
            swal("Oops!", errorMessage, "error");
          });
      }
    });
  };

  const showCreateBannerModal = () => {
    setRedirectionParams([{ key: "", value: "" }]);
    setRedirectionParams2([{ key: "", value: "" }]);
    setShowCreateModal(true);
  };

  const handleCreateBannerModalCancel = () => {
    setShowCreateModal(false);
    setCurrentBanner(defaultBanner);
    setErrors({});
  };

  const showUpdateBannerModal = (bannerObj) => {
    const updateObject = Object.create(defaultBanner);
    const arr = [];
    updateObject.id = bannerObj.id;
    updateObject.portfolio_id = bannerObj.portfolio_id;
    updateObject.portfolio_name = bannerObj.portfolio_name;
    updateObject.sip_amount = bannerObj.sip_amount;
    updateObject.sip_due_date = bannerObj.sip_due_date?.split("Z")[0];
    updateObject.client_code = bannerObj.client_code;
    updateObject.client_name = bannerObj.client_name;
    updateObject.sip_regn_nos = bannerObj.sip_regn_nos;
    updateObject.sub_broker_code = bannerObj.sub_broker_code;
    updateObject.reg_dates = bannerObj.reg_dates;
    setUpdateBanner(updateObject);
    setShowUpdateModal(true);
  };

  const handleUpdateModalCancel = () => {
    setShowUpdateModal(false);
    setUpdateBanner({});
    setUpdateErrors({});
    setIsUpdated(defaultIsUpdatedObject);
  };

  const renderEdit = (item) => (
    <IconContainer>
      <EditIcon onClick={() => showUpdateBannerModal(item)} />
    </IconContainer>
  );

  const generateBannerParams = (bannerObj, action) => {
    const paramsObj = {
      portfolio_id: bannerObj.portfolio_id,
      portfolio_name: bannerObj.portfolio_name,
      sip_amount: bannerObj.sip_amount,
      sip_due_date: bannerObj.sip_due_date,
      client_code: bannerObj.client_code,
      client_name: bannerObj.client_name,
      sip_regn_nos: bannerObj.sip_regn_nos,
      sub_broker_code: bannerObj.sub_broker_code,
      reg_dates: bannerObj.reg_dates,
    };
    if (action === "update") {
      paramsObj.id = bannerObj.id;
      console.log(
        "🚀 ~ file: index.jsx:295 ~ generateBannerParams ~ bannerObj.id:",
        bannerObj.id
      );
    }

    return paramsObj;
  };

  const createBanner = () => {
    const params = generateBannerParams(currentBanner, "create");
    console.log("🚀 ~ file: index.jsx:344 ~ createBanner ~ params:", params);
    setLoading(true);
    // console.log('Params ', params);
    CreateSIPUpdate(params)
      .then((res) => {
        let success = false;
        setLoading(false);
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            handleCreateBannerModalCancel();
            getBannerList(filters);
            swal("success!", "Successfully Created Update", "success");
          }
        }
        if (!success) {
          let err = ((res.data || {}).data || {}).error_desc;
          if (JSON.stringify(err).includes("duplicate")) {
            err = "This position is already exist.";
          }
          swal("Oops!", err, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Update Creation Failed";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
            if (
              JSON.stringify(error.response.data.error_desc).includes(
                "duplicate"
              )
            ) {
              errorMessage = "This position is already exist.";
            }
          }
        }
        swal("OOPS!", errorMessage, "error");
      });
  };

  const updateBannerApiCall = (isActiveParams = null) => {
    console.log(
      "🚀 ~ file: index.jsx:389 ~ updateBannerApiCall ~ updateBanner:",
      updateBanner
    );

    const updateParams =
      isActiveParams !== null
        ? isActiveParams
        : generateBannerParams(updateBanner, "update");
    setLoading(true);
    UpdateSipUpdate(updateParams)
      .then((res) => {
        setLoading(false);
        let success = false;
        if (res.status === 200) {
          if ((res.data || {}).success) {
            success = true;
            handleUpdateModalCancel();
            getBannerList(filters);
            swal("Yayy!", "Successfully Updated Update", "success");
          }
        }
        if (!success) {
          let err = ((res.data || {}).data || {}).error_desc;
          if (JSON.stringify(err).includes("duplicate")) {
            err = "This position is already exist.";
          }
          swal("Oops!", err, "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        let errorMessage = "Failed to Update Sip Update";
        if (error.response) {
          if (error.response.status === 400) {
            errorMessage = (error.response.data || {}).error_desc;
            if (
              JSON.stringify(error.response.data.error_desc).includes(
                "duplicate"
              )
            ) {
              errorMessage = "This position is already exist.";
            }
          }
        }
        swal("Oops!", errorMessage, "error");
      });
  };

  const tableColumns = [
    {
      title: "Client Code",
      dataIndex: "client_code",
      key: "client_code",
      width: 200,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      width: 70,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    // {
    //   title: "SIP Regn Number",
    //   dataIndex: "sip_regn_nos",
    //   key: "sip_regn_nos",
    //   width: 70,
    //   align: "center",
    //   render: (title) => <div>{formattedValue(title)}</div>,
    // },
    {
      title: "Sub Broker code",
      dataIndex: "sub_broker_code",
      key: "sub_broker_code",
      width: 70,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "Portfolio Name",
      dataIndex: "portfolio_name",
      key: "portfolio_name",
      width: 70,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "SIP Amount",
      dataIndex: "sip_amount",
      key: "sip_amount",
      width: 200,
      align: "center",
      render: (title) => <div>{formattedValue(title)}</div>,
    },
    {
      title: "SIP DUE DATE",
      dataIndex: "sip_due_date",
      key: "sip_due_date",
      render: (value) => <p>{dateFormating(value)}</p>,
      width: 200,
      align: "center",
    },
  ];

  if (!JSON.parse(filters.is_deleted || false)) {
    tableColumns.push({
      title: "Edit",
      dataIndex: "id",
      key: "id",
      render: (text, item) => renderEdit(item),
      align: "center",
    });
    tableColumns.push({
      title: "Delete",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, item) =>
        item.is_deleted === false ? (
          <IconContainer>
            <DeleteIcon onClick={() => deleteBanner(item.id)} />
          </IconContainer>
        ) : null,
    });
  }

  const createOptionList = (list) => {
    const optionArray = [];
    list.map((item) => {
      optionArray.push(
        <Option key={item.id} value={item.id}>
          {item.title}
        </Option>
      );
    });

    return optionArray;
  };

  const handleSelectChange = (value, key, action) => {
    if (action === "create") {
      if (key === "redirection_type-1") {
        setRedirectionParams([{ key: "", value: "" }]);
        setCurrentBanner({
          ...currentBanner,
          cta1: {
            redirection_type: value,
            redirection_url: "",
            redirection_in_app: "",
          },
        });
      } else if (key === "redirection_type-2") {
        setRedirectionParams2([{ key: "", value: "" }]);
        setCurrentBanner({
          ...currentBanner,
          cta2: {
            redirection_type: value,
            redirection_url: "",
            redirection_in_app: "",
          },
        });
      } else if (key === "redirection_in_app-1") {
        setRedirectionParams([{ key: "", value: "" }]);
        setCurrentBanner({
          ...currentBanner,
          cta1: { ...currentBanner.cta1, redirection_in_app: value },
        });
      } else if (key === "redirection_in_app-2") {
        setRedirectionParams2([{ key: "", value: "" }]);
        setCurrentBanner({
          ...currentBanner,
          cta2: { ...currentBanner.cta2, redirection_in_app: value },
        });
      } else if (key === "is_active") {
        setCurrentBanner({ ...currentBanner, is_active: value });
      } else if (key === "placement") {
        setCurrentBanner({ ...currentBanner, placement: value });
      } else if (key === "sub_placement") {
        setCurrentBanner({ ...currentBanner, sub_placement: value });
      } else if (key === "target_users") {
        setCurrentBanner({ ...currentBanner, target_users: value });
      } else if (key === "ui_version") {
        setCurrentBanner({ ...currentBanner, ui_version: value });
      }
    } else if (action === "update") {
      if (key === "redirection_type-1") {
        setRedirectionParams([{ key: "", value: "" }]);
        setUpdateBanner({
          ...currentBanner,
          cta1: {
            redirection_type: value,
            redirection_url: "",
            redirection_in_app: "",
          },
        });
      } else if (key === "redirection_type-2") {
        setRedirectionParams2([{ key: "", value: "" }]);
        setUpdateBanner({
          ...currentBanner,
          cta2: {
            redirection_type: value,
            redirection_url: "",
            redirection_in_app: "",
          },
        });
      } else if (key === "redirection_in_app-1") {
        setRedirectionParams([{ key: "", value: "" }]);
        setUpdateBanner({
          ...currentBanner,
          cta1: { ...currentBanner.cta1, redirection_in_app: value },
        });
      } else if (key === "redirection_in_app-2") {
        setRedirectionParams2([{ key: "", value: "" }]);
        setUpdateBanner({
          ...currentBanner,
          cta2: { ...currentBanner.cta2, redirection_in_app: value },
        });
      } else if (key === "is_active") {
        setUpdateBanner({ ...updateBanner, is_active: value });
      } else if (key === "placement") {
        setUpdateBanner({ ...updateBanner, placement: value });
      } else if (key === "sub_placement") {
        setUpdateBanner({ ...updateBanner, sub_placement: value });
      } else if (key === "target_users") {
        setUpdateBanner({ ...updateBanner, target_users: value });
      } else if (key === "ui_version") {
        setUpdateBanner({ ...updateBanner, ui_version: value });
      }
    }
  };

  const onFileChange = (banner, action, key) => {
    if (action === "create") {
      if (!banner) {
        setCurrentBanner({ ...currentBanner, banner_image: {} });
        return;
      }
      const file = banner.target?.files[0];
      setCurrentBanner({
        ...currentBanner,
        banner_image: { ...currentBanner.banner_image, [key]: file },
      });
    } else if (action === "update") {
      setIsUpdated({ ...isUpdated, banner_image: true });
      if (!banner) {
        setUpdateBanner({ ...updateBanner, banner_image: {} });
        return;
      }
      const file = banner.target?.files[0];
      setUpdateBanner({
        ...updateBanner,
        banner_image: { ...updateBanner.banner_image, [key]: file },
      });
    }
  };

  useEffect(() => {
    filters.page = currentPage;
    filters.page_limit = pageSize;
    getBannerList(filters);
  }, [currentPage, pageSize]);

  const validateFields = (action) => {
    const validationErrors = {};
    let isParamErrors = false;
    let bannerObj = {};

    if (action === "create") {
      bannerObj = currentBanner;
    } else if (action === "update") {
      bannerObj = updateBanner;
    }
    if (bannerObj.client_code.length === 0) {
      validationErrors.client_code = "Required Client Code";
    }

    if (bannerObj.client_name.length === 0) {
      validationErrors.client_name = "Required Client Name";
    }

    if (bannerObj.sip_regn_nos.length === 0) {
      validationErrors.sip_regn_nos = "Required Sip Registration Numbers";
    }

    if (bannerObj.sub_broker_code.length === 0) {
      validationErrors.sub_broker_code = "Required Sup Broker Code";
    }
    if (bannerObj.portfolio_name.length === 0) {
      validationErrors.portfolio_name = "Required Portfolio Name";
    }
    if (bannerObj.portfolio_id.length === 0) {
      validationErrors.portfolio_id = "Required Portfolio Id";
    }
    if (bannerObj.reg_dates.length === 0) {
      validationErrors.reg_dates = "Required Registration Dates";
    }
    if (bannerObj.sip_amount.length === 0) {
      validationErrors.sip_amount = "Required Sip Amount";
    }
    if (bannerObj.sip_due_date.length === 0) {
      validationErrors.sip_due_date = "Required Sip Due Date";
    }

    if (!isParamErrors && Object.keys(validationErrors).length === 1) {
      return {};
    }
    return validationErrors;
  };

  const handleCreateBannerSubmit = async (banner) => {
    banner.preventDefault();
    const validationErrors = validateFields("create");
    setErrors(validationErrors);
    if (true) {
      createBanner();
    }
  };

  const handleUpdateBannerSubmit = (banner) => {
    banner.preventDefault();
    const validationUpdateErrors = validateFields("update");
    setUpdateErrors(validationUpdateErrors);
    if (true) {
      updateBannerApiCall();
    }
  };

  const handleFilterChange = (filters) => {
    getBannerList(filters);
  };

  const IsActive = {
    key: "is_active",
    data: [
      {
        id: "true",
        title: "True",
        selected: false,
      },
      {
        id: "false",
        title: "False",
        selected: true,
      },
    ],
  };

  const IsDeleted = {
    key: "is_deleted",
    data: [
      {
        id: "true",
        title: "True",
        selected: false,
      },
      {
        id: "false",
        title: "False",
        selected: true,
      },
    ],
  };

  const SubPlacement = {
    key: "sub_placement",
    data: [
      {
        id: "topScroll",
        title: "Top Scroll",
        selected: true,
      },
      {
        id: "playToWin",
        title: "Play To Win",
        selected: false,
      },
      {
        id: "openYourAccount",
        title: "Open Your Account",
        selected: false,
      },
      {
        id: "getLoans",
        title: "Get Loans",
        selected: false,
      },
      {
        id: "community",
        title: "Community",
        selected: false,
      },
      {
        id: "offersForYou",
        title: "Offers For You",
        selected: false,
      },
    ],
  };

  const Placement = {
    key: "placement",
    data: [
      {
        id: "Home",
        title: "Home",
        selected: true,
      },
      {
        id: "Community",
        title: "Community",
        selected: false,
      },
    ],
  };

  const TargetUsers = {
    key: "target_users",
    data: [
      {
        id: "all",
        title: "All",
        selected: true,
      },
      {
        id: "invested",
        title: "Invested",
        selected: false,
      },
      {
        id: "non_invested",
        title: "Non Invested",
        selected: false,
      },
      {
        id: "signed_up",
        title: "Signed Up",
        selected: false,
      },
    ],
  };

  const UIVersion = {
    key: "ui_version",
    data: [
      {
        id: "v1",
        title: "v1",
        selected: true,
      },
      {
        id: "v2",
        title: "v2",
        selected: false,
      },
    ],
  };

  const RedirectionInApp = {
    key: "redirection_in_app",
    data: [
      {
        id: "Home",
        title: "Home",
        selected: true,
      },
      {
        id: "MyMoney",
        title: "MyMoney",
        selected: false,
      },
      {
        id: "More",
        title: "More",
        selected: false,
      },
      {
        id: "Profile",
        title: "Profile",
        selected: true,
      },
      {
        id: "About",
        title: "About",
        selected: true,
      },
      {
        id: "Faq",
        title: "Faq",
        selected: false,
      },
      {
        id: "Portfolio",
        title: "Portfolio",
        selected: false,
      },
      {
        id: "Invest",
        title: "Invest",
        selected: true,
      },
      {
        id: "InvestMore",
        title: "Invest More",
        selected: false,
      },
      {
        id: "InvestSummary",
        title: "Invest Summary",
        selected: false,
      },
      {
        id: "Learn",
        title: "Learn",
        selected: true,
      },
      {
        id: "Transactions",
        title: "Transactions",
        selected: true,
      },
      {
        id: "TransactionDetails",
        title: "Transaction Details",
        selected: false,
      },
      {
        id: "Notifications",
        title: "Notifications",
        selected: false,
      },
      {
        id: "MoneyJourneyPosts",
        title: "Money Journey Posts",
        selected: true,
      },
      {
        id: "Support",
        title: "Support",
        selected: true,
      },
      {
        id: "Community",
        title: "Community",
        selected: false,
      },
      {
        id: "Rewards",
        title: "Rewards",
        selected: false,
      },
      {
        id: "InvestmentProfileIntroduction",
        title: "Investment Profile Introduction",
        selected: true,
      },
      {
        id: "InvestmentProfileDetails",
        title: "Investment Profile Details",
        selected: false,
      },
      {
        id: "AccountOpeningPage",
        title: "Account Opening Page",
        selected: false,
      },
      {
        id: "PrivacyPolicy",
        title: "Privacy Policy",
        selected: true,
      },
      {
        id: "GoalCalculator",
        title: "Goal Calculator",
        selected: false,
      },
      {
        id: "RetirementCalculator",
        title: "Retirement Calculator",
        selected: false,
      },
      {
        id: "TaxSavingCalculator",
        title: "Tax Saving Calculator",
        selected: true,
      },
      {
        id: "PostDetailedView",
        title: "Post Detailed View",
        selected: false,
      },
      {
        id: "InvestmentWithdraw",
        title: "Retirement Calculator",
        selected: false,
      },
      {
        id: "LoanLending",
        title: "Loan Lending",
        selected: false,
      },
      {
        id: "Home-calculator",
        title: "Home Calculator",
        selected: false,
      },
      {
        id: "Referral",
        title: "referral",
        selected: false,
      },
    ],
  };

  const sortTypeList = {
    key: "sort_type",
    data: [
      {
        id: "asc",
        title: "Asc",
        selected: false,
      },
      {
        id: "desc",
        title: "Desc",
        selected: true,
      },
    ],
  };

  const sortByList = {
    key: "sort_by",
    data: [
      {
        id: "sub_broker_code",
        title: "Sub Broker Code",
        selected: true,
      },
    ],
  };

  const addParamsFunc = () => {
    const newArr = [...redirectionParams];
    newArr.push({ key: "", value: "" });
    setRedirectionParams(newArr);
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  const handleFileInputChange = async (e) => {
    try {
      setLoading(true);
      console.log(e.target.files[0]);
      let file = e.target.files[0];
      const response = await getBase64(file);
      file["base64"] = await response;
      let param = {
        fileBase64: file["base64"].split(",")[1],
      };
      await BulkCreateSIPUpdate(param);
      await getBannerList(filters);
      swal("Success", "Excel Sheet Uploaded", "success");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(
        "🚀 ~ file: index.jsx:1076 ~ handleFileInputChange ~ err:",
        err.response.data.errors
      );
      if (err.response.status === 504) {
        let errorMessage = "Your request is being processed \n Please check after 5 minutes";
        swal("Oops!", errorMessage, "error");
        return
      }
      if (Array.isArray(err.response.data.errors))
        swal("Oops!", err.response.data.errors[0], "error");
      else swal("Oops!", err.response.data.errors, "error");
    }
  };

  const removeParamsFunc = () => {
    const newArr = [...redirectionParams];
    if (newArr.length > 1) {
      setRedirectionParams(newArr);
      newArr.pop();
    }
  };
  const addParamsFunc2 = () => {
    const newArr = [...redirectionParams2];
    newArr.push({ key: "", value: "" });
    setRedirectionParams2(newArr);
  };

  const removeParamsFunc2 = () => {
    const newArr = [...redirectionParams2];
    if (newArr.length > 1) {
      setRedirectionParams2(newArr);
      newArr.pop();
    }
  };
  const classes = useStyles();
  const fileuploadref = useRef();

  const handleDateChange = (dateTime, key, action) => {
    if (dateTime.toString() === "Invalid Date") {
      // set error for invalid date
      return;
    }
    if (action === "create") {
      if (dateTime === null || dateTime === undefined) {
        setCurrentBanner({ ...currentBanner, sip_due_date: new Date() });
      } else {
        setCurrentBanner({ ...currentBanner, sip_due_date: dateTime });
      }
    } else if (action === "update") {
      setIsUpdated({ ...isUpdated, startTime: true });
      if (dateTime === null || dateTime === undefined) {
        setUpdateBanner({ ...updateBanner, sip_due_date: new Date() });
      } else {
        setUpdateBanner({ ...updateBanner, sip_due_date: dateTime });
      }
    }
  };
  return (
    <Grid container spacing={2}>
      <CommonLoader
        isLoading={loading}
        size="large"
        sentences={["please wait ..."]}
        type="pacman"
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {!loading && (
          <input
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            className={classes.input}
            ref={fileuploadref}
            id={"Fileupload"}
            onChange={handleFileInputChange}
            type="file"
          />
        )}
 
        <StyledButton
          onClick={() => {
            fileuploadref.current.click();
          }}
        >
          Upload
        </StyledButton>

        <StyledButton
          onClick={() => {
            swal({
              title: "Are you sure you want to delete all SIP?",
              text: "",
              icon: "warning",  
              buttons: true,
              dangerMode: true,
            }).then((success) => {
              if(success){
                bulkDeleteSIP()
              }
            })        
          }}
        >
          Delete All
        </StyledButton>

      </Grid>

      <Grid
        container
        spacing={2}
        style={{
          paddingRight: "1rem",
          paddingLeft: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
          <FilterTitleWrapper>Filters</FilterTitleWrapper>
          <CommonDropdown
            list={IsDeleted}
            title="Is Deleted"
            onChange={(filters) => handleFilterChange(filters)}
            filters={filters}
          />
          <CommonDatePicker
            title="Sip Due Date"
            type="inline"
            dateFormat="dd/MM/yyyy"
            filterKey="sip_sue_date"
            onChange={(filters) =>{
              if(filters?.sip_sue_date == undefined || filters?.sip_sue_date == null){
                filters.sip_sue_date = ""
              }
              console.log(filters)
              handleFilterChange(filters)
            }}
            filters={filters}
          />

          {client_code.length > 0 && (
            <CommonDropdown
              list={{
                key: "client_code",
                data: client_code,
              }}
              title="Client Code"
              searchable
              onChange={(filters) => handleFilterChange(filters)}
              filters={filters}
            />
          )}
        </FilterGrid>
        <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
          <CommonTable
            data={data}
            columns={tableColumns}
            pagination={false}
            scroll={{ x: "overflow" }}
          />
          <Paginator
            totalRecords={total}
            pageLimit={pageSize}
            pageNeighbours={2}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Grid>
      </Grid>
      <Grid>
        {showCreateModal && (
          <StyleModal
            title="Add Update"
            visible={showCreateModal}
            onOk={(e) => handleCreateBannerSubmit(e)}
            onCancel={() => handleCreateBannerModalCancel()}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={(e) => handleCreateBannerSubmit(e)}
                loading={loading}
              >
                Submit
              </Button>,
            ]}
          >
            <StyledDiv>
              <LabelInput>User ID</LabelInput>
              <Input
                placeholder="user Id"
                name="userId"
                value={currentBanner.user_id}
                onChange={(e) =>
                  setCurrentBanner({
                    ...currentBanner,
                    user_id: e.target.value,
                  })
                }
              />
              {errors.user_id && <ErrorText>{errors.user_id}</ErrorText>}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>PortFolio ID</LabelInput>
              <Input
                placeholder="PortFolio Id"
                name="portfolio_id"
                value={currentBanner.portfolio_id}
                onChange={(e) =>
                  setCurrentBanner({
                    ...currentBanner,
                    portfolio_id: e.target.value,
                  })
                }
              />
              {errors.portfolio_id && (
                <ErrorText>{errors.portfolio_id}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>PortFolio Name</LabelInput>
              <Input
                placeholder="PortFolio Name"
                name="portfolio_name"
                value={currentBanner.portfolio_name}
                onChange={(e) =>
                  setCurrentBanner({
                    ...currentBanner,
                    portfolio_name: e.target.value,
                  })
                }
              />
              {errors.portfolio_name && (
                <ErrorText>{errors.portfolio_name}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>SIP Due Date</LabelInput>
              <StyledDateTimePicker
                clearable="true"
                margin="normal"
                variant="inline"
                showTodayButton
                disablePast
                id="create-start-time-picker"
                value={currentBanner.sip_due_date}
                onChange={(e) => handleDateChange(e, "sip_due_date", "create")}
                KeyboardButtonProps={{
                  "aria-label": "select end date",
                }}
              />
              {errors.sip_due_date && (
                <ErrorText>{errors.sip_due_date}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>SIP Amount</LabelInput>
              <Input
                placeholder="SIP Amount"
                name="sip_amount"
                value={currentBanner.sip_amount}
                onChange={(e) =>
                  setCurrentBanner({
                    ...currentBanner,
                    sip_amount: e.target.value,
                  })
                }
              />
              {errors.sip_amount && <ErrorText>{errors.sip_amount}</ErrorText>}
            </StyledDiv>
          </StyleModal>
        )}
      </Grid>
      <Grid>
        {showUpdateModal && (
          <StyleModal
            title="Edit Update"
            visible={showUpdateModal}
            onOk={(e) => handleUpdateBannerSubmit(e)}
            onCancel={() => handleUpdateModalCancel()}
            footer={[
              <Button
                key="submit"
                type="primary"
                onClick={(e) => handleUpdateBannerSubmit(e)}
                loading={loading}
              >
                Update
              </Button>,
            ]}
          >
            <StyledDiv>
              <LabelInput>Client Code</LabelInput>
              <Input
                placeholder="Client Code"
                name="client_code"
                value={updateBanner.client_code}
                disabled
                onChange={(e) =>
                  setUpdateBanner({
                    ...updateBanner,
                    client_code: e.target.value,
                  })
                }
              />
              {updateErrors.client_code && (
                <ErrorText>{updateErrors.client_code}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Client Name</LabelInput>
              <Input
                placeholder="Client Name"
                name="client_name"
                value={updateBanner.client_name}
                disabled
                onChange={(e) =>
                  setUpdateBanner({
                    ...updateBanner,
                    client_name: e.target.value,
                  })
                }
              />
              {updateErrors.client_name && (
                <ErrorText>{updateErrors.client_name}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Sub Broker Code</LabelInput>
              <Input
                placeholder="Sub Broker Code"
                name="sub_broker_code"
                value={updateBanner.sub_broker_code}
                disabled
                onChange={(e) =>
                  setUpdateBanner({
                    ...updateBanner,
                    sub_broker_code: e.target.value,
                  })
                }
              />
              {updateErrors.sub_broker_code && (
                <ErrorText>{updateErrors.sub_broker_code}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>PortFolio Id</LabelInput>
              <Input
                placeholder="Portfolio Id"
                name="portfolio_id"
                value={updateBanner.portfolio_id}
                disabled
                onChange={(e) =>
                  setUpdateBanner({
                    ...updateBanner,
                    portfolio_id: e.target.value,
                  })
                }
              />
              {updateErrors.portfolio_id && (
                <ErrorText>{updateErrors.portfolio_id}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Portfolio Name</LabelInput>
              <Input
                placeholder="Porfolio Name"
                name="portfolio_name"
                value={updateBanner.portfolio_name}
                disabled
                onChange={(e) =>
                  setUpdateBanner({
                    ...updateBanner,
                    portfolio_name: e.target.value,
                  })
                }
              />
              {updateErrors.portfolio_name && (
                <ErrorText>{updateErrors.portfolio_name}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Sip Due Date</LabelInput>
              <StyledDateTimePicker
                clearable="true"
                margin="normal"
                variant="inline"
                showTodayButton
                disablePast
                id="create-start-time-picker"
                value={updateBanner?.sip_due_date}
                onChange={(e) => handleDateChange(e, "startTime", "update")}
                KeyboardButtonProps={{
                  "aria-label": "select end date",
                }}
              />
              {updateErrors.sip_due_date && (
                <ErrorText>{updateErrors.sip_due_date}</ErrorText>
              )}
            </StyledDiv>
            <StyledDiv>
              <LabelInput>Sip Amount</LabelInput>
              <Input
                placeholder="Sip Amount"
                type="number"
                name="sip_amount"
                value={updateBanner.sip_amount}
                onChange={(e) =>
                  setUpdateBanner({
                    ...updateBanner,
                    sip_amount: e.target.value,
                  })
                }
              />
              {updateErrors.sip_amount && (
                <ErrorText>{updateErrors.sip_amount}</ErrorText>
              )}
            </StyledDiv>
          </StyleModal>
        )}
      </Grid>
    </Grid>
  );
};

export default SipUpdate;
