/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Form } from 'antd';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import size from 'lodash/size';
import toString from 'lodash/toString';
import swal from 'sweetalert';
import {
    Wrapper,
    FormWrapper,
    StyleInput,
    SubmitButton,
    StyleLabel,
    PasswordInput,
    InputWrapper,
    HeadingText,
    ErrorText,
} from './styles';
import { ResetPasswordApi } from '../../api/forgotpassword';
import CommonLoader from '../Common/Loader';
import { getKey, removeKey } from '../../util/storage';

const ResetPassword = (props) => {
    const [loading, setLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [errors, setErrors] = useState({});
    const { pageProps } = props;
    const auth = useSelector((state) => state.auth);
    const { history } = pageProps;

    const resetPasswordFunction = () => {
        setLoading(true);
        const params = {
            username: toString(auth.admin.emp_code) || getKey('empCode'),
            new_password: newPassword,
            confirm_new_password: confirmNewPassword,
            session: toString(auth.session) || getKey('session'),
            first_name: firstName,
            middle_name: middleName,
            last_name: lastName,
        };

        ResetPasswordApi(params)
            .then((res) => {
                let success = false;
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        swal(
                            'success!',
                            ' successfully Reset Password',
                            'success',
                        );
                        removeKey('session');
                        history.push('/');
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error',
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to Reset Password';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('OOPS!', errorMessage, 'error');
            });
    };

    const validateFields = (values) => {
        const validationErrors = {};
        // Verification Code Errors
        if (!size(trim(values.firstName))) {
            validationErrors.firstName = 'Required Name';
        }

        // New Password Errors
        if (!size(trim(values.newPassword))) {
            validationErrors.newPassword = 'Required Password';
        } else if (size(trim(values.newPassword)) < 8) {
            validationErrors.newPassword = 'Password must be at least 8 characters';
        }

        // Confirm New Password
        if (!size(trim(values.confirmNewPassword))) {
            validationErrors.confirmNewPassword = 'Required Password';
        } else if (size(trim(values.confirmNewPassword)) < 8) {
            validationErrors.confirmNewPassword = 'Password must be at least 8 characters';
        } else if (values.confirmNewPassword !== values.newPassword) {
            validationErrors.confirmNewPassword = '*password doesnot match';
        }

        return validationErrors;
    };

    const submit = (event) => {
        event.preventDefault();
        const values = {
            firstName,
            newPassword,
            confirmNewPassword,
        };
        const validationErrors = validateFields(values);
        setErrors(validationErrors);
        if (isEmpty(validationErrors)) {
            resetPasswordFunction();
        }
    };

    return (
        <Wrapper>
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />
            <HeadingText>Reset Password & Update Profile</HeadingText>
            <FormWrapper>
                <Form>
                    <div>
                        <InputWrapper>
                            <StyleLabel>Enter-new-password</StyleLabel>
                            <PasswordInput
                                placeholder="Enter-new-password"
                                name="password"
                                iconRender={(visible) => (visible ? (
                                        <EyeTwoTone />
                                    ) : (
                                        <EyeInvisibleOutlined />
                                    ))
                                }
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            {errors.newPassword && (
                                <ErrorText>{errors.newPassword}</ErrorText>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <StyleLabel>Re-enter-new-password</StyleLabel>
                            <PasswordInput
                                name="confirm_Password"
                                placeholder="Re-enter-new-password"
                                iconRender={(visible) => (visible ? (
                                        <EyeTwoTone />
                                    ) : (
                                        <EyeInvisibleOutlined />
                                    ))
                                }
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)
                                }
                            />
                            {errors.confirmNewPassword && (
                                <ErrorText>
                                    {errors.confirmNewPassword}
                                </ErrorText>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <StyleLabel>First Name</StyleLabel>
                            <StyleInput
                                placeholder="First Name"
                                name="first_name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            {errors.firstName && (
                                <ErrorText>{errors.firstName}</ErrorText>
                            )}
                        </InputWrapper>
                        <InputWrapper>
                            <StyleLabel>Middle Name (optional)</StyleLabel>
                            <StyleInput
                                placeholder="Middle Name "
                                name="middle_name"
                                value={middleName}
                                onChange={(e) => setMiddleName(e.target.value)}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <StyleLabel>Last Name (optional)</StyleLabel>
                            <StyleInput
                                placeholder="Last Name"
                                name="last_name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </InputWrapper>
                        <SubmitButton onClick={submit}>SUBMIT</SubmitButton>
                    </div>
                </Form>
            </FormWrapper>
        </Wrapper>
    );
};

ResetPassword.propTypes = {
    pageProps: PropTypes.shape({}).isRequired,
};

export default ResetPassword;
