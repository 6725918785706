/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
// import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink, withRouter } from "react-router-dom";

import { lxmeLogo } from "../../constant/imageVariables";
import ThemeToggle from "../Common/ThemeToggle";
import Logout from "../Common/Logout";
import { formattedValue } from "../../util/helper";
// import { setCognitoLogout } from "../../redux/user/user.actions";

import {
  NavbarStyled,
  StyledMenuIcon,
  NavAction,
  Overlay,
  NavMenu,
  MenuList,
  MenuListItem,
  MenuListItemDark,
  MenuItemDarkLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  NavbarRightItems,
  UserInfoItem,
} from "./style";
import { getAdmin } from "../../util/storage";

const NavBar = () => {
  // const userRedux = useSelector((state) => state.user);
  // const userIP = useSelector((state) => state.ip.userIP);
  // const { userScopeData, employeeData } = userRedux;
  // const dispatch = useDispatch();
  // This should come out of redux
  const [userScopeData, setUserScopeData] = useState({
    admin_scope: false,
    calculators_scope: false,
    community_scope: false,
    users_scope: false,
    investment_scope: false,
    analytics_scope: false,
    event_scope: false,
    investment_user_mandate_scope: false,
    updates_scope: false,
  });
  const admin = getAdmin();

  const [menuActive, setMenuActive] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [fullName, setFullName] = useState("");
  const [expanded, setExpanded] = useState();

  useEffect(() => {
    const nickName = formattedValue(admin.firstName);
    if (nickName !== "-") {
      setDisplayName(nickName);
      let name = admin.firstName;
      if (formattedValue(admin.middleName) !== "-") {
        name += ` ${admin.middleName}`;
      }
      if (formattedValue(admin.lastName) !== "-") {
        name += ` ${admin.lastName}`;
      }
      setFullName(name);
    } else {
      setFullName("-");
    }
    if (admin.role === "admin") {
      setUserScopeData({
        admin_scope: true,
        calculators_scope: true,
        community_scope: true,
        users_scope: true,
        investment_scope: true,
        analytics_scope: true,
        event_scope: true,
        investment_user_mandate_scope: true,
        updates_scope: true,
      });
    } else if (admin.role === "ops") {
      setUserScopeData({
        admin_scope: false,
        calculators_scope: false,
        community_scope: true,
        users_scope: true,
        investment_scope: false,
        analytics_scope: false,
        event_scope: true,
        investment_user_mandate_scope: true,
        updates_scope: true,
      });
    }
  }, []);

  const handleMenuClick = (event) => {
    event.preventDefault();
    setMenuActive(true);
  };

  const handleCloseMenu = () => {
    setMenuActive(false);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <NavbarStyled>
        <StyledMenuIcon onClick={(e) => handleMenuClick(e)}>
          {/* <img src={menu} width="25" height="18" alt="LXME menu" /> */}
        </StyledMenuIcon>
        <NavAction as={Link} title="LXME" to="/community/post">
          <img src={lxmeLogo} alt="LXME logo" />
        </NavAction>

        <NavbarRightItems>
          <Logout />
          <ThemeToggle />
          {Boolean(userScopeData) && (
            <UserInfoItem showcursor>{`Hi ${displayName}`}</UserInfoItem>
          )}
        </NavbarRightItems>
      </NavbarStyled>

      <Overlay onClick={handleCloseMenu} visible={menuActive} />
      <NavMenu visible={menuActive}>
        <MenuList>
          <MenuListItemDark as="div">
            <span>{Boolean(admin) && fullName}</span>
            <MenuItemDarkLabel>
              {Boolean(admin) && admin.empCode}
            </MenuItemDarkLabel>
          </MenuListItemDark>
          {Boolean(userScopeData) && (
            <>
              {Boolean(userScopeData.analytics_scope) && (
                <Accordion
                  expanded={expanded === "analytics"}
                  onChange={handleChange("analytics")}
                >
                  <AccordionSummary aria-controls="analytics" id="analytics">
                    <MenuListItem as="div">ANALYTICS</MenuListItem>
                  </AccordionSummary>

                  <AccordionDetails>
                    {Boolean(userScopeData.analytics_scope) && (
                      <MenuListItem
                        as={NavLink}
                        submenu="true"
                        onClick={handleCloseMenu}
                        exact
                        to="/analytics/api"
                      >
                        API
                      </MenuListItem>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
              {Boolean(userScopeData.admin_scope) && (
                <Accordion
                  expanded={expanded === "application"}
                  onChange={handleChange("application")}
                >
                  <AccordionSummary
                    aria-controls="application"
                    id="application"
                  >
                    <MenuListItem as="div">APPLICATION</MenuListItem>
                  </AccordionSummary>

                  <AccordionDetails>
                    {Boolean(userScopeData.analytics_scope) && (
                      <MenuListItem
                        as={NavLink}
                        submenu="true"
                        onClick={handleCloseMenu}
                        exact
                        to="/application/application-version-history"
                      >
                        VERSION HISTORY
                      </MenuListItem>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
              {userScopeData.admin_scope && (
                <MenuListItem
                  as={NavLink}
                  onClick={handleCloseMenu}
                  exact
                  to="/audit-event"
                >
                  AUDIT EVENT
                </MenuListItem>
              )}

              {userScopeData.calculators_scope && (
                <MenuListItem
                  as={NavLink}
                  onClick={handleCloseMenu}
                  exact
                  to="/calculator"
                >
                  CALCULATOR
                </MenuListItem>
              )}

              {Boolean(userScopeData.community_scope) && (
                <Accordion
                  expanded={expanded === "community"}
                  onChange={handleChange("community")}
                >
                  <AccordionSummary aria-controls="community" id="community">
                    <MenuListItem as="div">COMMUNITY</MenuListItem>
                  </AccordionSummary>
                  <AccordionDetails>
                    {Boolean(userScopeData.community_scope) && (
                      <MenuListItem
                        as={NavLink}
                        submenu="true"
                        onClick={handleCloseMenu}
                        exact
                        to="/community/blog"
                      >
                        BLOG
                      </MenuListItem>
                    )}
                  </AccordionDetails>
                  <AccordionDetails>
                    {Boolean(userScopeData.community_scope) && (
                      <MenuListItem
                        as={NavLink}
                        submenu="true"
                        onClick={handleCloseMenu}
                        exact
                        to="/community/poll"
                      >
                        POLL
                      </MenuListItem>
                    )}
                  </AccordionDetails>
                  <AccordionDetails>
                    {Boolean(userScopeData.community_scope) && (
                      <MenuListItem
                        as={NavLink}
                        submenu="true"
                        onClick={handleCloseMenu}
                        exact
                        to="/community/post"
                      >
                        POST
                      </MenuListItem>
                    )}
                  </AccordionDetails>
                  <AccordionDetails>
                    {Boolean(userScopeData.community_scope) && (
                      <MenuListItem
                        as={NavLink}
                        submenu="true"
                        onClick={handleCloseMenu}
                        exact
                        to="/community/question"
                      >
                        QUESTION
                      </MenuListItem>
                    )}
                  </AccordionDetails>
                  <AccordionDetails>
                    {Boolean(userScopeData.community_scope) && (
                      <MenuListItem
                        as={NavLink}
                        submenu="true"
                        onClick={handleCloseMenu}
                        exact
                        to="/community/report"
                      >
                        REPORT
                      </MenuListItem>
                    )}
                  </AccordionDetails>
                  <AccordionDetails>
                    {Boolean(userScopeData.community_scope) && (
                      <MenuListItem
                        as={NavLink}
                        submenu="true"
                        onClick={handleCloseMenu}
                        exact
                        to="/community/reward"
                      >
                        REWARD
                      </MenuListItem>
                    )}
                  </AccordionDetails>
                  <AccordionDetails>
                    {Boolean(userScopeData.community_scope) && (
                      <MenuListItem
                        as={NavLink}
                        submenu="true"
                        onClick={handleCloseMenu}
                        exact
                        to="/community/referral"
                      >
                        REFERRAL
                      </MenuListItem>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
              {userScopeData.event_scope && (
                <MenuListItem
                  as={NavLink}
                  onClick={handleCloseMenu}
                  exact
                  to="/event"
                >
                  EVENT
                </MenuListItem>
              )}
              {userScopeData.admin_scope && (
                <MenuListItem
                  as={NavLink}
                  onClick={handleCloseMenu}
                  exact
                  to="/banner"
                >
                  BANNER
                </MenuListItem>
              )}
              {(
                <MenuListItem
                  as={NavLink}
                  onClick={handleCloseMenu}
                  exact
                  to="/fd"
                >
                  FIXED DEPOSIT
                </MenuListItem>
              )}
              {Boolean(
                userScopeData.investment_scope ||
                  userScopeData.investment_user_mandate_scope
              ) && (
                <Accordion
                  expanded={expanded === "investment"}
                  onChange={handleChange("investment")}
                >
                  <AccordionSummary aria-controls="investment" id="investment">
                    <MenuListItem as="div">INVESTMENT</MenuListItem>
                  </AccordionSummary>

                  <AccordionDetails>
                    {Boolean(userScopeData.investment_scope) && (
                      <MenuListItem
                        as={NavLink}
                        submenu="true"
                        onClick={handleCloseMenu}
                        exact
                        to="/investment/user-holding"
                      >
                        USER HOLDING
                      </MenuListItem>
                    )}
                  </AccordionDetails>
                  <AccordionDetails>
                    {Boolean(userScopeData.investment_scope) && (
                      <MenuListItem
                        as={NavLink}
                        submenu="true"
                        onClick={handleCloseMenu}
                        exact
                        to="/investment/model-portfolio"
                      >
                        MODEL PORTFOLIO
                      </MenuListItem>
                    )}
                  </AccordionDetails>
                  <AccordionDetails>
                    {Boolean(userScopeData.investment_scope) && (
                      <MenuListItem
                        as={NavLink}
                        submenu="true"
                        onClick={handleCloseMenu}
                        exact
                        to="/investment/order"
                      >
                        MF ORDER
                      </MenuListItem>
                    )}
                  </AccordionDetails>
                  <AccordionDetails>
                    {Boolean(userScopeData.investment_user_mandate_scope) && (
                      <MenuListItem
                        as={NavLink}
                        submenu="true"
                        onClick={handleCloseMenu}
                        exact
                        to="/investment/user-mandate"
                      >
                        USER MANDATE
                      </MenuListItem>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
              {Boolean(userScopeData.users_scope) && (
                <Accordion
                  expanded={expanded === "users"}
                  onChange={handleChange("users")}
                >
                  <AccordionSummary aria-controls="users" id="users">
                    <MenuListItem as="div">USER</MenuListItem>
                  </AccordionSummary>

                  <AccordionDetails>
                    {Boolean(userScopeData.users_scope) && (
                      <MenuListItem
                        as={NavLink}
                        submenu="true"
                        onClick={handleCloseMenu}
                        exact
                        to="/users/app-user"
                      >
                        APP USERS
                      </MenuListItem>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
              {Boolean(userScopeData.updates_scope) && (
                <Accordion
                  expanded={expanded === "updates"}
                  onChange={handleChange("updates")}
                >
                  <AccordionSummary aria-controls="updates" id="updates">
                    <MenuListItem as="div">UPDATE</MenuListItem>
                  </AccordionSummary>

                  <AccordionDetails>
                    {Boolean(userScopeData.updates_scope) && (
                      <MenuListItem
                        as={NavLink}
                        submenu="true"
                        onClick={handleCloseMenu}
                        exact
                        to="/updates/general"
                      >
                        GENERAL
                      </MenuListItem>
                    )}
                  </AccordionDetails>
                  <AccordionDetails>
                    {Boolean(userScopeData.updates_scope) && (
                      <MenuListItem
                        as={NavLink}
                        submenu="true"
                        onClick={handleCloseMenu}
                        exact
                        to="/updates/sip"
                      >
                        SIP
                      </MenuListItem>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          )}
        </MenuList>
      </NavMenu>
    </>
  );
};

export default withRouter(NavBar);
