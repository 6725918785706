/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Img,
    Suggestion,
    Text,
    TextWrapper,
} from './styles';

const SuggestionItem = ({ entry }) => {
    const defaultImageUrl = 'https://d35u5jwboagvu7.cloudfront.net/default-profile-image.svg';
    const [profilePic, setProfilePic] = useState(defaultImageUrl);

    useEffect(() => {
        const parsedProfile = JSON.parse(entry?.profile_picture);
        const profilePicArray = parsedProfile?.photo;
        const profilePicUrl = Array.isArray(profilePicArray)
                    ? profilePicArray?.[0]?.presigned_url : defaultImageUrl;
        setProfilePic(profilePicUrl);
        // eslint-disable-next-line
    }, [entry]);

    return (
        <Suggestion>
            <Img
               src={profilePic}
               onError={() => {
                    setProfilePic(defaultImageUrl);
               }}
            />
            <TextWrapper>
                <Text>
                    {entry.display.split('_')
                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ')}
                </Text>
            </TextWrapper>
        </Suggestion>
    );
};

SuggestionItem.propTypes = {
    entry: PropTypes.shape({}).isRequired,
};

export default SuggestionItem;
