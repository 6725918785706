import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Tag } from 'antd';
import Avatar from '@material-ui/core/Avatar';

export const FilterGrid = styled(Grid)`
    background-color: ${(props) => props.theme.background};
    margin-top: 0.5rem;
    margin-bottom: 4.5rem;

    @media (max-width: 767px) {
        margin-bottom: 1rem;
    }
`;
export const FilterTitleWrapper = styled.div`
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center !important;
    margin: 1rem 0 2rem 0;
    font-family: 'Titillium Web';
    border-bottom: 1px dashed ${(props) => props.theme.text};
    padding-bottom: 2rem;
    color: ${(props) => props.theme.text};
    @media (max-width: 1024px) {
        font-size: 1.4rem;
    }
`;

export const IconContainer = styled.div`
    font-size: 1.5rem;
`;

export const StyledButton = styled.button`
    background: ${(props) => props.theme.pinklogo};
    color: ${(props) => props.theme.light};
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    outline: none;
    border: 2px solid ${(props) => props.theme.pinklogo};
    border-radius: 3px;
    float: right;
`;

export const StyledTag = styled(Tag)`
    color: ${(p) => p.theme.text};
    background: ${(p) => p.theme.background};
`;

export const StyledAvatar = styled(Avatar)``;
export const UserInfoWrapper = styled.div`
    ${'' /* background: red; */}
    display: inline-flex;
    align-items: center;
    margin-top: 20px;
    background: white;
    padding: 2px 5px;
    padding-right: 2rem;
    border-radius: 5px;
`;
export const UserSection = styled.div`
    margin-left: 1rem;
`;
export const UserDescription = styled.div`
    display: flex;

`;
export const UserName = styled.p`
    font-size: 1.2rem;
    margin-bottom: 5px;
`;
export const UserEmail = styled.span``;
export const UserMobile = styled.span``;

export const SchemePortfolio = styled.div`
    .ant-table-thead > tr > th {
        color: ${(props) => props.theme.nestedTableText}!important;
        background-color: ${(props) => props.theme.nestedTableHead}!important;
    }
`;
