/* eslint-disable */
import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import PropTypes from 'prop-types';
import CommonLoader from '../Common/Loader';
import { GetQuestionDetialApi } from '../../api/questionsList';
import { DetailWrapper, Wrapper } from './styles';
import { getFormattedShortDate } from '../../util/helper';
import PostDetailFooter from '../PostDetailFooter';
import PostDetailHeader from '../PostDetailHeader';
import QuestionDetailBody from '../QuestionDetailBody';
import isEmpty from 'lodash/isEmpty';
import {getImageUrl} from '../../util/custom';


    
const QuestionDetail = (props) => {
    const { pageProps } = props;
    const { id: currentPostId } = ((pageProps || {}).match || {}).params || {};
    const [loading, setLoading] = useState(false);
    const [questionData, setQuestionData] = useState({
        authorName: '',
        profilePicture: '',
        content: '',
        images: '',
        videos: '',
        likeCount: '',
        commentCount: '',
        createdAt: '',
        bookMarkCount: '',
        postID: '',
        isModerationRequired: '',
        moderationReason: '',
    });
    const postType = 'Question';

    const GetPostDetail = () => {
        setLoading(true);
        const params = {
            id: currentPostId,
        };
        GetQuestionDetialApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setQuestionData({
                            postID: apiData.id,
                            authorName: (apiData.author || {}).name,
                            profilePicture:
                                (apiData.author || {}).profile_picture || {},
                            content: apiData.content,
                            images: getImageUrl(apiData.images),
                            likeCount: apiData.like_count,
                            commentCount: apiData.comment_count,
                            createdAt: getFormattedShortDate(
                                apiData.created_at
                            ),
                            bookMarkCount: apiData.bookmark_count,
                            isModerationRequired: apiData.is_moderation_required,
                            moderationReason: apiData.moderation_reason,
                        });
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    useEffect(() => {
        GetPostDetail();
    }, [pageProps]);

    return (
        <Wrapper>
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />
            <DetailWrapper>
                <PostDetailHeader
                    postID={questionData.postID}
                    postType={postType}
                    authorName={questionData.authorName}
                    authorImageURL={questionData.profilePicture}
                    createdAt={questionData.createdAt}
                    loading={loading}
                />
                <QuestionDetailBody
                    postID={questionData.postID}
                    postType={postType}
                    content={questionData.content}
                    contentImageURL={questionData.images}
                    loading={loading && isEmpty(questionData)}
                />
                <PostDetailFooter
                    postID={questionData.postID}
                    postType={postType}
                    likeCount={questionData.likeCount}
                    bookMarkCount={questionData.bookMarkCount}
                    commentCount={questionData.commentCount}
                    loading={loading}
                    fetchGetPostDetail={GetPostDetail}
                    isModerationRequired={questionData.isModerationRequired}
                    moderationReason={questionData.moderationReason}
                />
            </DetailWrapper>
        </Wrapper>
    );
};

QuestionDetail.propTypes = {
    pageProps: PropTypes.shape({}).isRequired,
};

export default QuestionDetail;
