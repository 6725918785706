/* eslint-disable */
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const Wrapper = styled.div`
    padding: 0.5rem;
    font-size: 1rem;
    padding-left: 3%;
`;

export const StyleTitle = styled.p`
    color: ${(props) => props.theme.text};
    font-style: bold;
    font-size: 1.5rem;
`;

export const SelectionBackground = styled.div`
    background: red;
    margin-top: 0.5rem;
    width: 100%;
`;

export const PollOptionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    z-index: 1;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-bottom: 1rem;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 2px 10px #6666;
    background: transparent;
    position: relative;
`;

export const StyledView = styled.div`
    width: 30px;
    height: 20px;
`;

export const PollOption = styled.div`
    flex: 1;
    color: #717c96;
    font-weight: 500;
`;

export const PollPercent = styled.div`
    color: #717c96;
    font-weight: 500;
`;
export const PollBackground = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => props.width || 0}%;
    background-color: ${(props) => (props.active ? '#ED147F' : '#ffebd0')};
    height: 100%;
    z-index: -1;
    border-radius: 5px;
`;
