/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import PublicHeader from '../component/PublicHeader';

const propTypes = {
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
        .isRequired,
    redirect: PropTypes.string,
    restricted: PropTypes.bool,
};

const defaultProps = {
    restricted: false,
    redirect: '/',
};

export const PublicRoute = ({
    component: Component,
    redirect: pathname,
    restricted,
    ...rest
}) => (
    <Route
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        render={(props) => (restricted ? (
                <Redirect to={{ pathname }} />
            ) : (
                <>
                    <PublicHeader />
                    <Component {...props} />
                </>
            ))}
    />
);

PublicRoute.propTypes = propTypes;
PublicRoute.defaultProps = defaultProps;

export default PublicRoute;
