/* eslint-disable implicit-arrow-linebreak */
import axios from './instance';
import {
    GetPostDetailApiURL,
    CreatePostApiURL,
    DeletePostApiURL,
    UpdatePostApiURL,
} from './apiConfig';

export const GetPostDetailApi = (params) =>
    axios.get(GetPostDetailApiURL, { params });

export const CreatePostApi = (params) => axios.post(CreatePostApiURL, params);

export const DeletePostApi = (params) =>
    axios.delete(DeletePostApiURL, { data: { ...params } });

export const UpdatePostApi = (params) =>
    axios.put(UpdatePostApiURL, params, {
        headers: {
            'Content-Type': 'application/json',
        },
        // data: params,
    });
