import CryptoJS from 'react-native-crypto-js';
import { encryptionKey } from '../constant/secret';

export const setKey = (key, value) => {
    try {
        if (typeof value !== 'string') {
            // eslint-disable-next-line no-param-reassign
            value = JSON.stringify(value);
        }
        const encryptedValue = CryptoJS.AES.encrypt(
            value,
            encryptionKey,
        ).toString();
        localStorage.setItem(key, encryptedValue);
        return true;
    } catch (e) {
        // saving error
        return false;
    }
};

export const getKey = (key) => {
    try {
        const ciphertext = localStorage.getItem(key);
        const bytes = CryptoJS.AES.decrypt(ciphertext, encryptionKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    } catch (e) {
        // saving error
        return '';
    }
};

export const removeKey = (key) => {
    try {
        localStorage.removeItem(key);
        return true;
    } catch (exception) {
        return false;
    }
};

export const clearAllKeys = () => {
    localStorage.clear();
};

export const setToken = (tokenObj) => {
    if ('access_token' in tokenObj) {
        setKey('accessToken', tokenObj.access_token);
        setKey('refreshToken', tokenObj.refresh_token);
        return;
    }
    if ('accessToken' in tokenObj) {
        setKey('accessToken', tokenObj.accessToken);
        setKey('refreshToken', tokenObj.refreshToken);
    }
};

export const getAccessToken = () => getKey('accessToken');

export const getRefreshToken = () => getKey('refreshToken');

export const clearAccessRefreshToken = () => removeKey('accessToken') && removeKey('refreshToken');

export const setAdmin = (adminObj) => {
    setKey('userInfo', adminObj);
    setKey('firstName', adminObj.first_name);
    setKey('lastName', adminObj.last_name);
    setKey('middleName', adminObj.middle_name);
    setKey('empCode', adminObj.emp_code);
    setKey('gender', adminObj.gender);
    setKey('role', adminObj.role);
};

export const getAdmin = () => ({
    firstName: getKey('firstName'),
    lastName: getKey('lastName'),
    middleName: getKey('middleName'),
    empCode: getKey('empCode'),
    gender: getKey('gender'),
    role: getKey('role'),
});

export const clearAdmin = () => removeKey('firstName')
    && removeKey('lastName')
    && removeKey('middleName')
    && removeKey('empCode')
    && removeKey('gender')
    && removeKey('role');

export const getData = (key) => getKey(key);
export const clearData = (key) => removeKey(key);
export const setData = (key, val) => setKey(key, val);
