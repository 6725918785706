/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import swal from 'sweetalert';
import DeleteIcon from '@material-ui/icons/Delete';
import { truncate } from 'lodash';
import CommonTable from '../Common/Table';
import { FilterGrid, FilterTitleWrapper, IconContainer } from './styles';
import CommonLoader from '../Common/Loader';
import CommonDropdown from '../Common/Dropdown';
import Paginator from '../Common/Paginator';
import CommonDatePicker from '../Common/DatePicker';
import {
    formattedValue,
    snakeToUpperCase,
    dateFormating,
} from '../../util/helper';
import {
    GetUserMandateListApi,
    GetUserMandateFilterApi,
    DeleteUserMandateApi,
} from '../../api/userMandate';
import CopyToClipboard from '../Common/CopyToClipboard';

const UserMandate = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(1);

    const [filters, setFilters] = useState({
        page: currentPage,
        page_limit: pageSize,
        sort_by: 'updated_at',
        sort_type: 'desc',
        is_deleted: 'false',
    });

    const [userMandateFilters, SetUserMandateFilters] = useState({
        vendor_mandate_id: {
            key: 'vendor_mandate_id',
            data: [],
        },
        mandate_type: {
            key: 'type',
            data: [],
        },
        status: {
            key: 'status',
            data: [],
        },
        user: {
            key: 'user_id',
            data: [],
        },
    });

    const getUserMandateList = (params) => {
        setLoading(true);
        GetUserMandateListApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setData(apiData.results);
                        setCurrentPage(apiData.page);
                        setPageSize(apiData.page_limit);
                        setTotal(apiData.total_count);
                        setFilters(params);
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error',
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const getUserMandateFilters = () => {
        setLoading(true);
        GetUserMandateFilterApi()
            .then((res) => {
                let success = false;
                setLoading(false);
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        SetUserMandateFilters({
                            vendor_mandate_id: {
                                key: 'vendor_mandate_id',
                                data: apiData.vendor_mandate_id_list,
                            },
                            mandate_type: {
                                key: 'type',
                                data: apiData.mandate_type_list,
                            },
                            status: {
                                key: 'status',
                                data: apiData.status_list,
                            },
                            user: {
                                key: 'user_id',
                                data: apiData.user_id_list,
                            },
                        });
                    }
                }
                if (!success) {
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error',
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    const deleteEvent = (id) => {
        swal({
            title: 'Are you sure you want to delete this Mandate ?',
            text: '',
            icon: 'warning',
            buttons: true,
            dangerMode: true,
        }).then((success) => {
            if (success) {
                setLoading(true);
                const deleteParams = {
                    id,
                };
                DeleteUserMandateApi(deleteParams)
                    .then((res) => {
                        let apiSuccess = false;
                        setLoading(false);
                        if (res.status === 200) {
                            if ((res.data || {}).success) {
                                apiSuccess = true;
                                getUserMandateList(filters);
                                swal(
                                    'success!',
                                    'Successfully Deleted Mandate',
                                    'success',
                                );
                            }
                        }
                        if (!apiSuccess) {
                            setLoading(false);
                            swal(
                                'Oops!',
                                ((res.data || {}).data || {}).error_desc,
                                'error',
                            );
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        let errorMessage = 'Failed to delete Mandate';
                        if (error.response) {
                            if (error.response.status === 400) {
                                errorMessage = (error.response.data || {})
                                    .error_desc;
                            }
                        }
                        swal('Oops!', errorMessage, 'error');
                    });
            }
        });
    };

    const tableColumns = [
        {
            title: 'Mandate ID',
            dataIndex: 'vendor_mandate_id',
            key: 'vendor_mandate_id',
            align: 'center',
            render: (value) => <div>{formattedValue(value)}</div>,
        },
        {
            title: 'UCC',
            dataIndex: 'user_details',
            key: 'user_details',
            align: 'center',
            render: (value) => <div>{formattedValue((value || {}).ucc)}</div>,
        },
        {
            title: 'ARCCC',
            dataIndex: 'user_details',
            key: 'user_details',
            align: 'center',
            render: (value) => <div>{formattedValue((value || {}).arccc)}</div>,
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            align: 'center',
            render: (value) => (
                <div>{snakeToUpperCase(formattedValue(value))}</div>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (status) => (
                <div>{snakeToUpperCase(formattedValue(status))}</div>
            ),
        },
        {
            title: 'Registration Date',
            dataIndex: 'registration_date',
            key: 'registration_date',
            textWrap: 'word-break',
            width: 110,
            align: 'center',
            render: (value) => <p>{dateFormating(value)}</p>,
        },
        {
            title: 'Upload Date',
            dataIndex: 'upload_date',
            key: 'upload_date',
            textWrap: 'word-break',
            width: 110,
            align: 'center',
            render: (text, item) => (
                <p>
                    {item.status === 'approved' || item.status === 'rejected'
                        ? dateFormating(text)
                        : '-'}
                </p>
            ),
        },
        {
            title: 'Approval Date',
            dataIndex: 'approval_date',
            key: 'approval_date',
            textWrap: 'word-break',
            width: 110,
            align: 'center',
            render: (text, item) => (
                <p>{item.status === 'approved' ? dateFormating(text) : '-'}</p>
            ),
        },
        {
            title: 'Created AT',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 110,
            align: 'center',
            render: (value) => <p>{dateFormating(value)}</p>,
        },
        {
            title: 'Updated AT',
            dataIndex: 'updated_at',
            key: 'updated_at',
            width: 110,
            align: 'center',
            render: (value) => <p>{dateFormating(value)}</p>,
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            textWrap: 'word-break',
            width: 150,
            render: (value) => (
                <div>
                    {truncate(formattedValue(value), {
                        length: 125,
                        separator: '...',
                    })}
                </div>
            ),
        },
        {
            title: 'Form URL',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 10,
            render: (text, item) => (
                <CopyToClipboard
                    content={
                        item.type === 'nach'
                            ? item.nach_form_url
                            : item.emandate_url
                    }
                />
            ),
        },
    ];

    if (!JSON.parse(filters.is_deleted || false)) {
        tableColumns.push({
            title: 'Delete',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            render: (text, item) => (item.is_deleted === false ? (
                    <IconContainer>
                        <DeleteIcon onClick={() => deleteEvent(item.id)} />
                    </IconContainer>
                ) : null),
        });
    }

    const sortTypeList = {
        key: 'sort_type',
        data: [
            {
                id: 'asc',
                title: 'Asc',
                selected: false,
            },
            {
                id: 'desc',
                title: 'Desc',
                selected: true,
            },
        ],
    };

    const sortByList = {
        key: 'sort_by',
        data: [
            {
                id: 'id',
                title: 'id',
                selected: false,
            },
            {
                id: 'created_at',
                title: 'created_at',
                selected: false,
            },
            {
                id: 'updated_at',
                title: 'updated_at',
                selected: true,
            },
            {
                id: 'status',
                title: 'status',
                selected: false,
            },
            {
                id: 'approval_date',
                title: 'Approval Date',
                selected: false,
            },
            {
                id: 'registration_date',
                title: 'Registration Date',
                selected: false,
            },
            {
                id: 'upload_date',
                title: 'Upload Date',
                selected: false,
            },
        ],
    };

    useEffect(() => {
        filters.page = currentPage;
        filters.page_limit = pageSize;
        getUserMandateList(filters);
        getUserMandateFilters();
    }, [currentPage, pageSize]);

    const handleFilterChange = (filters) => {
        getUserMandateList(filters);
    };

    const IsDeleted = {
        key: 'is_deleted',
        data: [
            {
                id: 'true',
                title: 'True',
                selected: false,
            },
            {
                id: 'false',
                title: 'False',
                selected: true,
            },
        ],
    };

    return (
        <Grid container spacing={2}>
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />
            <Grid
                container
                spacing={2}
                style={{
                    paddingRight: '1rem',
                    paddingLeft: '1rem',
                    paddingBottom: '1rem',
                    paddingTop: '2rem',
                }}
            >
                <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
                    <FilterTitleWrapper>Filters</FilterTitleWrapper>
                    <CommonDropdown
                        list={IsDeleted}
                        title="Is Deleted"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={sortByList}
                        title="Sort By"
                        searchable={false}
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDropdown
                        list={sortTypeList}
                        title="Sort Type"
                        searchable={false}
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    {userMandateFilters.status.data.length > 0 && (
                        <CommonDropdown
                            list={userMandateFilters.status}
                            title="Status"
                            searchable
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}
                    {userMandateFilters.mandate_type.data.length > 0 && (
                        <CommonDropdown
                            list={userMandateFilters.mandate_type}
                            title="Type"
                            searchable
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}
                    {userMandateFilters.vendor_mandate_id.data.length > 0 && (
                        <CommonDropdown
                            list={userMandateFilters.vendor_mandate_id}
                            title="Mandate ID"
                            searchable
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}
                    {userMandateFilters.user.data.length > 0 && (
                        <CommonDropdown
                            list={userMandateFilters.user}
                            title="UCC"
                            titleKey="ucc"
                            searchable
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}
                    {userMandateFilters.user.data.length > 0 && (
                        <CommonDropdown
                            list={userMandateFilters.user}
                            title="ARCCC"
                            titleKey="arccc"
                            searchable
                            onChange={(filters) => handleFilterChange(filters)}
                            filters={filters}
                        />
                    )}
                    <CommonDatePicker
                        title="Created At LTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="created_at_lte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Created At GTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="created_at_gte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Updated At LTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="updated_at_lte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Updated At GTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="updated_at_gte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Approval Date LTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="approval_date_lte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Approval Date GTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="approval_date_gte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Registration Date LTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="registration_date_lte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Registration Date GTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="registration_date_gte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Upload Date LTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="upload_date_lte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                    <CommonDatePicker
                        title="Upload Date GTE"
                        type="inline"
                        dateFormat="dd/MM/yyyy"
                        filterKey="upload_date_gte"
                        onChange={(filters) => handleFilterChange(filters)}
                        filters={filters}
                    />
                </FilterGrid>
                <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                    <CommonTable
                        data={data}
                        columns={tableColumns}
                        pagination={false}
                        scroll={{ x: 'overflow' }}
                    />
                    <Paginator
                        totalRecords={total}
                        pageLimit={pageSize}
                        pageNeighbours={2}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UserMandate;
