import styled from 'styled-components';
import { Skeleton } from 'antd';

export const Wrapper = styled.div`
    margin: 1rem auto;
`;

export const ImageWrapper = styled.img`
    display: block;
    max-width: 100%;
    margin: auto;
    cursor: pointer;
    object-fit: fill;
    object-position: center;
    padding: 0 1.5rem;
    max-height: 20rem;
`;

export const StlyedSkeleton = styled(Skeleton)`
    padding: 0.5rem 2rem;
`;
