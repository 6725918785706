import React from 'react';
import { withRouter } from 'react-router-dom';
import Reward from '../../component/Reward';
import { Wrapper } from './styles';

const RewardPage = () => (
    <Wrapper>
        <Reward />
    </Wrapper>
);

export default withRouter(RewardPage);
