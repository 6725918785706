/* eslint-disable no-unused-vars */
import axios from './instance';
import { GetCommentListApiURL, DeleteCommentApiURL } from './apiConfig';

/* eslint-disable implicit-arrow-linebreak */
export const GetCommentListApi = (params) =>
    axios.get(GetCommentListApiURL, { params });

export const DeleteCommentApi = (params) =>
    axios.delete(DeleteCommentApiURL, { data: { ...params } });
