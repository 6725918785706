import styled from 'styled-components';

export const ReadMoreText = styled.div`
    display: inline;
    width: 100%;
`;

export const ReadHide = styled.span`
    color: #e9137c;
    cursor: pointer;
`;
