import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Tag, Modal } from 'antd';
import Avatar from '@material-ui/core/Avatar';

export const FilterGrid = styled(Grid)`
    background-color: ${(props) => props.theme.background};
    margin-top: 0.5rem;
    margin-bottom: 4.5rem;

    @media (max-width: 767px) {
        margin-bottom: 1rem;
    }
`;

export const FilterTitleWrapper = styled.div`
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center !important;
    margin: 1rem 0 2rem 0;
    font-family: 'Titillium Web';
    border-bottom: 1px dashed ${(props) => props.theme.text};
    padding-bottom: 2rem;
    color: ${(props) => props.theme.text};
    @media (max-width: 1024px) {
        font-size: 1.4rem;
    }
`;

export const IconContainer = styled.div`
    cursor: pointer;
`;

export const StyledButton = styled.button`
    background: ${(props) => props.theme.pinklogo};
    color: ${(props) => props.theme.light};
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    outline: none;
    border: 2px solid ${(props) => props.theme.pinklogo};
    border-radius: 3px;
    float: right;
`;

export const StyledTag = styled(Tag)`
    color: ${(p) => p.theme.text};
    background: ${(p) => p.theme.background};
`;

export const StyledAvatar = styled(Avatar)`
    align: center;
`;

export const PortfolioInfoWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    margin-top: 20px;
    background: white;
    padding: 2px 5px;
    padding-right: 2rem;
    border-radius: 5px;
`;

export const PortfolioSection = styled.div`
    margin-left: 1rem;
    width:350px
`;

export const PortfolioDescription = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left:10px;
    padding-right:10px;
`;

export const PitchingDescription = styled.div`
    display: flex;
    padding-left:10px;
    padding-right:10px;
    color: ${(p) => p.theme.text};
`;

export const PortfolioName = styled.p`
    font-size: 1.2rem;
    margin-bottom: 5px;
    font-weight: 600;
    text-align: center !important;
    color: ${(p) => p.theme.text};
`;

export const PortfolioText = styled.span`
    margin-bottom: 10px;
    text-align: right !important;
    color: ${(p) => p.theme.text};
`;

export const PitchingText = styled.span`
    margin-bottom: 10px;    
`;

export const PortfolioLabel = styled.span`
    color: ${(props) => props.theme.pinklogo};
    font-weight: 600;
    margin-bottom: 10px;
`;

export const UserMobile = styled.span``;

export const SchemePortfolio = styled.div`
    .ant-table-thead > tr > th {
        color: ${(props) => props.theme.nestedTableText}!important;
        background-color: ${(props) => props.theme.nestedTableHead}!important;
    }
`;

export const StyleModal = styled(Modal)`
    .ant-modal-header {
        background: ${(props) => props.theme.dark};
        color: ${(props) => props.theme.dark};
    }

    .ant-modal-body {
        background: ${(props) => props.theme.dark};
    }

    .ant-modal-title {
        color: ${(props) => props.theme.text};
    }
    .ant-modal-footer {
        background: ${(props) => props.theme.dark};
    }
    .ant-modal-close-icon {
        color: ${(props) => props.theme.text};
    }

    .ant-modal .sc-AzgDb .jcGGxa {
        padding: 0px;
    }
`;

export const LabelInput = styled.div`
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 0.8rem;
    margin-top: 0.5rem;
    color: ${(props) => props.theme.text};
`;

export const ErrorText = styled.p`
    color: #ff0000;
    margin-bottom: 0;
`;

export const PortfolioTextLabel = styled.p`
    margin-bottom: 10px;
    line-height:25px;
    font-weight: 650;
    text-align: center !important;
    border-bottom: 1px dashed;    
    color: ${(p) => p.theme.text};
`;

export const ImageWrapper = styled.img`
    display: block;
    max-width: 100%;
    margin: auto;
    cursor: pointer;
    object-fit: fill;
    object-position: center;
    padding: 0 1.5rem;
    max-height: 20rem;
    background-color: ${(p) => p.theme.lightGray};
`;
