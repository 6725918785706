/* eslint-disable */
import React, { Fragment } from 'react';
import {startCase, map, isEmpty, head, size } from 'lodash';
import {
    LabelInput,
    ErrorText,
    BlockBox,
    StyledGrid,
    LabelInputItem,
} from './styles';
import Grid from '@material-ui/core/Grid';
import { Input } from 'antd';
// import ImagePreview from '../Common/ImagePreview';
import Image from '../Image';

const GoalCalculator = (props) => {
    const {
        calculatorState,
        onChange,
        errors,
    } = props;


    const updateMetaValue = (val, element, item) => {
        if (val.target) {
            val = val.target.value;
        }
        let currentVal = calculatorState;
        currentVal = {
            ...currentVal,
            metaData: {
                defaultValue: {
                    ...calculatorState.metaData.defaultValue,
                    [item]: {
                        ...calculatorState.metaData.defaultValue[item],
                        [element]: parseInt(val),
                    },
                },
            },
        };

        const metaInfo = currentVal.metaData.defaultValue;
        metaInfo[item][element] = parseInt(val);
        onChange({ ...currentVal });

    };


    const metaDataFun = () => {
        const { defaultValue } = calculatorState['metaData'];
        return (
            <StyledGrid container item xs={12} spacing={2}>
                {map(Object.keys(defaultValue), (item) => {
                    return (
                        <Fragment key={item}>
                            <Grid item xs={12} key={item}>
                                <LabelInputItem>
                                    {startCase(item)}
                                </LabelInputItem>
                            </Grid>
                            {map(Object.keys(defaultValue[item]), (element) => {
                                let metaInput = defaultValue[item][element];
                                if (typeof metaInput ==='string' && size(metaInput)>0){
                                    metaInput = parseInt(metaInput);
                                }
                                return (
                                    <Grid item xs={4} key={element}>
                                        <LabelInput>
                                            {startCase(element)}
                                        </LabelInput>
                                        <Input
                                            key={element}
                                            placeholder={startCase(element)}
                                            type="number"
                                            value={metaInput}
                                            onChange={(val) =>
                                                updateMetaValue(
                                                    val,
                                                    element,
                                                    item
                                                )
                                            }
                                        />
                                        {errors[item] &&
                                            errors[item][element] && (
                                                <ErrorText>
                                                    {errors[item][element]}
                                                </ErrorText>
                                            )}
                                    </Grid>
                                );
                            })}
                            {errors[item] && errors[item].main && (
                                <ErrorText>{errors[item].main}</ErrorText>
                            )}
                        </Fragment>
                    );
                })}
            </StyledGrid>
        );
    };


    const updateGoalCal = (val, item) => {
        if (!val) {
            return;
        }
        if (val.target) {
            val = val.target.value;
        }
        let currentVal = calculatorState;
        currentVal[item].value = val;
        onChange({ ...currentVal });
    };

    const renderInput = (item) => {
        return (
            <>
                <LabelInput>{startCase(item)}</LabelInput>
                <Input
                    key={item}
                    value={calculatorState[item].value}
                    placeholder={calculatorState[item].placeholder}
                    onChange={(val) => updateGoalCal(val, item)}
                    type={calculatorState[item].type || 'text'}
                />
                {errors[item] && <ErrorText>{errors[item]}</ErrorText>}
            </>
        );
    };

    const onFileChange = (event, item) => {
        let file = '';
        if (!event) {
            file = '';
        } else {
            file = event.target?.files[0];
        }
        let currentVal = calculatorState;
        currentVal[item].value = file;
        onChange({ ...currentVal });
    };

    const renderImage = (item) => {
        return (
            <>
                <LabelInput>Image</LabelInput>
                <Image
                    value={calculatorState[item].value}
                    onChange={(event) => onFileChange(event, item)}
                />
                {errors.image && (
                    <ErrorText>{errors.image}</ErrorText>
                )}
            </>
        );
    };



    return (
        <Grid container spacing={3}>
            {map(Object.keys(calculatorState), (item) => {
                if (item === 'metaData') {
                    return (
                        <Grid item xs={12} key={item}>
                            <BlockBox key={item}>{metaDataFun(item)}</BlockBox>
                        </Grid>
                    );
                }

                if (calculatorState[item].type === 'image') {
                    return <Grid item xs={12} key={item}>{renderImage(item)}</Grid>;
                }

                return (
                    <Grid
                        item
                        xs={calculatorState[item].grid ||  6}
                        key={item}
                    >
                        {renderInput(item)}
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default GoalCalculator;
