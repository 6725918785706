import styled from 'styled-components';
import { Input } from 'antd';
import Grid from '@material-ui/core/Grid';

export const Wrapper = styled.div`
    padding-right: 0.1rem;
    padding-left: 0.1rem;
    background-color: ${(props) => props.theme.background};
    padding-top: 1.5rem;
`;

export const FilterTitleWrapper = styled.div`
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center !important;
    margin: 1rem 0 2rem 0;
    font-family: 'Titillium Web';
    border-bottom: 1px dashed ${(props) => props.theme.text};
    padding-bottom: 2rem;
    color: ${(props) => props.theme.text};
    @media (max-width: 1024px) {
        font-size: 1.4rem;
    }
`;

export const StyledWrapper = styled.div`
    text-align: center;
    padding: 1em;
`;

export const StyledButton = styled.button`
    background: ${(props) => props.theme.pinklogo};
    color: ${(props) => props.theme.light};
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    outline: none;
    border: 2px solid ${(props) => props.theme.pinklogo};
    border-radius: 3px;
    float: right;
    cursor: pointer;
`;

export const LabelInput = styled.label`
    font-size: 0.8rem;
    font-weight: 700;
    color: ${(props) => props.theme.text};
`;

export const SpanValidation = styled.span`
    color: ${(props) => props.theme.validationColor};
`;

export const StyledInput = styled(Input)`
    margin-top: 0.5rem;
`;

export const FilterGrid = styled(Grid)`
    background-color: ${(props) => props.theme.background};
    margin-top: 0.5rem;
    margin-bottom: 4.5rem;

    @media (max-width: 767px) {
        margin-bottom: 1rem;
    }
`;

export const IconWrapper = styled.div`
    display: inline-block;
`;

export const ContentStyle = styled.div`
    word-break: break-word;
    /* word-wrap:break-word, */
    /* wordBreak */
`;
