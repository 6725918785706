/* eslint-disable comma-dangle */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import head from 'lodash/head';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import { GetReportDetialApi } from '../../api/reported';
import CommonLoader from '../Common/Loader';
import { Wrapper } from './styles';
import { getFormattedShortDate } from '../../util/helper';
import PostDetailFooter from '../PostDetailFooter';
import PostDetailHeader from '../PostDetailHeader';
import PostDetailBody from '../PostDetailBody';

const PostDetailWrapper = styled.div`
    width: 100%;
    margin: auto;
    border-radius: 0.3rem;
    padding-bottom: 1rem;
    background-color: ${(props) => props.theme.dark};
    border: 1px solid #e0e0e0;
`;

const ReportedDetail = (props) => {
    const [loading, setLoading] = useState(false);
    const [postData, setPostData] = useState({
        authorName: '',
        profilePicture: '',
        content: '',
        images: '',
        videos: '',
        likeCount: '',
        commentCount: '',
        createdAt: '',
        bookMarkCount: '',
        postID: '',
    });
    const { pageProps } = props;
    const postType = 'Post';

    const fetchGetPostDetail = (id) => {
        setLoading(true);
        const params = {
            id,
        };

        const getImages = (image) => {
            if (isEmpty(image)) {
                return '';
            }
            let photo = {};
            if ('photo' in image) {
                photo = image.photo;
            } else {
                photo = (head(Object.values(image)) || {}).photo;
            }
            if (size(photo) && photo[0].url) {
                return photo[0].url;
            }
            return '';
        };

        GetReportDetialApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data.data || {}).content || {};
                        setPostData({
                            postID: apiData.id,
                            authorName: (apiData.author || {}).name,
                            profilePicture: (
                                (apiData.author || {}).profile_picture || {}
                            ).presigned_url,
                            content: apiData.content,
                            images: getImages(apiData.images),
                            likeCount: apiData.like_count,
                            commentCount: apiData.comment_count,
                            createdAt: getFormattedShortDate(
                                apiData.created_at
                            ),
                            bookMarkCount: apiData.bookmark_count,
                        });
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    errorMessage = (error.response.data || {}).error_desc;
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    useEffect(() => {
        const { id } = ((pageProps || {}).match || {}).params || {};
        fetchGetPostDetail(id);
    }, [pageProps]);

    return (
        <Wrapper>
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />
            <PostDetailWrapper>
                <PostDetailHeader
                    postID={postData.postID}
                    postType={postType}
                    authorName={postData.authorName}
                    authorImageURL={postData.profilePicture}
                    createdAt={postData.createdAt}
                    loading={loading}
                />
                <PostDetailBody
                    postID={postData.postID}
                    postType={postType}
                    content={postData.content}
                    contentImageURL={postData.images}
                    loading={loading}
                />
                <PostDetailFooter
                    postID={postData.postID}
                    postType={postType}
                    likeCount={postData.likeCount}
                    bookMarkCount={postData.bookMarkCount}
                    commentCount={postData.commentCount}
                    loading={loading}
                />
            </PostDetailWrapper>
        </Wrapper>
    );
};

ReportedDetail.propTypes = {
    pageProps: PropTypes.shape({}).isRequired,
};

export default ReportedDetail;
