import React from "react";
import { withRouter } from "react-router-dom";
import Banner from "../../component/Banner";
import { Wrapper } from "./styles";

const BannerPage = () => (
  <Wrapper>
    <Banner />
  </Wrapper>
);

export default withRouter(BannerPage);
