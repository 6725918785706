import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { Modal } from 'antd';

export const FilterGrid = styled(Grid)`
    background-color: ${(props) => props.theme.background};
    margin-top: 0.5rem;
    margin-bottom: 4.5rem;

    @media (max-width: 767px) {
        margin-bottom: 1rem;
    }
`;

export const FilterTitleWrapper = styled.div`
    font-weight: 700;
    font-size: 1.5rem;
    text-align: center !important;
    margin: 1rem 0 2rem 0;
    font-family: 'Titillium Web';
    border-bottom: 1px dashed ${(props) => props.theme.text};
    padding-bottom: 2rem;
    color: ${(props) => props.theme.text};
    @media (max-width: 1024px) {
        font-size: 1.4rem;
    }
`;

export const StyledButton = styled.button`
    background: ${(props) => props.theme.pinklogo};
    color: ${(props) => props.theme.light};
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    outline: none;
    border: 2px solid ${(props) => props.theme.pinklogo};
    border-radius: 3px;
    float: right;
`;
export const IconContainer = styled.div`
    font-size: 1.5rem;
`;

export const StyleModal = styled(Modal)`
    .ant-modal-header {
        background: ${(props) => props.theme.dark};
        color: ${(props) => props.theme.dark};
    }

    .ant-modal-body {
        background: ${(props) => props.theme.dark};
    }

    .ant-modal-title {
        color: ${(props) => props.theme.text};
    }
    .ant-modal-footer {
        background: ${(props) => props.theme.dark};
    }
    .ant-modal-close-icon {
        color: ${(props) => props.theme.text};
    }

    .ant-modal .sc-AzgDb .jcGGxa {
        padding: 0px;
    }
`;

export const LabelInput = styled.div`
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 0.2rem;
    ${'' /* margin-top: 1.2rem; */}
    color: ${(props) => props.theme.text};
`;

export const LabelInputItem = styled.div`
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 0rem;
    margin-top: 0rem;
    padding: 0rem;
    color: ${(props) => props.theme.text};
`;

export const StyledLabel = styled.label`
    color: ${(props) => props.theme.text};
`;

export const IamgeInput = styled.input`
    color: ${(props) => props.theme.text};
    display: inline-block;
    margin-top: 1rem;
`;

export const ErrorText = styled.p`
    color: #ff0000;
    margin-bottom: 0;
    /* flex-flow: row-reverse wrap; */
`;

export const BlockBox = styled.div`
    display: flex;
    /* flex-flow: row-reverse wrap; */
    flex-direction: row;
    margin-top: 2rem;
`;

export const ImageInput = styled.input`
    color: ${(props) => props.theme.text};
    display: inline-block;
`;

export const Parent = styled.div`
    display: flex;
`;

export const StyledGrid = styled(Grid)`
    margin: 20px 0px;
`;
