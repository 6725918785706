export const PostStatusList = {
    key: 'status',
    data: [
        {
            id: 'draft',
            title: 'Draft',
            selected: false,
        },
        {
            id: 'review_pending',
            title: 'Review Pending',
            selected: false,
        },
        {
            id: 'rejected',
            title: 'Rejected',
            selected: false,
        },
        {
            id: 'published',
            title: 'Published',
            selected: true,
        },
    ],
};

export const CreateStatusList = {
    key: 'status',
    data: [
        {
            id: 'draft',
            title: 'Draft',
            selected: false,
        },
        {
            id: 'published',
            title: 'Published',
            selected: true,
        },
    ],
};

export const UpdateStatusList = {
    key: 'status',
    data: [
        {
            id: 'rejected',
            title: 'Rejected',
            selected: false,
        },
        {
            id: 'published',
            title: 'Published',
            selected: true,
        },
        {
            id: 'draft',
            title: 'Draft',
            selected: false,
        },
    ],
};
