/* eslint-disable */
/* eslint-disable import/prefer-default-export */
import {
    GetAppUsersListApiURL,
    ActiveUserApiURL,
    DeactiveUserApiURL,
    GetAppUserFiltersApiURL,
} from './apiConfig';
import axios from './instance';

export const GetAppUsersListApi = (params) =>
    axios.get(GetAppUsersListApiURL, { params });

export const ActiveUserApi = (params) =>
    axios.put(ActiveUserApiURL, params, {
        headers: {
            'Content-Type': 'application/json',
        },
        // data: params,
    });

export const DeactiveUserApi = (params) =>
    axios.put(DeactiveUserApiURL, params, {
        headers: {
            'Content-Type': 'application/json',
        },
        // data: params,
    });

export const GetAppUserFiltersApi = (params) =>
    axios.get(GetAppUserFiltersApiURL, { params });
