import React from 'react';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';
import { Tooltip } from 'antd';
import { RedFlagIcon, GreenFlagIcon } from './styles';

const IconTooltip = (props) => {
    const {
        content, position, icon, iconSize,
    } = props;

    const renderIcon = (iconName) => {
        if (iconName === 'info') {
            return <InfoIcon fontSize={iconSize} />;
        }
        if (iconName === 'redFlag') {
            return <RedFlagIcon fontSize={iconSize} />;
        }
        if (iconName === 'greenFlag') {
            return <GreenFlagIcon fontSize={iconSize} />;
        }
        return <div />;
    };

    return (
        <Tooltip title={content} placement={position}>
            {renderIcon(icon)}
        </Tooltip>
    );
};

IconTooltip.propTypes = {
    content: PropTypes.string.isRequired,
    position: PropTypes.string,
    icon: PropTypes.string.isRequired,
    iconSize: PropTypes.string,
};

IconTooltip.defaultProps = {
    position: 'top',
    iconSize: 'medium',
};

export default IconTooltip;
