import React from 'react';
import { withRouter } from 'react-router-dom';
import AppUsers from '../../component/AppUsers';
import { Wrapper } from './styles';

const AppUsersPage = () => (
    <Wrapper>
        <AppUsers />
    </Wrapper>
);

export default withRouter(AppUsersPage);
