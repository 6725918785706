import {
  GetReferralMobileNumberListURL,
  GetReferralRefreeIdListURL,
  GetReferralRewardNameListURL,
  GetReferralURL,
} from "./apiConfig";
import axios from "./instance";

export const fetchReferralList = (params) => {
  return axios.get(GetReferralURL, { params });
};

export const UpdateReferral = (params) => {
  return axios.put(`${GetReferralURL}/${params.id}`, params.params);
};

export const FetchReferralMobileNumberList = () => {
  return axios.get(`${GetReferralMobileNumberListURL}`);
};

export const FetchReferralRewrdNames = () => {
  return axios.get(`${GetReferralRewardNameListURL}`);
};

export const FetchReferralReferreeId = () => {
  return axios.get(`${GetReferralRefreeIdListURL}`);
};
