/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';

import CommonTable from '../Common/Table';
import CommonLoader from '../Common/Loader';
import {
    GetHoldingUserDetialApi,
    GetRefreshUserHoldingsApi,
} from '../../api/getHoldingList';
import {
    FilterGrid,
    FilterTitleWrapper,
    StyledButton,
    UserInfoWrapper,
    StyledAvatar,
    UserSection,
    UserName,
    UserDescription,
    UserEmail,
    UserMobile,
    SchemePortfolio,
} from './styles';
import { map } from 'lodash';
import { dateFormating } from '../../util/helper';
import { getUserProfilePicture } from '../../util/custom';
import { PlusCircleTwoTone, MinusCircleTwoTone } from '@ant-design/icons';

const UserHoldingDetail = (props) => {
    const { pageProps } = props;
    const [data, setData] = useState([]);
    const [display_name, setDisplayName] = useState('');
    const [userInfo, setUserInfo] = useState('');
    const [loading, setLoading] = useState(true);

    const GetHoldingUserDetial = (id) => {
        setLoading(true);
        const params = {
            user_id: id,
        };
        GetHoldingUserDetialApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        const userHoldingData = map(
                            apiData.holding_detail,
                            (holding) => {
                                return {
                                    // display_name: apiData.display_name,
                                    // mobile_number: apiData.mobile_number,
                                    // name: apiData.name,
                                    portfolio_holding:
                                        apiData.portfolio_holding,
                                    schemes: apiData.schemes,
                                    ...holding,
                                };
                            }
                        );
                        setData(userHoldingData);

                        setUserInfo({
                            name: apiData.display_name,
                            email: apiData.email,
                            mobile: apiData.mobile_number,
                            profilePicture: apiData.profile_picture,
                        });
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    useEffect(() => {
        const { id } = ((pageProps || {}).match || {}).params || {};
        if (id !== 'null') {
            GetHoldingUserDetial(id);
        }
    }, [pageProps]);

    const tableColumns = [
        // {
        //     title: 'ID',
        //     dataIndex: 'id',
        //     key: 'id',
        //     width: 10,
        //     align: 'center',
        // },
        // {
        //     title: 'User Name',
        //     dataIndex: 'display_name',
        //     key: 'display_name',
        //     align: 'center',
        // },
        // {
        //     title: 'Mobile Number',
        //     dataIndex: 'mobile_number',
        //     key: 'mobile_number',
        //     align: 'center',
        // },
        {
            title: 'Portfolio',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: 'Scheme',
            dataIndex: 'schemes',
            key: 'schemes',
            align: 'center',
            render: (item) => <p>-</p>,
            // render: (item) => (
            //     <>{item && item.map((val) => <p>{val.name}</p>)}</>
            // ),
        },
        {
            title: 'Current Value',
            dataIndex: 'portfolio_holding',
            key: 'portfolio_holding',
            align: 'center',
            render: (item) => <p>{item.current_value.toLocaleString('en')}</p>,
        },
        {
            title: 'Invested Value',
            dataIndex: 'portfolio_holding',
            key: 'portfolio_holding',
            align: 'center',
            render: (item) => <p>{item.invested_value.toLocaleString('en')}</p>,
        },
        {
            title: 'Gain Loss',
            dataIndex: 'portfolio_holding',
            key: 'portfolio_holding',
            align: 'center',
            render: (item) => (
                <p>{item.overall_gain_loss.toLocaleString('en')}</p>
            ),
        },
        {
            title: 'Gain Loss%',
            dataIndex: 'portfolio_holding',
            key: 'portfolio_holding',
            align: 'center',
            render: (item) => <p>{item.overall_gain_loss_percentage}%</p>,
        },
        {
            title: 'Effective Date',
            dataIndex: 'portfolio_holding',
            key: 'portfolio_holding',
            align: 'center',
            render: (item) => <p>{dateFormating(item.effective_date)}</p>,
        },
        {
            title: 'Level',
            dataIndex: 'level',
            key: 'level',
            align: 'center',
            render: (item) => <p>Portfolio</p>,
        },
    ];

    const userRefresh = ({ id }) => {
        setLoading(true);
        const params = {
            user_id: id,
        };
        GetRefreshUserHoldingsApi(params)
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const apiData = (res.data || {}).data || {};
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error'
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (error.response) {
                    if (error.response.status === 400) {
                        errorMessage = (error.response.data || {}).error_desc;
                    }
                }
                swal(errorMessage);
            });
    };

    // const expandable = {
    //     expandedRowRender: (record) => (

    //         (<p style={{ margin: 0 }}>{record.name}</p>)
    //     ),
    //     expandIcon: ({ expanded, onExpand, record }) =>
    //         expanded ? (
    //             <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
    //         ) : (
    //             <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
    //         ),
    // };
    const expandable = {
        expandedRowRender: (record) => {
            const schemes = record.schemes;
            // const displayName = record.display_name;
            for (let elem in schemes) {
                schemes[elem];
            }

            const columns = [
                // {
                //     title: 'ID',
                //     dataIndex: 'scheme_holding',
                //     key: 'scheme_holding',
                //     width: 10,
                //     align: 'center',
                //     render: (item) => <p>{item.user_id}</p>,
                // },
                // {
                //     title: 'User Name',
                //     dataIndex: 'name',
                //     key: 'name',
                //     width: 10,
                //     align: 'center',
                //     render: () => <p>{record.display_name}</p>,
                // },
                // {
                //     title: 'Mobile Number',
                //     dataIndex: 'name',
                //     key: 'name',
                //     width: 10,
                //     align: 'center',
                //     render: () => <p>{record.mobile_number}</p>,
                // },
                {
                    title: 'Portfolio',
                    dataIndex: 'name',
                    key: 'name',
                    align: 'center',
                    render: () => <p>{record.name}</p>,
                },
                {
                    title: 'Scheme',
                    dataIndex: 'name',
                    key: 'name',
                    align: 'center',
                    render: (item) => <p>{item}</p>,
                    // render: (item) => (
                    //     <>{item && item.map((val) => <p>{val.name}</p>)}</>
                    // ),
                },
                {
                    title: 'Current Value',
                    dataIndex: 'scheme_holding',
                    key: 'scheme_holding',
                    align: 'center',
                    render: (item) => <p>{item.current_value}</p>,
                },
                {
                    title: 'Invested Value',
                    dataIndex: 'scheme_holding',
                    key: 'scheme_holding',
                    align: 'center',
                    render: (item) => <p>{item.invested_value}</p>,
                },
                {
                    title: 'Gain Loss',
                    dataIndex: 'scheme_holding',
                    key: 'scheme_holding',
                    align: 'center',
                    render: (item) => <p>{item.overall_gain_loss}</p>,
                },
                {
                    title: 'Gain Loss %',
                    dataIndex: 'scheme_holding',
                    key: 'scheme_holding',
                    align: 'center',
                    render: (item) => (
                        <p>{item.overall_gain_loss_percentage}%</p>
                    ),
                },
                {
                    title: 'Effective Date',
                    dataIndex: 'scheme_holding',
                    key: 'scheme_holding',
                    align: 'center',
                    render: (item) => (
                        <p>{dateFormating(item.effective_date)}</p>
                    ),
                },
                {
                    title: 'Level',
                    dataIndex: 'scheme_holding',
                    key: 'scheme_holding',
                    align: 'center',
                    render: (item) => <p>{item.type}</p>,
                },

                // {
                //     title: 'Scheme',
                //     dataIndex: 'schemes',
                //     key: 'schemes',
                //     align: 'center',
                //     render: (item) => (
                //         <>{item && item.map((val) => <p>{val.name}</p>)}</>
                //     ),
                // },
            ];
            return (
                <SchemePortfolio>
                    <CommonTable
                        columns={columns}
                        data={schemes}
                        pagination={false}
                        rowKey="id"
                    />
                </SchemePortfolio>
            );
        },
        expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
                <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
            ) : (
                <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
            ),
    };

    return (
        <Grid container spacing={2}>
            <CommonLoader
                isLoading={loading}
                size="large"
                sentences={['please wait ...']}
                type="pacman"
            />

            <FilterGrid item xs={12} sm={2} md={2} lg={2} xl={2}>
                <FilterTitleWrapper>Filters</FilterTitleWrapper>
            </FilterGrid>

            <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
                <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    style={{ marginBottom: '1rem' }}
                >
                    <Grid item xs={6}>
                        <UserInfoWrapper>
                            <StyledAvatar
                                src={getUserProfilePicture(
                                    userInfo.profilePicture
                                )}
                            />
                            <UserSection>
                                <UserName>{userInfo.name}</UserName>
                                <UserDescription>
                                    <UserEmail>{userInfo.email}</UserEmail>
                                </UserDescription>
                            </UserSection>
                        </UserInfoWrapper>
                    </Grid>
                    <Grid item xs={6}>
                        <StyledButton
                            onClick={() =>
                                userRefresh(
                                    ((pageProps || {}).match || {}).params || {}
                                )
                            }
                        >
                            Refresh
                        </StyledButton>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <CommonTable
                        rowKey={'id'}
                        data={data}
                        columns={tableColumns}
                        pagination={false}
                        scroll={{ x: 'overflow' }}
                        // expandable={{ expandedRowRender }}
                        expandable={expandable}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
export default UserHoldingDetail;
