
import {
    GetCalculatorListApiURL,
    CreateCalculatorApiURL,
    DeleteCalculatorApiURL,
    UpdateCalculatorApiURL,
} from './apiConfig';
import axios from './instance';

export const GetCalculatorListApi = (params) =>
    axios.get(GetCalculatorListApiURL, { params });

export const CreateCalculatorApi = (params) =>
    axios.post(CreateCalculatorApiURL, params);

export const DeleteCalculatorApi = (params) =>
    axios.delete(DeleteCalculatorApiURL, { data: { ...params } });

export const UpdateCalculatorApi = (params) =>
    axios.put(UpdateCalculatorApiURL, params);
