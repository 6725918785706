import React from "react";
import { withRouter } from "react-router-dom";
import { Wrapper } from "./styles";
import SipUpdate from "../../component/SipUpdate";
import Referral from "../../component/Referral";

const ReferralPage = () => (
  <Wrapper>
    <Referral />
  </Wrapper>
);

export default withRouter(ReferralPage);
