import React, { useCallback } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import PropTypes from 'prop-types';
import { debounce, snakeCase } from 'lodash';

import mentionStyle from './styles';
import { getMentionsListApi } from '../../api/mentionsData';
import SuggestionItem from '../SuggestionItem';

const MentionInput = (props) => {
    const {
        value,
        placeholder,
        onChange,
        style,
    } = props;

    const fetchMentionsList = useCallback(
        debounce((query, callback) => {
            if (query.length > 2) {
                getMentionsListApi({
                    search_key: query,
                })
                    .then((response) => {
                        if (response.status === 200
                            && Array.isArray(response.data?.data)) {
                            return response.data?.data.map((item) => {
                                const modifiedObj = {
                                    display: snakeCase(item.display_name),
                                    ...item,
                                };
                                delete modifiedObj.display_name;
                                return modifiedObj;
                            });
                        }

                        return [];
                    })
                    .then(callback);
            }
        }, 1000),
        [],
    );

    return (
        <MentionsInput
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            style={{ ...mentionStyle, ...style }}
        >
            <Mention
                trigger="@"
                data={fetchMentionsList}
                displayTransform={(id, display) => `@${snakeCase(display)}`}
                renderSuggestion={
                    (entry) => (
                        <SuggestionItem entry={entry} />
                    )
                }
            />
        </MentionsInput>
    );
};

MentionInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    style: PropTypes.shape({}),
};

MentionInput.defaultProps = {
    style: {},
};

export default MentionInput;
