// /* eslint-disable */
// /* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import {
    Wrapper,
    ImageWrapper,
    StlyedSkeleton,
    Title,
    ContentWrapper,
} from './styles';

const BlogDetailBody = (props) => {
    const { content, contentImageURL, loading } = props;

    return loading ? (
        <StlyedSkeleton paragraph={{ rows: 4 }} />
    ) : (
        <Wrapper>
            <Title>
                <ContentWrapper>{content}</ContentWrapper>
            </Title>
            {!isEmpty(contentImageURL) && (
                <ImageWrapper src={contentImageURL} />
            )}
        </Wrapper>
    );
};

BlogDetailBody.propTypes = {
    contentImageURL: PropTypes.string,
    content: PropTypes.string,
    loading: PropTypes.bool,
};

BlogDetailBody.defaultProps = {
    loading: false,
    contentImageURL: '',
    content: '',
};

export default BlogDetailBody;
