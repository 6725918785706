/* eslint-disable object-curly-newline */
import 'date-fns';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Title, BottomWrapper, StyledDatePicker } from './styles';

const CommonDatePicker = (props) => {
    const { title, type, onChange, dateFormat, filters, filterKey } = props;

    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
        if (date === null) {
            delete filters[filterKey];
            onChange(filters);
        } else if (date === undefined || date.toString() === 'Invalid Date') {
            // do nothing
        } else {
            filters[filterKey] = date;
            onChange(filters);
        }
    };

    return (
        <>
            <Title>{title}</Title>
            <StyledDatePicker
                clearable="true"
                format={dateFormat}
                margin="normal"
                variant={type}
                id="date-picker"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
            <BottomWrapper />
        </>
    );
};

CommonDatePicker.propTypes = {
    onChange: PropTypes.func,
    title: PropTypes.string,
    dateFormat: PropTypes.string,
    type: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    filters: PropTypes.object,
    filterKey: PropTypes.string.isRequired,
};

CommonDatePicker.defaultProps = {
    onChange: () => {},
    dateFormat: 'dd/MM/yyyy',
    title: '',
    type: 'inline',
    filters: {},
};

export default CommonDatePicker;
