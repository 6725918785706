import React, { useState, useEffect } from 'react';
import swal from 'sweetalert';
import { GetApiAnalyticsDashboardApi } from '../../api/apiAnalytics';
import { Wrapper } from './styles';
import CommonLoader from '../Common/Loader';

const ApiAnalytics = () => {
    const [dashboardURL, setDashboardURL] = useState('');
    const [loading, setLoading] = useState(false);

    const loadApiAnalyticsDashboard = () => {
        setLoading(true);
        GetApiAnalyticsDashboardApi()
            .then((res) => {
                let success = false;
                if (res.status === 200) {
                    if ((res.data || {}).success) {
                        success = true;
                        const data = (res.data || {}).data || {};
                        setDashboardURL(data.url);
                        setLoading(false);
                    }
                }
                if (!success) {
                    setLoading(false);
                    swal(
                        'Oops!',
                        ((res.data || {}).data || {}).error_desc,
                        'error',
                    );
                }
            })
            .catch((err) => {
                setLoading(false);
                let errorMessage = 'Failed to fetch data';
                if (err.response) {
                    errorMessage = (err.response.data || {}).error_desc;
                }
                swal('Oops!', errorMessage, 'error');
            });
    };

    useEffect(() => {
        loadApiAnalyticsDashboard();
        // eslint-disable-next-line
    }, []);

    return (
        <Wrapper>
            <>
                <CommonLoader
                    isLoading={loading}
                    size="large"
                    sentences={['please wait ...']}
                    type="pacman"
                />

                {dashboardURL.length > 0 && (
                    <iframe
                        id="api-analytics-iframe"
                        src={dashboardURL}
                        allow="fullscreen"
                        style={{ width: '100%', height: '100%' }}
                        title="API Analytics"
                    />
                )}
            </>
        </Wrapper>
    );
};

export default ApiAnalytics;
